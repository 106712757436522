import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../utility/requestObject";
import HeaderBlock from "../vehicle/VehicleHeaderBlock";
import documentDetails_ from "./wet-test-certification/documentDetails_";
import VerifiedBy from "../../../utility/verfifiedBy";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../utility/addInspectionName";
import Adddocs from "../../../utility/Adddocs";
import removePdf from "../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const InspectionVaporTightnessCertificateEdit = () => {
   const { taskOrderId,fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [statusInspection, setStatus] = useState("");
   var documentLists = [];
   const gall=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("greaterthan2499Gallons", isChecked?"1":"0");
   // sessionStorage.setItem("greaterthan2499Gallons",  $("input[name='greaterthan2499Gallons']:checked").val()==="1"?"1":"0");
  
}
const galltwonine=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("1500to2499Gallons", isChecked?"1":"0");
   // sessionStorage.setItem("1500to2499Gallons",  $("input[name='1500to2499Gallons']:checked").val()==="1"?"1":"0");
}
const gallthousand=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("1000to1499Gallons", isChecked?"1":"0");
   // sessionStorage.setItem("1000to1499Gallons",  $("input[name='1000to1499Gallons']:checked").val()==="1"?"1":"0");
}
const gallthousandnine=function(e)
{
   //alert($("input[name='lessthan999Gallons']:checked").val());
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("lessthan999Gallons", isChecked?"1":"0");
   // sessionStorage.setItem("lessthan999Gallons",  $("input[name='lessthan999Gallons']:checked").val()==="1"?"1":"0");
}
const overfiils=function(e)
{
 
   sessionStorage.setItem("overfiils",  $("input[name='overfill']:checked").val()==="1"?"1":"0");
   // sessionStorage.setItem("overfiils",  $("input[name='overfill']:checked").val()!=""?$("input[name='overfill']:checked").val():"");
   
}
const redirect=(page)=>
{

     navigate(page);
  
}
const optic=function(e)
{
 
   sessionStorage.setItem("optic",  $("input[name='optic']:checked").val()!=""?$("input[name='optic']:checked").val():"");
   if($("input[name='optic']:checked").val()=='2'){
      // alert("ddd");
      $('#otherField').show();
   }else{
      $('#otherField').hide();
   }
}

 $(document).on("keyup", "#otherData", function () {
   sessionStorage.setItem("otherData",  $("#otherData").val()!=""?$("#otherData").val():"");
 });

 $(document).on("keyup", "#locationTesting", function () {
   sessionStorage.setItem("location",  $("#locationTesting").val()!=""?$("#locationTesting").val():"");
 });

   const connect = function (e) {

      sessionStorage.setItem("connect", $("input[name='connect']:checked").val() === "1" ? "1" : "0");

   }
   const liquid = function (e) {

      sessionStorage.setItem("liquid", $("input[name='liquid']:checked").val() === "1" ? "1" : "0");

   }
   const compartments = function (e) {
      // alert($("input[name='compartments']:checked").val());
      sessionStorage.setItem("compartments", $("input[name='compartments']:checked").val() === "1" ? "1" : "0");

   }
   const stablization = function (e) {

      sessionStorage.setItem("stablization", $("input[name='stablization']:checked").val() === "1" ? "1" : "0");

   }
//    const location=function(e)
// {
 
//    sessionStorage.setItem("location",  $("input[name='location']:checked").val()==="1"?"1":"0");
   
// }

   const cove = function (e) {

      sessionStorage.setItem("cove", $("input[name='cove']:checked").val() === "1" ? "1" : "0");

   }
   const internally = function (e) {

      sessionStorage.setItem("internally", $("input[name='internally']:checked").val() === "1" ? "1" : "0");

   }
   const recovery = function (e) {

      sessionStorage.setItem("recovery", $("input[name='recovery']:checked").val() === "1" ? "1" : "0");

   }
   const pressure = function (e) {

      sessionStorage.setItem("pressure", $("input[name='pressure']:checked").val() === "1" ? "1" : "0");

   }
   const manometer = function (e) {

      sessionStorage.setItem("manometer", $("input[name='manometer']:checked").val() === "1" ? "1" : "0");

   }
   const loadOfNonVolatile = function (e) {

      sessionStorage.setItem("loadOfNonVolatile", $("input[name='loadOfNonVolatile']:checked").val() === "1" ? "1" : "0");

   }

   const steamCleaned = function (e) {

      sessionStorage.setItem("steamCleaned", $("input[name='steamCleaned']:checked").val() === "1" ? "1" : "0");

   }
   const purgeEachCompartmentWithAirFor20Minutes = function (e) {

      sessionStorage.setItem("purgeEachCompartmentWithAirFor20Minutes", $("input[name='purgeEachCompartmentWithAirFor20Minutes']:checked").val() === "1" ? "1" : "0");

   }
   const [roles, setRoles] = useState("");
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    useEffect(() => {
        setRoles(localStorage.getItem("Userroles"));
      $("#otherField").hide();
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId, inspectionId);

   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {
     
         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {

         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
         setinspectionTypeId(res.rows.inspectionType.id);
         $("#notes").val(res.rows ? res.rows.notes : "");
         $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
         setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
         documentLists.push(res.rows.documentList);
         sessionStorage.setItem("documentList",JSON.stringify(res.rows.documentList));
         setStatus(res.rows.status);
         if (res.rows) {
            if (res.rows.verifiedDate) {
               setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
         
         // alert(res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic);
         $('input[name="optic"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic + '"]').trigger("click");
         $('input[name="thermister"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.thermister + '"]').trigger("click");
         $('input[name="other"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.other + '"]').trigger("click");

         $('input[name="overfill"][value="' + res.rows.vaporTightnessCertification.overfillProtectionSystemRepaired + '"]').trigger("click");

         $("#otherData").val(res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.other);
         if(res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='2'){
            $("#otherField").show();
         }else{
            $("#otherField").hide();
         }


         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");

         $('input[name="connect"][value="' + res.rows.vaporTightnessCertification.otherProperties.connect + '"]').trigger("click");
         $('input[name="liquid"][value="' + res.rows.vaporTightnessCertification.otherProperties.liquid + '"]').trigger("click");
         $('input[name="compartments"][value="' + res.rows.vaporTightnessCertification.otherProperties.compartments + '"]').trigger("click");
         $('input[name="stablization"][value="' + res.rows.vaporTightnessCertification.otherProperties.stablization + '"]').trigger("click");
         // $('input[name="location"][value="' + res.rows.vaporTightnessCertification.otherProperties.location + '"]').trigger("click");
         $("#locationTesting").val(res.rows.vaporTightnessCertification.otherProperties.location);
         $('input[name="cove"][value="' + res.rows.vaporTightnessCertification.otherProperties.cove + '"]').trigger("click");
         $('input[name="internally"][value="' + res.rows.vaporTightnessCertification.otherProperties.internally + '"]').trigger("click");
         $('input[name="recovery"][value="' + res.rows.vaporTightnessCertification.otherProperties.recovery + '"]').trigger("click");
         $('input[name="pressure"][value="' + res.rows.vaporTightnessCertification.otherProperties.pressure + '"]').trigger("click");
         $('input[name="manometer"][value="' + res.rows.vaporTightnessCertification.otherProperties.manometer + '"]').trigger("click");

         $('input[name="loadOfNonVolatile"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.loadOfNonVolatile + '"]').trigger("click");
         $('input[name="steamCleaned"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.steamCleaned + '"]').trigger("click");
         $('input[name="purgeEachCompartmentWithAirFor20Minutes"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.purgeEachCompartmentWithAirFor20Minutes + '"]').trigger("click");
         // alert(res.rows.vaporTightnessCertification.tankCapacityInGallons.lessthan999Gallons);

         $('input[name="lessthan999Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.lessthan999Gallons + '"]').trigger("click");
         $('input[name="1000to1499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.g1000to1499Gallons + '"]').trigger("click");
         $('input[name="1500to2499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.g1500to2499Gallons + '"]').trigger("click");
         $('input[name="greaterthan2499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.greaterthan2499Gallons + '"]').trigger("click");
        
      });

      // setEndDate(inspectionDetails.verifiedDate);
   }

   $(document).ready(function () {

      $("input[name='run1_pi'],input[name='run1_pf'],input[name='run1_a'],input[name='run1_ti'],input[name='run1_tf'],input[name='run1_titf'],input[name='run2_pi'],input[name='run2_pf'],input[name='run2_a'],input[name='run2_ti'],input[name='run2_tf'],input[name='run2_titf'],input[name='run3_pi'],input[name='run3_pf'],input[name='run3_a'],input[name='run3_ti'],input[name='run3_tf'],input[name='run3_titf']").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
            return false;
         }
      });
   });

   $(document).ready(function () {
      $("input[name='run4_vi'],input[name='run4_ti'],input[name='run5_vi'],input[name='run5_ti'],input[name='run6_vi'],input[name='run6_ti']").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }
      });
   });

   $(document).ready(function () {
      $("input[name='water_pf'],input[name='water_pfpi'],input[name='water_ti'],input[name='water_tf'],input[name='water_titf']").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }
      });
   });


   const save = (status) => {

      // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());

      // console.log("results=="+JSON.stringify(inputList));

      // alert(sessionStorage.getItem("lessthan999Gallons")==null?"0":sessionStorage.getItem("lessthan999Gallons"));


      try {
         for (var i = 0; i < inputList.length; i++) {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension = "";
            var byteString = "";
            if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
               extension = "pdf";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
            } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
               extension = "jpg";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
               extension = "png";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
               extension = "word";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
            }
            else {

            }
             
            // if(JSON.parse(sessionStorage.getItem('documentList')).length>0)
            //  {
        
            //       var i;
            //       var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            //       for (i = 0; i < storedArray.length; i++) 
            //       {
              
            //                           documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension},{"id":storedArray[i].id,"name":storedArray[i].name,"filePath":storedArray[i].filePath, "title": storedArray[i].title,"extension":storedArray[i].extension});
            //       }
         
            //   }else
            //   {
            //                       documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
            //   }

           documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(), "extension": extension });
         }
      } catch (exception) {

         //  Swal.fire({  
         //      position: 'center',  
         //      icon: 'error',  
         //      title: "",  
         //      text:"Please attach document",
         //      showConfirmButton: true 

         //    }).then((result) => 
         //    {

         //          if(result.isConfirmed) 
         //          {


         //          }
         //    });
         //    return false;
         documentList.push();
      }
      try {
         if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

             var i;
             var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
             for (i = 0; i < storedArray.length; i++) {

                 documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
             }

         } else {

              documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
         }

     } catch (ex) {

     }

      if ($("#inspectedBy").val() === "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter inspectedBy",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }

      var section1Vapor =

      {
         "tankCapacityInGallons":
         {
            // "lessthan999Gallons": sessionStorage.getItem("lessthan999Gallons") == null ? "0" : sessionStorage.getItem("lessthan999Gallons"),
            // "g1000to1499Gallons": sessionStorage.getItem("1000to1499Gallons") == null ? "0" : sessionStorage.getItem("1000to1499Gallons"),
            // "g1500to2499Gallons": sessionStorage.getItem("1500to2499Gallons") == null ? "0" : sessionStorage.getItem("1500to2499Gallons"),
            // "greaterthan2499Gallons": sessionStorage.getItem("greaterthan2499Gallons") == null ? "0" : sessionStorage.getItem("greaterthan2499Gallons")
            
             "lessthan999Gallons": $("input[name='lessthan999Gallons']:checked").val()=="1"?"1":"0",
            "g1000to1499Gallons":$("input[name='1000to1499Gallons']:checked").val()=="1"?"1":"0",
            "g1500to2499Gallons": $("input[name='1500to2499Gallons']:checked").val()=="1"?"1":"0",
            "greaterthan2499Gallons":$("input[name='greaterthan2499Gallons']:checked").val()=="1"?"1":"0"
         },
         "typeOfOverProtectionSystem":
         {
            "optic": $("input[name='optic']:checked").val()!=""?$("input[name='optic']:checked").val():"",
            // "optic": sessionStorage.getItem("optic") == null ? "0" : sessionStorage.getItem("optic"),
            // "thermister": sessionStorage.getItem("thermister") == null ? "0" : sessionStorage.getItem("thermister"),
            // "other": sessionStorage.getItem("other") == null ? "0" : sessionStorage.getItem("other"),
            // "other": $("#otherData").val() == '' ? $("#otherData").val() == '' : '',
            // "other": sessionStorage.getItem("otherData") == null ? "" : sessionStorage.getItem("otherData"),
            "other": $("#otherData").val()!=''?$("#otherData").val():''
         },
         "otherProperties":
         {
            // "optic": $("input[name='optic']:checked").val()!=""?$("input[name='optic']:checked").val():"",

            // "connect": sessionStorage.getItem("connect") == null ? "0" : sessionStorage.getItem("connect"),
            // "liquid": sessionStorage.getItem("liquid") == null ? "0" : sessionStorage.getItem("liquid"),
            // "compartments": sessionStorage.getItem("compartments") == null ? "0" : sessionStorage.getItem("compartments"),
            // "stablization": sessionStorage.getItem("stablization") == null ? "0" : sessionStorage.getItem("stablization"),
            // "location": sessionStorage.getItem("location") == null ? "0" : sessionStorage.getItem("location"),
            // "cove": sessionStorage.getItem("cove") == null ? "0" : sessionStorage.getItem("cove"),
            // "internally": sessionStorage.getItem("internally") == null ? "0" : sessionStorage.getItem("internally"),
            // "recovery": sessionStorage.getItem("recovery") == null ? "0" : sessionStorage.getItem("recovery"),
            // "pressure": sessionStorage.getItem("pressure") == null ? "0" : sessionStorage.getItem("pressure"),
            // "manometer": sessionStorage.getItem("manometer") == null ? "0" : sessionStorage.getItem("manometer"),

            "connect": $("input[name='connect']:checked").val()!=""?$("input[name='connect']:checked").val():"",
            "liquid": $("input[name='liquid']:checked").val()!=""?$("input[name='liquid']:checked").val():"",
            "compartments": $("input[name='compartments']:checked").val()!=""?$("input[name='compartments']:checked").val():"",
            "stablization": $("input[name='stablization']:checked").val()!=""?$("input[name='stablization']:checked").val():"",
            // "location": $("input[name='location']:checked").val()!=""?$("input[name='location']:checked").val():"",
            "location": $("input[name='locationTesting']").val()!=""?$("input[name='locationTesting']").val():"",
            "cove": $("input[name='cove']:checked").val()!=""?$("input[name='cove']:checked").val():"",
            "internally": $("input[name='internally']:checked").val()!=""?$("input[name='internally']:checked").val():"",
            "recovery": $("input[name='recovery']:checked").val()!=""?$("input[name='recovery']:checked").val():"",
            "pressure": $("input[name='pressure']:checked").val()!=""?$("input[name='pressure']:checked").val():"",
            "manometer": $("input[name='manometer']:checked").val()!=""?$("input[name='manometer']:checked").val():"",
         },
         "overfillProtectionSystemRepaired": $("input[name='overfill']:checked").val()!=""?$("input[name='overfill']:checked").val():"",
         // "overfillProtectionSystemRepaired": sessionStorage.getItem("overfill") == null ? "0" : sessionStorage.getItem("overfill"),
         "purgedTankCompartmentsOfVapour": {

            // "loadOfNonVolatile": sessionStorage.getItem("loadOfNonVolatile") == null ? "0" : sessionStorage.getItem("loadOfNonVolatile"),
            // "steamCleaned": sessionStorage.getItem("steamCleaned") == null ? "0" : sessionStorage.getItem("steamCleaned"),
            // "purgeEachCompartmentWithAirFor20Minutes": sessionStorage.getItem("purgeEachCompartmentWithAirFor20Minutes") == null ? "0" : sessionStorage.getItem("purgeEachCompartmentWithAirFor20Minutes")

            "loadOfNonVolatile":$("input[name='loadOfNonVolatile']:checked").val()!=""?$("input[name='loadOfNonVolatile']:checked").val():"",
            "steamCleaned": $("input[name='steamCleaned']:checked").val()!=""?$("input[name='steamCleaned']:checked").val():"",
            "purgeEachCompartmentWithAirFor20Minutes": $("input[name='purgeEachCompartmentWithAirFor20Minutes']:checked").val()!=""?$("input[name='purgeEachCompartmentWithAirFor20Minutes']:checked").val():""
         },
         "waterGaugeReading": {
            "pf": $("input[name='water_pf']").val() != "" ? $("input[name='water_pf']").val() : "",
            "pfpi": $("input[name='water_pfpi']").val() != "" ? $("input[name='water_pfpi']").val() : "",
            "ti": $("input[name='water_ti']").val() != "" ? $("input[name='water_ti']").val() : "",
            "tf": $("input[name='water_tf']").val() != "" ? $("input[name='water_tf']").val() : "",
            "titf": $("input[name='water_titf']").val() != "" ? $("input[name='water_titf']").val() : "",
         },
         "run1": {
            "pi": $("input[name='run1_pi']").val(),
            "pf": $("input[name='run1_pf']").val(),
            "a": $("input[name='run1_a']").val(),
            "ti": $("input[name='run1_ti']").val(),
            "tf": $("input[name='run1_tf']").val(),
            "titf": $("input[name='run1_titf']").val()
         },
         "run2": {
            "pi": $("input[name='run2_pi']").val(),
            "pf": $("input[name='run2_pf']").val(),
            "a": $("input[name='run2_a']").val(),
            "ti": $("input[name='run2_ti']").val(),
            "tf": $("input[name='run2_tf']").val(),
            "titf": $("input[name='run2_titf']").val()
         },
         "run3": {
            "pi": $("input[name='run3_pi']").val(),
            "pf": $("input[name='run3_pf']").val(),
            "a": $("input[name='run3_a']").val(),
            "ti": $("input[name='run3_ti']").val(),
            "tf": $("input[name='run3_tf']").val(),
            "titf": $("input[name='run3_titf']").val()
         },
         "run4": {
            "vi": $("input[name='run4_vi']").val() != "" ? $("input[name='run4_vi']").val() : "",
            "ti": $("input[name='run1_ti']").val() != "" ? $("input[name='run4_ti']").val() : "",

         },
         "run5": {
            "vi": $("input[name='run5_vi']").val() != "" ? $("input[name='run5_vi']").val() : "",
            "ti": $("input[name='run5_ti']").val() != "" ? $("input[name='run5_ti']").val() : "",

         },
         "run6": {
            "vi": $("input[name='run6_vi']").val() != "" ? $("input[name='run6_vi']").val() : "",
            "ti": $("input[name='run6_ti']").val() != "" ? $("input[name='run6_ti']").val() : "",

         }
      }

      console.log(JSON.stringify(section1Vapor));

      sessionStorage.setItem("section1Vapor", JSON.stringify(section1Vapor));

      var requestObjectForInsp =
      {
         "id": inspectionId,
         "fkAccountId": 1,
         "type": "Vapor Tightness Certification",
         "workOrderNumber": $("#workOrderNumber").val(),
         "notes": $("#notes").val(),
         "inspectedBy": $("#inspectedBy").val(),
         "inspectedDate": $("#inspectedDate").val(),
         "verifiedBy": $("#verifiedBy").val(),
         "verifiedDate": $("#verifiedByBate").val(),
         "status": status,
         "taskOrderId":taskOrderId,
         "expiryDate": $("#expiryDate").val(),
         "inspectionType":
         {
            "id": inspectionTypeId,
            "name":"Vapor Tightness Certification"
         },
         // "result":
         // {
         //    "approvedCertify": "0",
         //    "inspectionFailed": "0"
         // },
         // "dispositionOfVehicle":
         // {
         //    "vehicleReturnedServices": "0",
         //    "vehicleWithdrawnFromService": "0",
         //    "vehicleSentToAnotherRepairFacility": "0"
         // },
         "result": {
            "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
         },
         "dispositionOfVehicle": {
            "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
            "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
         },
         "vehicleDetails":
         {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
         },

         "vaporTightnessCertification": section1Vapor,
         "documentList": documentLists
      }

      // setLoading(true);
      $("#loadingforsubmit").show();
      var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: "Your changes have been successfully saved!",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {
               // sessionStorage.removeItem("greaterthan2499Gallons");
               // sessionStorage.removeItem("1500to2499Gallons");
               // sessionStorage.removeItem("1000to1499Gallons");
               // sessionStorage.removeItem("lessthan999Gallons");
               // sessionStorage.removeItem("optic");
               // sessionStorage.removeItem("thermister");
               // sessionStorage.removeItem("other");
               // sessionStorage.removeItem("connect");
               // sessionStorage.removeItem("liquid");
               // sessionStorage.removeItem("compartments");
               // sessionStorage.removeItem("stablization");
               // sessionStorage.removeItem("cove");
               // sessionStorage.removeItem("internally");
               // sessionStorage.removeItem("recovery");
               // sessionStorage.removeItem("pressure");
               // sessionStorage.removeItem("manometer");
               // sessionStorage.removeItem("loadOfNonVolatile");
               // sessionStorage.removeItem("steamCleaned");
               // sessionStorage.removeItem("purgeEachCompartmentWithAirFor20Minutes");
               // sessionStorage.removeItem("section1Vapor");

               sessionStorage.removeItem("greaterthan2499Gallons");
                sessionStorage.removeItem("1500to2499Gallons");
                sessionStorage.removeItem("1000to1499Gallons");
                sessionStorage.removeItem("lessthan999Gallons");
                sessionStorage.removeItem("overfiils");
                sessionStorage.removeItem("1500to2499Gallons");
                sessionStorage.removeItem("optic");
                sessionStorage.removeItem("thermister");
                sessionStorage.removeItem("other");
                sessionStorage.removeItem("connect");
                sessionStorage.removeItem("liquid");
                sessionStorage.removeItem("compartments");
                sessionStorage.removeItem("stablization");
                sessionStorage.removeItem("cove");
                sessionStorage.removeItem("internally");
                sessionStorage.removeItem("recovery");
                sessionStorage.removeItem("pressure");
                sessionStorage.removeItem("manometer");
                sessionStorage.removeItem("loadOfNonVolatile");
                sessionStorage.removeItem("steamCleaned");
                sessionStorage.removeItem("purgeEachCompartmentWithAirFor20Minutes");
                 sessionStorage.removeItem("section1Vapor");
                sessionStorage.removeItem("section2VaporRun1");
                sessionStorage.removeItem("section2VaporRun2");
                sessionStorage.removeItem("section2VaporRun3");
                sessionStorage.removeItem("waterGauge");
                sessionStorage.removeItem("section4Run4");
                sessionStorage.removeItem("section4Run5");
                sessionStorage.removeItem("section4Run6");
                sessionStorage.removeItem("location");
                sessionStorage.removeItem("otherData");
               navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
         });
      });

   }
   // handle input change
   const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
   };

   // handle click event of the Remove button
   const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
   };

   // handle click event of the Add button
   const handleAddClick = () => {
      setInputList([...inputList, {}]);
   };
   const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
         cb(null, reader.result)
      }
      reader.onerror = function (error) {
         cb(error, null)
      }
   }
   const onUploadFileChange = (event, index) => {


      var target = event.target;
      if (target.files < 1 || !target.validity.valid) {
         return
      }
      fileToBase64(target.files[0], (err, result) => {
         if (result) {
            const { name, value } = event.target;
            const list = [...inputList];
            list[index][name] = result;
            setBase64file(result);
            // setFile(result)
            setfileName(target.files[0]);
            // setFileName(target.files[0])
         }
      })
   }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                        <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>
               
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">
                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>
                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-2 form-inspection-title text-center">Vapor Tightness Certification Form                                    </h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div>
                           <div class="col-lg-12">
                              <div class="inspection-wrap">
                                 <div class="text-center mb-2 mt-2">
                                 <h6>Form for cargo tank truck vapor tightness testing :</h6>
                                    <small>
                                       <span class="mb-3"> Air regulation for vapor tightness: VA SAPCB Article 4-37, 40 CFR 60.505, 40 CFR 63.425(e) and 40 CFR 63.11092(f).</span>
                                    </small>
                                 </div>
                                 {/* <!-- accordion --> */}
                                 <div class="accordion" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                       <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                          <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                             EPA Reference Method 27 MACT R Annual Certification - Allowed Pressure Change
                                          </button>
                                       </h2>
                                       <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" >
                                          <div class="accordion-body pb-4">
                                             <div class="row mb-4">
                                                <div class="col-lg-6 mb-2">
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="greaterthan2499Gallons" id="greaterthan2499Gallons" class="mr-2" value="1" onChange={e => gall(e)} />
                                                      <span>(tank truck ≥ 2500 gallons) 1.0 inch</span>
                                                   </div>
                                                </div>

                                                <div class="col-lg-6 mb-2">
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="1500to2499Gallons" class="mr-2" value="1" onChange={e => galltwonine(e)} />
                                                      <span>(tank truck 1500-2499 gallons) 1.5 inch</span>
                                                   </div>
                                                </div>

                                                <div class="col-lg-6 mb-2">
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="1000to1499Gallons" class="mr-2" value="1" onChange={e => gallthousand(e)} />
                                                      <span>(tank truck 1.499 to 1.000 gallons) 2.0 inch</span>
                                                   </div>
                                                </div>

                                                <div class="col-lg-6 mb-2">
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="lessthan999Gallons" class="mr-2" value="1" onChange={e => gallthousandnine(e)} />
                                                      <span>(tank truck less 999 gallons) 2.5 inch</span>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row">
                                                <div class="col-lg-12">
                                                   <div class="table-2 mb-4">
                                                      <table class="table">
                                                         <thead>
                                                            <tr>
                                                               <th scope="col"></th>
                                                               <th scope="col">Good</th>
                                                               <th scope="col">Faulty</th>
                                                               <th scope="col" style={{ width: "110px" }}>Repaired</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td>Overfill Protection System Repaired</td>
                                                               <td class="text-center">  <input type="radio" name="overfill" value="1" aria-label="Radio button for following text input" onChange={e => overfiils(e)} /></td>
                                                               <td class="text-center">  <input type="radio" name="overfill" value="0" aria-label="Radio button for following text input" onChange={e => overfiils(e)} /></td>
                                                               <td class="text-center">  <input type="radio" name="overfill" value="2" aria-label="Radio button for following text input" onChange={e => overfiils(e)} /></td>
                                                               {/* <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                               {/* <td class="text-center">  <input type="radio" name="overfill" value="1" aria-label="Radio button for following text input"  checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='1'?"checked":"":""} onChange={e => overfiils(e)}/></td>
                                                                      <td class="text-center">  <input type="radio" name="overfill" value="0" aria-label="Radio button for following text input" checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='0'?"checked":"":""}  onChange={e => overfiils(e)} /></td>
                                                                     <td class="text-center">  <input type="radio" name="overfill" value="2" aria-label="Radio button for following text input" checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='2'?"checked":"":""} onChange={e => overfiils(e)} /></td> */}

                                                               {/* {(() => {
                                                                  if (inspectionDetails.vaporTightnessCertification) {
                                                                     if (inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="1" checked onChange={e => overfiils(e)} /></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="0" onChange={e => overfiils(e)} /></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="2" onChange={e => overfiils(e)} /></td></>)
                                                                     } else if (inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired === "0") {
                                                                        return (<><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="0" onChange={e => overfiils(e)} checked/></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="1" onChange={e => overfiils(e)}  /></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="1" onChange={e => overfiils(e)} /></td></>)
                                                                     } else if(inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired === "2"){
                                                                        return (<><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="2" onChange={e => overfiils(e)} checked/></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="1" onChange={e => overfiils(e)}  /></td><td class="text-center"><input type="radio" name="overfill" aria-label="Radio button for following text input" value="0" onChange={e => overfiils(e)} /></td></>)
                                                                     }else{
                                                                       
                                                                     }
                                                                  }

                                                               })()} */}
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row">
                                                <div class="col-lg-12">
                                                   <div class="table-2 mb-4">
                                                      <table class="table">
                                                         <thead>
                                                            <tr>
                                                               <th scope="col"></th>
                                                               <th scope="col">Optic</th>
                                                               <th scope="col">Thermister</th>
                                                               <th scope="col" style={{ width: "110px" }}>Other</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td>Type overfill protection system</td>
                                                               <td class="text-center">  <input type="radio" name="optic" value="1" aria-label="Radio button for following text input" onChange={e => optic(e)} /></td>
                                                               <td class="text-center">  <input type="radio" name="optic" value="0" aria-label="Radio button for following text input" onChange={e => optic(e)} /></td>
                                                               <td class="text-center">  <input type="radio" name="optic" value="2" aria-label="Radio button for following text input" onChange={e => optic(e)} /><div id="otherField">
                                                <input type="text" name="otherData" id="otherData" class="form-control" />
                                             </div></td>

                                                               {/* <td class="text-center">  <input type="radio" name="optic" value="1" aria-label="Radio button for following text input" checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='1'?"checked":"":""}  onChange={e => optic(e)}/></td>
                                                               <td class="text-center">  <input type="radio" name="optic" value="0" aria-label="Radio button for following text input" checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='0'?"checked":"":""}  onChange={e => optic(e)} /></td>
                                                                <td class="text-center">  <input type="radio" name="optic" value="2" aria-label="Radio button for following text input" checked={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='2'?"checked":"":""} onChange={e => optic(e)} /></td> */}
                                                               {/* {(() => {
                                                                  if (inspectionDetails.vaporTightnessCertification) {
                                                                     if (inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="1" checked onChange={e => optic(e)} /></td><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="0" onChange={e => optic(e)} /></td></>)
                                                                     } else if (inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic === "0") {
                                                                        return (<><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="0" onChange={e => optic(e)} /></td><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="0" onChange={e => optic(e)} checked /></td></>)
                                                                     } else {
                                                                        return (<><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="2" onChange={e => optic(e)} /></td><td class="text-center"><input type="radio" name="optic" aria-label="Radio button for following text input" value="2" onChange={e => optic(e)} checked /></td></>)
                                                                     }
                                                                  }

                                                               })()} */}
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             {/* <div id="otherField">
                                                <input type="text" name="otherData" id="otherData" class="form-control" />
                                             </div><br /> */}

                                             {/* {(() => {
                                                if (inspectionDetails.vaporTightnessCertification) {


                                                   if (inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic == '2') {

                                                      return (<><div id="otherField">
                                                         <input type="text" name="otherData" id="otherData" class="form-control" />
                                                      </div><br /></>)
                                                   }
                                                }
                                             })()} */}


                                             {/* <div class="row mb-4">
                                                <div class="col-md-12">
                                                   <h4>Type over protection system</h4>
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="optic" value="1" class="mr-2" onChange={e => optic(e)} />
                                                      <span>Optic</span>
                                                   </div>

                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="thermister" value="1" class="mr-2" onChange={e => thermister(e)} />
                                                      <span>Thermister</span>
                                                   </div>

                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" name="other" value="1" class="mr-2" onChange={e => other(e)} />
                                                      <span>Other</span>
                                                   </div>
                                                </div>
                                             </div> */}
                                             <div class="row mb-4">
                                                <div class="col-lg-12">
                                                   <div class="table-2 mb-4">
                                                      <table class="table">
                                                         <thead>
                                                            <tr>
                                                               <th scope="col"></th>
                                                               <th scope="col">Yes</th>
                                                               <th scope="col">No</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                    <tr>
                                                     <td>Connect static electrical ground to tank</td>
                                                      <td class="text-center">  <input type="radio" name="connect" value="1" aria-label="Radio button for following text input"  onChange={e => connect(e)}/></td>
                                                      <td class="text-center">  <input type="radio" name="connect" value="0"  aria-label="Radio button for following text input" onChange={e => connect(e)}/></td>
                                                    </tr>

                                                    <tr>
                                                      <td>Purged lines of liquid	</td>
                                                       <td class="text-center">  <input type="radio" name="liquid" value="1" aria-label="Radio button for following text input" onChange={e => liquid(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="liquid" value="0"  aria-label="Radio button for following text input" onChange={e => liquid(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Purged tank compartments of Vapor</td>
                                                       <td class="text-center">  <input type="radio" name="compartments" value="1" aria-label="Radio button for following text input" onChange={e => compartments(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="compartments" value="0"  aria-label="Radio button for following text input" onChange={e => compartments(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Temp Stablization</td>
                                                       <td class="text-center">  <input type="radio" name="stablization" value="1" aria-label="Radio button for following text input" onChange={e => stablization(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="stablization" value="0"  aria-label="Radio button for following text input" onChange={e => stablization(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Testing location: <input type="text" name="locationTesting" id="locationTesting"/></td>
                                                      <td class="text-center"></td>
                                                       <td class="text-center"></td>
                                                       {/* <td class="text-center">  <input type="radio" name="location" value="1" aria-label="Radio button for following text input" onChange={e => location(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="location" value="0"  aria-label="Radio button for following text input" onChange={e => location(e)}/></td> */}
                                                     </tr>

                                                     <tr>
                                                      <td>Open & Close each dome cover</td>
                                                       <td class="text-center">  <input type="radio" name="cove" value="1" aria-label="Radio button for following text input"  onChange={e => cove(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="cove" value="0"  aria-label="Radio button for following text input"  onChange={e => cove(e)}/></td>
                                                     </tr>
                                                       
                                                  </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                                <div class="col-lg-12">
                                                   <div class="table-2 mb-4">
                                                      <table class="table">
                                                         <thead>
                                                            <tr>
                                                               <th scope="col"></th>
                                                               <th scope="col">Yes</th>
                                                               <th scope="col">No</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                      <tr>
                                                       <td>Connect compartments of tank internally</td>
                                                        <td class="text-center">  <input type="radio" name="internally" value="1" aria-label="Radio button for following text input" onChange={e => internally(e)}/></td>
                                                        <td class="text-center">  <input type="radio" name="internally" value="0"  aria-label="Radio button for following text input" onChange={e => internally(e)}/></td>
                                                      </tr>

                                                      <tr>
                                                         <td>Attach test cap to vapor recovery coupling</td>
                                                          <td class="text-center">  <input type="radio" name="recovery" value="1" aria-label="Radio button for following text input" onChange={e => recovery(e)}/></td>
                                                          <td class="text-center">  <input type="radio" name="recovery" value="0"  aria-label="Radio button for following text input" onChange={e => recovery(e)}/></td>
                                                        </tr>

                                                        <tr>
                                                         <td>Connect pressure-vacuum supply & pressure relief valve to shut-off valve                                                         </td>
                                                          <td class="text-center">  <input type="radio" name="pressure" value="1" aria-label="Radio button for following text input" onChange={e => pressure(e)}/></td>
                                                          <td class="text-center">  <input type="radio" name="pressure" value="0"   aria-label="Radio button for following text input" onChange={e => pressure(e)}/></td>
                                                        </tr>

                                                        <tr>
                                                         <td>Attach Manometer (or equivalent) to pressure tap</td>
                                                          <td class="text-center">  <input type="radio"  name="manometer" value="1" aria-label="Radio button for following text input" onChange={e => manometer(e)}/></td>
                                                          <td class="text-center">  <input type="radio"  name="manometer" value="0"   aria-label="Radio button for following text input" onChange={e => manometer(e)}/></td>
                                                        </tr>
      
                                                     
                                                    </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row">
                                                <div class="col-md-12">
                                                   {/* <h4>Type over protection system</h4> */}
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" id="vehicle1" name="loadOfNonVolatile" value="1" class="mr-2" onChange={e => loadOfNonVolatile(e)} />
                                                      <span>Load of Non-Volatile</span>
                                                   </div>
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" id="vehicle1" name="steamCleaned" value="1" class="mr-2" onChange={e => steamCleaned(e)} />
                                                      <span>Steam cleaned</span>
                                                   </div>
                                                   <div class="d-flex align-items-center">
                                                      <input type="checkbox" id="vehicle1" name="purgeEachCompartmentWithAirFor20Minutes" value="1" class="mr-2" onChange={e => purgeEachCompartmentWithAirFor20Minutes(e)} />
                                                      <span>Purge each compartment with air for 20 minutes</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="accordion-item">
                                       <h2 class="accordion-header " id="panelsStayOpen-headingTwo">
                                          <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                             EPA METHOD 27 Pressure Test
                                          </button>
                                       </h2>
                                       <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo" >
                                          <div class="accordion-body pb-4">
                                             <div class="p-3 text-left" style={{ backgroundColor: "#ccc" }}>
                                                <p>Increase pressure to a minimum of 18 inches (maximum of 26.6) Water Gauge (Manometer); Indicate starting pressure (Pi) and pressure (Pf) at the end of 5 minutes. Record initial (Ti) and final time (Tf) of test or duration if stop watch is used
                                                </p>
                                             </div>
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 1</td>
                                                               <td style={{ width: "auto", display: "grid" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge
                                                                        Readings (Inches)
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pi =</label>
                                                                        <b className="mn40 mb-2">
                                                                           <input type="input" name="run1_pi" defaultValue={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.pi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        </b>
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pf =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run1_pf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run1.pf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">a =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run1_a" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run1.a : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">ti =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run1_ti" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run1.ti : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.ti:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run1_tf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run1.tf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf-ti =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run1_titf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run1.titf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 2</td>
                                                               <td style={{ width: "100%", display: "grid" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge
                                                                        Readings (Inches)
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pi =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run2_pi" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.pi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.pi:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pf =</label>
                                                                        <b className="mn40 mb-2"><input type="input" name="run2_pf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.pf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} /></b>
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.pf:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">b =</label>
                                                                        <input type="input" name="run2_a" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.a : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.a:""}</b> */}
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">ti =</label>
                                                                        <input type="input" name="run2_ti" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.ti : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.ti:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf =</label>
                                                                        <input type="input" name="run2_tf" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.tf : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.tf:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf-ti =</label>
                                                                        <input type="input" name="run2_titf" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run2.titf : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.titf:""}</b> */}
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 3</td>
                                                               <td className="d-flex flex-column" style={{ width: "auto", display: "grid;" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge
                                                                        Readings (Inches)
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pi =</label>
                                                                        <input type="input" name="run3_pi" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.pi : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.pi:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">pf =</label>
                                                                        <input type="input" name="run3_pf" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.pf : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.pf:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">c =</label>
                                                                        <input type="input" name="run3_a" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.a : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.a:""}</b> */}
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">ti =</label>
                                                                        <input type="input" name="run3_ti" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.ti : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.ti:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf =</label>
                                                                        <input type="input" name="run3_tf" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.tf : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.tf:""}</b> */}
                                                                     </div>
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">tf-ti =</label>
                                                                        <input type="input" name="run3_titf" class="form-control ml-3" id="abc" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run3.titf : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        {/* <b className="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.titf:""}</b> */}
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>


                                          </div>
                                       </div>
                                    </div>
                                    <div class="accordion-item">
                                       <h2 class="accordion-header " id="panelsStayOpen-headingthree">
                                          <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                             MACT R - 40 CFR 63.425(E) Internal Vapor Valve Test
                                          </button>
                                       </h2>
                                       <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree" >
                                          <div class="accordion-body pb-4">
                                             <div class="table-6">
                                                <table style={{ width: "100%" }}>
                                                   <tr class="border">
                                                      <th colspan="6" class="text-center p-2">Water Gauge Reading</th>

                                                   </tr>
                                                   <tr class="border vapor-table">
                                                      <td class="border-right border-bottom p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn40 d-ruby">pi = 0-inch</label>

                                                         </div>
                                                      </td>
                                                      <td class="border-right border-bottom p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn30">pf =</label>
                                                            <input type="input"  name="water_pf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.waterGaugeReading.pf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "60px" }} />
                                                         </div>
                                                      </td>
                                                      <td class="border-bottom p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn40 d-ruby">pf-Pi =</label>
                                                            <input type="input"  name="water_pfpi" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.waterGaugeReading.pfpi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "60px" }} />
                                                         </div>
                                                      </td>
                                                      <td class="border-right p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn30">Ti =</label>
                                                            <input type="input" name="water_ti" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.waterGaugeReading.ti : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "60px" }} />
                                                         </div>
                                                      </td>
                                                      <td class="border-right p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn30">Tf =</label>
                                                            <input type="input" name="water_tf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.waterGaugeReading.tf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "60px" }} />
                                                         </div>
                                                      </td>
                                                      <td class="border-bottom p-2">
                                                         <div class="d-flex align-items-center ">
                                                            <label for="" className="mn40 d-ruby">Tf-Ti =</label>
                                                            <input type="input"  name="water_titf" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.waterGaugeReading.titf : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "60px" }} />
                                                         </div>
                                                      </td>



                                                   </tr>

                                                </table>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                    <div class="accordion-item">
                                       <h2 class="accordion-header " id="panelsStayOpen-headingfour">
                                          <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapsefour">
                                             EPA METHOD 27 Vacuum Test
                                          </button>
                                       </h2>
                                       <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfour" >
                                          <div class="accordion-body pb-4">
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 1</td>
                                                               <td style={{ width: "100%", display: "grid" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3.00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">3.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Vi =</label>
                                                                        <input type="input" name="run4_vi" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run4.vi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>

                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Ti =</label>
                                                                        <input type="input" name="run4_ti" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run4.ti : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>


                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 2</td>
                                                               <td style={{ width: "100%", display: "grid" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3.00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">3.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Vi =</label>
                                                                        <input type="input" name="run5_vi" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run5.vi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>

                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Ti =</label>
                                                                        <input type="input" name="run5_ti" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run5.ti : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>


                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row mt-3">
                                                <div class="col-lg-12">
                                                   <div class="table-5">
                                                      <table>
                                                         <tbody>
                                                            <tr>
                                                               <td class="text-center">Run 3</td>
                                                               <td style={{ width: "100%", display: "grid" }}>
                                                                  <div>
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                     </div>
                                                                     <div class="d-flex justify-content-center border-bottom">
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3:00</div>
                                                                        <div class="d-flex justify-content-center w-half m-auto p-2">3:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2">
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Vi =</label>
                                                                        <input type="input" name="run6_vi" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run6.vi : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>

                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div class="border-bottom">
                                                                     <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                                     <div class="d-flex justify-content-center">
                                                                        <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="p-2 border-left">
                                                                     <div class="d-flex align-items-center mb-2">
                                                                        <label for="" className="mn40">Ti =</label>
                                                                        <input type="input" name="run6_ti" defaultValue={inspectionDetails.vaporTightnessCertification ? inspectionDetails.vaporTightnessCertification.run6.ti : ""} class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                     </div>


                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12" style={{marginTop:"-18px"}}>
                                             <div class="align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />
                                                
                                                   {/* <b>{data.name}.{data.extension}</b> */}
                                                   <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                    <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </a></p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                   
                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />

                           </div>

                           <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />


                        </div>
                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                        <div class="divider"></div>
                        {(() => {
                           if (!roles.match("Technician")) {
                              if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                 return (
                                    <>
                                       <div class="result-didspos">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                <b class="mb-0 ml-2">Tank Returned to Service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                       <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId}/>
                                       {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                    </>
                                 )
                              } else {
                                 return (
                                    <><div class="result-didspos ">
                                       <div class="p-4">
                                          <span>Result</span>
                                          <div class="d-flex align-items-center">
                                             {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                             <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                          </div>
                                          <div class="d-flex align-items-center">
                                             {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                             <b class="mb-0 ml-2">Inspection Failed</b>
                                          </div>
                                       </div>
                                       <div class="p-4">
                                          <span>Disposition of Tank</span>
                                          <div class="d-flex align-items-center">
                                             {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                             <b class="mb-0 ml-2">Tank returned to service</b>
                                          </div>
                                          <div class="d-flex align-items-center">
                                             {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                             <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                          </div>
                                          <div class="d-flex align-items-center">
                                             {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                             <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                          </div>
                                       </div>
                                    </div>
                                    <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
                                       {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                    </>
                                 )
                              }
                           }
                        })()}
                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end">
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                           {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                           <button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>
                           {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a> */}
                           {(() => {
                              if(roles.match("Manager")||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
                                 return(<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED")}>Save</button></>)
                              }
                              else{
                                 return(<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                           })()}
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   )
};

InspectionVaporTightnessCertificateEdit.propTypes = {};

InspectionVaporTightnessCertificateEdit.defaultProps = {};

export default InspectionVaporTightnessCertificateEdit;
