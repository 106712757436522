import services from "../services/services";
import { requestObject } from "./requestObject";
var GetTruckType=(setTruckTypes)=>
{
    
     //////////////////////////getTruckType////////////////////////////
           var truckType=
            {
                
            };


               
         // const requestOptions = 
         // {
         //    method: 'POST',
         //    headers: { 'Content-Type': 'application/json'},
         //    body: JSON.stringify(truckType)
         // };
         var requestOptions=requestObject.RequestHeader(truckType);
         services.getTruckType(requestOptions).then((res) => 
         {
            setTruckTypes(res.rows);
         });
    

}


export default GetTruckType;