import React,{history,useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate ,useParams} from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import  $ from "jquery";
import services from "../../../../services/services";
import {useLoading} from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const ExternalVisualSectionFour = () => 
{
  // var section3= {
  //   "inoperative": $("input[name='step3inoperative']:checked").val()==="1"?"1":"0",
  //               "doomcover": $("input[name='step3cover']:checked").val()==="1"?"1":"0",
  //               "gaskets":$("input[name='step3gaskets']:checked").val()==="1"?"1":"0",
  //               "vaccumevent":$("input[name='step3vacuum']:checked").val()==="1"?"1":"0",
  //               "popofvalves":$("input[name='step3offvalve']:checked").val()==="1"?"1":"0",
  //               "reputerDisc":$("input[name='step3rupture']:checked").val()==="1"?"1":"0",
  //               "pressureGauage":$("input[name='step3gauge']:checked").val()==="1"?"1":"0",
  //               "hairHookup":$("input[name='step3hookup']:checked").val()==="1"?"1":"0",
  //               "bleeder":$("input[name='step3bleeder']:checked").val()==="1"?"1":"0",
  //               "port":$("input[name='step3cleanout']:checked").val()==="1"?"1":"0",
  //               "drainsClogged":$("input[name='step3clogged']:checked").val()==="1"?"1":"0",
  //               "spillcollar":$("input[name='step3spill']:checked").val()==="1"?"1":"0",
  //               "leakofAnyKind":$("input[name='step3leaksofany']:checked").val()==="1"?"1":"0",
    
  // };
  // console.log(JSON.stringify(section3));

  // sessionStorage.setItem("section3",JSON.stringify(section3));

  useEffect(() => {

    scrollTop();
    getSection4();

  }, []);

  function getSection4() {

    JSON.parse(sessionStorage.getItem("section4"));

    console.log("ddd" + JSON.parse(sessionStorage.getItem("section4")));

    var obj = JSON.parse(sessionStorage.getItem("section4"))
    console.log(obj == null ? '' : obj.corrodedOrAbradedAreas);

    // setSection1(obj);
    // console.log("section Data" + JSON.stringify(section1));

    var corroded = obj == null ? '' : obj.corroded;
    var distortions = obj == null ? '' : obj.distortions;
    var indicationOferasion = obj == null ? '' : obj.indicationOferasion;
    var preventSafeOperation = obj == null ? '' : obj.preventSafeOperation;
    var devicenotfunctioning = obj == null ? '' : obj.devicenotfunctioning;


    $('input[name="step4abradedArea"][value="' + corroded + '"]').trigger("click");
    $('input[name="step4Distortions"][value="' + distortions + '"]').trigger("click");
    $('input[name="step4erosion"][value="' + indicationOferasion + '"]').trigger("click");
    $('input[name="step4safeoperation"][value="' + preventSafeOperation + '"]').trigger("click");
    $('input[name="step4devices"][value="' + devicenotfunctioning + '"]').trigger("click");
  

  }

  const sectionSubmit = () => {
    var section4= {
      "corroded":  $("input[name='step4abradedArea']:checked").val()==="1"?"1":"0",
      "distortions": $("input[name='step4Distortions']:checked").val()==="1"?"1":"0",
      "indicationOferasion": $("input[name='step4erosion']:checked").val()==="1"?"1":"0",
      "preventSafeOperation": $("input[name='step4safeoperation']:checked").val()==="1"?"1":"0",
      "devicenotfunctioning": $("input[name='step4devices']:checked").val()==="1"?"1":"0"
      
    };
    console.log(JSON.stringify(section4));
  
    sessionStorage.setItem("section4",JSON.stringify(section4));

  }


  const handleSubmit=()=>
  {

let form_is_valid = true;


var step4abradedArea=$("input[name='step4abradedArea']:checked").val();
var step4Distortions=$("input[name='step4Distortions']:checked").val();
var step4erosion=$("input[name='step4erosion']:checked").val();
var step4safeoperation=$("input[name='step4safeoperation']:checked").val();
var step4devices=$("input[name='step4devices']:checked").val();


console.log(step4abradedArea+''+step4Distortions+''+step4erosion+''+step4safeoperation+''+step4devices);

if(step4abradedArea==undefined||step4Distortions==undefined||step4erosion==undefined||step4safeoperation==undefined||step4devices==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
// alert(form_is_valid);
if(form_is_valid==true){
$("#section5").trigger('click');
}

return form_is_valid;

}

  return(
  <>

         
                        <div class="card">

                          {/* <div class="divider"></div> */}
                            <div class="row px-3">
                            <div class="w-100">
            <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
            <p id="error_message"></p>
          </div>
       <WorkOrderNumberDiv />
                                <div class="col-lg-12 px-0">
                                  
                                    <div class="inspection-wrap">

                                        <div class="step-wrap p-3 mb-3">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick "><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Tank Shell</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">External Piping</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Spill Collar</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 current-tick">4</div>
                                                     <div class="labl-section">Emergency Devices</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">5</div>
                                                     <div class="labl-section">Bolts</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">6</div>
                                                     <div class="labl-section">Vehicle Condition</div>
                                                 </div>
                                             </div>
                                        </div>

                                        <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 4: Emergency devices, valves including self-Closing stop valves. Excess flow remote closure device are there any :</th>                                                 
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>                                              
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>Corroded or abraded areas  </td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4abradedArea" value="1" onChange={() => sectionSubmit()}/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4abradedArea" value="0" onChange={() => sectionSubmit()}/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>Distortions </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4Distortions" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4Distortions" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Indications of erosion </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4erosion" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4erosion" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>External damage that will prevent safe operation </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4safeoperation" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4safeoperation" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Devices not functioning properly  </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4devices" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step4devices" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                </tbody>
                                              </table>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                                <a id="section3" class="btn btn-secondary mr-1 ">Back</a>
                                   {/* <a href="#" class="btn btn-secondary mr-1 ">Next</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("draft")}>Cancel </a> */}
                                <a id="section5" class="btn btn-primary mr-1" style={{display:"none"}}>Next</a>
                                <a  class="btn btn-primary mr-1"  onClick={() => handleSubmit()}>Next</a>
                            </div>
                           </div>

              
  </>
)};

ExternalVisualSectionFour.propTypes = {};

ExternalVisualSectionFour.defaultProps = {};

export default ExternalVisualSectionFour;