import { Cookies } from 'react-cookie';
const cookies = new Cookies();
const Token = cookies.get("_AK");
export const requestObject = 
{
      RequestHeader,
     
}
function RequestHeader(requestedFromModule)
{
      const requestOptions = 
      {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 
         'Authorization': "Bearer "+Token
      },
           
          body: JSON.stringify(requestedFromModule)
      };

      return requestOptions;
  
}