import services from "../services/services";
import { requestObject } from "./requestObject";
var GetTrailerSubType=(setTrailerType)=>
{
    
     //////////////////////////getstate////////////////////////////
           var statereq=
            {
                
            };


               
         // const requestOptions = 
         // {
         //    method: 'POST',
         //    headers: { 'Content-Type': 'application/json'
         //             },
                     
         //       body: JSON.stringify(statereq)
         // };
         var requestOptions=requestObject.RequestHeader(statereq);

         services.getTrailerSubTypes(requestOptions).then((res) => 
         {
           
            setTrailerType(res.rows);
         });
    

}


export default GetTrailerSubType;