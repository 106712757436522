import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import services from "../../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";

import AnnualVehicle1EditOne from "./editOne-inspection-annualvehicle-1";
import AnnualVehicle2Edit from "./edit-inspection-annualvehicle-2";
import AnnualVehicle3Edit from "./edit-inspection-annualvehicle-3";
import AnnualVehicle4Edit from "./edit-inspection-annualvehicle-4";
import AnnualVehicle5Edit from "./edit-inspection-annualvehicle-5";

const EditAnnualVehicle = () => {

    const {fkAccountId, cid, vid } = useParams();
    const navigate = useNavigate();
    const [inspectionType, setInspectionType] = useState();
    const [inspectionTypes, setInspectionTypes] = useState();
    const [inspectionAnnualSteps, setInspectionAnnualSteps] = useState("section1");
    const [itemName, setFieldName] = useState('');
    var vehicleType = "";
    useEffect(() => {


        if (CheckAuthentication)
        {
            getInspectionType();
            localStorage.setItem("customerId", cid);
            localStorage.setItem("vehicleId", vid);
            $(document).ready(function () {
                console.log("ready!");
            });

            ///inspection type check///////////////////////////////
            $('#inspectionType').on('change', function () {

                const res = this.value.replace(/ /g, '');

                if (res.match("ExternalVisualInspection")) {

                    setInspectionTypes("External Visual Inspection");
                }
                else {
                    setInspectionTypes(this.value.toString().trim());

                }

                if (res.match("Annual Vehicle Inspection")) {

                    setInspectionTypes("Annual Vehicle Inspection");
                }
                else {
                    setInspectionTypes(this.value.toString().trim());

                }

            });

        }
        else {
            sessionStorage.clear();
            localStorage.clear();
           //navigate("/login");
             window.location.href="/login";
        }


    }, []);

 const redirect=(page)=>
  {

     navigate(page);

  }
    const getInspectionType = () => {


        var vehicle_request =
        {

        };

        //   const requestOptions =
        //   {
        //         method: 'POST',
        //         headers:{'Content-Type': 'application/json'},
        //         body: JSON.stringify(vehicle_request)
        //   };

        var requestOptions = requestObject.RequestHeader(vehicle_request);
        //  vehicleType=document.getElementById("vehicleType").innerHTML;

        services.getInspectionTypeS(requestOptions).then((res) => {
            console.log("results=" + JSON.stringify(res.rows));

            setInspectionType(res.rows);

        });
    }


    $(document).ready(function() {

        $("#annualVehicle1").on("click",function () {

         
            setInspectionAnnualSteps("section1");
        });


    //  $('#annualVehicle2').click(function() {
    //      alert("section2")
    //     setInspectionAnnualSteps("section2");
    // });


    $("#annualVehicle2").on("click",function () {
        console.log("section2");
        setInspectionAnnualSteps("section2");
    });


    $("#annualVehicle3").on("click",function () {
        console.log("section3");
        setInspectionAnnualSteps("section3");
    });

    $("#annualVehicle4").on("click",function () {
        console.log("section4");
        setInspectionAnnualSteps("section4");
    });


    $("#annualVehicle5").on("click",function () {
        console.log("section5");
        setInspectionAnnualSteps("section5");
    });

});

    return (
        <>
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex justify-content-between align-items-center my-4">
                                <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <HeaderBlock cids={fkAccountId} />
                        </div>
                    </div>



                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <VehicleBasicInfo customerId={vid} />
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 m-auto">


                           <div class="white-color p-3">
                                {(() => {
                                    if (inspectionAnnualSteps == "section1") {
                                        return <> <AnnualVehicle1EditOne /></>
                                    }

                                    else if (inspectionAnnualSteps == "section2") {
                                        return <><AnnualVehicle2Edit /></>
                                    }
                                    else if (inspectionAnnualSteps === "section3") {

                                        return <><AnnualVehicle3Edit /></>
                                    }
                                    else if (inspectionAnnualSteps === "section4") {
                                        return <><AnnualVehicle4Edit /></>
                                    }
                                    else if (inspectionAnnualSteps === "section5") {
                                        return <><AnnualVehicle5Edit /></>
                                    }
                                    else {

                                    }

                                })()
                                }
                            </div>

                        </div>

                </div>
</div>



            </div>
        </>
    )
};

EditAnnualVehicle.propTypes = {};

EditAnnualVehicle.defaultProps = {};

export default EditAnnualVehicle;
