import React, { history, useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Cropper from "react-cropper";
import { useForm } from "react-hook-form";
import { loadingComponent } from "../../../utility/Loading";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
import DocumentMeta from "react-document-meta";
const Scheduledemo_thankyou = () => {
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState();
   const [documentIdsArray, setDocumentIDs] = useState([]);
   

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup",
   });


   useEffect(() => {
      $("#loadingfordocs").hide();
      
   }, []);
   
   const meta = {
    title: 'Thank You For Scheduling a Demo | DotStow',
  description: 'Thank you for scheduling a demo with DotStow. We look forward to speaking with you soon.',
  canonical: 'https://dotstow.com/thank-you-schedule-demo',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'thank you dotstow'
    }
  }};
   return (
      <>
      <DocumentMeta {...meta} />
    <div class="pp w-100" style={{backgroundColor: "#E0E9EC", marginTop:"-60px", width:"100%"}}>
    <header >
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container" style={{ maxWidth: "1200px", margin: "auto" }}>
                            <a class="navbar-brand" href="/login"><img src="/img/logo-dotstow.svg" style={{ width: "90px" }} /></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                                <a class="btn btn-outline-primary text-uppercase" href="/login" >Home</a>
                                {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                            </div>
                        </div>
                    </nav>
                </header>
        <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="schedule_wrap pb-5 mb-0">
                        <img src="img/dotstow-logo-circle.png" class="circle-logo" alt="" />

                        <div class="position-relative thankyou">
                            <img src="img/thankyou.png" alt="" />
                        </div>
                        <p>Thank you for scheduling a demo to learn more about how DotStow can help with your compliance document management. We look forward to speaking with you soon.</p>

                    </div>
                </div>
            </div>
         </div>
    </section>

    <section class="newsection" style={{backgroundColor: "#E0E9EC"}}>
        <div class="container">
            <div class="title_new_wrap mb-5" style={{textAlign: "center"}}>
                <h2 class="mb-1">What DotStow Clients Are Saying</h2>
                <span class="text-center">Testimonials</span>
            </div>
            <div class="tesmonialnormal">
                <div class="testmoin-item">
                    <p>
                        “Since implementing DotStow, our business has experienced a significant boost in efficiency and productivity. The software's user-friendly interface and automated features have streamlined our compliance processes, saving us valuable time and effort.
                        It has truly become an indispensable tool for our trucking operations. <br/> <br/> Efficiency and accuracy have significantly improved since we integrated DotStow into our operations. Our administrative burden
                        has lightened, and the software's ability to consolidate data has made compliance audits a breeze. It's been a game-changer for our company. <br/><br/> We've been using DotStow for a while now, and it has transformed the way we handle
                        compliance. It has eliminated the stress and complexity of managing multiple trucks, allowing us to focus on our core business.
                    </p>
                    <b>- Scott Beem</b>
                    <div class="quote">
                        <img src="img/quate.png" alt=""/>
                    </div>
                </div>

                <div class="testmoin-item">
                    <p>
                        “I have been using DotStow for approximately 8 months. The website is very easy to use, even to the not so" tech savvy" individuals. The website helps to eliminate minor errors when filling out paperwork (ex. writing in wrong numbers, vin numbers, contacts,
                        testing results). I have also found using DotStow has relieved the stress of having to "find" years past paperwork, driver information, contacts, records, and tests that need to be performed, eliminating time wasted. At the click
                        of a button, it's all there, due to the organized records. Everything is paperless and easily accessible for all involved.”
                    </p>
                    <b>- Kandi</b>
                    <div class="quote">
                        <img src="img/quate.png" alt=""/>
                    </div>
                </div>

                <div class="testmoin-item">
                    <p>
                        “As the owner of a repair facility I am involved in details at a higher level, but using DotStow has given me the ability to quickly and easily locate and view documents with minimal intervention from my office staff. To further test the software I attempted
                        to complete a set of test papers myself and within 30 minutes and again minimal intervention I was successful.”
                    </p>
                    <b>- Bryan Baker</b>
                    <div class="quote">
                        <img src="img/quate.png" alt=""/>
                    </div>
                </div>

                <div class="testmoin-item">
                    <p>
                        “How DotStow has impacted my daily routine. I am able to complete test papers and send to the appropriate people in a matter of minutes as well as knowing exactly where each customer's paperwork is located and stored for quick access.”
                    </p>
                    <b>- Crystal Bake</b>
                    <div class="quote">
                        <img src="img/quate.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="footer_wrap">
        <div class="container">
        <div class="row footerlinks">
                <div class="col-lg-3">
                    <h3>Contact Us</h3>
                    <p> DotStow, <br/> 11708 Ashville Pike, <br/> Ashville, Ohio 43103, <br/> Phone: 614-907-2725</p>
                </div>
                <div class="col-lg-6 app-icon-wrap">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h3>Quick Links</h3>
                    <ul class="mx-0 ml-0">
                        <li><a href="/login" class="footerlinks">Login</a></li>
                        <li><a href="/privacy" class="footerlinks">Privacy Policy</a></li>
                        <li><a href="/terms" class="footerlinks">Terms & Conditions</a></li>

                    </ul>
                </div>
                <div class="col-lg-6 app-icon-wrapbottom">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-6">
                    <div class="copyrights">
                        <p>© {(new Date().getFullYear())} DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a> <a href="/terms">Terms & Conditions</a></p>
                    </div>
                </div>
                <div class="col-sm-auto col-md-auto ">
                    <div class="social_icons">
                        <a href="#" class="svg_icon">
                            <svg height="20" viewBox="0 0 9.327 20.073">
                           <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                        </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 24.7 20.073">
                           <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                         </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 20.073 20.073">
                           <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                           <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)"/>
                           <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                         </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
            </div>
      </>
   )
};

Scheduledemo_thankyou.propTypes = {};

Scheduledemo_thankyou.defaultProps = {};

export default Scheduledemo_thankyou;
