import services from '../../../../services/services';
import { requestObject } from '../../../../utility/requestObject';
import {FETCH_CUSTOMER_REQUEST,FETCH_CUSTOMER_SUCCESS,FETCH_CUSTOMER_FAIL} from './reducers';

////////////////////////////////////////////
    
export const fetchStateRequest=()=>{
    return {
      type:FETCH_CUSTOMER_REQUEST
    }
  }
  export const fetchStateSuccess=(customerList)=>{
    return {
      type:FETCH_CUSTOMER_SUCCESS,
      data: customerList
    }
  }
  export const fetchStateFail=(error)=>{
    return {
      type:FETCH_CUSTOMER_FAIL,
      data: error
    }
  }
    ///////////////////////Customer Api Call///////////// 

    export const fetchCustomer_List = (id,statusofAccount,isPrimary,setContactPrimary)=>
    {

        return function(dispatch)
        {
           
            dispatch(fetchStateRequest());
          

            var contactRequest=
            {
               
      
                    
                  
                      "fkCustomerId":id,
                  
                      "firstName":"",
                  
                      "pageNumber":"1",
                  
                      "pageSize":"2" ,
                  
                      "sortBy": "",
                  
                      "isActive":statusofAccount,
                  
                      "sortDir":"",
                  
                      "isPrimaryAndAccountContactsOnly": isPrimary
                  
                   
            };
           
            // const requestOptions = 
            // {
            //       method: 'POST',
            //       headers:{'Content-Type': 'application/json'},
            //       body: JSON.stringify(contactRequest)
            // };
          
            var requestOptions=requestObject.RequestHeader(contactRequest);
            services.getContactList(requestOptions).then((res) => 
            {
              
               setContactPrimary(res.rows);
               dispatch(fetchStateSuccess(res.rows))
                 
            }).catch(error=>
            {
  
                dispatch(fetchStateFail(error))
  
            });;

            
        }
    }
   