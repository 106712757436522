import React, { history, useEffect, useState, useMemo } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../utility/pagination";
import Search from "../../../utility/search";
import $ from "jquery";

import { loadingComponent } from "../../../utility/Loading";
import { requestObject } from "../../../utility/requestObject";
import services from "../../../services/services";
import userList from "./userList";


const Users = () => {

   const [dispatcherList, setdispathcerlist] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalRecors, setTotalRecord] = useState([]);
   const [searchtxt, setsearchText] = useState("");
   const [totalItems, setTotalItems] = useState(0);
   const [totalpages, settotalPages] = useState();
   const navigate = useNavigate();
   const [inactiveStatus, setInactiveStatus] = useState(true);
   var roles = sessionStorage.getItem("securityRoleName");
   useEffect(() => {

      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, inactiveStatus);
      $('#display_pages_in_items').on('change', function () {

         setitemperpage(this.value);
         userList("", "1", this.value, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, inactiveStatus);
      });
   }, []);
   const redirect = (page) => {

      navigate(page);

   }
   const handlePageChange = (offset, triggerevent) => {


      userList("", offset, ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, inactiveStatus);

   }
   /////////////////////////////emp search/////////////////////////
   const searchList = (searchText) => {
      setsearchText(searchText);
      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchText, inactiveStatus);

   }

   var activestatus = "false";
   const activeInctive = (event) => {

      if (event.target.checked) {

      } else {
         activestatus = "true";
      }
      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, activestatus);

      setInactiveStatus(activestatus);
   }


   return (
      <>
         {loadingComponent}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">Users</h4>
                     {/* text-uppercase */}
                  </div>
               </div>

               <div class="row mb-1">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="card">
                        <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                           {/* <div class="search position-relative align-self-start mb-1 mt-1 mr-4"> */}
                           {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a> */}
                           <div class="d-flex justify-content-start">
                              <div class="search position-relative">
                                 <Search
                                    placeholderName="Search by EMP Id / Name / Role"
                                    onSearch={value => {
                                       searchList(value);
                                    }}

                                 />
                                 <a href="#" class="search-icon">
                                    <img src="/images/icons/search.svg" alt="" />
                                 </a>
                              </div>
                              <input type="checkbox"

                                 onChange={(e) => activeInctive(e)}
                                 id="inactive"
                                 name="inactive"
                                 style={{ marginLeft: "21px", marginTop: "4px" }} />
                              <span style={{ marginLeft: "10px", marginTop: "15px" }}>
                                 Inactive records only
                              </span>
                           </div>
                           <div class="d-flex align-self-start mb-1 mt-1">
                              <a type="button" class="btn btn-success" onClick={() => redirect("/addusers")}>Add Employee</a>
                           </div>
                        </div>
                        <div class="card-body pt-0 pb-0">
                           <div class="row">
                              <div class="col-12">
                                 <div class="table-responsive  border-top">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th>Emp ID</th>
                                             <th>Name</th>

                                             {/* <th>Email id</th>
                                                                     <th>Contact Number</th> */}
                                             <th>Role</th>
                                             <th style={{ width: "100px" }}>Actions</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                          {
                                             dispatcherList ? dispatcherList.map((data, index) => (
                                                <tr>
                                                   <td>{data.id ? data.id : "--"}</td>
                                                   <td class="text-capitalize">{data.firstName ? data.firstName : ""} {data.lastName ? data.lastName : ""} </td>

                                                   {/* <td>{data.email ? data.email : "--"}</td>
                                                                           <td>{data.phoneNumber ? data.phoneNumber : "--"}</td> */}
                                                   <td>{data.securityRoleName ? data.securityRoleName : "--"}</td>
                                                   <td><a onClick={() => redirect("/userDetails/" + data.id)} class="btn btn-outline-primary text-uppercase">View</a>&nbsp;
                                                      {/* {roles=="Manager"?data.securityRoleName=="Owner"?"":<a  onClick={() => redirect("/EditUser/" + data.id)}  class="btn btn-outline-primary text-uppercase">Edit</a>:<a  onClick={() => redirect("/EditUser/" + data.id)}  class="btn btn-outline-primary text-uppercase">Edit</a>} */}
                                                      {(() => {
                                                         if (roles == "Manager") {
                                                            if (data.securityRoleName == "Owner") {
                                                               return (
                                                                  ""
                                                               )
                                                            } else {
                                                               if (data.securityRoleName == "Technician" || data.securityRoleName == "Inspector") {
                                                                  return (<a onClick={() => redirect("/EditUser/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>)
                                                              }else{
                                                               if (sessionStorage.getItem('userId') == data.id) { return (<><a onClick={() => redirect("/EditUser/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a></>) } else {
                                                                  return (''
                                                                  )
                                                               }
                                                            }

                                                            }
                                                         } else {


                                                            if (data.securityRoleName == "Owner") {
                                                               if (sessionStorage.getItem('userId') == data.id) {
                                                                  return (<><a onClick={() => redirect("/EditUser/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a></>
                                                                  )
                                                               } else { return ("") }
                                                            } else {
                                                               return (<><a onClick={() => redirect("/EditUser/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a></>
                                                               )
                                                            }

                                                         }

                                                      })()}
                                                   </td>
                                                </tr>
                                             )) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                             </div>
                                          }
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between p-3">
                           <div class="d-flex">
                              <div class="dropdown show mr-3 ">
                                 <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {
                                       displayPage.map((data, index) => (
                                          <option value={data}>{data}</option>
                                       ))
                                    }
                                 </select>
                                 {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div> */}
                              </div>
                              <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                           </div>
                           <nav aria-label="Page navigation example">
                              <Pagination
                                 total={totalRecors}
                                 itemsPerPage={ITEMS_PER_PAGE}
                                 currentPage={currentPage}
                                 //onPageChange={page => setCurrentPage(page)}
                                 onPageChange={handlePageChange}
                              />

                           </nav>
                           {/* <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   )
};

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
