import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
// import DatePicker from "react-datepicker";

import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const PressureTestEdit = () => {
    const { taskOrderId, fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const [expiryDate, setexpiryDate] = useState(new Date());
    const [inspectionTypeId, setinspectionTypeId] = useState("");
    const [approveCertify, setApproveCertify] = useState('');
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    var documentLists = [];
    const [roles, setRoles] = useState("");
    const [statusInspection, setStatus] = useState("");
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    useEffect(() => {
        setRoles(localStorage.getItem("Userroles"));
        getInpection(fkAccountId, inspectionId);

    }, []);

    const autoFillUnitDesign = () => {
        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        $("#unitDesignText").val(thicknessDesignPressure);
    }

    $(document).ready(function () {

        $("#unitDesignText,#appliedUnitText,#MC300301002303305300,#MC304307,#MC310311312,#MC330331,#MC336,#DOT40e,#DOT407,#DOT412").keypress(function (e) {
            //     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //              return false;
            //   }
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 2) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 2)) {
                return false;
            }
        });
    });

    $(document).ready(function () {

        $("#thicknessDesignPressure,#mawp").keypress(function (e) {


            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 2) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 2)) {
                return false;
            }
        });
    });

    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForWet =
        {

            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForWet);

        services.getInspectionDetails(requestOptions).then((res) => {
            // setInspectionId(res.rows.id);

            setinspectionTypeId(res.rows.inspectionType.id);
            sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
            $("#MC300301002303305300").val(res.rows.pressureTest ? res.rows.pressureTest.specification.MC300301002303305300 : "");
            $("#MC304307").val(res.rows.pressureTest ? res.rows.pressureTest.specification.MC304307 : "");
            $("#MC310311312").val(res.rows.pressureTest ? res.rows.pressureTest.specification.MC310311312 : "");
            $("#MC330331").val(res.rows.pressureTest ? res.rows.pressureTest.specification.MC330331 : "");
            $("#MC336").val(res.rows.pressureTest ? res.rows.pressureTest.specification.MC336 : "");
            $("#DOT40e").val(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT40e : "");
            $("#DOT407").val(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT407 : "");
            $("#DOT412").val(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT412 : "");

            $("#unitDesignText").val(res.rows.pressureTest ? res.rows.pressureTest.unitdeSignPressure ? res.rows.pressureTest.unitdeSignPressure.unitDesignInput : "" : "");
            $("#appliedUnitText").val(res.rows.pressureTest ? res.rows.pressureTest.pressureAppliedToUnit ? res.rows.pressureTest.pressureAppliedToUnit.appliedUnitText : "" : "");
            //    alert(res.rows.pressureTest?res.rows.pressureTest.unitdeSignPressure?res.rows.pressureTest.unitdeSignPressure.unitDesign:"":"");
            $('input[name="unitDesign"][value="' + res.rows.pressureTest ? res.rows.pressureTest.unitdeSignPressure ? res.rows.pressureTest.unitdeSignPressure.unitDesign : "" : "" + '"]').trigger("click");
            $('input[name="appliedUnit"][value="' + res.rows.pressureTest ? res.rows.pressureTest.unitdeSignPressure ? res.rows.pressureTest.unitdeSignPressure.appliedUnit : "" : "" + '"]').trigger("click");


            setInspectionDetails(res.rows);
            setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
            $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
            $("#notes").val(res.rows ? res.rows.notes : "");
            $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
            setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
            documentLists.push(res.rows.documentList);
            sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
            setStatus(res.rows.status);
            if (res.rows) {
                if (res.rows.verifiedDate) {
                    setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
                }
                if (res.rows.expiryDate) {
                    setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
                }
            }
        });

    }

    const approveAndcertify = function (e) {
        setApproveCertify($("input[name='approvedCertify']:checked").val());

    }
    const save = (status) => {
        // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
        if (status == 'DRAFT') {
            var documentList = [];

            try {
                for (var i = 0; i < inputList.length; i++) {
                    // console.log("filebase64=="+inputList[i].filetobase64);
                    var extension = "";
                    var byteString = "";
                    if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                        extension = "pdf";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                    } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                        extension = "jpg";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                        extension = "png";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                        extension = "word";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
                        extension = "bmp";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                        extension = "docm";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
                        extension = "docx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                        extension = "dotx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                        extension = "dotx";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
                        extension = "dotm";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
                    }
                    else {

                    }


                    documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
                }

            } catch (exception) {
                //   Swal.fire({  
                //       position: 'center',  
                //       icon: 'error',  
                //       title: "",  
                //       text:"Please attach document",
                //       showConfirmButton: true 

                //     }).then((result) => 
                //     {

                //           if(result.isConfirmed) 
                //           {


                //           }
                //     });
                //     return false;
                // documentList.push();
            }
            try {
                if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                    var i;
                    var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                    for (i = 0; i < storedArray.length; i++) {

                        documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                    }

                } else {

                    documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
                }

            } catch (ex) {

            }

            if ($("#inspectedBy").val() === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Please enter inspectedBy",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {


                    }
                });
                return false;
            }

            var requestObjectInspection =
            {
                "id": inspectionId,
                // "fkAccountId": 1,
                "type": "Pressure Test/Retest Inspection",
                "inspectedBy": $("#inspectedBy").val(),
                "inspectedDate": $("#inspectedDate").val(),
                "verifiedBy": $("#verifiedBy").val(),
                "verifiedDate": $("#verifiedByBate").val(),
                "workOrderNumber": $("#workOrderNumber").val(),
                "notes": $("#notes").val(),
                "status": status,
                "taskOrderId": taskOrderId,
                "expiryDate": $("#expiryDate").val(),
                "inspectionType":
                {
                    "id": inspectionTypeId,
                    "name": "Pressure Test/Retest Inspection"
                },
                "result":
                {
                    "approvedCertify": "0",
                    "inspectionFailed": "0"
                },
                "dispositionOfVehicle":
                {
                    "vehicleReturnedServices": "0",
                    "vehicleWithdrawnFromService": "0",
                    "vehicleSentToAnotherRepairFacility": "0"
                },
                "vehicleDetails":
                {
                    "vinNumber": $("#vin").html(),
                    "lisencePlateNumber": $("#lisencePlateNumber").html(),
                    "id": vid,
                    "unitNumber": $("#lisencePlateNumber").html()
                },
                "pressureTest": {
                    "inspectAllSurfacesForLeaks": {
                        "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                        "internalValves": $("input[name='internalAllValves']:checked").val() === "1" ? "1" : "0",
                        "externalValves": $("input[name='externalvalves']:checked").val() === "1" ? "1" : "0",
                    },
                    "reiterbeingPressureTested":
                    {
                        "inspectAllSurfacesAndComponent": $("input[name='inspectallsurfaces']:checked").val() === "1" ? "1" : "0",
                        "wheelPlateRemoved": $("input[name='5thwheel']:checked").val() === "1" ? "1" : "0"
                    },
                    "specification": {
                        "MC300301002303305300": $("#MC300301002303305300").val() ? $("#MC300301002303305300").val() : "",
                        "MC304307": $("#MC304307").val() ? $("#MC304307").val() : "",
                        "MC310311312": $("#MC310311312").val() ? $("#MC310311312").val() : "",
                        "MC330331": $("#MC330331").val() ? $("#MC330331").val() : "",
                        "MC336": $("#MC336").val() ? $("#MC336").val() : "",
                        "DOT40e": $("#DOT40e").val() ? $("#DOT40e").val() : "",
                        "DOT407": $("#DOT407").val() ? $("#DOT407").val() : "",
                        "DOT412": $("#DOT412").val() ? $("#DOT412").val() : ""
                    },
                    "systemPressureTest": {
                        "areThereAnyLeaks": $("input[name='removesteam']:checked").val() ? $("input[name='removesteam']:checked").val() : ""
                    },
                    "unitdeSignPressure": {
                        "unitDesignInput": $('#unitDesignText').val() ? $('#unitDesignText').val() : '',
                        // "unitDesign": $("input[name='unitDesign']:checked").val() === "1" ? "1" : "0"
                        // "tankMaking": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                    },
                    "pressureAppliedToUnit": {
                        "appliedUnitText": $('#appliedUnitText').val() ? $('#appliedUnitText').val() : '',
                        "appliedUnit": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                        // "tankMaking": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                    },
                    "mawp": $('#mawp').val() ? $('#mawp').val() : '',
                    "testPressure": $('#thicknessDesignPressure').val() ? $('#thicknessDesignPressure').val() : ''
                },

                "documentList": documentLists

            };


            $("#loadingforsubmit").show();
            // const requestOptions = 
            // {
            //    method: 'POST',
            //    headers: { 'Content-Type': 'application/json'
            //             },

            //       body: JSON.stringify(requestObject)
            // };
            var requestOptions = requestObject.RequestHeader(requestObjectInspection);
            services.addInspection(requestOptions).then((res) => {
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: "Your changes have been successfully saved!",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                    }
                });
            });
        } else {

            if (handleSubmit() == true) {

                var documentList = [];


                try {
                    for (var i = 0; i < inputList.length; i++) {
                        // console.log("filebase64=="+inputList[i].filetobase64);
                        var extension = "";
                        var byteString = "";
                        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                            extension = "pdf";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                            extension = "jpg";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                            extension = "png";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                            extension = "word";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
                            extension = "bmp";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                            extension = "docm";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
                            extension = "docx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                            extension = "dotx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                            extension = "dotx";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                        }
                        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
                            extension = "dotm";
                            byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
                        }
                        else {

                        }

                        documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
                    }
                } catch (exception) {
                    //   Swal.fire({  
                    //       position: 'center',  
                    //       icon: 'error',  
                    //       title: "",  
                    //       text:"Please attach document",
                    //       showConfirmButton: true 

                    //     }).then((result) => 
                    //     {

                    //           if(result.isConfirmed) 
                    //           {


                    //           }
                    //     });
                    //     return false;
                    // documentList.push();
                }
                try {
                    if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                        var i;
                        var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                        for (i = 0; i < storedArray.length; i++) {

                            documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                        }

                    } else {

                        documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
                    }

                } catch (ex) {

                }
                if ($("#inspectedBy").val() === "") {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: "Please enter inspectedBy",
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {


                        }
                    });
                    return false;
                }

                var requestObjectInspection =
                {
                    "id": inspectionId,
                    "type": "Pressure Test/Retest Inspection",
                    "inspectedBy": $("#inspectedBy").val(),
                    "inspectedDate": $("#inspectedDate").val(),
                    "verifiedBy": $("#verifiedBy").val(),
                    "verifiedDate": $("#verifiedByBate").val(),
                    "workOrderNumber": $("#workOrderNumber").val(),
                    "expiryDate": $("#expiryDate").val(),
                    "notes": $("#notes").val(),
                    "status": status,
                    "taskOrderId": taskOrderId,
                    "inspectionType":
                    {
                        "id": inspectionTypeId,
                        "name": "Pressure Test/Retest Inspection"
                    },
                    "result":
                    {
                        "approvedCertify": "0",
                        "inspectionFailed": "0"
                    },
                    "dispositionOfVehicle":
                    {
                        "vehicleReturnedServices": "0",
                        "vehicleWithdrawnFromService": "0",
                        "vehicleSentToAnotherRepairFacility": "0"
                    },
                    // "result": {
                    //     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                    //     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                    //  },
                    //  "dispositionOfVehicle": {
                    //     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                    //     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                    //     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                    //  },
                    "vehicleDetails":
                    {
                        "vinNumber": $("#vin").html(),
                        "lisencePlateNumber": $("#lisencePlateNumber").html(),
                        "id": vid,
                        "unitNumber": $("#lisencePlateNumber").html()
                    },
                    "pressureTest": {
                        "inspectAllSurfacesForLeaks": {
                            "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                            "internalValves": $("input[name='internalAllValves']:checked").val() === "1" ? "1" : "0",
                            "externalValves": $("input[name='externalvalves']:checked").val() === "1" ? "1" : "0",
                        },
                        "reiterbeingPressureTested":
                        {
                            "inspectAllSurfacesAndComponent": $("input[name='inspectallsurfaces']:checked").val() === "1" ? "1" : "0",
                            "wheelPlateRemoved": $("input[name='5thwheel']:checked").val() === "1" ? "1" : "0"
                        },
                        "specification": {
                            "MC300301002303305300": $("#MC300301002303305300").val() ? $("#MC300301002303305300").val() : "",
                            "MC304307": $("#MC304307").val() ? $("#MC304307").val() : "",
                            "MC310311312": $("#MC310311312").val() ? $("#MC310311312").val() : "",
                            "MC330331": $("#MC330331").val() ? $("#MC330331").val() : "",
                            "MC336": $("#MC336").val() ? $("#MC336").val() : "",
                            "DOT40e": $("#DOT40e").val() ? $("#DOT40e").val() : "",
                            "DOT407": $("#DOT407").val() ? $("#DOT407").val() : "",
                            "DOT412": $("#DOT412").val() ? $("#DOT412").val() : ""
                        },
                        "systemPressureTest": {
                            "areThereAnyLeaks": $("input[name='removesteam']:checked").val() ? $("input[name='removesteam']:checked").val() : ""
                        },
                        "unitdeSignPressure": {
                            "unitDesignInput": $('#unitDesignText').val() ? $('#unitDesignText').val() : '',
                            "unitDesign": $("input[name='unitDesign']:checked").val() === "1" ? "1" : "0"
                        },
                        "pressureAppliedToUnit": {
                            "appliedUnitText": $('#appliedUnitText').val() ? $('#appliedUnitText').val() : '',
                            "appliedUnit": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                        },
                        "mawp": $('#mawp').val() ? $('#mawp').val() : '',
                        "testPressure": $('#thicknessDesignPressure').val() ? $('#thicknessDesignPressure').val() : ''
                    },

                    "documentList": documentLists

                };


                // setLoading(true);
                // const requestOptions = 
                // {
                //    method: 'POST',
                //    headers: { 'Content-Type': 'application/json'
                //             },

                //       body: JSON.stringify(requestObject)
                // };
                var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                if (statusInspection == "DRAFT") {
                    $("#loadingforsubmit").show();
                    services.addInspection(requestOptions).then((res) => {
                        $("#loadingforsubmit").hide();
                        // alert(res.success);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "",
                            text: "Your changes have been successfully saved!",
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {
                                navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                            }
                        });
                    });
                } else {

                    if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "",
                            text: "Please select disposition of tank",
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {

                                // alert("hhhhd")
                            }
                        });
                        return false;
                    }
                    if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "",
                            text: "Please select inspection result",
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {


                            }
                        });
                        return false;
                    }
                    if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

                    } else {

                        status = "Rejected";
                    }

                    var requestObjectInspection =
                    {
                        "id": inspectionId,
                        "expiryDate": $("#expiryDate").val(),
                        "verifiedBy": $("#verifiedBy").val(),
                        "verifiedDate": $("#verifiedByBate").val(),
                        "status": status,
                        "taskOrderId": taskOrderId,
                        "result": {
                            "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                            "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                        },
                        "dispositionOfVehicle": {
                            "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                            "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                        }

                    };
                    $("#loadingforsubmit").show();
                    services.addInspection(requestOptions).then((res) => {
                        // setLoading(false);
                        $("#loadingforsubmit").hide();
                        if (res.success) {

                            //certify inspection
                            var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                            services.certifyInspection(requestOptions).then((res) => {
                                $("#loadingforsubmit").hide();
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: "",
                                    text: res.message,
                                    showConfirmButton: true

                                }).then((result) => {

                                    if (result.isConfirmed) {
                                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                                    }
                                });
                            });
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: "",
                                text: "Your changes have been successfully saved!",
                                showConfirmButton: true

                            }).then((result) => {

                                if (result.isConfirmed) {
                                    navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                                }
                            });

                        }
                    });



                }
            }
        }

    }
    const handleSubmit = () => {

        let form_is_valid = true;

        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        if (!thicknessDesignPressure) {
            form_is_valid = false;
            $("#thicknessDesignPressure-message").html('*Design pressure is required');

            // $('html, body').animate({
            //     scrollTop: $(".form-inspection-title").offset().top - 80
            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            return false

        } else {

            $("#thicknessDesignPressure-message").html('');
            form_is_valid = true;
        }



        var mawp = $('#mawp').val();
        if (!mawp) {
            form_is_valid = false;
            $("#mawp-message").html('*MAWP is required');
            // $('html, body').animate({
            //     scrollTop: $(".form-inspection-title").offset().top - 80
            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            return false
        } else {
            $("#mawp-message").html('');
            form_is_valid = true;
        }


        var closeAllValves = $("input[name='closeAllValves']:checked").val();
        var internalValves = $("input[name='internalAllValves']:checked").val();
        var externalValves = $("input[name='externalvalves']:checked").val();

        var inspectallsurfaces = $("input[name='inspectallsurfaces']:checked").val();
        var wheel = $("input[name='5thwheel']:checked").val();
        var removesteam = $("input[name='removesteam']:checked").val();

        console.log(closeAllValves + '' + internalValves + '' + externalValves + '' + inspectallsurfaces + '' + wheel + '' + removesteam);

        if (closeAllValves == undefined || internalValves == undefined || externalValves == undefined || inspectallsurfaces == undefined || wheel == undefined || removesteam == undefined) {
            $('html, body').animate({
                scrollTop: $(".header-top-bg").offset().top
            }, 500);
            $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
            form_is_valid = false;

        } else {
            $('#error_message').html('');
        }

        return form_is_valid;

    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const redirect = (page) => {

        navigate(page);

    }
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {}]);
    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }
    const onUploadFileChange = (event, index) => {


        var target = event.target;
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                const { name, value } = event.target;
                const list = [...inputList];
                list[index][name] = result;
                setBase64file(result);
                // setFile(result)
                setfileName(target.files[0]);
                // setFileName(target.files[0])
            }
        })
    }

    return (
        <>
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex justify-content-between align-items-center my-4">
                                <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <HeaderBlock cids={fkAccountId} />
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card">

                                <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                        <VehicleBasicInfo customerId={vid} />
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="row p-3">
                                    <div class="col-lg-12">
                                        <h4 class="form-inspection-title text-center">Pressure Test / Retest Inspection</h4>
                                        <span id="thicknessDesignPressure-message"  class="error"></span>
                                        <span id="mawp-message"  class="error"></span>
                                        <p id="error_message" class="center error" ></p>
                                    </div>
                                    <div class="col-lg-12"><WorkOrderNumberDiv /></div>
                                    <div class="col-lg-12">
                                        <div class="inspection-wrap">

                                            <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                                <p>NOTE: FUSIBLE CAPS, PRESSURE RELIEF VALVES, VACUUM VENTS, RUPTURE DISC MUST BE REMOVED AND OUTLETS BLANKED OFF FOR THE PRESSURE TEST PROCEDURE(PROCEDURE MUST BE COMPLETED FOR EACH COMPARTMENT) </p>
                                                <p>NOTE: All reclosing pressure relief valves must be removed from the cargo tank for inspection and testing. Each reclosing pressure relief valve must open all the required set pressure and reseal to a leak tight condition at 90 percent of the of the set-to-discharge pressure prescribed for the applicable cargo tank specification.</p>
                                            </div>

                                            <div class="table-3 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        {/* <tr>
                                                            <th scope="col">Specification</th>
                                                            <th scope="col">Test Pressure</th>
                                                        </tr> */}
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8 col-sm-12">MC 300, 301, 302, 303, 305, 306</div>
                                                                    <div class="col-md-4 col-sm-12">
                                                                        {/* <input type="text" class="form-control" id="MC300301002303305300" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>3 psig or design pressure, whichever is greater.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 304, 307 	                                                            </div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="MC304307" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td> 40 psig or 1.5 times the design pressure, whichever is greater.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 310,311,312</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="MC310311312" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>3 psig, or 1.5 times the design pressure, whichever is greater.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 330,331</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="MC330331" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>1.5 times either the MAWP or the re-rated pressure, whichever is applicable</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 338</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="MC336" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>1.25 times either the MAWP or the re-rated pressure, whichever is applicable.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 406</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="DOT40e" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>5 psig or 1.5 times the MAWP, whichever is greater</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 407</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="DOT407" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>40 psig or 1.5 tunes the MAWP, whichever is greater</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 412</div>
                                                                    <div class="col-md-4">
                                                                        {/* <input type="text" class="form-control" id="DOT412" aria-describedby="emailHelp" width="90px" /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>1.5 times the MAWP.</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row align-items-center justify-content-start mb-3">
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Design Pressure <span class="star">*</span></lable>
                                                        <input type="text" class="form-control" id="thicknessDesignPressure" defaultValue={inspectionDetails.pressureTest ? inspectionDetails.pressureTest.testPressure : ''} aria-describedby="emailHelp" width="90px" maxLength="7" onKeyUp={autoFillUnitDesign} />
                                                    </div>


                                                </div>
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>MAWP <span class="star">*</span></lable>
                                                        <input type="text" class="form-control" id="mawp" defaultValue={inspectionDetails.pressureTest ? inspectionDetails.pressureTest.mawp : ''} aria-describedby="emailHelp" width="90px" maxLength="7" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            {/* close all valves and fill tank with manhole cover open. Inspect all surfaces for leeks at all top and bottom component Installations below water line. Are there
                                                                any leaks at the cleanouts - welds - valve sumps -nozzles */}
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td><p>Close all valves and fill tank with manhole cover open. Inspect all surfaces for leeks at all top and bottom component installations below water line. Are there any leaks? at the cleanouts - welds - valve sumps -nozzles.</p></td>
                                                            {/* <td class="text-center">  <input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="1" /></td>
                                                    <td class="text-center">  <input type="radio" name="closeAllValves" value="0" aria-label="Radio button for following text input" /></td> */}
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.closeAllValves === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="0" /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()}

                                                        </tr>

                                                        <tr>
                                                            <td>Internal valves: open external valves - Inspect for leaks • close external valves. Are there any leaks?</td>
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.internalValves === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()}
                                                            {/* {inspectionDetails.pressureTest?inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.internalValves=="1"?<td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="1" checked /></td>:<td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="0"  /></td>:""}
                                                 {inspectionDetails.pressureTest?inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.internalValves=="0"?<td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="1"  /></td>:<td class="text-center"><input type="radio" name="internalAllValves" aria-label="Radio button for following text input" value="1" checked /></td>:""} */}
                                                        </tr>

                                                        <tr>
                                                            <td>External valves: open Internal valves, fill piping with water • Inspect for leaks • close Internal valves. Are there any leaks?</td>
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.externalValves === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()}

                                                            {/* {inspectionDetails.pressureTest?inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.externalValves=="1"?<td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="1" checked /></td>:<td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="0"  /></td>:""}
                                                 {inspectionDetails.pressureTest?inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.externalValves=="0"?<td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="1"  /></td>:<td class="text-center"><input type="radio" name="externalvalves" aria-label="Radio button for following text input" value="1" checked /></td>:""} */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>Inspect all surfaces and component installations for leaks • pay particular attention to manhole assembly area/internal valve sump. Are there any leaks?</td>
                                                            {/* <td class="text-center">  <input type="radio" name="inspectallsurfaces" value="1" aria-label="Radio button for following text input" /></td>
                                                   <td class="text-center">  <input type="radio" name="inspectallsurfaces" value="0" aria-label="Radio button for following text input" /></td> */
                                                            }
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.reiterbeingPressureTested.inspectAllSurfacesAndComponent === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()}
                                                            {/* {inspectionDetails.pressureTest?inspectionDetails.pressureTest.reiterbeingPressureTested.inspectAllSurfacesAndComponent=="1"?<td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="1" checked /></td>:<td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="0"  /></td>:""}
                                                     {inspectionDetails.pressureTest?inspectionDetails.pressureTest.reiterbeingPressureTested.inspectAllSurfacesAndComponent=="0"?<td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="1"  /></td>:<td class="text-center"><input type="radio" name="inspectallsurfaces" aria-label="Radio button for following text input" value="1" checked /></td>:""} */}
                                                        </tr>

                                                        <tr>
                                                            <td>5th wheel plate removed & inspected</td>
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.reiterbeingPressureTested.wheelPlateRemoved === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()}
                                                            {/* {inspectionDetails.pressureTest?inspectionDetails.pressureTest.reiterbeingPressureTested.wheelPlateRemoved=="1"?<td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="1" checked /></td>:<td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="0"  /></td>:""}
                                                     {inspectionDetails.pressureTest?inspectionDetails.pressureTest.reiterbeingPressureTested.wheelPlateRemoved=="0"?<td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="1"  /></td>:<td class="text-center"><input type="radio" name="5thwheel" aria-label="Radio button for following text input" value="1" checked /></td>:""} */}
                                                            {/* <td class="text-center">  <input type="radio" name="5thwheel" value="1" aria-label="Radio button for following text input" /></td>
                                                   <td class="text-center">  <input type="radio" name="5thwheel" value="0" aria-label="Radio button for following text input" /></td> */}
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>




                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">STEAM/HEAT SYSTEM PRESSURE TEST</th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                            <th scope="col">N/A</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>Remove steam traps - Intransit heat lines, plug all outlets. Fill heat system with water, apply pressure (1.5 times system design pressure) hold pressure for 5 minutes for a successful test. Are there any leaks?</td>
                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="2" defaultChecked={false} /></td></>)
                                                                    } else if (inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks === "2") {
                                                                        return (<><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td><td><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="2" defaultChecked={true} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td><td><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="2" defaultChecked={false} /></td></>)
                                                                    }

                                                                }

                                                            })()}

                                                            {/* {inspectionDetails.pressureTest?inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks=="1"?<td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1" checked /></td>:<td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="0"  /></td>:""}
                                                     {inspectionDetails.pressureTest?inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks=="0"?<td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1"  /></td>:<td class="text-center"><input type="radio" name="removesteam" aria-label="Radio button for following text input" value="1" checked /></td>:""} */}
                                                            {/* <td class="text-center">  <input type="radio" name="removesteam" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="removesteam" value="0" aria-label="Radio button for following text input" /></td> */}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row align-items-center justify-content-start mb-3">
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Unit design pressure</lable>
                                                        <input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" width="90px" maxLength="7" />
                                                    </div>


                                                </div>
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Pressure applied to unit</lable>
                                                        <input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="90px" maxLength="7" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            {/* <td className="unitDzine"><span>Unit design pressure</span><input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" width="40px" /><span>Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="40px" maxLength="7" /></td>
                                                            <td class="text-center"> </td>
                                                            <td class="text-center"> </td> */}
                                                            {/* {(() => {
                                                                if (inspectionDetails.pressureTest) {
                                                                    if (inspectionDetails.pressureTest.unitdeSignPressure.unitDesign === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="unitDesign" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="unitDesign" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="unitDesign" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="unitDesign" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }

                                                            })()} */}
                                                            {/* <td class="text-center">  <input type="radio" name="unitDesign" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="unitDesign" value="0" aria-label="Radio button for following text input" /></td> */}
                                                        </tr>

                                                        <tr>
                                                            {/* <td className="unitDzine"><span>Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="40px" /><span>PSI</span></td> */}
                                                            {/* <td class="text-center">  <input type="radio" name="appliedUnit" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="appliedUnit" value="0" aria-label="Radio button for following text input" /></td> */}

                                                            <td>Tank marking: Date ("Month and Year") and service symbol (P) after all defects are corrected.</td>

                                                            {(() => {
                                                                if (inspectionDetails.pressureTest) {

                                                                    if (inspectionDetails.pressureTest.pressureAppliedToUnit.appliedUnit === "1") {

                                                                        return (<><td class="text-center"><input type="radio" name="appliedUnit" aria-label="Radio button for following text input" value="1" defaultChecked={true} /></td><td class="text-center"><input type="radio" name="appliedUnit" aria-label="Radio button for following text input" value="0" defaultChecked={false} /></td></>)
                                                                    } else {
                                                                        return (<><td class="text-center"><input type="radio" name="appliedUnit" aria-label="Radio button for following text input" value="1" defaultChecked={false} /></td><td class="text-center"><input type="radio" name="appliedUnit" aria-label="Radio button for following text input" value="0" defaultChecked={true} /></td></>)
                                                                    }
                                                                }


                                                            })()}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12" style={{ marginTop: "-18px" }}>
                                                    <div class="align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />

                                                        {/* <b>{data.title}.{data.extension}</b> */}
                                                        <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                            <img src="" class="mr-2" />
                                                            <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                                                    if (data.extension == 'pdf') {
                                                                        return (
                                                                            <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                        )
                                                                    } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                        return (
                                                                            <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                        )
                                                                    } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                        return (
                                                                            <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                        )
                                                                    }
                                                                })()}
                                                                    {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                    {data.name ? data.name : "--"} </a></p></span>
                                                            <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>

                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                <div class="row p-3">
                                    <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                </div>

                                {/* <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}
                                <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                                {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                                {(() => {
                                    if (statusInspection == "DRAFT") {
                                        <></>
                                    } else {
                                        if (!roles.match("Technician")) {
                                            if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                                return (
                                                    <>
                                                        <div class="result-didspos">
                                                            <div class="p-4">
                                                                <span>Result</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Inspection Failed</b>
                                                                </div>
                                                            </div>
                                                            <div class="p-4">
                                                                <span>Disposition of Tank</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                                    <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <><div class="result-didspos ">
                                                        <div class="p-4">
                                                            <span>Result</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                                            </div>
                                                        </div>
                                                        <div class="p-4">
                                                            <span>Disposition of Tank</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                                    </>
                                                )
                                            }
                                        }
                                    }
                                })()}
                                <div class="divider2"></div>
                                <div class="row p-3 pr-4 d-flex justify-content-end">
                                    <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                    {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                                    {statusInspection == "DRAFT" ? <button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button> : ""}
                                    {(() => {
                                        if (statusInspection == "DRAFT") {
                                            return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                        } else {
                                            if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                                return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                            }
                                            else {
                                                return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </>
    )
};

PressureTestEdit.propTypes = {};

PressureTestEdit.defaultProps = {};

export default PressureTestEdit;
