import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import { ErrorMessage } from "@hookform/error-message"
import { useNavigate, useParams } from "react-router-dom";

import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import services from "../../../../services/services";
// import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import "cropperjs/dist/cropper.css";
import Getstates from "../../../../utility/getstates";
import getCroppedImg from "../../../../utility/cropImage";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import GetTruckType from "../../../../utility/getTruckTypes";
import moment from 'moment'
import Cropper from "react-cropper";
import GetTankerSubType from "../../../../utility/getTankerSubType";
import GetTrailerSubType from "../../../../utility/getTrailerSubType";
import vehicle_details_api from "../../vehicle/vehicle_details_api";
import { requestObject } from "../../../../utility/requestObject";
import { loadingComponent } from "../../../../utility/Loading";
import DatePicker from "react-datepicker";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const FleetEditVehicle = () => {


   // select.appendTo('body');

   const { id } = useParams();
   const navigate = useNavigate();
   // const dummyImge = window.location.origin + "/images/nia.JPG";
   const [dummyImge, setDummyImge] = useState(window.location.origin + "/images/no-vehicle-image.png")
   const [imgsrc, setImageSrc] = useState()
   const [image, setImage] = useState()
   const [states, setState] = useState([])
   const [cropper, setCropper] = useState()
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
   const [rotation, setRotation] = useState(0)
   const [cropData, setCropData] = useState("")
   const [itemName, setFieldName] = useState('')
   const [truckType, setTruckType] = useState('')
   const [trailerType, setTrailerType] = useState('')
   const [isinstalled, setinstalled] = useState('')
   const [tankerSubTypes, setTankerSubTypes] = useState('')
   const [loading, setLoading] = useLoading("Please wait...");
   const [croppedImage, setCroppedImage] = useState(null)

   const [vechileDetails, setVehicleDetails] = useState([]);
   const [customValues, setCustomValues] = useState([]);

   const [isInsulated, setIsInsulated] = useState([]);
   const [compartmented, setCompartmented] = useState('true');
   const [length, setLength] = useState();
   const [width, setWidthLength] = useState();
   const [height, setHeight] = useState();
   const [capacity, setCapacity] = useState();
   const [cid, setCustomerId] = useState([]);
   const [naviationfrom, setNaviation] = useState("");
   const [manufacturerDate, setManufacturerDate] = useState('');
   const [originalDate, setOriginalDate] = useState('');

   const [trailerSubtype, setTrailerSubType] = useState("");

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all"
      // criteriaMode: "all", mode: "onBlur"
   });


   // var compartmentArray=$('input[name="compartment_gallancy[]"]').length;

   // for(let j = 0; j < compartmentArray.length; j++){
   //    console.log(compartmentArray[j].value);
   // }

   function TankerSubTypeFunction() {
      var tankerSubType = $('#tanker_Sub_Type option:selected').text();
      if (tankerSubType == 'Other') {
         $("#otherTankerSubType").show();
      } else {
         $("#otherTankerSubType").hide();
      }
   }

   function TankerFunction() {
      var linedValue2 = $('#trailer_Sub_Type option:selected').text();
      if (linedValue2 == 'tanker' || linedValue2 == 'Tanker') {
         $("#spec_plate").show();
         setTrailerSubType("Tanker")
         GetTankerSubType(setTankerSubTypes, "tanker");
      } else {
         $("#spec_plate").hide();
         setTrailerSubType("trailer")
         GetTankerSubType(setTankerSubTypes, "trailer");
         $("#otherTankerSubType").hide();
      }

   }


   $(document).ready(function () {

      $('#vin_Number').keypress(function (e) {
         // $('#vin_Number,#unitNumber').keypress(function( e ) {
         if (e.which === 32) {
            return false;
         }
      });

      $("#mawp,#testPressure,#manufacturerDate,#originalDate").keypress(function (e) {

         if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
            ((e.which < 48 || e.which > 57) &&
               (e.which != 0 && e.which != 8))) {
            return false;
         }

         var text = $(this).val();

         if ((text.indexOf('.') != -1) &&
            (text.substring(text.indexOf('.')).length > 3) &&
            (e.which != 0 && e.which != 8) &&
            ($(this)[0].selectionStart >= text.length - 3)) {
            return false;
         }
      });




      $('input[name="compartment_gallancy[]"]').keypress(function (e) {

         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }

      });
      var nofcompartment = sessionStorage.getItem("nofcompartments");

      // alert(vechileDetails.isInsulated);

   });


   useEffect(() => {
      setNaviation(sessionStorage.getItem("naviationfrom"));
      $("#otherTankerSubType").hide();
      $("#spec_plate").hide();
      vehicle_details_apiD(id);



      var minOffset = 0, maxOffset = 60; // Change to whatever you want
      var thisYear = (new Date()).getFullYear();
      var select = $('#year');

      for (var i = minOffset; i <= maxOffset; i++) {
         var year = thisYear - i;
         $('<option>', { value: year, text: year }).appendTo(select);
      }

      $('#installedBymanfactures').hide();
      Getstates(setState);
      $("#truck").hide();
      $("#trailor").hide();
      $("#tractor_display").show();
      // GetTankerSubType(setTankerSubTypes);
      GetTrailerSubType(setTrailerType);
      GetTruckType(setTruckType);
      ///compart update/////////////
      // var nofcompartment=sessionStorage.getItem("nofcompartments");
      // var capacityGallons=sessionStorage.getItem("compartmentCapacityInGallons");
      // var isInstalledWithVaporCollectionSys=sessionStorage.getItem("isInstalledWithVaporCollectionSys");

      // if(isInstalledWithVaporCollectionSys==="true")
      // {

      //       setinstalled(1);
      //       $('#installedBymanfactures').show();


      // }else{

      //    setinstalled(0);
      //    $('#installedBymanfactures').hide();
      // }
      // if(nofcompartment!=="")
      // {

      //    $('.capacity-Gallons').show();
      //    var array = capacityGallons.split(',');

      //    $("#divTxtGroup").empty();
      //    var num = nofcompartment;
      //    if (num > 0) {
      //       for (var i = 0; i < num; i++) {
      //          // alert(array[i]);

      //          var newTextBoxDiv1 = $(document.createElement('div')).attr("id", 'divTxt' + i);
      //          $('#titleHeading').html('<center><h5>Compartment capacity in gallons</h5></center><br/>');

      //          newTextBoxDiv1.after().html('<div class="form-group"><label for="exampleInputEmail1">Compartment ' + (i +1)+ ' <span class="star">*</span>: </label><input type="text" class="form-control" name="compartment_gallancy[]" id="compartment' + i + '" aria-describedby="emailHelp" placeholder="capacity in gallons"" value= ' + array[i]+ '><span id="compartmentMessage' + i + '"></div>');
      //          newTextBoxDiv1.appendTo("#divTxtGroup");
      //          // $("#compartment"+(i+1)).val(array[i]);
      //       }
      //    }
      //    $('#nofcompartment').show();

      // }else
      // {
      //     $('.capacity-Gallons').hide();
      //    $('#nofcompartment').hide();
      // }
      ///compart update/////////////

      // $('.capacity-Gallons').hide();
      $('#otherDisplay1').hide();
      $('#otherDisplay').hide();
      $('#isinstalled').on('change', function () {

         if (this.value == "true") {
            setinstalled(1);
            $('#installedBymanfactures').show();

         } else {
            setinstalled(0);
            $('#installedBymanfactures').hide();

         }

      });
      $('#Compartmented').on('change', function () {
         //   if (this.value == "true") {

         $('#noofCompartments').show();
         $('#nofcompartment').show();
         $('.capacity-Gallons').show();

         //    } else {
         //       $('#noofCompartments').hide();
         //       $('#nofcompartment').hide();
         //       $('.capacity-Gallons').hide();
         // }
      });

      $('.configuration').on('change', function () {
         console.log(this.value)
         if (this.value == "other") {
            $('#otherDisplay1').show();
            $('#otherDisplay').show();
         } else {
            $('#otherDisplay1').hide();
            $('#otherDisplay').hide();
         }

      });


      // $("input[name=installed_manfacture][value='manufacture']").attr("checked","checked").trigger('click');

      $('#Compartmented').on('click', function () {

         // if (this.value == "true") {

         $('#noofCompartments').show();
         $('#nofcompartment').show();
         $('.capacity-Gallons').show();

         // } else {
         //    $('#noofCompartments').hide();
         //    $('#nofcompartment').hide();
         //    $('.capacity-Gallons').hide();
         // }
      });



      $("#4x2").attr('checked', true).trigger('click');
      $("#4x21").attr('checked', true).trigger('click');
      $("#connector").attr('checked', true).trigger('click');
      $("#Cabconfig").attr('checked', true).trigger('click');
      $("#traileroption1").attr('checked', true).trigger('click');
      $("#installed_manfacture").attr('checked', true).trigger('click');

      $("#trailor").show();
      $("#tractor_display").hide();
      $("#truck").hide();
      $("input[name='truck']").on("click", function () {

         switch ($("input[name='truck']:checked").val()) {

            case "1":
               $("#trailor").hide();
               $("#tractor_display").show();
               $("#truck").hide();
               break;
            case "3":

               $("#trailor").show();
               $("#tractor_display").hide();
               $("#truck").hide();
               break;
            case "2":

               $("#truck").show();
               $("#trailor").hide();
               $("#tractor_display").hide();
               break;

         }

      });

   }, []);
   const redirectBack = () => {

      if (naviationfrom === "fleetVehicle") {
         navigate("/fleetowner/Vehicle")

      } else {
         navigate("/fleetowner/VehicleInformation/" + id)
      }
   }
   const vehicle_details_apiD = (id) => {


      var vehicle_request =
      {

         "id": id

      };


      var requestOptions = requestObject.RequestHeader(vehicle_request);

      services.getVehicle_Details(requestOptions).then((res) => {
         setVehicleDetails(res.rows);
         console.log(JSON.stringify(res.rows));
         $('#unitNumber').val(res.rows.unitNumber);
         $('#vin_Number').val(res.rows.vinNumber);
         $('#serialNumber').val(res.rows.serialNumber);
         $('#liecencePlate').val(res.rows.lisencePlateNumber);
         //  $('#state').val(res.rows.stateName);
         $('#make').val(res.rows.make);
         $('#model').val(res.rows.model);
         $('#Manfacturer').val(res.rows.manfacturer);
         $('#year').val(res.rows.year);

         $('#vehicleTypeName').val(res.rows.vehicleTypeName);
         if (res.rows.vehicleTypeName == "Tractor") {

            $("#option1").attr('checked', true).trigger('click');
            // $('#traileroption1').removeAttr('checked');
            // $('#option2').removeAttr('checked');

            $("#truck").hide();
            $("#trailor").hide();
            $("#tractor_display").show();

            // $("#option1").attr('disabled', true);
            $("#traileroption1").attr('disabled', true);
            $("#option2").attr('disabled', true);

         } else if (res.rows.vehicleTypeName == "Truck") {
            // alert("rueck");
            // $("#option1").removeAttr('checked');
            // $('#traileroption1').removeAttr('checked');
            $('#option2').attr('checked', true).trigger('click');

            $("#truck").show();
            $("#trailor").hide();
            $("#option1").attr('disabled', true);
            $("#traileroption1").attr('disabled', true);
            // $("#option2").attr('disabled', true);
            $("#tractor_display").hide();

         } else if (res.rows.vehicleTypeName == "Trailor") {

            // $("#option1").prop('checked', false);
            $('#traileroption1').prop('checked', true);
            // $('#option2').removeAttr('checked');

            $("#truck").hide();
            $("#trailor").show();
            $("#tractor_display").hide();

            $("#option1").attr('disabled', true);
            //  $("#traileroption1").attr('disabled', true);
            $("#option2").attr('disabled', true);
         } else {
            $("#truck").hide();
            $("#trailor").show();
            $("#tractor_display").hide();
         }


         $('#tankerSubTypeId').val(res.rows.tankerSubTypeId);
         $('#tankerSubTypeName').val(res.rows.tankerSubTypeName);

         if (res.rows.trailerSubTypeName == 'Tanker') {
            GetTankerSubType(setTankerSubTypes, "tanker");
         } else {
            GetTankerSubType(setTankerSubTypes, "trailer");
         }

         if (res.rows.tankerSubTypeName == 'tanker') {
            $("#spec_plate").show();
         }

         if (res.rows.tankerSubTypeName == 'Other') {
            $("#otherTankerSubType").show();
         } else {
            $("#otherTankerSubType").hide();
         }

         $('#otherTankerValue').val(res.rows.otherTankerSubType);

         $('#trailerSubTypeId').val(res.rows.trailerSubTypeId);
         $('#trailerSubTypeName').val(res.rows.trailerSubTypeName);

         //  $('#isInsulated').val(res.rows.isInsulated);
         //  setIsInsulated(res.rows.isInsulated);
         $('#Cargo').val(res.rows.cargo);
         //  $('#isLined').val(res.rows.isLined);
         $('#LiningType').val(res.rows.liningType);
         $('#capacity_in_Gallons').val(res.rows.capacityInGallons);

         var nofcompartment = res.rows.numberOfCompartments;
         var capacityGallons = res.rows.compartmentCapacityInGallons;
         var isInstalledWithVaporCollectionSys = res.rows.isInstalledWithVaporCollectionSys;
         //   alert("rwerwre"+isInstalledWithVaporCollectionSys);
         if (isInstalledWithVaporCollectionSys == true) {

            setinstalled(1);
            $('#installedBymanfactures').show();


         } else {

            setinstalled(0);
            $('#installedBymanfactures').hide();
         }
         if (nofcompartment !== "") {

            $('.capacity-Gallons').show();
            try {
               var array = capacityGallons.split(',');
               $("#divTxtGroup").empty();
               var num = nofcompartment;
               if (num > 0) {
                  for (var i = 0; i < num; i++) {
                     var newTextBoxDiv1 = $(document.createElement('div')).attr("id", 'divTxt' + i);
                     $('#titleHeading').html('<center><h5>Compartment capacity in gallons</h5></center><br/>');

                     newTextBoxDiv1.after().html('<div class="form-group"><label for="exampleInputEmail1">Compartment ' + (i + 1) + ' <span class="star">*</span>: </label><input type="text" class="form-control" name="compartment_gallancy[]" id="compartment' + i + '" aria-describedby="emailHelp" placeholder="capacity in gallons"" value= ' + array[i] + '><p id="compartmentMessage' + i + '" class="error"></p></div>');
                     newTextBoxDiv1.appendTo("#divTxtGroup");

                  }
               }
               $('#noofCompartments').show();

            } catch (ex) {

            }


         } else {
            $('.capacity-Gallons').hide();
            $('#noofCompartments').hide();
         }

         //  $('#isCompartmented').val(res.rows.isCompartmented);

         //  setCompartmented(res.rows.isCompartmented);

         //  $('#Compartmented').on("load",function () {

         //    alert(compartmented);
         //     // setCompartmented()

         //     if (compartmented == "true") {

         //        $('#noofCompartments').show();
         //        $('#nofcompartment').show();
         //        $('.capacity-Gallons').show();
         //     }
         //  });

         //  $('#numberOfCompartments').val(res.rows.numberOfCompartments);
         //   if(res.rows.numberOfCompartments!=''){
         //         $("#divTxtGroup").empty();
         //         var num = res.rows.numberOfCompartments;
         //         if (num > 0) {
         //            for (var i = 1; i <= num; i++) {
         //               var newTextBoxDiv1 = $(document.createElement('div')).attr("id", 'divTxt' + i);
         //               $('#titleHeading').html('<center><h5>compartment capacity in gallons</h5></center><br/>');

         //               newTextBoxDiv1.after().html('<div class="form-group"><label for="exampleInputEmail1">Compartment ' + i + ' <span class="star">*</span>: </label><input type="text" class="form-control" name="compartment_gallancy[]" id="compartment' + i + '" aria-describedby="emailHelp" placeholder="capacity in gallons"" ><span id="compartmentMessage' + i + '"></span></div>');
         //               newTextBoxDiv1.appendTo("#divTxtGroup");

         //            }
         //         }

         //   }



         $('#compartmentCapacityInGallons').val(res.rows.compartmentCapacityInGallons);
         //  $('#isInstalledWithVaporCollectionSys').val(res.rows.isInstalledWithVaporCollectionSys);
         //  $('#installedByManfacturer').val(res.rows.installedByManfacturer);

         $('#truckTypeId').val(res.rows.truckTypeId);
         $('#truckTypeName').val(res.rows.truckTypeName);

         $('#lengthFeet').val(res.rows.lengthInFeet);
         $('#widthInFeet').val(res.rows.widthInFeet);

         $('#Heightinfeet').val(res.rows.heightInFeet);

         // $('#isRefrigerated').val(res.rows.isRefrigerated);

         //  $('input[name="insulated_val"][value="' + res.rows.isInsulated + '"]').trigger("click");
         //  $('input[name="Refrigerated_val"][value="' + res.rows.isRefrigerated + '"]').trigger("click");

         if (res.rows.isInsulated == '1') {
            $('#isinsulated').prop('checked', true);
         }
         if (res.rows.isRefrigerated == '1') {
            $('#Refrigerated').prop('checked', true);
         }

         $('#CapacityTons').val(res.rows.capacityTons);

         $('#axleConfiguration').val(res.rows.axleConfiguration);

         // setTimeout(() => {
         //  $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
         //  $('input[name="configuration"][value="' + res.rows.axleConfiguration+'1' + '"]').trigger("click");
         // } , 1000);
         $('input[name="installed_manfacture"][value="' + res.rows.installedByManfacturer + '"]').trigger("click");

         $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
         $('input[name="configurationt"][value="' + res.rows.axleConfiguration + '"]').trigger("click");

         switch (res.rows.axleConfiguration) {
            case "4X2":
               $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               $('input[name="configurationt"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               break;
            case "6X2":
               $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               $('input[name="configurationt"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               break;
            case "6X4":
               $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               $('input[name="configurationt"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               break;
            case "6X6":
               $('input[name="configuration"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               $('input[name="configurationt"][value="' + res.rows.axleConfiguration + '"]').trigger("click");
               break;
            default:
               try {
                  var other1 = res.rows.axleConfiguration.split(/[,X]/);
                  $("#othervalue1").val(other1[0]);
                  $("#othervalue2").val(other1[1]);
                  $("#othervalue1truck").val(other1[0]);
                  $("#othervalue2truck").val(other1[1]);
               } catch (ex) {

               }

               $('input[name="configuration"][value="other"]').trigger("click");
               $('input[name="configurationt"][value="other"]').trigger("click");

               break;

         }
         // if(res.rows.axleConfiguration=="4X2"){
         //   $('#4X2').prop('checked', true);
         //   $('#4X21').prop('checked', true);
         //   }else if(res.rows.axleConfiguration=="6X2"){
         //     $('#6X2').prop('checked', true);
         //     $('#6X21').prop('checked', true);
         //   }else if(res.rows.axleConfiguration=="6X4"){
         //      $('#6X4').prop('checked', true);
         //      $('#6X41').prop('checked', true);
         //    }else if(res.rows.axleConfiguration=="6X6"){
         //      $('#6X6').prop('checked', true);
         //      $('#6X61').prop('checked', true);
         //    }
         $('#Cabconfig').val(res.rows.cabConfiguration);

         if (res.rows.cabConfiguration == "standard") {
            $('#Cabconfig').prop('checked', true);
         } else if (res.rows.cabConfiguration == "Cab over engine") {
            $('#caboverengine').prop('checked', true);
         }

         $('#connector').val(res.rows.connector);

         if (res.rows.connector == "Fifth Wheel") {
            $('#connector').prop('checked', true);
         } else if (res.rows.connector == "Drawbar") {
            $('#Drawbar').prop('checked', true);
         } else if (res.rows.connector == "Tow Hitch") {
            $('#Hitch').prop('checked', true);
         }

         $('#engineMake').val(res.rows.engineMake);
         $('#engineMakeTruck').val(res.rows.engineMake);
         $('#engineModel').val(res.rows.engineModel);
         $('#engineModelTruck').val(res.rows.engineModel);

         $('#horsePower').val(res.rows.horsePower);
         $('#TruckhorsePower').val(res.rows.horsePower);

         $('#Transmission').val(res.rows.transmission);
         $('#TransmissionTruck').val(res.rows.transmission);
         $('#Suspension').val(res.rows.suspension);
         $('#SuspensionTruck').val(res.rows.suspension);
         $('#notes').val(res.rows.notes);
         $('#question').val(res.rows.notes);
         $('#notesTrailer').val(res.rows.notes);


         $("#mawp").val(res.rows.mawp)
         $("#shell_material").val(res.rows.shellMaterial)
         $("#head_material").val(res.rows.headMaterial)
         $("#weld_material").val(res.rows.weldMaterial)
         $("#shell_topends").val(res.rows.mfgShellThicknessTopEnds)
         $("#shell_topcenter").val(res.rows.mfgShellThicknessTopCentre)
         $("#shell_topside").val(res.rows.mfgShellThicknessSide)
         $("#shell_topbottom").val(res.rows.mfgShellThicknessBottom)
         $("#min_shell_topends").val(res.rows.minShellThicknessTopEnds)
         $("#min_shell_topcenter").val(res.rows.minShellThicknessTopCentre)
         $("#min_shell_topside").val(res.rows.minShellThicknessSide)
         $("#min_shell_topbottom").val(res.rows.minShellThicknessBottom)
         $("#mfg_head").val(res.rows.mfgHeadThickness)
         $("#min_head").val(res.rows.minHeadThickness)
         $("#DOT_classificatin").val(res.rows.dotClassification)
         $("#testPressure").val(res.rows.testPressure)
         $("#manufacturerDate").val(res.rows.mnthYrManfacture)
         $("#originalDate").val(res.rows.originatTestDate)

         setManufacturerDate(res.rows.mnthYrManfacture ? new Date(moment(res.rows.mnthYrManfacture, "MM/YYYY").format('YYYY/MM')) : '');
         setOriginalDate(res.rows.originatTestDate ? new Date(moment(res.rows.originatTestDate, "MM/YYYY").format('YYYY/MM')) : '');


         //     var text=res.rows.compartmentCapacityInGallons;

         //     const a = text.split(",");

         //     for (var i = 0; i < a.length; i++) {
         //     $('#compartment'+i).val(a[i])
         //   }

         //   $('#compartment1').val(a[1]);


         setDummyImge(res.rows.customValues ? "data:image/png;base64," + res.rows.customValues.vehicleImageFileByte : window.location.origin + "/images/no-vehicle-image.png");
         // $("#vehicleImage").attr("src",vechileDetails.vehicleImageFileByte?"data:image/png;base64,"+vechileDetails.vehicleImageFileByte:"/images/transport-logo.jpg");
         $("#vehicleImage").attr("src", res.customValues.vehicleImageFileByte ? "data:image/png;base64," + res.customValues.vehicleImageFileByte : "/images/no-vehicle-image.png");
         setImage(res.customValues.vehicleImageFileByte ? "data:image/png;base64," + res.customValues.vehicleImageFileByte : "/images/no-vehicle-image.png");
      });

   }


   // function handleChange(event) {
   //    setImageSrc(URL.createObjectURL(event.target.files[0]))

   // }
   const getCropData = () => {

      document.getElementById("btnclose").click();


      // $("#uploadedImage").attr("src","")

      $("#vehicleImage").attr("src", imgsrc);

      // if (typeof cropper !== "undefined") {

      //    setCropData(cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 450,  maxHeight: 450}).toDataURL('image/jpeg'));
      //    document.getElementById("btnclose").click();
      // }
   };
   const handleLengthChange = (e) => {
      const value = e.target.value.replace(/\D/g, "");
      setLength(value);
   }
   const handleWidthChange = (e) => {
      const value = e.target.value.replace(/\D/g, "");
      setWidthLength(value);
   }
   const handheightChange = (e) => {
      const value = e.target.value.replace(/\D/g, "");
      setHeight(value);
   }
   const handleCapicityChange = (e) => {
      const value = e.target.value.replace(/\D/g, "");
      setCapacity(value);
   }
   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(
            imgsrc,
            croppedAreaPixels,
            rotation
         )

         setCroppedImage(croppedImage)
      } catch (e) {
         console.error(e)
      }
   }, [croppedAreaPixels, rotation])
   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      showCroppedImage()
   }, [])

   // $(document).ready(function () {

   //    });


   function handleSubmitCom() {
      // alert($("#manufacturerDate").val());
      if ($("input[name='truck']:checked").val() == '3') {
         let form_is_valid = true;

         for (var i = 0; i < $('input[name="compartment_gallancy[]"]').length; i++) {

            var compVal = $('#compartment' + i).val();

            if (!compVal) {
               form_is_valid = false;
               $("#compartmentMessage" + i).text("Compartment " + (i + 1) + " is required").css('color', 'red');
               return false;

            } else {

               $("#compartmentMessage" + i).text('');
               form_is_valid = true;
            }

         }

         var trailer_Sub_Type = $("#trailer_Sub_Type").val();
         if (trailer_Sub_Type == '') {
            $('#trailer_Sub_Type_errorMessage').html("Trailer sub type is required");
            $('#trailer_Sub_Type').focus().select();
            form_is_valid = false;
         } else {
            $('#trailer_Sub_Type_errorMessage').html('');
         }


         $('#tanker_Sub_TypeError').html('');

         if ($('#trailer_Sub_Type option:selected').text() == "Tanker") {

            var tanker_Sub_Type = $("#tanker_Sub_Type").val();
            if (tanker_Sub_Type == '') {
               $('#tanker_Sub_TypeError').html("Tanker sub type is required");
               $('#tanker_Sub_Type').focus().select();
               form_is_valid = false;
            } else {
               $('#tanker_Sub_TypeError').html('');
            }

            var otherTankerValue = $("#otherTankerValue").val();
            // use / /g to remove all spaces from the string
            const remText = otherTankerValue.replace(/ /g, "");

            // get the length of the string after removal
            const lengthOther = remText.length;
            if (lengthOther >= 50) {
               $('#otherTankerValue_error').html("Other allow should be 50 characters");
               form_is_valid = false;
            } else {
               $('#otherTankerValue_error').html('');
            }

            var DOT_classificatin = $("#DOT_classificatin").val();
            if (DOT_classificatin == '') {
               $('#DOT_classificatin_errorMessage').css("color", "#d33232").html("DOT classification is required");
               // $('#DOT_classificatin').focus();
               form_is_valid = false;
            } else {
               $('#DOT_classificatin_errorMessage').html('');
            }

            var coroosive_cargo = $("#coroosive_cargo").val();
            if (coroosive_cargo == '') {
               $('#coroosive_cargo_errorMessage').css("color", "#d33232").html("Corrosive cargo is required");
               form_is_valid = false;
            } else {
               $('#coroosive_cargo_errorMessage').html('');
            }


            var dead_airspace = $("#dead_airspace").val();
            if (dead_airspace == '') {
               $('#dead_airspace_errorMessage').css("color", "#d33232").html("Dead airspace / double bulkhead is required");
               form_is_valid = false;
            } else {
               $('#dead_airspace_errorMessage').html('');
            }


            var mawp = $("#mawp").val();
            if (mawp == '') {
               $('#mawp_errorMessage').css("color", "#d33232").html("MAWP is required");
               form_is_valid = false;
            } else {
               $('#mawp_errorMessage').html('');
            }

            var testPressure = $("#testPressure").val();
            if (testPressure == '') {
               $('#testPressure_errorMessage').css("color", "#d33232").html("Test pressure is required");
               form_is_valid = false;
            } else {
               $('#testPressure_errorMessage').html('');
            }


            var manufacturerDateT = $("#manufacturerDate").val();
            if (manufacturerDateT == '') {
               $('#manufacturerDate_errorMessage').css("color", "#d33232").html("Month and year of manufacture");
               form_is_valid = false;
            } else {
               $('#manufacturerDate_errorMessage').html('');
            }



            var originalDateT = $("#originalDate").val();
            if (originalDateT == '') {
               $('#originalDate_errorMessage').css("color", "#d33232").html("Original test date is required");
               form_is_valid = false;
            } else {
               $('#originalDate_errorMessage').html('');
            }
         }

         return form_is_valid;
      } else {
         return true;
      }
   }

   ////////////////change pic//////////////////////////
   // const onChange = (e) => {
   //    e.preventDefault();
   //    let files;
   //    if (e.dataTransfer) {
   //       files = e.dataTransfer.files;
   //    } else if (e.target) {
   //       files = e.target.files;
   //    }
   //    const reader = new FileReader();
   //    reader.onload = () => {
   //       setImage(reader.result);
   //    };
   //    reader.readAsDataURL(files[0]);
   // };
   const onChangeIMG = (e) => {
      const MIN_FILE_SIZE = 5120 // 5MB
      const fileSizeKiloBytes = Math.floor(e.target.files[0].size / 1024); //

      if (fileSizeKiloBytes <= MIN_FILE_SIZE) {
         e.preventDefault();
         let files;
         var ext = e.target.value.split('.').pop();
         var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

         if (!allowedExtensions.exec(ext)) {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Invalid file type",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {

                  e.target.value = '';
                  return false;
               }
            });

         } else {
            $("#DB_Image").hide();
            $('#selectedImage').show();
            $('#uploadImageDisable').prop('disabled', false);
            if (e.dataTransfer) {
               files = e.dataTransfer.files;
            } else if (e.target) {
               files = e.target.files;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
               $('#selectedImage').css({
                  'background-image': `url(${event.target.result})`,
                  'background-size': 'cover', // Ensures the image covers the div
                  'background-position': 'center', // Centers the image
                  'width': '300px', // Set the width of the div
                  'height': '300px', // Set the height of the div
                  'border': '1px solid #ddd'
               });

               setImageSrc(event.target.result)
            };
            reader.readAsDataURL(files[0]);

         }
      } else {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Max file size is 5MB",
            showConfirmButton: true

         }).then((result) => {
         });
      }


   };
   $("#noofCompartments").change(function () {
      $("#divTxtGroup").empty();
      var num = this.value;
      var capacityGallons = sessionStorage.getItem("compartmentCapacityInGallons");
      var array = capacityGallons.split(',');
      if (num > 0) {
         for (var i = 0; i < num; i++) {
            var newTextBoxDiv1 = $(document.createElement('div')).attr("id", 'divTxt' + i);
            $('#titleHeading').html('<center><h5>Compartment capacity in gallons</h5></center><br/>');

            newTextBoxDiv1.after().html('<div class="form-group"><label for="exampleInputEmail1">Compartment ' + (i + 1) + ' <span class="star">*</span>: </label><input type="text" class="form-control" name="compartment_gallancy[]" id="compartment' + i + '" aria-describedby="emailHelp" placeholder="capacity in gallons"" ><p id="compartmentMessage' + i + '" class="error"></p></div>');
            newTextBoxDiv1.appendTo("#divTxtGroup");
            $("#compartment" + i).val(array[i]);

         }
      }
   });
   //////////////submitvalues///////////////////////////

   const submitValue = () => {
      if (handleSubmitCom() == true) {
         // alert("@@@@@");
         var compartment_in_gallance = [];
         var array_comp = [];
         for (var i = 0; i < parseInt($("#noofCompartments").val()); i++) {
            var val = $("#compartment" + i).val();
            array_comp.push(val)

         }

         var arr = $('input[name="compartment_gallancy[]"]').map(function (index) {

            compartment_in_gallance = this.value + "," + compartment_in_gallance; // $(this).val()
         }).get();

         //   alert(compartment_in_gallance.length);

         // var compartment_in_gallance = "";
         // $("input[name=compartment_gallancy]").each(function () {
         //    compartment_in_gallance += this.value + ",";
         // });

         var selectedaioConceptName = $('#isInstalled').find(":selected").val();;
         // console.log(selectedaioConceptName);
         $("#loadingforsubmit").show();
         // setLoading(true);
         //   alert($("input[name='installed_manfacture']:checked").val());

         var axleConfigurationT = '';
         var engineMakeT = '';
         var engineModelT = '';
         var transmissionT = '';
         var suspensionT = '';
         var notesT = '';
         var otherValues = '';
         var insulated = '';
         var horsePower = "";
         if (vechileDetails.vehicleTypeName == 'Truck') {
            axleConfigurationT = $("input[name='configurationt']:checked").val() ? $("input[name='configurationt']:checked").val() : "";
            engineMakeT = $("#engineMakeTruck").val() ? $("#engineMakeTruck").val().trim() : "";
            engineModelT = $("#engineModelTruck").val() ? $("#engineModelTruck").val().trim() : "";
            transmissionT = $("#TransmissionTruck").val() ? $("#TransmissionTruck").val().trim() : "";
            suspensionT = $("#SuspensionTruck").val() ? $("#SuspensionTruck").val().trim() : "";
            notesT = $("#notes").val() ? $("#notes").val().trim() : "";
            otherValues = $("#othervalue1truck").val() + "X" + $("#othervalue2truck").val();
            insulated = $("input[name='insulated_val']:checked").val() === "on" ? 1 : 0;
            horsePower = $("#TruckhorsePower").val().trim();
         } else if (vechileDetails.vehicleTypeName == 'Tractor') {
            axleConfigurationT = $("input[name='configuration']:checked").val() ? $("input[name='configuration']:checked").val() : "";
            engineMakeT = $("#engineMake").val() ? $("#engineMake").val().trim() : "";
            engineModelT = $("#engineModel").val() ? $("#engineModel").val().trim() : "";
            transmissionT = $("#Transmission").val() ? $("#Transmission").val().trim() : "";
            suspensionT = $("#Suspension").val() ? $("#Suspension").val().trim() : "";
            notesT = $("#question").val() ? $("#question").val().trim() : "";
            otherValues = $("#othervalue1").val() + "X" + $("#othervalue2").val();
            horsePower = $("#horsePower").val().trim();
         } else {
            insulated = $("#Insulated").val();
            notesT = $("#notesTrailer").val() ? $("#notesTrailer").val().trim() : "";
         }

         var vechileReq =
         {


            "id": id,
            "fkCustomerId": vechileDetails.fkCustomerId,
            "fkVehicleId": id,
            "vehicleTypeId": $("input[name='truck']:checked").val(),
            "lisencePlateNumber": $("#liecencePlate").val() ? $("#liecencePlate").val().trim() : "",
            "vinNumber": $("#vin_Number").val() ? $("#vin_Number").val().trim() : "",
            "unitNumber": $("#unitNumber").val() ? $("#unitNumber").val().trim() : "",
            "make": $("#make").val() ? $("#make").val().trim() : "",
            "manfacturer": $("#Manfacturer").val() ? $("#Manfacturer").val().trim() : "",
            "model": $("#model").val() ? $("#model").val().trim() : "",
            "year": $("#year").val() ? $("#year").val() : "",
            "notes": notesT,
            "serialNumber": $("#serialNumber").val() ? $("#serialNumber").val().trim() : "",
            "fkStateId": $("#state").val() ? $("#state").val() : "",
            "tractorId": vechileDetails.tractorId,
            "axleConfiguration": axleConfigurationT === "other" ? otherValues : axleConfigurationT,
            "cabConfiguration": $("input[name='cabconfiguration']:checked").val() ? $("input[name='cabconfiguration']:checked").val() : "",
            "connector": $("input[name='connector']:checked").val() ? $("input[name='connector']:checked").val() : "",

            "engineMake": engineMakeT,
            "engineModel": engineModelT,
            "transmission": transmissionT,
            "suspension": suspensionT,

            "horsePower": horsePower,
            "truckId": vechileDetails.truckId,
            "truckTypeId": $("#truckType").val() ? $("#truckType").val() : "",
            "truckTypeName": "truck",
            "lengthInFeet": $("#lengthFeet").val() ? $("#lengthFeet").val().trim() : "",
            "widthInFeet": $("#widthInFeet").val() ? $("#widthInFeet").val().trim() : "",
            "heightInFeet": $("#Heightinfeet").val() ? $("#Heightinfeet").val().trim() : "",
            "isInsulated": insulated,
            "isRefrigerated": $("input[name='Refrigerated_val']:checked").val() === "on" ? 1 : 0,
            "capacityTons": $("#CapacityTons").val() ? $("#CapacityTons").val() : '',
            // "capacityTons": parseInt($("#CapacityTons").val()) ? parseInt($("#CapacityTons").val()) : 0,
            "trailerId": vechileDetails.trailerId,
            "trailerSubTypeId": $("#trailer_Sub_Type").val() ? $("#trailer_Sub_Type").val() : "",
            "tankerSubTypeId": $("#tanker_Sub_Type").val() ? $("#tanker_Sub_Type").val() : "",
            "cargo": $("#Cargo").val() ? $("#Cargo").val().trim() : "",
            "isLined": $("#isLined").val() ? $("#isLined").val() : "",
            "liningType": $("#LiningType").val() ? $("#LiningType").val().trim() : "",
            "capacityInGallons": $("#capacity_in_Gallons").val() ? $("#capacity_in_Gallons").val().trim() : "",
            "isCompartmented": $("#Compartmented").val() ? $("#Compartmented").val() : "",
            "numberOfCompartments": $("#noofCompartments").val() ? $("#noofCompartments").val() : "",
            "compartmentCapacityInGallons": compartment_in_gallance.length != '0' ? array_comp.toString().replace(/[\[\]']+/g, '') : "",
            "isInstalledWithVaporCollectionSys": $("#isinstalled").val() ? $("#isinstalled").val() : "",

            "mnthYrManfacture": $("#manufacturerDate").val() ? $("#manufacturerDate").val() : "",
            "corrosiveCargo": $("select#coroosive_cargo option").filter(":selected").val() ? $("select#coroosive_cargo option").filter(":selected").val() == '1' ? true : false : false,
            "deadAirspace": $("select#dead_airspace option").filter(":selected").val() ? $("select#dead_airspace option").filter(":selected").val() == '1' ? true : false : false,
            "mawp": $("#mawp").val() ? $("#mawp").val().trim() : "",
            "originatTestDate": $("#originalDate").val() ? $("#originalDate").val() : "",
            "shellMaterial": $("#shell_material").val() ? $("#shell_material").val().trim() : "",
            "headMaterial": $("#head_material").val() ? $("#head_material").val().trim() : "",
            "weldMaterial": $("#weld_material").val() ? $("#weld_material").val().trim() : "",
            "mfgShellThicknessTopEnds": $("#shell_topends").val() ? $("#shell_topends").val().trim() : "",
            "mfgShellThicknessTopCentre": $("#shell_topcenter").val() ? $("#shell_topcenter").val().trim() : "",
            "mfgShellThicknessSide": $("#shell_topside").val() ? $("#shell_topside").val().trim() : "",
            "mfgShellThicknessBottom": $("#shell_topbottom").val() ? $("#shell_topbottom").val().trim() : "",
            "minShellThicknessTopEnds": $("#min_shell_topends").val() ? $("#min_shell_topends").val().trim() : "",
            "minShellThicknessTopCentre": $("#min_shell_topcenter").val() ? $("#min_shell_topcenter").val().trim() : "",
            "minShellThicknessSide": $("#min_shell_topside").val() ? $("#min_shell_topside").val().trim() : "",
            "minShellThicknessBottom": $("#min_shell_topbottom").val() ? $("#min_shell_topbottom").val().trim() : "",
            "mfgHeadThickness": $("#mfg_head").val() ? $("#mfg_head").val().trim() : "",
            "minHeadThickness": $("#min_head").val() ? $("#min_head").val().trim() : "",
            "dotClassification": $("#DOT_classificatin").val() ? $("#DOT_classificatin").val().trim() : "",
            "testPressure": $("#testPressure").val() ? $("#testPressure").val().trim() : "",
            "otherTankerSubType": $("input[name='truck']:checked").val() == '3' ? $('#tanker_Sub_Type option:selected').text() == 'Other' ? $("#otherTankerValue").val() ? $("#otherTankerValue").val() : "" : "" : "",

            // "isInstalledWithVaporCollectionSys": isinstalled ? isinstalled : "",
            // "installedByManfacturer": isinstalled == "true" ? $("#installed_manfacture").val() === "true" ? "true" : "false" : "",
            // "installedByManfacturer": $("#isinstalled").val() == "true" ? $("input[name='installed_manfacture']:checked").val() === "true" ? "true" : "false" : "",
            "installedByManfacturer": $("#isinstalled").val() == "true" ? $("input[name='installed_manfacture']:checked").val() === "manufacture" ? "manufacture" : "retrofitted" : "",
            // "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : parseInt(Math.random() * 1000000000),
            // "imageExtension": cropper.getCroppedCanvas() ? "jpeg" : "",
            // "imageByteString": cropData.replace("data:image/jpeg;base64,", "")
            "imageName": imgsrc ? parseInt(Math.random() * 1000000000) : parseInt(Math.random() * 1000000000),
            "imageExtension": imgsrc ? "jpeg" : "",
            "imageByteString": $('#vehicleImage').attr('src') === "/images/no-vehicle-image.png" || $('#vehicleImage').attr('src') === "/images/no-vehicle-image.jpg" || $('#vehicleImage').attr('src') === "/images/no-vehicle-image.jpeg" ? "" : $('#vehicleImage').attr('src').replace(/^data:image\/(png|jpeg|jpg);base64,/, "")

         }

         var requestOptions = requestObject.RequestHeader(vechileReq);

         services.updateVehicle(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {
                  navigate("/fleetowner/VehicleInformation/" + id);

               }
            });

         });


      }
   }

   function vehicleTypeRadio() {

      // $('input[name="truck"]:checked').change(function () {
      var checkedValue = $('input[name="truck"]:checked').val();
      if (checkedValue == '1') {
         // alert(checkedValue);
         $("#option1").attr('checked', true).trigger('click');
         $('#traileroption1').removeAttr('checked');
         $('#option2').removeAttr('checked');
      }
      else if (checkedValue == '3') {

         $("#option1").prop('checked', false);
         // $("#option1").removeAttr('checked');
         $('#traileroption1').prop('checked', true);
         $('#option2').removeAttr('checked');
      } else if (checkedValue == '2') {
         $("#option1").removeAttr('checked');
         $('#traileroption1').removeAttr('checked');
         $('#option2').attr('checked', true).trigger('click');
      }
      //   })
   }


   const redirect = (page) => {

      navigate(page);

   }

   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
   });

   return (
      <>
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <form onSubmit={handleSubmit(submitValue)}>
                  <div class="row mb-3">
                     {/* <div class="col-lg-9 m-auto  ">
                        <HeaderBlock cid={id} />
                     </div> */}
                  </div>
                  <div class="row">
                     <div class="col-lg-9 m-auto mb-2">
                        <h4 class="font-weight-bold text-uppercase">Edit Vehicle</h4>
                     </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-lg-9 m-auto grid-margin stretch-card">
                        <div class="card">
                           <div class=" p-3 mt-2 mb-2">
                              <h4>Vehicle Information</h4>
                           </div>
                           <div class="card-body pt-0 pb-2">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Unit Number <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" id="unitNumber" defaultValue="Unit Number" aria-describedby="emailHelp" onChange={e => setFieldName(e.target.value)} {...register("unitnumber", {
                                          required: "Unit number is required.",
                                       })} />
                                       <ErrorMessage
                                          errors={errors}
                                          name="unitnumber"
                                          class="invalid-feedback"
                                          render={({ messages }) => {

                                             return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                   <p key={type} class="error">{message}</p>
                                                ))
                                                : null;
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1" ><span class="lableUpper">VIN</span> Number <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" defaultValue="12345678901234567" id="vin_Number" aria-describedby="emailHelp" {...register('vin_Number', { required: true, maxLength: 17, minLength: 17 })} maxLength="17" />
                                       {errors.vin_Number && errors.vin_Number.type === "required" && <p class="error">VIN number is required</p>}
                                       {errors.vin_Number && errors.vin_Number.type === "maxLength" && <p class="error">VIN number should be 17 characters only</p>}
                                       {errors.vin_Number && errors.vin_Number.type === "minLength" && <p class="error">VIN number should be 17 characters only</p>}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Serial Number</label>
                                       <input type="text" class="form-control custome-input" id="serialNumber" aria-describedby="emailHelp" />
                                    </div>
                                 </div>


                              </div>
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Licence Plate</label>
                                       <input type="text" class="form-control custome-input" id="liecencePlate" aria-describedby="emailHelp" />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleFormControlSelect1">State </label>
                                       {/* <span class="star">*</span> */}
                                       {/* <select class="form-control custome-select" id="state">
                                          <option>One</option>
                                          <option>Two</option>
                                          <option>Three</option>
                                       </select> */}
                                       {/* <select class="form-control custome-select" id="state" onChange={e => setFieldName(e.target.value)}    {...register("State", {
                                                required: "State is required.",
                                                 })}>
                                            <option value="">Select state</option>
                                             {
                                                         states?states.map((state, index) => (
                                                            <option value={state.id}>{state.name}</option>
                                                         )): <option value=""></option>
                                             }
                                       </select> */}

                                       <select class="form-control custome-select" id="state" defaultValue="hawai"  >
                                          {/* defaultValue='ohio' */}
                                          {/* {...register("State", {
                                          required: "State is required.",
                                       })} */}

                                          <option value="">---Select state---</option>
                                          {
                                             states ? states.map((state, index) => (
                                                <option key={state.key} defaultValue selected={vechileDetails.vehicleStateName == state.name ? "selected" : ""} value={state.id} >{state.name}</option>
                                             )) : <option value=""></option>
                                          }
                                       </select>
                                       {/* <ErrorMessage
                                          errors={errors}
                                          name="State"
                                          class="invalid-feedback"
                                          render={({ messages }) => {
                                            
                                             return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                   <p key={type} class="error">{message}</p>
                                                ))
                                                : null;
                                          }}></ErrorMessage> */}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Make</label>
                                       <input type="text" class="form-control custome-input" id="make" defaultValue="Make" aria-describedby="emailHelp" />
                                       {/* {...register('make', { required: true })} */}
                                       {/* {errors.make && errors.make.type === "required" && <p class="error">Make is required</p>} */}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Model</label>
                                       <input type="text" class="form-control custome-input" id="model" defaultValue="Model" aria-describedby="emailHelp" />
                                       {/* {...register('model', { required: true })} */}
                                       {/* {errors.model && errors.model.type === "required" && <p class="error">Model is required</p>} */}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Manufacturer</label>
                                       <input type="text" class="form-control custome-input" id="Manfacturer" defaultValue="Manfacturer" aria-describedby="emailHelp" />
                                       {/* {...register('Manfacturer', { required: true })}  */}
                                       {/* {errors.Manfacturer && errors.Manfacturer.type === "required" && <p class="error">Manufacturer is required</p>} */}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleFormControlSelect1">Year<span class="star">*</span></label>
                                       <select class="form-control custome-select" id="year" defaultValue="2022" >
                                          {/* {...register('year', { required: true })} */}
                                          <option selected value="">Select</option>
                                          {/* <option value="2010">2010</option>
                                          <option value="2009">2009</option>
                                          <option value="2008">2008</option> */}
                                       </select>
                                       {errors.year && errors.year.type === "required" && <p class="error">Year is required</p>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-lg-9 m-auto grid-margin stretch-card">
                        <div class="card">
                           <div class=" p-3 mt-2 ">
                              <h4>Vehicle Type</h4>
                           </div>
                           <div class="card-body pt-0 pb-2">
                              <div class="row px-2 ">
                                 <div class="radio-bg w-100">
                                    <div class="col-6">
                                       <div class="form-group d-flex align-items-center mb-0">
                                          <input type="radio" name="truck" class="ml-0 mr-2" id="option1" autocomplete="off" value="1" disabled /> <b>Tractor</b>
                                          <input type="radio" name="truck" class="ml-4 mr-2" id="traileroption1" autocomplete="off" value="3" disabled /> <b>Trailer</b>
                                          <input type="radio" name="truck" class="ml-4 mr-2" id="option2" autocomplete="off" value="2" disabled /> <b>Truck</b>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="tractor" id="tractor_display">
                                 <div class="row my-3">
                                    <div class="col-md-12"><h4 class="title-sm">Axle Configuration</h4></div>
                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label d-flex">
                                             <input type="radio" class="form-check-input configuration" name="configuration" id="4X2" value="4X2" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">4X2</span>
                                       </div>

                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configuration" id="6X2" value="6X2" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X2</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configuration" id="6X4" value="6X4" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X4</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configuration" id="6X6" value="6X6" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X6</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-check form-check-success">
                                          <div class="d-flex">
                                             <label class="form-check-label">
                                                <input type="radio" class="form-check-input configuration" name="configuration" id="other" value="other" />
                                                <i class="input-helper"></i></label>
                                             <span class="title-hd">other</span>
                                          </div>

                                          <div class="align-items-center mt-2" id="otherDisplay1" style={{ "max-width": "200px", "display": "flex", " margin-top": "15px !important" }}>
                                             <div> <input type="text" class="form-control sm-form" id="othervalue1" aria-describedby="emailHelp" /></div>
                                             <span class="mx-2">X</span>
                                             <div> <input type="text" class="form-control sm-form" id="othervalue2" aria-describedby="emailHelp" /></div>
                                          </div>
                                       </div>
                                    </div>

                                 </div>


                                 <div class="row my-4">
                                    <div class="col-md-12"><h4 class="title-sm">Cab configuration</h4></div>
                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input" name="cabconfiguration" id="Cabconfig" value="Standard" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">standard</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input" name="cabconfiguration" id="caboverengine" value="Cab over engine" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">Cab over engine</span>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="row my-4">
                                    <div class="col-md-12"><h4 class="title-sm">Connector</h4></div>
                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input" name="connector" id="connector" value="Fifth Wheel" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">Fifth Wheel</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input" name="connector" id="Drawbar" value="Drawbar" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">Drawbar</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input" name="connector" id="Hitch" value="Tow Hitch" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">Tow Hitch</span>
                                       </div>
                                    </div>
                                 </div>



                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Engine Make</label>
                                          <input type="text" class="form-control custome-input" id="engineMake" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Engine Model</label>
                                          <input type="text" class="form-control custome-input" id="engineModel" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    {/* <div class="col-6">
                                        <div class="form-group">
                                           <label for="exampleFormControlSelect1">Insulated</label>
                                           <input type="text" class="form-control custome-input" id="Insulated" aria-describedby="emailHelp"/>
                                        </div>
                                     </div> */}

                                 </div>


                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Transmission</label>
                                          <input type="text" class="form-control custome-input" id="Transmission" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Suspension</label>
                                          <input type="text" class="form-control custome-input" id="Suspension" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">HorsePower</label>
                                          <input type="text" class="form-control custome-input" id="horsePower" aria-describedby="emailHelp" />
                                       </div>
                                    </div>

                                 </div>

                                 <div class="row mt-4">
                                    <div class="col-md-12">
                                       <div class="form-group">
                                          <label for="exampleInputPassword1"><b>Additional Notes</b></label>
                                          <textarea class="form-control mb-2 w-100" maxLength="300" style={{ height: "100px;" }} id="question" rows="3"></textarea>
                                          {/* <a href="#" type="button" class="btn btn-success">Submit</a> */}
                                       </div>
                                    </div>

                                 </div>
                              </div>

                              <div class="tractor" id="trailor">
                                 <div class="row my-3">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Trailer Sub Type <span class="star">*</span></label>
                                          <select class="form-control custome-select" id="trailer_Sub_Type" onChange={(e) => TankerFunction()}>
                                             <option value="">---Select---</option>
                                             {
                                                trailerType ? trailerType.sort((a, b) => a.name.localeCompare(b.name)).map((trailer, index) => (
                                                   <option value={trailer.id} selected={vechileDetails.trailerSubTypeId == trailer.id ? "selected" : ''}>{trailer.name}</option>
                                                )) : <option value=""></option>
                                             }

                                          </select>
                                          <p id="trailer_Sub_Type_errorMessage" class="error"></p>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Tanker Sub Type</label>
                                          <select class="form-control custome-select" id="tanker_Sub_Type" onChange={(e) => TankerSubTypeFunction()}>
                                             <option value="">---Select---</option>
                                             {
                                                tankerSubTypes ? tankerSubTypes.sort((a, b) => a.name.localeCompare(b.name)).map((tanker, index) => (
                                                   <option value={tanker.id} defaultValue selected={vechileDetails.tankerSubTypeId == tanker.id ? "selected" : ''}>{tanker.name}</option>
                                                )) : <option value=""></option>
                                             }
                                          </select>

                                          <p id="tanker_Sub_TypeError" class="error"></p>
                                       </div>
                                    </div>
                                    <div id="otherTankerSubType" class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Other</label>
                                          <input type="text" class="form-control custome-input" id="otherTankerValue" aria-describedby="emailHelp" />
                                          <p id="otherTankerValue_error" class="error"></p>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Cargo</label>
                                          <input type="text" class="form-control custome-input" id="Cargo" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Insulated</label>
                                          <select class="form-control custome-select" id="Insulated">
                                             <option value="">---Select---</option>
                                             <option selected={vechileDetails.isInsulated == true ? "selected" : ''} value="true" >Yes</option>
                                             <option selected={vechileDetails.isInsulated == false ? "selected" : ''} value="false">No</option>

                                          </select>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Lined</label>

                                          <select class="form-control custome-select" id="isLined" >
                                             <option value="">---Select---</option>
                                             <option selected={vechileDetails.isLined == true ? "selected" : ''} value="true" >Yes</option>
                                             <option selected={vechileDetails.isLined == false ? "selected" : ''} value="false">No</option>

                                          </select>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Lining Type</label>
                                          <input type="text" class="form-control custome-input" id="LiningType" aria-describedby="emailHelp" />
                                          <p id="LiningTypeError" class="error"></p>
                                       </div>
                                    </div>

                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Compartmentalized<span class="star">*</span></label>
                                          <select class="form-control custome-select" defaultValue="true" id="Compartmented"  {...register("compartmentalized", {
                                             required: "Compartmentalized is required",
                                          })}>
                                             <option value="">---Select---</option>
                                             <option value="true" selected={vechileDetails.isCompartmented == true ? "selected" : ''} >Yes</option>
                                             <option value="false" selected={vechileDetails.isCompartmented == false ? "selected" : ''}>No</option>

                                          </select>
                                          <ErrorMessage
                                             errors={errors}
                                             name="compartmentalized"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}></ErrorMessage>
                                       </div>
                                    </div>
                                    <div class="col-6" id="nofcompartment">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">No of Compartments <span class="star">*</span></label>
                                          {/* <input type="text" class="form-control custome-input" id="Suspension" aria-describedby="emailHelp"/> */}
                                          <select class="form-control custome-select" defaultValue="1" id="noofCompartments" name="ddlSelect" {...register("compartments", {
                                             required: "No of Compartments is required",
                                          })}>
                                             {/* {...register('compartments', { required: true })} */}
                                             <option selected value="">-----Select-----</option>
                                             <option value="1" selected={vechileDetails.numberOfCompartments == 1 ? "selected" : ''}>One</option>
                                             <option value="2" selected={vechileDetails.numberOfCompartments == 2 ? "selected" : ''}>Two</option>
                                             <option value="3" selected={vechileDetails.numberOfCompartments == 3 ? "selected" : ''}>Three</option>
                                             <option value="4" selected={vechileDetails.numberOfCompartments == 4 ? "selected" : ''}>Four</option>
                                             <option value="5" selected={vechileDetails.numberOfCompartments == 5 ? "selected" : ''}>Five</option>
                                             <option value="6" selected={vechileDetails.numberOfCompartments == 6 ? "selected" : ''}>Six</option>
                                          </select>
                                          <ErrorMessage
                                             errors={errors}
                                             name="compartments"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}></ErrorMessage>
                                          {/* {errors.compartments && errors.compartments.type === "required" && <span class="error">No of Compartments is required</span>} */}
                                       </div>
                                    </div>
                                 </div>
                                 <div class="capacity-Gallons pr-4 pl-4 pt-3 pb-2 pb-0 mb-2 mt-3" id="nofcompartment">
                                    <span id="titleHeading"></span>
                                    <div class="row" id='divTxtGroup'>

                                       <div class="col-3" >

                                       </div>

                                    </div>
                                 </div>
                                 <div class="row mt-4">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Capacity in Gallons</label>
                                          <input type="text" class="form-control custome-input" id="capacity_in_Gallons" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row mt-4">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Is installed with a vapor collection system </label>
                                          <select class="form-control custome-select" id="isinstalled">
                                             <option value="">-----Select-----</option>
                                             <option value="true" selected={vechileDetails.isInstalledWithVaporCollectionSys == true ? "selected" : ''}>Yes</option>
                                             <option value="false" selected={vechileDetails.isInstalledWithVaporCollectionSys == false ? "selected" : ''}>No</option>

                                          </select>
                                       </div>
                                    </div>
                                    <div class="col-6" id="installedBymanfactures">
                                       <div class="form-group">
                                          {/* <label for="exampleFormControlSelect1">Installed by Manufacturer/ Retrofitted </label>
                                       <select class="form-control custome-select" id="installed_manfacture">
                                         <option value="">-----Select-----</option>
                                          <option value="Yes"> Yes</option>
                                          <option value="No">No</option>
                                       </select> */}
                                          <div class="d-flex form-check form-check-success"><label class="form-check-label d-flex"><input type="radio" class="form-check-input configuration" name="installed_manfacture" id="installed_manfacture" value="manufacture" /><i class="input-helper"></i></label><span class="title-hd">Installed by manufacturer</span></div>
                                          {/* <input type="radio" class="form-check-input configuration" name="installed_manfacture" />
                                       <span class="title-hd mx-4">Installed by manufacturer</span> */}


                                       </div>
                                       <div class="form-group">
                                          <div class="d-flex form-check form-check-success"><label class="form-check-label d-flex"><input type="radio" class="form-check-input configuration" name="installed_manfacture" value="retrofitted" /><i class="input-helper"></i></label><span class="title-hd">Installed by retrofitted</span></div>
                                          {/* {...register('installed_manfacture', { required: true })} */}
                                          {/* <input type="radio" class="form-check-input configuration" name="installed_manfacture" />

                                       <span class="title-hd mx-4">Installed by retrofitted</span> */}
                                       </div>
                                       {/* {errors.installed_manfacture && errors.installed_manfacture.type === "required" && <span class="error">Please check atleast one option</span>} */}
                                    </div>


                                 </div>



                                 {(() => {
                                    if (vechileDetails.trailerSubTypeId == "4" || vechileDetails.trailerSubTypeName == "Tanker" || trailerSubtype == "Tanker") {
                                       return (
                                          <div class="px-3 py-3" id="spec_plate">
                                             <h4 class="mt-3">Spec Plate</h4>
                                             <div class="row  p-3 gray spec-plat" style={{ background: "#e6e6e6" }}>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1"><span class="lableUpper">DOT</span> Classification <span class="star">*</span></label>
                                                      <input type="text" class="form-control" id="DOT_classificatin" />
                                                      <p class="error" id="DOT_classificatin_errorMessage"></p>
                                                      {/* {...register('DOT', { required: true })} */}
                                                      {/* {errors.DOT && errors.DOT.type === "required" && <p class="error">DOT classification is required</p>} */}
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Month and Year of manufacture<span class="star">*</span></label>
                                                      {/* <input type="text" class="form-control" id="exampleInputUsername1" /> */}
                                                      <DatePicker dateFormat="MM/yyyy" selected={manufacturerDate} onChange={(date) => setManufacturerDate(date)} showMonthYearPicker name="manufacturerDate" class="form-control" id="manufacturerDate" placeholderText="MM/YYYY" />
                                                      <p class="error" id="manufacturerDate_errorMessage"></p>
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1"> Corrosive Cargo <span class="star">*</span></label>
                                                      <select class="form-control custome-select" id="coroosive_cargo" name="coroosive_cargo" >
                                                         <option value="">-----Select-----</option>
                                                         <option value="1" selected={vechileDetails.corrosiveCargo == true ? "selected" : ''}>Yes</option>
                                                         <option value="0" selected={vechileDetails.corrosiveCargo == false ? "selected" : ''}>No</option>
                                                      </select>
                                                      {/* {...register('coroosive_cargo', { required: true })} */}
                                                      {/* {errors.coroosive_cargo && errors.coroosive_cargo.type === "required" && <p class="error">Corrosive cargo is required</p>} */}
                                                      <p class="error" id="coroosive_cargo_errorMessage"></p>
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Dead Airspace / Double bulkhead <span class="star">*</span></label>
                                                      <select class="form-control custome-select" id="dead_airspace" name="dead_airspace" >
                                                         <option value="">-----Select-----</option>
                                                         <option value="1" selected={vechileDetails.deadAirspace == true ? "selected" : ''}>Yes</option>
                                                         <option value="0" selected={vechileDetails.deadAirspace == false ? "selected" : ''}>No</option>
                                                      </select>
                                                      <p class="error" id="dead_airspace_errorMessage"></p>
                                                      {/* {...register('dead_airspace', { required: true })}
                      {errors.dead_airspace && errors.dead_airspace.type === "required" && <p class="error">Dead Airspace / Double bulkhead is required</p>} */}
                                                   </div>
                                                </div>
                                                {/* <div class="col-md-6">
                   <div class="form-group">
                      <label for="exampleInputUsername1"> Corrosive Cargo <span class="star">*</span></label>
                      <select class="form-control custome-select" id="abc" name="Primarycontactrole">
                         <option value="">Choose</option>
                         <option value="1">Yes</option>
                         <option value="2">No</option>
                      </select>
                   </div>
                </div> */}
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1"> <span className="text-uppercase">MAWP (PSIG)</span> <span class="star">*</span> </label>
                                                      <input type="text" class="form-control" id="mawp" />
                                                      <p class="error" id="mawp_errorMessage"></p>
                                                      {/* {...register('mawp', { required: true })} */}
                                                      {/* {errors.mawp && errors.mawp.type === "required" && <p class="error">MAWP is required</p>} */}
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Test Pressure  <span className="text-uppercase">(PSIG)</span> <span class="star">*</span> </label>
                                                      <input type="text" class="form-control" id="testPressure" />
                                                      <p class="error" id="testPressure_errorMessage"></p>
                                                      {/* {...register('testPressure', { required: true })} */}
                                                      {/* {errors.testPressure && errors.testPressure.type === "required" && <p class="error">Test pressure is required</p>} */}
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Original test date <span class="star">*</span></label>
                                                      {/* <input type="text" class="form-control" id="exampleInputUsername1" /> */}
                                                      <DatePicker dateFormat="MM/yyyy" selected={originalDate} onChange={(date) => setOriginalDate(date)} showMonthYearPicker name="originalDate" class="form-control" id="originalDate" placeholderText="MM/YYYY" />
                                                      <p class="error" id="originalDate_errorMessage"></p>
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Shell Material </label>
                                                      <input type="text" class="form-control" id="shell_material" />
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Head Material </label>
                                                      <input type="text" class="form-control" id="head_material" />
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1">Weld Material  </label>
                                                      <input type="text" class="form-control" id="weld_material" />
                                                   </div>
                                                </div>
                                                <div class="col-md-12">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1" className="text-capitalization">Mfg Shell Thickness  </label>
                                                      <div className="row">
                                                         <div className="col-lg-3">
                                                            <div class="form-group mb-0">
                                                               <small>Top ends</small>
                                                               <input type="text" class="form-control" id="shell_topends" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group mb-0">
                                                               <small>Top Center</small>
                                                               <input type="text" class="form-control" id="shell_topcenter" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group mb-0">
                                                               <small>Side</small>
                                                               <input type="text" class="form-control" id="shell_topside" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group mb-0">
                                                               <small>Bottom</small>
                                                               <input type="text" class="form-control" id="shell_topbottom" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-12">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1" className="text-capitalization">Min Shell Thickness </label>
                                                      <div className="row">
                                                         <div className="col-lg-3">
                                                            <div class="form-group">
                                                               <small>Top ends</small>
                                                               <input type="text" class="form-control" id="min_shell_topends" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group">
                                                               <small>Top Center</small>
                                                               <input type="text" class="form-control" id="min_shell_topcenter" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group">
                                                               <small>Side</small>
                                                               <input type="text" class="form-control" id="min_shell_topside" />
                                                            </div>
                                                         </div>
                                                         <div className="col-lg-3">
                                                            <div class="form-group">
                                                               <small>Bottom</small>
                                                               <input type="text" class="form-control" id="min_shell_topbottom" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1"> Mfg Head Thickness </label>
                                                      <input type="text" class="form-control" id="mfg_head" />
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <div class="form-group">
                                                      <label for="exampleInputUsername1"> Min Head Thickness </label>
                                                      <input type="text" class="form-control" id="min_head" />
                                                   </div>
                                                </div>

                                                <hr />

                                             </div>
                                          </div>
                                       )
                                    }
                                 })()}

                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="form-group">
                                          <label for="exampleInputPassword1"><b>Additional Notes</b></label>
                                          <textarea class="form-control mb-2 w-100" maxLength="300" style={{ height: "100px;" }} id="notesTrailer" rows="3"></textarea>
                                       </div>
                                    </div>

                                 </div>
                              </div>

                              <div class="tractor" id="truck">
                                 <div class="row my-3">
                                    <div class="col-md-12"><h4 class="title-sm">Axle Configuration</h4></div>
                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label d-flex">
                                             <input type="radio" class="form-check-input configuration" name="configurationt" id="4X21" value="4X2" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">4X2</span>
                                       </div>

                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configurationt" id="6X2" value="6X2" />

                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X2</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configurationt" id="6X4" value="6X4" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X4</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex form-check form-check-success">
                                          <label class="form-check-label">
                                             <input type="radio" class="form-check-input configuration" name="configurationt" id="6X6" value="6X6" />
                                             <i class="input-helper"></i></label>
                                          <span class="title-hd">6X6</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-check form-check-success">
                                          <div class="d-flex">
                                             <label class="form-check-label">
                                                <input type="radio" class="form-check-input configuration" name="configurationt" id="other" value="other" />
                                                <i class="input-helper"></i></label>
                                             <span class="title-hd">other</span>
                                          </div>

                                          <div class="align-items-center mt-2" id="otherDisplay" style={{ "max-width": "200px", "display": "flex", " margin-top": "15px !important" }}>
                                             <div> <input type="text" class="form-control sm-form" id="othervalue1truck" aria-describedby="emailHelp" /></div>
                                             <span class="mx-2">X</span>
                                             <div> <input type="text" class="form-control sm-form" id="othervalue2truck" aria-describedby="emailHelp" /></div>
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Truck Type</label>
                                          <select class="form-control custome-select" id="truckType">

                                             <option value="">-----Select-----</option>
                                             {
                                                truckType ? truckType.map((truckType, index) => (
                                                   <option value={truckType.id} defaultValue selected={vechileDetails.truckTypeId == truckType.id ? "selected" : ''}>{truckType.name}</option>
                                                )) : <option value=""></option>
                                             }
                                          </select>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Engine Make</label>
                                          <input type="text" class="form-control custome-input" id="engineMakeTruck" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Engine Model</label>
                                          <input type="text" class="form-control custome-input" id="engineModelTruck" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    {/* <div class="col-6">
                                        <div class="form-group">
                                           <label for="exampleFormControlSelect1">Insulated</label>
                                           <input type="text" class="form-control custome-input" id="Insulated" aria-describedby="emailHelp"/>
                                        </div>
                                     </div> */}

                                 </div>


                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Transmission</label>
                                          <input type="text" class="form-control custome-input" id="TransmissionTruck" aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Suspension</label>
                                          <input type="text" class="form-control custome-input" id="SuspensionTruck" aria-describedby="emailHelp" />
                                       </div>
                                    </div>


                                 </div>
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Length Ft</label>
                                          <input type="text" class="form-control custome-input" id="lengthFeet" aria-describedby="emailHelp" value={length} onChange={handleLengthChange} />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Width Ft</label>
                                          <input type="text" class="form-control custome-input" id="widthInFeet" aria-describedby="emailHelp" value={width} onChange={handleWidthChange} />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Height Ft</label>
                                          <input type="text" class="form-control custome-input" id="Heightinfeet" aria-describedby="emailHelp" value={height} onChange={handheightChange} />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">Capacity Tons</label>
                                          <input type="text" class="form-control custome-input" id="CapacityTons" aria-describedby="emailHelp" value={capacity} onChange={handleCapicityChange} />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">HorsePower</label>
                                          <input type="text" class="form-control custome-input" id="TruckhorsePower" aria-describedby="emailHelp" />
                                       </div>
                                    </div>

                                 </div>

                                 <div class="row">
                                    <div class="col-md-3">
                                       <div class="d-flex align-items-center">
                                          <input type="checkbox" id="isinsulated" name="insulated_val" class="mr-2" />
                                          <span>Insulated</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="d-flex align-items-center">
                                          <input type="checkbox" id="Refrigerated" name="Refrigerated_val" class="mr-2" />
                                          <span>Refrigerated</span>
                                       </div>
                                    </div>

                                    {/* <div class="col-md-3">
                                        <div class="d-flex align-items-center">
                                            <input type="checkbox" id="capacityTons" name="vehicle1" value="2" class="mr-2"/>
                                           <span>Capacity Tons</span>
                                        </div>
                                    </div> */}
                                 </div>


                                 <div class="row mt-4">
                                    <div class="col-md-12">
                                       <div class="form-group">
                                          <label for="exampleInputPassword1"><b>Additional Notes</b></label>
                                          <textarea class="form-control mb-2 w-100" maxLength="300" id="notes" rows="3" style={{ height: "100px;" }}></textarea>
                                          {/* <a href="#" type="button" class="btn btn-success">Submit</a> */}
                                       </div>
                                    </div>

                                 </div>
                              </div>


                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row mb-2">
                     <div class="col-lg-9 m-auto grid-margin stretch-card">
                        <div class="card">

                           <div class="card-body pt-0 pb-2 p-3">
                              <div class="row d-flex align-items-center">
                                 <div class="col-md-5 ">
                                    <div class="instruction">
                                       <span>Vehicle Image</span>
                                       <p>Max File Size : 5MB</p>
                                       <p>Formats: JPG/PNG</p>
                                  
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="avatar">
                                       <img 
                                       // src={cropData ? cropData : dummyImge} 
                                       id="vehicleImage" />

                                       {/* <Cropper
                                       image={imgsrc}
                                       crop={crop}
                                       rotation={rotation}
                                       zoom={zoom}
                                       aspect={4 / 3}
                                       onCropChange={setCrop}
                                       onRotationChange={setRotation}
                                       onCropComplete={onCropComplete}
                                       onZoomChange={setZoom}
                                    /> */}
                                    </div>
                                    {/* <UploadImage/> */}



                                 </div>
                                 {/* <input type="file" onChange={handleChange}/> */}
                                 <div class="col-md-4"><a class="btn btn-outline-dark btn-fw" id="EditImage" data-toggle="modal" data-target="#uploadPic" data-backdrop="static" data-keyboard="false">
                                    {/* <input type="file" data-bs-toggle="modal" data-bs-target="#exampleModal" onChange={handleChange}/></a> */}Upload Photo</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <div class="col-lg-9 m-auto grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body pt-0 pb-2 p-3">
                        <div class="row d-flex align-items-center">
                             <div class="col-md-4 text-center">

                                <div class="vehiclephoto">
                                 <Cropper
                                          image={imgsrc}
                                          crop={crop}
                                          rotation={rotation}
                                          zoom={zoom}
                                          aspect={4 / 3}
                                          onCropChange={setCrop}
                                          onRotationChange={setRotation}
                                          onCropComplete={onCropComplete}
                                          onZoomChange={setZoom}
                                       />
                                </div>
                              </div>




                          <input type="file" onChange={handleChange}/>

                         <div class="col-md-4 text-center"><a href="" class="btn btn-outline-dark btn-fw">Upload Image</a></div>
                          <div class="col-md-4 text-left">
                            <div class="instruction">
                             <span>Instruction</span>
                             <p>Max File Size : 50MB</p>
                             <p>Formats: JPG/PNG/BMP</p>
                             <p>Ratio: 1:1 (width and height must be equal)</p>
                            </div>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                  <div class="row mb-2">
                     <div class="col-lg-9 m-auto ">
                        <div class="white-color p-3 d-flex">
                           <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                           <a onClick={() => redirectBack()} class="btn gray-mid-color mw-120">CANCEL</a>
                           {/* <a href={`/customerDetails/` + id} class="btn gray-mid-color mw-120">CANCEL</a> */}
                        </div>
                     </div>
                  </div>
               </form>
            </div>

         </div>
         <div class="modal fade" id="uploadPic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div class="modal-body">
                     <input type="file" class="w-100 p-3 mb-3" onChange={onChangeIMG} accept="image/jpeg,image/png,image/jpeg" />
                     <div class="row mb-2" >
                        <div class="col-12">

                           <div class="image-upload-plg">





                              {/* <Cropper
                                 style={{ height: 320, width: "100%" }}
                                 zoomTo={0.5}
                                 initialAspectRatio={4 / 3}
                                 preview=".img-preview"
                                 src={image}
                                 viewMode={3}

                                 minCropBoxHeight={10}
                                 minCropBoxWidth={10}
                                 background={false}
                                 responsive={true}
                                 autoCropArea={1}
                                 checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                                 guides={true}
                              /> */}
                              {/* <Cropper
                                 style={{ height: 300, width: "100%" }}
                                 aspectRatio={5 / 3}
                                 guides={false}
                                 dragMode={"move"}
                                 src={image}
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                              /> */}
                           </div>
                           <div class="row mb-2 upload-image-center" id="selectedImage">
                              {/* <div className="m-auto mt-2">
                                 <img src={image} style={{ width: "400px", height: "240px", marginTop:"20px" }} ></img>

                              </div> */}
                           </div>

                        </div>
                      
                        <div>
                        
                           {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                        </div>
                     </div>
                     <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "300px", height: "300px", marginTop: "20px" ,objectFit:"cover",objectPosition: "center"}} ></img>

                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnclose">Close</button>
                     <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={getCropData}>Save changes</button>
                  </div>
               </div>
            </div>
         </div>
         {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">×</span>
                           </button>
                     </div>
                     <div class="modal-body">
                     <div class="row mb-2">
                        <div class="col-12">

                        <div class="image-upload-plg">
                        <input type="file" class="w-100 p-3" onChange={onChange} />




                <Cropper
                  style={{ height: 260, width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={4 / 3}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}

                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>


                        </div>

                     <div>
                     <div className="box" style={{ width: "100%", float: "right",display:"none" }}>
                        <h1>Preview</h1>
                        <div
                           className="img-preview"

                        />
                     </div>
                     {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div>
                     </div>
                 </div>
                  </div>
                  <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  id="btnclose">Close</button>
                  <button type="button" class="btn btn-primary" onClick={getCropData}>Save changes</button>
                  </div>
               </div>
            </div>
            </div> */}
      </>
   )
};


FleetEditVehicle.propTypes = {};

FleetEditVehicle.defaultProps = {};

export default FleetEditVehicle;
