import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import moment from "moment";
const DOT = () => {
    const { taskOrderId, cid, vid } = useParams();
    const [fileName, setfileName] = useState("")
    const [endDate, setEndDate] = useState(new Date());
    // new Date()
    const [startTime, setTime] = useState();
    const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);

    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    var customerid = localStorage.getItem("customerId");
    const [noOfRows, setNoOfRows] = useState(1);
    const [loading, setLoading] = useLoading("Please wait...");
    const [inputList, setInputList] = useState([{}]);
    const [compartments, setcompartments] = useState();
    const [manywaytest, setmanywaytest] = useState();
    const navigate = useNavigate();
    var compartmentReq;
    const [base64file, setBase64file] = useState('');
    useEffect(() => {
        if (CheckAuthentication) {
            $('#otherField').hide();
            $('#otherFieldTextFiled').hide();
            const compGallons=sessionStorage.getItem("compartmentCapacityInGallons");
            if(compGallons==null){
                compartmentReq=''  
            }else{
                compartmentReq= compGallons.toString().replace(/,(?=\s*$)/, '').split(',')
            }
           

            $("#inspectedBy").val(sessionStorage.getItem("customerName"));
            setcompartments(compartmentReq);
            setmanywaytest(compartmentReq);
        }
        else {
            sessionStorage.clear();
            localStorage.clear();
            //navigate("/login");
            window.location.replace("login");
        }
    }, []);

    $(document).ready(function () {

        // $("#pressureTest1startTime").attr("readonly", "readonly");
        // $("#pressureTest1finishTime").attr("readonly", "readonly");
        // $("#pressureTest2startTime").attr("readonly", "readonly");
        // $("#pressureTest2finishTime").attr("readonly", "readonly");
        // $("#vaccumeTest1startTime").attr("readonly", "readonly");
        // $("#vaccumeTest1finishTime").attr("readonly", "readonly");

        // $("#vaccumeTest2startTime").attr("readonly", "readonly");
        // $("#vaccumeTest2finishTime").attr("readonly", "readonly");
        // $("#vaporValveTeststartTime").attr("readonly", "readonly");
        // $("#vaporValveTestfinishTime").attr("readonly", "readonly");
        // $("#pressureTest3startTime").attr("readonly", "readonly");
        // $("#pressureTest3finishTime").attr("readonly", "readonly");





        $("#pressureTest1Change,#pressureTest2change,#vaccumeTest1change,#vaccumeTest2change,#vaporValveTestchange,#pressureTest3Change,#pressureTest1startTimePressure,#pressureTest1finishTimePressure,#pressureTest2startTimePressure,#pressureTest2finishTimePressure,#vaccumeTest1startTimePressure,#vaccumeTest1startTimePressure,#vaccumeTest1finishTimePressure,#vaccumeTest2startTimePressure,#vaccumeTest2finishTimePressure,#vaporValveTeststartTimePressure,#vaporValveTestfinishTimePressure,#pressureTest3startTimePressure,#pressureTest3finishTimePressure").keypress(function (e) {
            // #vaccumeTest1Result,#vaccumeTest2Result
            // if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //     return false;
            // }

            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 2) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 2)) {
                return false;
            }


        });


    });



    $(document).on("input", "input[name='checkPerformed']", function () {

        var otherValue = $("input[name='checkPerformed']:checked").val();
        if (otherValue == 'other') {
            $('#otherField').show();
        } else {
            $('#otherField').hide();
        }
    });

    $(document).on("input", "input[name='other']", function () {

        var otherValue = $("input[name='other']:checked").val();
        if (otherValue == '1') {
            $('#otherFieldTextFiled').show();
        } else {
            $('#otherFieldTextFiled').hide();
        }

    });
    var sum = 0;
    $('#checkAll').click(function () {
        var checked = $(this).is(":checked");
        if (checked) {
            sum = 0;
            for (var i = 0; i < compartments.length; i++) {
                $('input[name=sec' + i + 'Distortion').prop('checked', this.checked);
                var comp = $("#comp" + i + "gallons").val();
                sum += parseFloat(comp);


            }
            if (isNaN(Math.abs(sum))) {
                $("#allValues").val("")
            } else {
                $("#allValues").val(Math.abs(sum))
            }

        }
        else {
            sum = 0;
            for (var i = 0; i < compartments.length; i++) {
                $('input[name=sec' + i + 'Distortion').prop('checked', checked);

            }
            $("#allValues").val("");
        }

    });

    var sum2 = 0;
    $('#checkAllmany').click(function () {
        var checked = $(this).is(":checked");
        if (checked) {

            for (var i = 0; i < compartments.length; i++) {
                $('input[name=sec' + i + 'manyDistortion').prop('checked', this.checked);
                var comp = $("#many" + i + "test").val();
                sum2 += parseFloat(comp);


            }
            $("#allmanyValues").val(sum2);
        }
        else {
            sum2 = 0;
            for (var i = 0; i < compartments.length; i++) {
                $('input[name=sec' + i + 'manyDistortion').prop('checked', checked);

            }
            $("#allmanyValues").val("");
        }
    });

    const handlemanyChange = (e, index) => {

        let isChecked = e.target.checked;

        if (isChecked) {
            $('input[name=sec' + index + 'manyDistortion').prop('checked', isChecked);

            var comp = $("#many" + index + "test").val();
            sum2 += parseFloat(comp);
            $("#allmanyValues").val(sum2);
        } else {
            var comp = $("#many" + index + "test").val();
            sum2 -= parseFloat(comp);
            $("#allmanyValues").val(sum2);
        }



    }
    function isANumber(n) {
        var numStr = /^-?(\d+\.?\d*)$|(\d*\.?\d+)$/;
        return numStr.test(n.toString());
    }


    const textChange = (e, index) => {


        //$('input[name=sec'+index+'Distortion').prop('checked', isChecked);
        // var comp=$("#comp" + index + "gallons").val();

        // sum+= parseFloat(comp);
        //  $("#allValues").val(Math.abs(sum));
        sum = 0;
        for (var i = 0; i < compartments.length; i++) {

            var comp = $("#comp" + i + "gallons").val();
            sum += parseFloat(comp);


        }
        if (isNaN(Math.abs(sum))) {
            $("#allValues").val("")
        } else {
            $("#allValues").val(Math.abs(sum))
        }



    }
    const handleChange = (e, index) => {

        let isChecked = e.target.checked;
        $('#checkAll').prop('checked', false);

        if (isChecked) {
            $('input[name=sec' + index + 'Distortion').prop('checked', isChecked);
            var comp = $("#comp" + index + "gallons").val();
            sum += parseFloat(comp);
            $("#allValues").val(Math.abs(sum));
        } else {

            var comp = $("#comp" + index + "gallons").val();
            sum -= parseFloat(comp);
            $("#allValues").val(Math.abs(sum));
        }



    }

    const redirect = (page) => {

        navigate(page);

    }

    const save = (status) => {
        // alert(moment($("#pressureTest1startTimePressure").val(),["HH:mm"]).format('HH:mm A'))
        // console.log("results=="+JSON.stringify(inputList));
        var jsonStr = [];
        var jsonstrMany = [];
        var documentList = [];

        for (var i = 0; i < compartments.length; i++) {

            jsonStr.push({
                "working": $("input[name=sec" + (i) + "Distortion]:checked").val() === "1" ? "1" : "0",
                "safeFillAmountInGals": $("#comp" + (i) + "gallons").val() ? $("#comp" + (i) + "gallons").val() : "0",
            })
        }
        for (var i = 0; i < manywaytest.length; i++) {

            jsonstrMany.push({
                "working": $("input[name=sec" + (i) + "manyDistortion]:checked").val() === "1" ? "1" : "0",
                "safeFillAmountInGals": $("#many" + (i) + "test").val() ? $("#many" + (i) + "test").val() : "0",
            })
        }
        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                    extension = "word";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                }
                else {

                }


                documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }
        } catch (exception) {
            documentList.push();
        }
        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter inspectedBy",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }


        var requestObjectForInsp =
        {

            "type": "DOT Cargo Tank Test",
            "workOrderNumber": $("#workOrderNumber").val(),
            "notes": $("#notes").val(),
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "status": status,
            "taskOrderId": taskOrderId,
            "inspectionType":
            {
                "id": sessionStorage.getItem("selectedinspectionId"),
                "name": sessionStorage.getItem("selectedinspectionType")
            },
            "result":
            {
                "approvedCertify": "0",
                "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
                "vehicleReturnedServices": "0",
                "vehicleWithdrawnFromService": "0",
                "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id": vid,
                "unitNumber": $("#lisencePlateNumber").html()
            },
            "dOTCargoTankTest":
            {
                "compartmentList": jsonStr,
                "manwayCompartmentsList": jsonstrMany,
                "checkPerformed": {
                    "checkPerformed": $("input[name='checkPerformed']:checked").val() ? $("input[name='checkPerformed']:checked").val() : '',
                    "otherData": $("#otherData").val() != '' ? $("#otherData").val() : ''
                },
                "pressureTest1":
                {
                    "startTime": $("#pressureTest1startTime").val() != '' ? $("#pressureTest1startTime").val() : '',
                    "finishTime": $("#pressureTest1finishTime").val() != '' ? $("#pressureTest1finishTime").val() : '',
                    // "startTime": $("#pressureTest1startTime").val() != '' ? $("#pressureTest1startTime").val()>'12'?moment($("#pressureTest1startTime").val(),["HH:mm"]).format('HH:mm'):moment($("#pressureTest1startTime").val(),["hh:mm"]).format('hh:mm A') : '',
                    // "finishTime": $("#pressureTest1finishTime").val() != '' ? moment($("#pressureTest1finishTime").val(),["hh:mm"]).format('hh:mm A') : '',
                    "startTimePressure": $("#pressureTest1startTimePressure").val() != '' ? $("#pressureTest1startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest1finishTimePressure").val() != '' ? $("#pressureTest1finishTimePressure").val() : '',
                    "change": $("#pressureTest1Change").val() != '' ? $("#pressureTest1Change").val() : ''
                },
                "pressureTest2":
                {
                    "startTime": $("#pressureTest2startTime").val() != '' ? $("#pressureTest2startTime").val() : '',
                    "finishTime": $("#pressureTest2finishTime").val() != '' ? $("#pressureTest2finishTime").val() : '',
                    "startTimePressure": $("#pressureTest2startTimePressure").val() != '' ? $("#pressureTest2startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest2finishTimePressure").val() != '' ? $("#pressureTest2finishTimePressure").val() : '',
                    "change": $("#pressureTest2change").val() != '' ? $("#pressureTest2change").val() : '',
                    "testResult": $("#pressureTest2Result").val() != '' ? $("#pressureTest2Result").val() : ''
                },
                "vaccumeTest1": {
                    "startTime": $("#vaccumeTest1startTime").val() != '' ? $("#vaccumeTest1startTime").val() : '',
                    "finishTime": $("#vaccumeTest1finishTime").val() != '' ? $("#vaccumeTest1finishTime").val() : '',
                    "startTimePressure": $("#vaccumeTest1startTimePressure").val() != '' ? $("#vaccumeTest1startTimePressure").val() : '',
                    "finishTimePressure": $("#vaccumeTest1finishTimePressure").val() != '' ? $("#vaccumeTest1finishTimePressure").val() : '',
                    "change": $("#vaccumeTest1change").val() != '' ? $("#vaccumeTest1change").val() : '',
                    // "testResult": $("#vaccumeTest1Result").val() != '' ? $("#vaccumeTest1Result").val() : ''
                },
                "vaccumeTest2": {
                    "startTime": $("#vaccumeTest2startTime").val() != '' ? $("#vaccumeTest2startTime").val() : '',
                    "finishTime": $("#vaccumeTest2finishTime").val() != '' ? $("#vaccumeTest2finishTime").val() : '',
                    "startTimePressure": $("#vaccumeTest2startTimePressure").val() != '' ? $("#vaccumeTest2startTimePressure").val() : '',
                    "finishTimePressure": $("#vaccumeTest2finishTimePressure").val() != '' ? $("#vaccumeTest2finishTimePressure").val() : '',
                    "change": $("#vaccumeTest2change").val() != '' ? $("#vaccumeTest2change").val() : '',
                    "testResult": $("#vaccumeTest2Result").val() != '' ? $("#vaccumeTest2Result").val() : ''
                }, "componentsTested": {
                    "productPiping": $("input[name='productPiping']:checked").val() === "1" ? "1" : "0",
                    "dischargeValves": $("input[name='dischargeValves']:checked").val() === "1" ? "1" : "0",
                    "dischargeHoses": $("input[name='dischargeHoses']:checked").val() === "1" ? "1" : "0",
                    "manholeAssemblies": $("input[name='manholeAssemblies']:checked").val() === "1" ? "1" : "0",
                    "tankVessel": $("input[name='tankVessel']:checked").val() === "1" ? "1" : "0",
                    "vaporCollection": $("input[name='vaporCollection']:checked").val() === "1" ? "1" : "0",
                    "compartmentVent": $("input[name='compartmentVent']:checked").val() === "1" ? "1" : "0",
                    "vaporvent": $("input[name='vaporvent']:checked").val() === "1" ? "1" : "0",
                    "other": $("input[name='other']:checked").val() === "1" ? "1" : "0",
                    "otherDataCom": $("#notes22").val() ? $("#notes22").val() : ''

                }, "vaporValveTest": {
                    "startTime": $("#vaporValveTeststartTime").val() != '' ? $("#vaporValveTeststartTime").val() : '',
                    "finishTime": $("#vaporValveTestfinishTime").val() != '' ? $("#vaporValveTestfinishTime").val() : '',
                    "startTimePressure": $("#vaporValveTeststartTimePressure").val() != '' ? $("#vaporValveTeststartTimePressure").val() : '',
                    "finishTimePressure": $("#vaporValveTestfinishTimePressure").val() != '' ? $("#vaporValveTestfinishTimePressure").val() : '',
                    "change": $("#vaporValveTestchange").val() != '' ? $("#vaporValveTestchange").val() : ''
                }, "pressureTest3": {
                    "startTime": $("#pressureTest3startTime").val() != '' ? $("#pressureTest3startTime").val() : '',
                    "finishTime": $("#pressureTest3startTime").val() != '' ? $("#pressureTest3finishTime").val() : '',
                    "startTimePressure": $("#pressureTest3startTimePressure").val() != '' ? $("#pressureTest3startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest3finishTimePressure").val() != '' ? $("#pressureTest3finishTimePressure").val() : '',
                    "change": $("#pressureTest3Change").val() != '' ? $("#pressureTest3Change").val() : ''

                },


            },
            "documentList": documentList

        }
        $("#loadingforsubmit").show();
        // setLoading(true);
        //   const requestOptions =
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: res.message,
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                    navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                }
            });
        });

    }
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {}]);
    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }


    const onUploadFileChange = (event, index) => {


        var target = event.target;
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                const { name, value } = event.target;
                const list = [...inputList];
                list[index][name] = result;
                setBase64file(result);
                // setFile(result)
                setfileName(target.files[0]);
                // setFileName(target.files[0])
            }
        })
    }
    const NumericOnly = (e) => { //angka only
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    function ValidateHhMm(inputField) {
        // alert(inputField);
        // alert(inputField.target.value);
        // var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);
        var isValid = /\d{2,}(:[0-5]\d){2}/.test(inputField);
        //  alert(isValid)
        if (isValid) {
            //   $("#pressureTest1startTimeERR").text("");
            return true;
        } else {
            //   $("#pressureTest1startTimeERR").text("Formate is wrong");
            return false;
        }

        // return isValid;
    }
    return (
        <>
            {LoadingComponentForSubmit}
            <div class="row">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">


                        {/* <div class="divider"></div> */}
                        <div class="row">
                            <div class="w-100 px-3">
                                <h4 class="mb-2 form-inspection-title text-center">DOT Cargo Tank - EPA - Pressure Vacuum Test (OHIO)</h4>
                            </div>
                            <div class="col-lg-12 mx-0"><WorkOrderNumberDiv /></div>
                            <div class="w-100 px-3">
                                <div class="row mb-2 mt-2">
                                    <div class="col-lg-12">
                                        <div class="py-2">
                                            <h5 class="text-uppercase mb-0">Check Performed</h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 mb-2">
                                        <div class="d-flex align-items-center">
                                            <input type="radio" name="checkPerformed" value="new" class="mr-2" />
                                            <span>New</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 mb-2">
                                        <div class="d-flex align-items-center">
                                            <input type="radio" name="checkPerformed" value="annual_retest" class="mr-2" />
                                            <span>Annual Retest</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 mb-2" style={{ maxWidth: "100px" }}>
                                        <div class="d-flex align-items-center">
                                            <input type="radio" name="checkPerformed" value="repair" class="mr-2" />
                                            <span>Repair </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-2 d-flex align-items-center">
                                        <div class="d-flex align-items-center" style={{ minWidth: "150px" }}>
                                            <input type="radio" name="checkPerformed" value="other" class="mr-2" />
                                            <span>Other (Specify)</span>

                                        </div>
                                        <div id="otherField">
                                            <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" size="10" name="otherData" id="otherData" class="mr-2" /> </div></div>
                                        </div>
                                    </div>



                                </div>
                                <div class="inspection-wrap">

                                    <div class="table-2">
                                        <table class="table mb-4">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "50%", lineHeight: "40px" }}>Compartment Tested/Inspected </th>
                                                    <th scope="col" style={{ "width": "40%", lineHeight: "40px" }}>Safe fill amount(GALS)</th>
                                                    <th scope="col" class="d-flex align-items-center justify-content-center"><input type="checkbox" placeholder="" onChange={NumericOnly} id="checkAll" class="mr-2 " /></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    compartments ? compartments.map((data, index) => (
                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center">
                                                                    <div class="col-md-4">Compartment #{index + 1} </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="row align-items-center">

                                                                    <div class="col-md-4"><input type="text" class="form-control" id={"comp" + index + "gallons"} placeholder="" defaultValue={data == "null" ? 0 : data} onChange={e => textChange(e, index)} /></div>

                                                                </div>
                                                            </td>
                                                            <td class="text-center">  <input type="checkbox" aria-label="Radio button for following text input" name={"sec" + (index) + "Distortion"} value="1" onChange={e => handleChange(e, index)} /></td>

                                                        </tr>
                                                    )) : ""

                                                }


                                                <td>Total in Gallons</td> <td><input type="text" aria-label="Radio button for following text input" id="allValues" onChange={NumericOnly} disabled style={{ maxWidth: "129px" }} /></td>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="w-100 px-3">
                                <div class="inspection-wrap">

                                    <div class="table-2">
                                        <table class="table mb-4">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "50%" }}>Manway Tested/Inspected(fill cover gaskets & vents) </th>
                                                    <th scope="col" class="d-flex align-items-center justify-content-center"><input type="checkbox" aria-describedby="emailHelp" placeholder="" id="checkAllmany" class="mr-2" />ALL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    manywaytest ? manywaytest.map((data, index) => (
                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center">
                                                                    <div class="col-md-4">Manway #{index + 1} </div>

                                                                </div>
                                                            </td>

                                                            <td class="text-center">  <input type="checkbox" aria-label="Radio button for following text input" name={"sec" + index + "manyDistortion"} value="1" onChange={e => handlemanyChange(e, index)} /></td>

                                                        </tr>

                                                    )) : ""

                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}
                            <div class="px-3">
                                <div class="inspection-wrap">



                                    <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                        <p>Test Method: US EPA Method 27, 40CFR, Part 60 APPX. A (03.420) <br />
                                            Test Requirements: Modified Method 27, Pressure 18" W.C., Vacuum 6" W.C. <br />
                                            *Note: Two consecutive test within 1/2 W.C. Test Result is average of two consecutive test
                                        </p>
                                    </div>


                                    <div class="table-3 mb-4">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colSpan="2">Pressure - 18" W.C / 5 mins. Less than 1" W.C loss</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test  #1</div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-2 mm-60">Start Time</span>
                                                                    <input type="time" id="pressureTest1startTime" class="form-control" onChange={e => ValidateHhMm(e.target.value)} aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker 

                                                                    //    showTimeSelect
                                                                        // showTimeSelectOnly
                                                                        // timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        showTimeInput
                                                                        // timeInputLabel="Time:"
                                                                        dateFormat="HH:mm aa" selected={pressureTest1startTime} onChange={(date) => setPressureTest1startTime(date)} type="text" id="pressureTest1startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="text" class="form-control mr-3" id="pressureTest1startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-2 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="pressureTest1finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={pressureTest1finishTime} onChange={(date) => setPressureTest1finishTime(date)} type="text" id="pressureTest1finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="text" class="form-control mr-3" id="pressureTest1finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-2 mm-60">Change </span>
                                                                    <input type="text" class="form-control mr-2" id="pressureTest1Change" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test  #2</div>


                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Start Time</span>
                                                                    <input type="time" class="form-control" id="pressureTest2startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm" selected={pressureTest2startTime} onChange={(date) => setPressureTest2startTime(date)} type="text" id="pressureTest2startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="pressureTest2startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="pressureTest2finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={pressureTest2finishTime} onChange={(date) => setPressureTest2finishTime(date)} type="text" id="pressureTest2finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="pressureTest2finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Change </span>
                                                                    <input type="input" class="form-control mr-2" id="pressureTest2change" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>

                                                            </div>

                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-0 mm-60" >Test Result </span>
                                                                    <input type="input" class="form-control mr-2" id="pressureTest2Result" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </td>

                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="table-3 mb-4">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>

                                                    <th scope="col" colSpan="2">Vacuum - 6" W.C / 5 mins. Less than 1" W.C loss</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">

                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test  #3</div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Start Time</span>
                                                                    <input type="time" class="form-control" id="vaccumeTest1startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaccumeTest1startTime} onChange={(date) => setVaccumeTest1startTime(date)} type="text" id="vaccumeTest1startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaccumeTest1startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="vaccumeTest1finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaccumeTest1finishTime} onChange={(date) => setVaccumeTest1finishTime(date)} type="text" id="vaccumeTest1finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaccumeTest1finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60" >Change </span>
                                                                    <input type="input" class="form-control mr-2" id="vaccumeTest1change" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test  #4</div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Start Time</span>
                                                                    <input type="time" class="form-control" id="vaccumeTest2startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaccumeTest2startTime} onChange={(date) => setVaccumeTest2startTime(date)} type="text" id="vaccumeTest2startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaccumeTest2startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="vaccumeTest2finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaccumeTest2finishTime} onChange={(date) => setVaccumeTest2finishTime(date)} type="text" id="vaccumeTest2finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaccumeTest2finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Change </span>
                                                                    <input type="input" class="form-control mr-2" id="vaccumeTest2change" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-0 mm-60" >Test Result </span>
                                                                    <input type="input" class="form-control mr-2" id="vaccumeTest2Result" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="col-lg-12">
                                            <div class="p-4 text-center">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Components Tested / Inspected</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="productPiping" value="1" class="mr-2" />
                                                <span>Product Piping/Gaskets</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="dischargeValves" value="1" class="mr-2" />
                                                <span>Discharge Valves</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="dischargeHoses" value="1" class="mr-2" />
                                                <span>Discharge Hoses</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="manholeAssemblies" value="1" class="mr-2" />
                                                <span>Manhole Assemblies</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="tankVessel" value="1" class="mr-2" />
                                                <span>Tank vessel weld seams</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="vaporCollection" value="1" class="mr-2" />
                                                <span>Vapor collection piping</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="compartmentVent" value="1" class="mr-2" />
                                                <span>Compartment Vent</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="vaporvent" value="1" class="mr-2" />
                                                <span>Vapor vent hood / hose assemblies'</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="other" value="1" class="mr-2" />
                                                <span>Other  (List)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="otherFieldTextFiled">
                                        <div class="d-flex align-items-center"><textarea maxLength="300" class="form-control mb-2" name="notes22" id="notes22" rows="3" spellcheck="false" placeholder=""></textarea><br /><br /> </div>
                                    </div>

                                    <div class="table-3 mb-4">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Vapor Valve Test: (40CFR 63.420(E) (2))</th>
                                                    <th scope="col">Pressure 18" W.C/ 5 min. duration &lt;5" W.C loss</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test #1</div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Start Time</span>
                                                                    <input type="time" class="form-control" id="vaporValveTeststartTime" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaporValveTeststartTime} onChange={(date) => setVaporValveTeststartTime(date)} type="text" id="vaporValveTeststartTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaporValveTeststartTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="vaporValveTestfinishTime" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={vaporValveTestfinishTime} onChange={(date) => setVaporValveTestfinishTime(date)} type="text" id="vaporValveTestfinishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="vaporValveTestfinishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60" >Change </span>
                                                                    <input type="input" class="form-control mr-2" id="vaporValveTestchange" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-12 mb-3">Test #2</div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Start Time</span>
                                                                    <input type="time" class="form-control" id="pressureTest3startTime" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={pressureTest3startTime} onChange={(date) => setPressureTest3startTime(date)} type="text" id="pressureTest3startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="pressureTest3startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60">Finish Time</span>
                                                                    <input type="time" class="form-control" id="pressureTest3finishTime" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    {/* <DatePicker showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="HH:mm aa"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="HH:mm aa" selected={pressureTest3finishTime} onChange={(date) => setPressureTest3finishTime(date)} type="text" id="pressureTest3finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3">Pres.</span>
                                                                    <input type="input" class="form-control mr-3" id="pressureTest3finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                    <span class="mr-3 mm-60" >Change </span>
                                                                    <input type="input" class="form-control mr-2" id="pressureTest3Change" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <span>"W.C</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row py-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>


                        {/* <div class="divider"></div>
                            <div class="row p-3">
                                 <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by <span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date <span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}
                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

DOT.propTypes = {};

DOT.defaultProps = {};

export default DOT;