import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../utility/requestObject";
import HeaderBlock from "../vehicle/VehicleHeaderBlock";
import documentDetails_ from "./wet-test-certification/documentDetails_";
import VerifiedBy from "../../../utility/verfifiedBy";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";


const InspectionVaporTightnessCertificateRead = () => {
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [certify, setApproveCertify] = useState();
   const [roles, setRoles] = useState("");
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [statusInspection, setStatus] = useState("");
   
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
    
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId,inspectionId)
   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
  
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setApproveCertify(res.result?res.result.approvedCertify:"");

         setDocumentLists(res.rows.documentList);
         
         // alert(res.rows.vaporTightnessCertification.tankCapacityInGallons.lessthan999Gallons);
         
         $('input[name="lessthan999Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.lessthan999Gallons + '"]').trigger("click").attr("disabled", true);
         $('input[name="1000to1499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.g1000to1499Gallons + '"]').trigger("click").attr("disabled", true);
         $('input[name="1500to2499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.g1500to2499Gallons + '"]').trigger("click").attr("disabled", true);
         $('input[name="greaterthan2499Gallons"][value="' + res.rows.vaporTightnessCertification.tankCapacityInGallons.greaterthan2499Gallons + '"]').trigger("click").attr("disabled", true);

         $('input[name="optic"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic + '"]').trigger("click").attr("disabled", true);
         $('input[name="thermister"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic + '"]').trigger("click").attr("disabled", true);
         $('input[name="other"][value="' + res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.optic + '"]').trigger("click").attr("disabled", true);
          $("#otherData").val(res.rows.vaporTightnessCertification?res.rows.vaporTightnessCertification.typeOfOverProtectionSystem?res.rows.vaporTightnessCertification.typeOfOverProtectionSystem.other:"":""); 
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");

         $('input[name="loadOfNonVolatile"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.loadOfNonVolatile + '"]').trigger("click").attr("disabled", true);
         $('input[name="steamCleaned"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.steamCleaned + '"]').trigger("click").attr("disabled", true);
         $('input[name="purgeEachCompartmentWithAirFor20Minutes"][value="' + res.rows.vaporTightnessCertification.purgedTankCompartmentsOfVapour.purgeEachCompartmentWithAirFor20Minutes + '"]').trigger("click").attr("disabled", true);
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

   }

   const redirect=(page)=>
   {
   
      navigate(page);
   
   }
   ////////////////documentDetails Customer/////////////////////////////
   const save=(status)=>
   {
 
   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Inspected by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }

   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select disposition of tank",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select inspection result",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
         if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
         {

         }else{

         status="Rejected";
         }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,
          
           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }
          
         
       }; 
       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) => 
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
 
   } 
  return(
  <>
  {LoadingComponentForSubmit}
     <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
               <div class="row mb-0">
                     <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                        <div class="d-flex justify-content-between align-items-center my-4">
                           <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                        </div>
                     </div>
                  </div>
               <div class="row mb-2">
                           <div class="col-lg-11 col-md-12 m-auto ">
                           <HeaderBlock cids={fkAccountId} />
                           </div>
                  </div>
                  
                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">
                           <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div>
                           <div class="divider"></div>
                           <div class="row p-3">
                              <div class="col-lg-12">
                                 <h4 class="mb-2 form-inspection-title text-center">Vapor Tightness Certification Form                                    </h4>
                              </div>
                              <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div>
                              <div class="col-lg-12">
                                 <div class="inspection-wrap">
                                    <div class="text-center mb-2 mt-2">
                                    <h6>Form for cargo tank truck vapor tightness testing :</h6>
                                       <small>
                                       <span class="mb-3"> Air regulation for vapor tightness: VA SAPCB Article 4-37, 40 CFR 60.505, 40 CFR 63.425(e) and 40 CFR 63.11092(f).</span>
                                       </small>
                                    </div>
                                    {/* <!-- accordion --> */}
                                    <div class="accordion" id="accordionPanelsStayOpenExample">
                                       <div class="accordion-item">
                                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                             EPA Reference Method 27 MACT R Annual Certification - Allowed Pressure Change
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" >
                                             <div class="accordion-body pt-3 pb-4">
                                             <div class="row mb-4">
                                          <div class="col-lg-6 mb-2">
                                             <div class="d-flex align-items-center">
                                                 <input type="checkbox" name="greaterthan2499Gallons"  id="greaterthan2499Gallons" class="mr-2" value="1" readonly/>
                                                <span>(tank truck ≥ 2500 gallons) 1.0 inch</span>
                                             </div>
                                         </div>

                                         <div class="col-lg-6 mb-2">
                                          <div class="d-flex align-items-center">
                                              <input type="checkbox" name="1500to2499Gallons"  class="mr-2" value="1" />
                                             <span>(tank truck 1500-2499 gallons) 1.5 inch</span>
                                          </div>
                                      </div>

                                      <div class="col-lg-6 mb-2">
                                       <div class="d-flex align-items-center">
                                           <input type="checkbox"  name="1000to1499Gallons"  class="mr-2" value="1" />
                                          <span>(tank truck 1,499 to 1,000 gallons) 2.0 inch</span>
                                       </div>
                                   </div>

                                   <div class="col-lg-6 mb-2">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox"  name="lessthan999Gallons"  class="mr-2" value="1" />
                                       <span>(tank truck less 999 gallons) 2.5 inch</span>
                                    </div>
                                </div>
                                       </div>
                                                <div class="row">
                                                   <div class="col-lg-12">
                                                      <div class="table-2 mb-4">
                                                         <table class="table">
                                                            <thead>
                                                               <tr>
                                                                  <th scope="col"></th>
                                                                  <th scope="col">Good</th>
                                                      <th scope="col">Faulty</th>
                                                      <th scope="col" style={{width:"110px"}}>Repaired</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               <tr>
                                                                  <td>Overfill Protection System Repaired</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.overfillProtectionSystemRepaired=='2'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="row">
                                                   <div class="col-lg-12">
                                                      <div class="table-2 mb-4">
                                                         <table class="table">
                                                            <thead>
                                                               <tr>
                                                                  <th scope="col"></th>
                                                                  <th scope="col">Optic</th>
                                                      <th scope="col">Thermister</th>
                                                      <th scope="col" style={{width:"110px"}}>Other</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               <tr>
                                                                  <td>Type overfill protection system</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='2'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}{(() => {

if(inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.typeOfOverProtectionSystem.optic=='2'?true:false:false){
   return (<><input type="text" name="otherData" id="otherData" class="form-control"/><br/></>);
}
})()}</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                                
                                                
                                                {/* <div class="row mb-4">
                                          <div class="col-md-12">
                                             <h4>Type over protection system</h4>
                                             <div class="d-flex align-items-center">
                                                <input type="checkbox"  name="optic" value="1"  class="mr-2" />
                                               <span>Optic</span>
                                            </div>

                                            <div class="d-flex align-items-center">
                                             <input type="checkbox"  name="thermister" value="1"  class="mr-2" />
                                            <span>Thermister</span>
                                         </div>

                                         <div class="d-flex align-items-center">
                                          <input type="checkbox"  name="other" value="1"  class="mr-2" />
                                         <span>Other</span>
                                      </div>
                                          </div>
                                       </div> */}
                                                <div class="row mb-4">
                                                   <div class="col-lg-12">
                                                      <div class="table-2 mb-4">
                                                         <table class="table">
                                                            <thead>
                                                               <tr>
                                                                  <th scope="col"></th>
                                                                  <th scope="col">Yes</th>
                                                                  <th scope="col">No</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               <tr>
                                                                  <td>Connect static electrical ground to tank</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.connect=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.connect=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Purged lines of liquid	</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.liquid=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.liquid=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Purged tank compartments of Vapor:</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.compartments=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.compartments=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Temp Stablization</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.stablization=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.stablization=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Testing location: <input type="text" name="locationTesting" id="locationTesting" readOnly value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.location:""}/></td>
                                                                  <td class="text-center"></td>
                                                                  <td class="text-center"></td>
                                                                  {/* <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.location=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.location=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                               </tr>
                                                               <tr>
                                                                  <td>Open & Close each dome cover</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.cove=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.cove=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                   <div class="col-lg-12">
                                                      <div class="table-2 mb-4">
                                                         <table class="table">
                                                            <thead>
                                                               <tr>
                                                                  <th scope="col"></th>
                                                                  <th scope="col">Yes</th>
                                                                  <th scope="col">No</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               <tr>
                                                                  <td>Connect compartments of tank internally</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.internally=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.internally=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Attach test cap to vapor recovery coupling</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.recovery=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.recovery=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Connect pressure-vacuum supply & pressure relief valve to shut-off valve                                                         </td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.pressure=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.pressure=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Attach Manometer (or equivalent) to pressure tap</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.manometer=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                                  <td class="text-center">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.otherProperties.manometer=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      {/* <h4>Type over protection system</h4> */}
                                                      <div class="d-flex align-items-center">
                                                         <input type="checkbox" id="vehicle1" name="loadOfNonVolatile" value="1" class="mr-2" />
                                                         <span>Load of Non-Volatile</span>
                                                      </div>
                                                      <div class="d-flex align-items-center">
                                                         <input type="checkbox" id="vehicle1" name="steamCleaned" value="1" class="mr-2" />
                                                         <span>Steam cleaned</span>
                                                      </div>
                                                      <div class="d-flex align-items-center">
                                                         <input type="checkbox" id="vehicle1" name="purgeEachCompartmentWithAirFor20Minutes" value="1" class="mr-2" />
                                                         <span>Purge each compartment with air for 20 minutes</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingTwo">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                             EPA METHOD 27 Pressure Test
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="p-3 text-left" style={{backgroundColor: "#ccc"}}>
                                                   <p>Increase pressure to a minimum of 18 inches (maximum of 26.6) Water Gauge (Manometer); Indicate starting pressure (Pi) and pressure (Pf) at the end of 5 minutes. Record initial (Ti) and final time (Tf) of test or duration if stop watch is used																																					
                                                   </p>
                                                </div>
                                                <div class="row mt-3">
                                                   <div class="col-lg-12">
                                                      <div class="table-5">
                                                         <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 1</td>
                                                                  <td style={{width: "100%", display: "grid", padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight:"35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings (Inches)
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">pi =</label>
                                                                          <b class="mn10 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.pi:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40 ">pf =</label>
                                                                           <b class="mn10 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.pf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40 ">a =</label>
                                                                           <b class="mn10 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.a:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti =</label>
                                                                           <b class="mn30 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.ti:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for=""class="mn40">tf =</label>
                                                                           <b class="mn30 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.tf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti-tf =</label>
                                                                           <b class="mn30 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run1.titf:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="row mt-3">
                                                   <div class="col-lg-12">
                                                      <div class="table-5">
                                                         <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 2</td>
                                                                  <td style={{width:"100%", display:"grid" , padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings (Inches)
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">pi =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.pi:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">pf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.pf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">b =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.a:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.ti:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">tf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.tf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti-tf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run2.titf:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="row mt-3">
                                                   <div class="col-lg-12">
                                                      <div class="table-5">
                                                         <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 3</td>
                                                                  <td style={{width: "auto", display: "grid" , padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings (Inches)
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9:00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">pi =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.pi:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">pf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.pf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">c =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.a:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.ti:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">tf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.tf:""}</b>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" class="mn40">ti-tf =</label>
                                                                           <b class="mn40 mb-2">{inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run3.titf:""}</b>
                                                                        </div>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>

                                                
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingthree">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                             MACT R - 40 CFR 63.425(E) Internal Vapor Valve Test
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="table-6">
                                                   <table class="w-100">
                                                      <tr>
                                                         <th colspan="6" class="text-center p-3">Water Gauge Readings (Inches)</th>
                                                      </tr>
                                                      <tr>
                                                        <td><div class="d-flex align-items-center"><span>pi=0-inch</span> </div></td>
                                                        {/* <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.pf:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/> */}
                                                        <td><div class="d-flex align-items-center"><span>pf</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.pf:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td>
                                                        <td><div class="d-flex align-items-center"><span>pi-pf</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.pfpi:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td>
                                                        <td><div class="d-flex align-items-center"><span className="mn-30 d-ruby">ti</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.ti:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td>
                                                        <td><div class="d-flex align-items-center"><span>tf</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.tf:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td>
                                                        <td><div class="d-flex align-items-center"><span>tf-ti</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.titf:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td>
                                                        {/* <td><div class="d-flex align-items-center"><span>tf-ti</span> <input type="input" class="form-control ml-3" value={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.waterGaugeReading?inspectionDetails.vaporTightnessCertification.waterGaugeReading.pf:"":""} readOnly id="abc" aria-describedby="emailHelp" style={{width: "90px;"}}/></div></td> */}
                                                      </tr>
                                                   
                                                    </table>
                                                </div>
                                               
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingfour">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapsefour">
                                             EPA METHOD 27 Vacuum Test
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfour" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="row mt-3">
                                                   <div class="col-lg-12">
                                                      <div class="table-5">
                                                      <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 1</td>
                                                                  <td style={{width: "auto", display: "grid" , padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3:00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">3:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Vi ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run4.vi:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                                  <td class="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Ti ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run4.ti:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                         <br/>
                                                         <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 2</td>
                                                                  <td style={{width: "auto", display: "grid" , padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3:00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">3:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2">
                                                                     </div>
                                                                  </td>
                                                                  <td className="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Vi ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run5.vi:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                                  <td className="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Ti ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run5.ti:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                         <br/>
                                                         <table>
                                                            <tbody>
                                                               <tr>
                                                                  <td class="text-center">Run 3</td>
                                                                  <td style={{width: "auto", display: "grid" , padding:"0px"}}>
                                                                     <div>
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Water Gauge
                                                                           Readings
                                                                        </div>
                                                                        <div class="d-flex justify-content-center border-bottom">
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3:00</div>
                                                                           <div class="d-flex justify-content-center w-half m-auto p-2">3:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left">
                                                                     </div>
                                                                  </td>
                                                                  <td className="p-0">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Total Inches Water</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">6:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 border-left ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Vi ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run6.vi:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                                  <td className="p-0 border-left">
                                                                     <div class="border-bottom">
                                                                        <div class="border-bottom p-2" style={{minHeight: "35px", lineHeight:"35px"}}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                        <div class="d-flex justify-content-center">
                                                                           <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="p-2 ">
                                                                        <div class="d-flex align-items-center mb-2">
                                                                           <label for="" className="mn-40">Ti ={inspectionDetails.vaporTightnessCertification?inspectionDetails.vaporTightnessCertification.run4?inspectionDetails.vaporTightnessCertification.run6.ti:"":""}</label>
                                                                           <b></b>
                                                                        </div>
                                                                      
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </div>
                                                   </div>   
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="divider2"></div>
                           <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                  
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                            
                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
              <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"}/> */}

                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
            </>
          )
        } else {
          return (
            <>
          <div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}
         
         </>
          )
        }
      }
      })()}
                             </div>
                           <div class="divider2"></div>
                           {(() => 
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') 
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                        </div>
                     </div>
                  </div>
               </div>

            </div>
  </>
)};

InspectionVaporTightnessCertificateRead.propTypes = {};

InspectionVaporTightnessCertificateRead.defaultProps = {};

export default InspectionVaporTightnessCertificateRead;
