import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const AnnualVehicle1 = () => {
   
   const [section1, setSection1] = useState('');
   const [serviceBrakeDate, setServiceBrakeDate] = useState(null);
   const [parkingBrakeDate, setParkingBrakeDate] = useState(null);
   const [drumsAndRotorsDate, setDrumsAndRotorsDate] = useState(null);
   const [hoseDate, setHoseDate] = useState(null);
   const [tubingDate, setTubingDate] = useState(null);
   const [lowPressureWarningDate, setLowPressureWarningDate] = useState(null);
   const [tractorPrptectionDate, setTractorPrptectionDate] = useState(null);
   const [airCompressorDate, setaAirCompressorDate] = useState(null);
   const [electricBrakesDate, setElectricBrakesDate] = useState(null);
   const [hydraulicDate, setHydraulicDate] = useState(null);
   const [vacccumeSystemDate, setVacccumeSystemDate] = useState(null);
   const [antilockBrakeDate, setAntilockBrakeDate] = useState(null);
   const [automaticBrakeDate, setAutomaticBrakeDate] = useState(null);
   // new Date();

   useEffect(() => {
      if(CheckAuthentication)
      {
      $("input[name='serviceBrake_repaired']").attr('disabled', 'true');
      $("input[name='serviceBrakeDate']").hide();
      $("input[name='serviceBrakeDateTemp']").attr('disabled', 'true');

      $("input[name='parkingBrake_repaired']").attr('disabled', 'true');
      $("input[name='parkingBrakeDate']").hide();
      $("input[name='parkingBrakeDateTemp']").attr('disabled', 'true');

      $("input[name='drumsAndRotors_repaired']").attr('disabled', 'true');
      $("input[name='drumsAndRotorsDate']").hide();
      $("input[name='drumsAndRotorsDateTemp']").attr('disabled', 'true');

      $("input[name='hose_repaired']").attr('disabled', 'true');
      $("input[name='hoseDate']").hide();
      $("input[name='hoseDateTemp']").attr('disabled', 'true');

      $("input[name='tubing_repaired']").attr('disabled', 'true');
      $("input[name='tubingDate']").hide();
      $("input[name='tubingDateTemp']").attr('disabled', 'true');

      $("input[name='lowPressureWarning_repaired']").attr('disabled', 'true');
      $("input[name='lowPressureWarningDate']").hide();
      $("input[name='lowPressureWarningDateTemp']").attr('disabled', 'true');

      $("input[name='tractorPrptection_repaired']").attr('disabled', 'true');
      $("input[name='tractorPrptectionDate']").hide();
      $("input[name='tractorPrptectionDateTemp']").attr('disabled', 'true');

      $("input[name='airCompressor_repaired']").attr('disabled', 'true');
      $("input[name='airCompressorDate']").hide();
      $("input[name='airCompressorDateTemp']").attr('disabled', 'true');


      $("input[name='electricBrakes_repaired']").attr('disabled', 'true');
      $("input[name='electricBrakesDate']").hide();
      $("input[name='electricBrakesDateTemp']").attr('disabled', 'true');

      $("input[name='hydraulic_repaired']").attr('disabled', 'true');
      $("input[name='hydraulicDate']").hide();
      $("input[name='hydraulicDateTemp']").attr('disabled', 'true');


      $("input[name='vacccumeSystem_repaired']").attr('disabled', 'true');
      $("input[name='vacccumeSystemDate']").hide();
      $("input[name='vacccumeSystemDateTemp']").attr('disabled', 'true');

      $("input[name='antilockBrake_repaired']").attr('disabled', 'true');
      $("input[name='antilockBrakeDate']").hide();
      $("input[name='antilockBrakeDateTemp']").attr('disabled', 'true');


      $("input[name='automaticBrake_repaired']").attr('disabled', 'true');
      $("input[name='automaticBrakeDate']").hide();
      $("input[name='automaticBrakeDateTemp']").attr('disabled', 'true');
   
      scrollTop();
      getSection1();
   }
   else
   {
      sessionStorage.clear();
      localStorage.clear();
     //navigate("/login");
       window.location.href="/login";
   }
   }, []);


   function getSection1() {

      JSON.parse(sessionStorage.getItem("section1Break"));

      console.log("ddd" + JSON.parse(sessionStorage.getItem("section1Break")));

      var obj = JSON.parse(sessionStorage.getItem("section1Break"))
      console.log(obj == null ? '' : obj.serviceBrakeNF);


      var lowPressureWarningNF = obj == null ? '' : obj.lowPressureWarningNF;
      var lowPressureWarning = obj == null ? '' : obj.lowPressureWarning;
      var lowPressureWarningDate = obj == null ? '' : obj.lowPressureWarningDate;

      $('input[name="lowPressureWarning_na"][value="' + lowPressureWarningNF + '"]').trigger("click");
      $('input[name="lowPressureWarning_repaired"][value="' + lowPressureWarning + '"]').trigger("click").prop('checked', true);


      var tractorPrptectionNF = obj == null ? '' : obj.tractorPrptectionNF;
      var tractorPrptection = obj == null ? '' : obj.tractorPrptection;
      var tractorPrptectionDate = obj == null ? '' : obj.tractorPrptectionDate;

      $('input[name="tractorPrptection_na"][value="' + tractorPrptectionNF + '"]').trigger("click");
      $('input[name="tractorPrptection_repaired"][value="' + tractorPrptection + '"]').trigger("click").prop('checked', true);

      var airCompressorNF = obj == null ? '' : obj.airCompressorNF;
      var airCompressor = obj == null ? '' : obj.airCompressor;
      var airCompressorDate = obj == null ? '' : obj.airCompressorDate;

      $('input[name="airCompressor_na"][value="' + airCompressorNF + '"]').trigger("click");
      $('input[name="airCompressor_repaired"][value="' + airCompressor + '"]').trigger("click").prop('checked', true);


      var electricBrakesNF = obj == null ? '' : obj.electricBrakesNF;
      var electricBrakes = obj == null ? '' : obj.electricBrakes;
      var electricBrakesDate = obj == null ? '' : obj.electricBrakesDate;

      $('input[name="electricBrakes_na"][value="' + electricBrakesNF + '"]').trigger("click");
      $('input[name="electricBrakes_repaired"][value="' + electricBrakes + '"]').trigger("click").prop('checked', true);


      var hydraulicNF = obj == null ? '' : obj.hydraulicNF;
      var hydraulic = obj == null ? '' : obj.hydraulic;
      var hydraulicDate = obj == null ? '' : obj.hydraulicDate;

      $('input[name="hydraulic_na"][value="' + hydraulicNF + '"]').trigger("click");
      $('input[name="hydraulic_repaired"][value="' + hydraulic + '"]').trigger("click").prop('checked', true);


      var vacccumeSystemNF = obj == null ? '' : obj.vacccumeSystemNF;
      var vacccumeSystem = obj == null ? '' : obj.vacccumeSystem;
      var vacccumeSystemDate = obj == null ? '' : obj.vacccumeSystemDate;

      $('input[name="vacccumeSystem_na"][value="' + vacccumeSystemNF + '"]').trigger("click");
      $('input[name="vacccumeSystem_repaired"][value="' + vacccumeSystem + '"]').trigger("click").prop('checked', true);

   
      var antilockBrakeNF = obj == null ? '' : obj.antilockBrakeNF;
      var antilockBrake = obj == null ? '' : obj.antilockBrake;
      var antilockBrakeDate = obj == null ? '' : obj.antilockBrakeDate;

      $('input[name="antilockBrake_na"][value="' + antilockBrakeNF + '"]').trigger("click");
      $('input[name="antilockBrake_repaired"][value="' + antilockBrake + '"]').trigger("click").prop('checked', true);
    

      var automaticBrakeNF = obj == null ? '' : obj.automaticBrakeNF;
      var automaticBrake = obj == null ? '' : obj.automaticBrake;
      var automaticBrakeDate = obj == null ? '' : obj.automaticBrakeDate;

      $('input[name="automaticBrake_na"][value="' + automaticBrakeNF + '"]').trigger("click");
      $('input[name="automaticBrake_repaired"][value="' + automaticBrake + '"]').trigger("click").prop('checked', true);
    

      // setSection1(obj);
      // console.log("section Data" + JSON.stringify(section1));

      var serviceBrakeNF = obj == null ? '' : obj.serviceBrakeNF;
      var serviceBrake = obj == null ? '' : obj.serviceBrake;
      var serviceBrakeDate = obj == null ? '' : obj.serviceBrakeDate==''?null:obj.serviceBrakeDate;


      $('input[name="serviceBrake_na"][value="' + serviceBrakeNF + '"]').trigger("click");
      $('input[name="serviceBrake_repaired"][value="' + serviceBrake + '"]').trigger("click").prop('checked', true);
     

      var parkingBrakeNF = obj == null ? '' : obj.parkingBrakeNF;
      var parkingBrake = obj == null ? '' : obj.parkingBrake;
      var parkingBrakeDate = obj == null ? '' : obj.parkingBrakeDate;


      $('input[name="parkingBrake_na"][value="' + parkingBrakeNF + '"]').trigger("click");
      $('input[name="parkingBrake_repaired"][value="' + parkingBrake + '"]').trigger("click").prop('checked', true);


      var drumsAndRotorsNF = obj == null ? '' : obj.drumsAndRotorsNF;
      var drumsAndRotors = obj == null ? '' : obj.drumsAndRotors;
      var drumsAndRotorsDate = obj == null ? '' : obj.drumsAndRotorsDate;

      $('input[name="drumsAndRotors_na"][value="' + drumsAndRotorsNF + '"]').trigger("click");
      $('input[name="drumsAndRotors_repaired"][value="' + drumsAndRotors + '"]').trigger("click").prop('checked', true);

    
      var hoseNF = obj == null ? '' : obj.hoseNF;
      var hose = obj == null ? '' : obj.hose;
      var hoseDate = obj == null ? '' : obj.hoseDate;

      $('input[name="hose_na"][value="' + hoseNF + '"]').trigger("click");
      $('input[name="hose_repaired"][value="' + hose + '"]').trigger("click").prop('checked', true);


      
      var tubingNF = obj == null ? '' : obj.tubingNF;
      var tubing = obj == null ? '' : obj.tubing;
      var tubingDate = obj == null ? '' : obj.tubingDate;

      $('input[name="tubing_na"][value="' + tubingNF + '"]').trigger("click");
      $('input[name="tubing_repaired"][value="' + tubing + '"]').trigger("click").prop('checked', true);

      if (serviceBrakeNF||parkingBrakeNF||drumsAndRotorsNF||hoseNF||tubingNF||lowPressureWarningNF||tractorPrptectionNF||airCompressorNF||electricBrakesNF||hydraulicNF||vacccumeSystemNF||antilockBrakeNF||automaticBrakeNF == 'verified') {
         checkVerified();
         $("#BrakeDate").val(serviceBrakeDate);
         $("#ParkingBrakeDate").val(parkingBrakeDate);
         $("#DrumsAndRotorsDate").val(drumsAndRotorsDate);
         $("#HoseDate").val(hoseDate);
         $("#TubingDate").val(tubingDate);
         $("#LowPressureWarningDate").val(lowPressureWarningDate);
         $("#TractorPrptectionDate").val(tractorPrptectionDate);
         $("#AirCompressorDate").val(airCompressorDate);
         $("#ElectricBrakesDate").val(electricBrakesDate);
         $("#HydraulicDate").val(hydraulicDate);
         $("#VacccumeSystemDate").val(vacccumeSystemDate);
         $("#AntilockBrakeDate").val(antilockBrakeDate);
         $("#AntilockBrakeDate").val(antilockBrakeDate);
         $("#AutomaticBrakeDate").val(automaticBrakeDate);
         // setServiceBrakeDate(new Date(serviceBrakeDate));
         // setParkingBrakeDate(new Date(parkingBrakeDate));
         // setDrumsAndRotorsDate(new Date(drumsAndRotorsDate));
         // setHoseDate(new Date(hoseDate));
         // setTubingDate(new Date(tubingDate));
         // setLowPressureWarningDate(new Date(lowPressureWarningDate));
         // setTractorPrptectionDate(new Date(tractorPrptectionDate));
         // setaAirCompressorDate(new Date(airCompressorDate));
         // setElectricBrakesDate(new Date(electricBrakesDate));
         // setHydraulicDate(new Date(hydraulicDate));
         // setVacccumeSystemDate(new Date(vacccumeSystemDate));
         // setAntilockBrakeDate(new Date(antilockBrakeDate));
         // setAntilockBrakeDate(new Date(antilockBrakeDate));
         // setAutomaticBrakeDate(new Date(automaticBrakeDate));
      }
      

   }

   const checkVerified = () => {

      var serviceBrakeValue = $("input[name='serviceBrake_na']:checked").val();

      if (serviceBrakeValue == 'na') {
         $("input[name='serviceBrake_repaired']").attr('disabled', 'true');
         $("input[name='serviceBrakeDateTemp']").show();
         $("input[name='serviceBrakeDateTemp']").attr('disabled', 'true');
         $("input[name='serviceBrakeDate']").hide();
         // $("input[name='serviceBrake_repaired']").removeAttribute('checked');
         $("input[name='serviceBrake_repaired']").prop("checked", false);
         setServiceBrakeDate('');

      } if (serviceBrakeValue == 'verified') {

         $("input[name='serviceBrake_repaired']").removeAttr('disabled');
         $("input[name='serviceBrakeDateTemp']").removeAttr('disabled');

         if ($("input[name='serviceBrake_repaired']").is(':checked')) {
            $("input[name='serviceBrakeDate']").show();
            $("input[name='serviceBrakeDateTemp']").hide();
         } else {
            $("input[name='serviceBrakeDate']").hide();
            $("input[name='serviceBrakeDateTemp']").show();
         }

      }

      var parkingBrakeValue = $("input[name='parkingBrake_na']:checked").val();


      if (parkingBrakeValue == 'na') {
         $("input[name='parkingBrake_repaired']").attr('disabled', 'true');
         $("input[name='parkingBrakeDateTemp']").show();
         $("input[name='parkingBrakeDateTemp']").attr('disabled', 'true');
         $("input[name='parkingBrakeDate']").hide();
         $("input[name='parkingBrake_repaired']").prop("checked", false);
         setParkingBrakeDate('');

      } if (parkingBrakeValue == 'verified') {

         $("input[name='parkingBrake_repaired']").removeAttr('disabled');
         $("input[name='parkingBrakeDateTemp']").removeAttr('disabled');

         if ($("input[name='parkingBrake_repaired']").is(':checked')) {
            $("input[name='parkingBrakeDate']").show();
            $("input[name='parkingBrakeDateTemp']").hide();
         } else {
            $("input[name='parkingBrakeDate']").hide();
            $("input[name='parkingBrakeDateTemp']").show();
         }

      }

      var drumsAndRotorsValue = $("input[name='drumsAndRotors_na']:checked").val();


      if (drumsAndRotorsValue == 'na') {
         $("input[name='drumsAndRotors_repaired']").attr('disabled', 'true');
         $("input[name='drumsAndRotorsDateTemp']").show();
         $("input[name='drumsAndRotorsDateTemp']").attr('disabled', 'true');
         $("input[name='drumsAndRotorsDate']").hide();
         $("input[name='drumsAndRotors_repaired']").prop("checked", false);
         setDrumsAndRotorsDate('');

      } if (drumsAndRotorsValue == 'verified') {

         $("input[name='drumsAndRotors_repaired']").removeAttr('disabled');
         $("input[name='drumsAndRotorsDateTemp']").removeAttr('disabled');

         if ($("input[name='drumsAndRotors_repaired']").is(':checked')) {
            $("input[name='drumsAndRotorsDate']").show();
            $("input[name='drumsAndRotorsDateTemp']").hide();
         } else {
            $("input[name='drumsAndRotorsDate']").hide();
            $("input[name='drumsAndRotorsDateTemp']").show();
         }

      }

      var hoseValue = $("input[name='hose_na']:checked").val();

      if (hoseValue == 'na') {
         $("input[name='hose_repaired']").attr('disabled', 'true');
         $("input[name='hoseDateTemp']").show();
         $("input[name='hoseDateTemp']").attr('disabled', 'true');
         $("input[name='hoseDate']").hide();
         $("input[name='hose_repaired']").prop("checked", false);
         setHoseDate('');

      } if (hoseValue == 'verified') {

         $("input[name='hose_repaired']").removeAttr('disabled');
         $("input[name='hoseDateTemp']").removeAttr('disabled');

         if ($("input[name='hose_repaired']").is(':checked')) {
            $("input[name='hoseDate']").show();
            $("input[name='hoseDateTemp']").hide();
         } else {
            $("input[name='hoseDate']").hide();
            $("input[name='hoseDateTemp']").show();
         }

      }


      var tubingValue = $("input[name='tubing_na']:checked").val();

      if (tubingValue == 'na') {
         $("input[name='tubing_repaired']").attr('disabled', 'true');
         $("input[name='tubingDateTemp']").show();
         $("input[name='tubingDateTemp']").attr('disabled', 'true');
         $("input[name='tubingDate']").hide();
         $("input[name='tubing_repaired']").prop("checked", false);
         setTubingDate('');

      } if (tubingValue == 'verified') {

         $("input[name='tubing_repaired']").removeAttr('disabled');
         $("input[name='tubingDateTemp']").removeAttr('disabled');

         if ($("input[name='tubing_repaired']").is(':checked')) {
            $("input[name='tubingDate']").show();
            $("input[name='tubingDateTemp']").hide();
         } else {
            $("input[name='tubingDate']").hide();
            $("input[name='tubingDateTemp']").show();
         }

      }

      // lowPressureWarning
      var lowPressureWarningValue = $("input[name='lowPressureWarning_na']:checked").val();

      if (lowPressureWarningValue == 'na') {
         $("input[name='lowPressureWarning_repaired']").attr('disabled', 'true');
         $("input[name='lowPressureWarningDateTemp']").show();
         $("input[name='lowPressureWarningDateTemp']").attr('disabled', 'true');
         $("input[name='lowPressureWarningDate']").hide();
         $("input[name='lowPressureWarning_repaired']").prop("checked", false);
         setLowPressureWarningDate('');

      } if (lowPressureWarningValue == 'verified') {

         $("input[name='lowPressureWarning_repaired']").removeAttr('disabled');
         $("input[name='lowPressureWarningDateTemp']").removeAttr('disabled');

         if ($("input[name='lowPressureWarning_repaired']").is(':checked')) {
            $("input[name='lowPressureWarningDate']").show();
            $("input[name='lowPressureWarningDateTemp']").hide();
         } else {
            $("input[name='lowPressureWarningDate']").hide();
            $("input[name='lowPressureWarningDateTemp']").show();
         }

      }
      // tractorPrptection
      var tractorPrptectionValue = $("input[name='tractorPrptection_na']:checked").val();

      if (tractorPrptectionValue == 'na') {
         $("input[name='tractorPrptection_repaired']").attr('disabled', 'true');
         $("input[name='tractorPrptectionDateTemp']").show();
         $("input[name='tractorPrptectionDateTemp']").attr('disabled', 'true');
         $("input[name='tractorPrptectionDate']").hide();
         $("input[name='tractorPrptection_repaired']").prop("checked", false);
         setTractorPrptectionDate('');

      } if (tractorPrptectionValue == 'verified') {

         $("input[name='tractorPrptection_repaired']").removeAttr('disabled');
         $("input[name='tractorPrptectionDateTemp']").removeAttr('disabled');

         if ($("input[name='tractorPrptection_repaired']").is(':checked')) {
            $("input[name='tractorPrptectionDate']").show();
            $("input[name='tractorPrptectionDateTemp']").hide();
         } else {
            $("input[name='tractorPrptectionDate']").hide();
            $("input[name='tractorPrptectionDateTemp']").show();
         }

      }

      // airCompressor
      var airCompressorValue = $("input[name='airCompressor_na']:checked").val();

      if (airCompressorValue == 'na') {
         $("input[name='airCompressor_repaired']").attr('disabled', 'true');
         $("input[name='airCompressorDateTemp']").show();
         $("input[name='airCompressorDateTemp']").attr('disabled', 'true');
         $("input[name='airCompressorDate']").hide();
         $("input[name='airCompressor_repaired']").prop("checked", false);
         setaAirCompressorDate('');
      } if (airCompressorValue == 'verified') {

         $("input[name='airCompressor_repaired']").removeAttr('disabled');
         $("input[name='airCompressorDateTemp']").removeAttr('disabled');

         if ($("input[name='airCompressor_repaired']").is(':checked')) {
            $("input[name='airCompressorDate']").show();
            $("input[name='airCompressorDateTemp']").hide();
         } else {
            $("input[name='airCompressorDate']").hide();
            $("input[name='airCompressorDateTemp']").show();
         }

      }
      // electricBrakes
      var electricBrakesValue = $("input[name='electricBrakes_na']:checked").val();

      if (electricBrakesValue == 'na') {
         $("input[name='electricBrakes_repaired']").attr('disabled', 'true');
         $("input[name='electricBrakesDateTemp']").show();
         $("input[name='electricBrakesDateTemp']").attr('disabled', 'true');
         $("input[name='electricBrakesDate']").hide();
         $("input[name='electricBrakes_repaired']").prop("checked", false);
         setElectricBrakesDate('');

      } if (electricBrakesValue == 'verified') {

         $("input[name='electricBrakes_repaired']").removeAttr('disabled');
         $("input[name='electricBrakesDateTemp']").removeAttr('disabled');

         if ($("input[name='electricBrakes_repaired']").is(':checked')) {
            $("input[name='electricBrakesDate']").show();
            $("input[name='electricBrakesDateTemp']").hide();
         } else {
            $("input[name='electricBrakesDate']").hide();
            $("input[name='electricBrakesDateTemp']").show();
         }

      }

      // hydraulic
      var hydraulicValue = $("input[name='hydraulic_na']:checked").val();

      if (hydraulicValue == 'na') {
         $("input[name='hydraulic_repaired']").attr('disabled', 'true');
         $("input[name='hydraulicDateTemp']").show();
         $("input[name='hydraulicDateTemp']").attr('disabled', 'true');
         $("input[name='hydraulicDate']").hide();
         $("input[name='hydraulic_repaired']").prop("checked", false);
         setHydraulicDate('');

      } if (hydraulicValue == 'verified') {

         $("input[name='hydraulic_repaired']").removeAttr('disabled');
         $("input[name='hydraulicDateTemp']").removeAttr('disabled');

         if ($("input[name='hydraulic_repaired']").is(':checked')) {
            $("input[name='hydraulicDate']").show();
            $("input[name='hydraulicDateTemp']").hide();
         } else {
            $("input[name='hydraulicDate']").hide();
            $("input[name='hydraulicDateTemp']").show();
         }

      }
      // vacccumeSystem
      var vacccumeSystemValue = $("input[name='vacccumeSystem_na']:checked").val();

      if (vacccumeSystemValue == 'na') {
         $("input[name='vacccumeSystem_repaired']").attr('disabled', 'true');
         $("input[name='vacccumeSystemDateTemp']").show();
         $("input[name='vacccumeSystemDateTemp']").attr('disabled', 'true');
         $("input[name='vacccumeSystemDate']").hide();
         $("input[name='vacccumeSystem_repaired']").prop("checked", false);
         setVacccumeSystemDate('');

      } if (vacccumeSystemValue == 'verified') {

         $("input[name='vacccumeSystem_repaired']").removeAttr('disabled');
         $("input[name='vacccumeSystemDateTemp']").removeAttr('disabled');

         if ($("input[name='vacccumeSystem_repaired']").is(':checked')) {
            $("input[name='vacccumeSystemDate']").show();
            $("input[name='vacccumeSystemDateTemp']").hide();
         } else {
            $("input[name='vacccumeSystemDate']").hide();
            $("input[name='vacccumeSystemDateTemp']").show();
         }

      }

      // antilockBrake
      var antilockBrakeValue = $("input[name='antilockBrake_na']:checked").val();

      if (antilockBrakeValue == 'na') {
         $("input[name='antilockBrake_repaired']").attr('disabled', 'true');
         $("input[name='antilockBrakeDateTemp']").show();
         $("input[name='antilockBrakeDateTemp']").attr('disabled', 'true');
         $("input[name='antilockBrakeDate']").hide();
         $("input[name='antilockBrake_repaired']").prop("checked", false);
         setAntilockBrakeDate('');

      } if (antilockBrakeValue == 'verified') {

         $("input[name='antilockBrake_repaired']").removeAttr('disabled');
         $("input[name='antilockBrakeDateTemp']").removeAttr('disabled');

         if ($("input[name='antilockBrake_repaired']").is(':checked')) {
            $("input[name='antilockBrakeDate']").show();
            $("input[name='antilockBrakeDateTemp']").hide();
         } else {
            $("input[name='antilockBrakeDate']").hide();
            $("input[name='antilockBrakeDateTemp']").show();
         }

      }
      // automaticBrake
      var automaticBrakeValue = $("input[name='automaticBrake_na']:checked").val();

      if (automaticBrakeValue == 'na') {
         $("input[name='automaticBrake_repaired']").attr('disabled', 'true');
         $("input[name='automaticBrakeDateTemp']").show();
         $("input[name='automaticBrakeDateTemp']").attr('disabled', 'true');
         $("input[name='automaticBrakeDate']").hide();
         $("input[name='automaticBrake_repaired']").prop("checked", false);
         setAutomaticBrakeDate('');

      } if (automaticBrakeValue == 'verified') {

         $("input[name='automaticBrake_repaired']").removeAttr('disabled');
         $("input[name='automaticBrakeDateTemp']").removeAttr('disabled');

         if ($("input[name='automaticBrake_repaired']").is(':checked')) {
            $("input[name='automaticBrakeDate']").show();
            $("input[name='automaticBrakeDateTemp']").hide();
         } else {
            $("input[name='automaticBrakeDate']").hide();
            $("input[name='automaticBrakeDateTemp']").show();
         }

      }



     

   }

    

   const handleSubmit = () => {


      var section1Break = {
         "serviceBrakeNF": $("input[name='serviceBrake_na']:checked").val() === "na" ? "na" : "verified",
         "serviceBrake": $("input[name='serviceBrake_repaired']:checked").val() === "1" ? "1" : "0",
         "serviceBrakeDate": $("input[name='serviceBrakeDate']").val(),

         "parkingBrakeNF": $("input[name='parkingBrake_na']:checked").val() === "na" ? "na" : "verified",
         "parkingBrake": $("input[name='parkingBrake_repaired']:checked").val() === "1" ? "1" : "0",
         "parkingBrakeDate": $("input[name='parkingBrakeDate']").val(),

         "drumsAndRotorsNF": $("input[name='drumsAndRotors_na']:checked").val() === "na" ? "na" : "verified",
         "drumsAndRotors": $("input[name='drumsAndRotors_repaired']:checked").val() === "1" ? "1" : "0",
         "drumsAndRotorsDate": $("input[name='drumsAndRotorsDate']").val(),

         "hoseNF": $("input[name='hose_na']:checked").val() === "na" ? "na" : "verified",
         "hose": $("input[name='hose_repaired']:checked").val() === "1" ? "1" : "0",
         "hoseDate": $("input[name='hoseDate']").val(),

         "tubingNF": $("input[name='tubing_na']:checked").val() === "na" ? "na" : "verified",
         "tubing": $("input[name='tubing_repaired']:checked").val() === "1" ? "1" : "0",
         "tubingDate": $("input[name='tubingDate']").val(),

         "lowPressureWarningNF": $("input[name='lowPressureWarning_na']:checked").val() === "na" ? "na" : "verified",
         "lowPressureWarning": $("input[name='lowPressureWarning_repaired']:checked").val() === "1" ? "1" : "0",
         "lowPressureWarningDate": $("input[name='lowPressureWarningDate']").val(),

         "tractorPrptectionNF": $("input[name='tractorPrptection_na']:checked").val() === "na" ? "na" : "verified",
         "tractorPrptection": $("input[name='tractorPrptection_repaired']:checked").val() === "1" ? "1" : "0",
         "tractorPrptectionDate": $("input[name='tractorPrptectionDate']").val(),

         "airCompressorNF": $("input[name='airCompressor_na']:checked").val() === "na" ? "na" : "verified",
         "airCompressor": $("input[name='airCompressor_repaired']:checked").val() === "1" ? "1" : "0",
         "airCompressorDate": $("input[name='airCompressorDate']").val(),

         "electricBrakesNF": $("input[name='electricBrakes_na']:checked").val() === "na" ? "na" : "verified",
         "electricBrakes": $("input[name='electricBrakes_repaired']:checked").val() === "1" ? "1" : "0",
         "electricBrakesDate": $("input[name='electricBrakesDate']").val(),

         "hydraulicNF": $("input[name='hydraulic_na']:checked").val() === "na" ? "na" : "verified",
         "hydraulic": $("input[name='hydraulic_repaired']:checked").val() === "1" ? "1" : "0",
         "hydraulicDate": $("input[name='hydraulicDate']").val(),

         "vacccumeSystemNF": $("input[name='vacccumeSystem_na']:checked").val() === "na" ? "na" : "verified",
         "vacccumeSystem": $("input[name='vacccumeSystem_repaired']:checked").val() === "1" ? "1" : "0",
         "vacccumeSystemDate": $("input[name='vacccumeSystemDate']").val(),

         "antilockBrakeNF": $("input[name='antilockBrake_na']:checked").val() === "na" ? "na" : "verified",
         "antilockBrake": $("input[name='antilockBrake_repaired']:checked").val() === "1" ? "1" : "0",
         "antilockBrakeDate": $("input[name='antilockBrakeDate']").val(),

         "automaticBrakeNF": $("input[name='automaticBrake_na']:checked").val() === "na" ? "na" : "verified",
         "automaticBrake": $("input[name='automaticBrake_repaired']:checked").val() === "1" ? "1" : "0",
         "automaticBrakeDate": $("input[name='automaticBrakeDate']").val(),
      };
      console.log(JSON.stringify(section1Break));

      sessionStorage.setItem("section1Break", JSON.stringify(section1Break));

      let form_is_valid = true;

      var serviceBrake_na = $("input[name='serviceBrake_na']:checked").val();
      var parkingBrake_na = $("input[name='parkingBrake_na']:checked").val();
      var drumsAndRotors_na = $("input[name='drumsAndRotors_na']:checked").val();
      var hose_na = $("input[name='hose_na']:checked").val();
      var tubing_na = $("input[name='tubing_na']:checked").val();
      var lowPressureWarning_na = $("input[name='lowPressureWarning_na']:checked").val();
      var tractorPrptection_na = $("input[name='tractorPrptection_na']:checked").val();
      var airCompressor_na = $("input[name='airCompressor_na']:checked").val();
      var electricBrakes_na = $("input[name='electricBrakes_na']:checked").val();
      var hydraulic_na = $("input[name='hydraulic_na']:checked").val();
      var vacccumeSystem_na = $("input[name='vacccumeSystem_na']:checked").val();
      var antilockBrake_na = $("input[name='antilockBrake_na']:checked").val();
      var automaticBrake_na = $("input[name='automaticBrake_na']:checked").val();

      if (serviceBrake_na == undefined || parkingBrake_na == undefined || drumsAndRotors_na == undefined || hose_na == undefined || tubing_na == undefined || lowPressureWarning_na == undefined || tractorPrptection_na == undefined || airCompressor_na == undefined || electricBrakes_na == undefined || hydraulic_na == undefined || vacccumeSystem_na == undefined || antilockBrake_na == undefined || automaticBrake_na == undefined) {

         // $('html, body').animate({
         //    scrollTop: $("#inspectionType").offset().top
         //  }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
         form_is_valid = false;

      } else {
         $('#error_message').html('');
      }

      if (form_is_valid == true) {
         $("#annualVehicle2").trigger('click');
      }

      return form_is_valid;


   }


   return (
      <>



         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div> */}

                  {/* <div class="divider"></div> */}
                  <div class="row px-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">ANNUAL VEHICLE COMPONENTS INSPECTED</h4>
                        <p id="error_message" class="error"></p>
                     </div>
                  
                   
                  
                     <div class="w-100">
                     <WorkOrderNumberDiv />
                        <div class="inspection-wrap">
                           <div class="step-wrap p-3 mb-2">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 current-tick">1</div>
                                    {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""/></div> --> */}
                                    <div class="labl-section">Brake</div>
                                 </div>
                                 <div class="step-itm mr-3" href="/annualVehicle2">
                                    <div class="no-cl mr-2">2</div>
                                    <div class="labl-section">Coupling  </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">3</div>
                                    <div class="labl-section">Exhaust-fuel-lighting-safe</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">4</div>
                                    <div class="labl-section">Steering-suspension </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">5</div>
                                    <div class="labl-section">Wheels-glazing </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mb-4">
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>Brake system</h5>
                              <div className="border">
                                 <table className="w-100">
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th className="text-center">NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Service Brakes</td>
                                          <td ><input type="radio" name="serviceBrake_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="serviceBrake_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="serviceBrake_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={serviceBrakeDate} onChange={(date) => setServiceBrakeDate(date.getTime())} name="serviceBrakeDate" class="form-control" id="BrakeDate" placeholderText="MM-DD-YYYY" /><input type="text" id="BrakeDate44" class="form-control" name="serviceBrakeDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Parking Brake System</td>
                                          <td ><input type="radio" name="parkingBrake_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="parkingBrake_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="parkingBrake_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={parkingBrakeDate} onChange={(date) => setParkingBrakeDate(date)} name="parkingBrakeDate" class="form-control" id="ParkingBrakeDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="parkingBrakeDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Brake Drums or Rotors</td>
                                          <td ><input type="radio" name="drumsAndRotors_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="drumsAndRotors_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="drumsAndRotors_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={drumsAndRotorsDate} onChange={(date) => setDrumsAndRotorsDate(date)} name="drumsAndRotorsDate" class="form-control" id="DrumsAndRotorsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="drumsAndRotorsDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>d. Brake Hose</td>
                                          <td ><input type="radio" name="hose_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="hose_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="hose_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={hoseDate} onChange={(date) => setHoseDate(date)} name="hoseDate" class="form-control" id="HoseDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="hoseDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>e. Brake Tubing</td>
                                          <td ><input type="radio" name="tubing_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="tubing_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="tubing_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={tubingDate} onChange={(date) => setTubingDate(date)} name="tubingDate" class="form-control" id="TubingDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="tubingDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>f. Low Pressure Warning Device</td>
                                          <td ><input type="radio" name="lowPressureWarning_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="lowPressureWarning_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="lowPressureWarning_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={lowPressureWarningDate} onChange={(date) => setLowPressureWarningDate(date)} name="lowPressureWarningDate" class="form-control" id="LowPressureWarningDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="lowPressureWarningDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>g. Tractor Protection Valve</td>
                                          <td ><input type="radio" name="tractorPrptection_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="tractorPrptection_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="tractorPrptection_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={tractorPrptectionDate} onChange={(date) => setTractorPrptectionDate(date)} name="tractorPrptectionDate" class="form-control" id="TractorPrptectionDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="tractorPrptectionDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>h. Air Compressor</td>
                                          <td ><input type="radio" name="airCompressor_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="airCompressor_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="airCompressor_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={airCompressorDate} onChange={(date) => setaAirCompressorDate(date)} name="airCompressorDate" class="form-control" id="AirCompressorDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="airCompressorDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>i. Electric Brakes</td>
                                          <td ><input type="radio" name="electricBrakes_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="electricBrakes_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="electricBrakes_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={electricBrakesDate} onChange={(date) => setElectricBrakesDate(date)} name="electricBrakesDate" class="form-control" id="ElectricBrakesDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="electricBrakesDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>j. Hydraulic Brakes</td>
                                          <td ><input type="radio" name="hydraulic_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="hydraulic_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="hydraulic_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={hydraulicDate} onChange={(date) => setHydraulicDate(date)} name="hydraulicDate" class="form-control" id="HydraulicDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="hydraulicDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>k. Vacuum System</td>
                                          <td ><input type="radio" name="vacccumeSystem_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="vacccumeSystem_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="vacccumeSystem_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={vacccumeSystemDate} onChange={(date) => setVacccumeSystemDate(date)} name="vacccumeSystemDate" class="form-control" id="VacccumeSystemDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="vacccumeSystemDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>l. Antilock Brake System</td>
                                          <td ><input type="radio" name="antilockBrake_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="antilockBrake_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="antilockBrake_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={antilockBrakeDate} onChange={(date) => setAntilockBrakeDate(date)} name="antilockBrakeDate" class="form-control" id="AntilockBrakeDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="antilockBrakeDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>m. Automatic Brake Adjusters</td>
                                          <td ><input type="radio" name="automaticBrake_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="automaticBrake_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="automaticBrake_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={automaticBrakeDate} onChange={(date) => setAutomaticBrakeDate(date)} name="automaticBrakeDate" class="form-control" id="AutomaticBrakeDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="automaticBrakeDateTemp" /></td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                          
                           </div>

                        </div>
                     </div>
                  </div>


                  <div class="divider2"></div>
                  <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                     <a id="annualVehicle2" class="btn btn-primary mr-1" style={{ display: "none" }}>Next</a>
                     <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button>
                  </div>
               </div>
            </div>
         </div>


      </>
   )
};

AnnualVehicle1.propTypes = {};

AnnualVehicle1.defaultProps = {};

export default AnnualVehicle1;
