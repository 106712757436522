import React, { history, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import { Controller, useForm } from "react-hook-form";
import services from "../../../services/services";
import "cropperjs/dist/cropper.css";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import { requestObject } from "../../../utility/requestObject";
import AdddocsDispatcher from "../../../utility/AdddocsDispatcher";
import DatePicker from "react-datepicker";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import AddDynamicInspectionList from "../../../utility/AddDynamicInspectionList";
import AddDynamicDocument from "../../../utility/AddDynamicDocument";
import AddGarage from "./addGarage";
import { exit } from "process";
import Chosen from "../../../utility/chosen";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import AddDocumentVehicleInspected from "../../../utility/AddDocumentVehicleInspected";
import AutoSuggest from "react-autosuggest";
import moment from "moment";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const FleetAssignToGarrage = () => {

    // $("#loadingforsubmit").hide();
    const inputRef = useRef();

    const { cid, id } = useParams();
    const navigate = useNavigate();
    const [singleSelections, setSingleSelections] = useState('');

    const [inputList, setInputList] = useState([{}]);
    const [garageId, setgarageId] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [fileName, setfileName] = useState("");
    const [assignDate, setAssignDate] = useState();
    const [createdDate, setCreatedDate] = useState();
    const [expiredDate, setExpiredDate] = useState();
    const [listVendors, setListVendors] = useState([]);
    const [inspectionList, setInspectionList] = useState([]);
    const [documentCategoryList, setDocumentCategoryList] = useState([]);
    const [inspectionNames, setInspectionNames] = useState([]);
    const [inspectionTypes, setInspectionTYpes] = useState([]);
    const [value, setValue] = useState("");
    var resolveAll = sessionStorage.getItem("resolveAll");
    var resolveSignleInspection = sessionStorage.getItem("resolveSignleInspection");

    var reminderDocumentId = sessionStorage.getItem("reminderDocumentId");
    const [singleInspectionName, setInspectionName] = useState([]);

    const [suggestions, setSuggestions] = useState([]);
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });




    const handleOnSelect = (item) => {
        // the item selected

        setgarageId(item.id);
    }
    const handleOnFocus = (item) => {
        // the item selected
        setgarageId(item.id);
    }
    const handleOnHover = (item) => {
        // the item selected
        setgarageId(item.id);
    }

    const addNewGarage = () => {

        $("#firstName").val("")
        $("#email").val("")
        $("#deskNumber").val("")
        $("#ext").val("")
        $("#mobileNumber").val("")
        $("#address1").val("")
        $("#address2").val("")
        $("#city").val("")
        $("#state").val("")
        $("#zipcode").val("")
    }
    const handleOnSearch = (item) => {
        // the item selected
        setgarageId(item.id);
    }

    
    useEffect(() => {
        // alert(resolveSignleInspection);
    //     var won1='';
    // if(sessionStorage.getItem("reminderWorkOrderNumber")==='null'){
    //      won1='';
    // }else{
    //     won1=sessionStorage.getItem("reminderWorkOrderNumber");
    // }

    //$('#workOrderNumber').val("");
       
        if (resolveAll) {
            getDocumentValidationCheck();
            // sessionStorage.removeItem("resolveAll");

            // $("#resolve").hide();
            $("#resolveAll").show();
        } else {

            var inspectionNam = [];
            inspectionNam.push({ "documentSubCategoryName": sessionStorage.getItem("selectedinspectionName") })
            setInspectionNames(inspectionNam);

            // $("#resolve").show();

            $("#resolveAll").show();
            inspectionListApi(inspectionNam);
        }
        // if(reminderDocumentId){
        // if (resolveSignleInspection) {
        //     document_details_init(reminderDocumentId);
        //     // sessionStorage.removeItem("resolveAll");
        //     $("#resolveSingleInspection").show();
        //     $("#resolve").hide();
        // } 
        // }
        // else {
        //     $("#resolveSingleInspection").hide();
        // }
        sessionStorage.setItem("subcategory", "inspection");

        $('input[name="AssignGarage"][value="1"]').trigger("click");
        // alert($('input[name="AssignGarage"]:checked').val());
        if ($('input[name="AssignGarage"]:checked').val() == '1') {
            $('#assignGarageBlock').show();
            $('#documentBlock').hide();
        } else {
            $('#assignGarageBlock').hide();
            $('#documentBlock').show();
        }

        listVendorsApi();
        // inspectionListApi();
        documentCategoryListApi();

    }, []);

    const document_details_init = (documentId) => {
        var document_request =
        {

            "id": documentId

        };


        var requestOptions = requestObject.RequestHeader(document_request);

        services.getDocumentDetails(requestOptions).then((res) => {


            //    setDocumentDetails(res.rows);
            setInspectionName(res.rows.documentSubCategoryName);


        });

    }

    var inspectionNam = [];


    const getDocumentValidationCheck = () => {

        var vehicle_request =
        {

            "fkVehicleId": id
        };

        var requestOptions = requestObject.RequestHeader(vehicle_request);
        services.getExpiringDocuments(requestOptions).then((res) => {

            for (var i = 0; i < res.rows.length; i++) {

                inspectionNam.push({ "documentSubCategoryName": res.rows[i].documentSubCategoryName })
                setInspectionNames(inspectionNam);

            }
            inspectionListApi(inspectionNam);

        });
    }


    $(document).ready(function () {
        $('.chosen-select').chosen();
    });

    const documentCategoryListApi = () => {
        var getVehicleDocumentSubCategoriesReq =
        {

        };

        var requestOptions = requestObject.RequestHeader(getVehicleDocumentSubCategoriesReq);
        services.getVehicleDocumentSubCategories(requestOptions).then((res) => {

            setDocumentCategoryList(res.rows);
        });
    }

    const listVendorsApi = () => {
        var listVendorsReq =
        {
            "pageNumber": '1',
            "pageSize": '1000',
            "isActive": true
        };

        var requestOptions = requestObject.RequestHeader(listVendorsReq);
        services.listVendors(requestOptions).then((res) => {

            setListVendors(res.rows);
        });
    }
    const lowerCasedCompanies = listVendors.map(company => {
        return {
            id: company.id,
            name: company.name.toLowerCase()
        };
    });
    function getSuggestions(value) {
        return lowerCasedCompanies.filter(company =>
            company.name.includes(value.trim().toLowerCase())
        );
    }
    const inspectionListApi = (inspectionNam) => {

        var inspectionListReq =
        {

        };

        var requestOptions = requestObject.RequestHeader(inspectionListReq);
        // services.getInspectionDocumentSubCategories(requestOptions).then((res) => {
        services.getInspectionTypeForAssignToGarage(requestOptions).then((res) => {

            setInspectionList(res.rows);



            var dummayarray = res.rows;
            var tempArray = [];
            for (var i = 0; i < dummayarray.length; i++) {
                console.log("names==" + dummayarray[i].name)
                // const rr=dummayarray[i].name;
                // alert("sdsd   "+rr.replace(/[\p{P}$+<=>^`|~]/gu, ''))
                tempArray.push({ "id": dummayarray[i].id, "documentSubCategoryName": dummayarray[i].name })
                // for(var j=0;j<inspectionNames.length;j++)
                // {
                //     console.log("names=="+inspectionNames[j].documentSubCategoryName)

                //     if (dummayarray[i].name === inspectionNames[j].documentSubCategoryName) {
                //         dummayarray.splice(i, 1);
                //       i = i-1;
                //     }
                // }

            }
            console.log("jss==" + JSON.stringify(tempArray));

            var confirmedList = tempArray.filter((a) => {
                // alert(a.documentSubCategoryName)
                return !inspectionNam.find((p) => p.documentSubCategoryName === a.documentSubCategoryName);
                //    console.log("fianl=="+tempArray.find((p) => p.documentSubCategoryName.replace(/[\p{P}$+<=>^`|~]/gu, '') === a.documentSubCategoryName));
            });
            console.log("fianl==" + JSON.stringify(confirmedList));
            setInspectionTYpes(confirmedList);

        });
    }

    const CheckBlock = () => {
        const checkValue = $('input[name="AssignGarage"]:checked').val();
        if (checkValue == '1') {
            $('#assignGarageBlock').show();
            sessionStorage.setItem("subcategory", "inspection");
            $('#documentBlock').hide();
        } else {
            $('#assignGarageBlock').hide();
            sessionStorage.setItem("subcategory", "documentSubcategory");
            $('#documentBlock').show();
        }
    }

    const validateDate = () => {
        let form_is_valid = true;

        var WON = $("#workOrderNumber").val();
        if (WON == '') {
            $('#error-message-workOrderNumber').css("color", "#d33232").html("Work order number is required");
            $("#workOrderNumber").focus();
            form_is_valid = false;
        } else {
            $('#error-message-workOrderNumber').html('');
        }

        var garage = $(".react-autosuggest__input").val();
        if (garage == '') {
            $('#error-message-garage').css("color", "#d33232").html("Vendor is required");
            // $("#react-autosuggest__input").focus();
            form_is_valid = false;
        } else {
            $('#error-message-garage').html('');
        }

        var assignDate = $("#assignDate").val();
        if (assignDate == '') {
            $('#error-message').css("color", "#d33232").html("Assign date is required");
            // $("#assignDate").focus();
            form_is_valid = false;
        } else {
            $('#error-message').html('');
        }

        if (resolveSignleInspection) { } else {


            if (resolveAll) {

            } else {

                var inspection = $("#inspectionName0 option:selected").val();

                if (inspection == '') {
                    $('#error-messageIn').css("color", "#d33232").html("*Inspection is required");
                    form_is_valid = false;
                } else {
                    $('#error-messageIn').html('');
                }
            }

        }
        return form_is_valid;
    }

    const validateDocument = () => {
        let form_is_valid = true;

        var title = $("#title0").val();
        if (title == '') {
            $('#title_errorMessage').css("color", "#d33232").html("Title is required");
            form_is_valid = false;
        } else {
            $('#title_errorMessage').html('');
        }

        var type = $('#doucmenttype option:selected').val();
        // alert(type)
        if (type == '') {
            $('#type_errorMessage').css("color", "#d33232").html("Type is required");
            form_is_valid = false;
        } else {
            $('#type_errorMessage').html('');
        }

        var createdDate = $("#createdDate").val();
        if (createdDate == '') {
            $('#createdDate_errorMessage').css("color", "#d33232").html("Created date is required");
            form_is_valid = false;
        } else {
            $('#createdDate_errorMessage').html('');
        }

        var expiredDate = $("#expiredDate").val();
        if (expiredDate == '') {
            $('#expiredDate_errorMessage').css("color", "#d33232").html("Expired date is required");
            form_is_valid = false;
        } else {
            $('#expiredDate_errorMessage').html('');
        }

        return form_is_valid;
    }

    const submitValue = () => {

        if (validateDate()) {

            var inspectionArray = [];
            var inspectionData = [];
            var inspect = {};
            var inspectionJson = [];
            $.each($("select[name='inspectionName[]'] option:selected"), function () {
                if ($(this).val() == '') {

                } else {

                    inspectionArray.push($(this).val());
                    inspectionData.push($(this).text().toString())
                    inspect.id = $(this).val();
                    inspect.name = $(this).text().toString();
                    inspectionJson.push({ ...inspect });
                }

            });



            $('input:checkbox[name=inspectionIds]:checked').each(function () {
                // alert($(this).next('span').text());
                inspectionArray.push($(this).val());
                inspectionData.push($(this).next('span').text());
                // inspectionData.push($(this).text().toString())
                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
                // inspect.name = $(this).next("label").text().split(' ').join(' ').toString();
                inspectionJson.push({ ...inspect });

            });


            $("#loadingforsubmit").show();

            var vechileReq =
            {
                "accountId": 1,
                "vehicleId": id,
                // "vendorId":singleSelections.toString(),
                // "vendorId":6,
                "vendorId": garageId,
                // "inspectionIdList":[1],
                "inspectionIdList": inspectionArray,
                // "inspectionData": JSON.stringify(inspectionJson),
                "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
                "assignDate": $("#assignDate").val() != '' ? moment($("#assignDate").val()).format('YYYY-MM-DD') : '',
                "notes": $("#notes").val() != '' ? $("#notes").val() : ''



                //    "imageName":cropper.getCroppedCanvas()?parseInt(Math.random() * 1000000000):"",
                //    "imageExtension":cropper.getCroppedCanvas()?"png":"",
                //    "imageByteString":cropper.getCroppedCanvas()?cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", ""):"",
                //    "documentList": documentList
            }
            // console.log("result"+JSON.stringify(vechileReq))
            var requestOptions = requestObject.RequestHeader(vechileReq);
            services.assignToGarage(requestOptions).then((res) => {
                $("#loadingforsubmit").hide();
                if (res.message == "Successfully assigned Vehicle") {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            navigate("/fleetowner/reminder");
                            sessionStorage.removeItem('resolveSignleInspection');
                            sessionStorage.removeItem('removeAll');
                        }
                    });
                } else {

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                        }
                    });

                }

            });

        }

    }


    const addDocument = (status) => {
        if (validateDocument()) {

            var documentNameArray = [];
            $.each($("input[name='documentName[]']"), function () {
                documentNameArray.push($(this).val());
            });

            var documentDateArray = [];
            $.each($("input[name='documentDate[]']"), function () {
                documentDateArray.push($(this).val());
            });

            var expiryDateArray = [];

            $.each($("input[name='expiredDate[]']"), function () {
                expiryDateArray.push($(this).val());
            });

            var doucmenttypeArray = [];
            $.each($("select[name='doucmenttype[]'] option:selected"), function () {
                doucmenttypeArray.push($(this).val());
            });

            var documentList = [];
            try {
                for (var i = 0; i < inputList.length; i++) {
                    // console.log("filebase64=="+inputList[i].filetobase64);
                    var extension = "";
                    var byteString = "";
                    if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                        extension = "pdf";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                    } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                        extension = "jpg";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                        extension = "png";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                    }
                    else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                        extension = "word";
                        byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                    }
                    else {

                    }


                    // documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "extension": extension });
                    documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": documentNameArray[i], "fkDocumentCategoryId": "1", "documentSubCategoryId": doucmenttypeArray[i], "documentDate": moment(documentDateArray[i]).format('YYYY-MM-DD'), "expiryDate": moment(expiryDateArray[i]).format('YYYY-MM-DD'), "extension": extension });
                }
            } catch (exception) {
                documentList.push();

            }

            // var AdddocumentList = [];

            // try {
            //     for (var i = 0; i < $("input[name='documentName[]']").length; i++) {

            //         AdddocumentList.push({
            //             "title": documentNameArray[i], "fkDocumentCategoryId": doucmenttypeArray[i], "documentDate": documentDateArray[i], "expiryDate": expiryDateArray[i], "documentList": documentList

            //         });
            //     }
            // } catch (exception) {
            //     AdddocumentList.push();
            // }


            // console.log("@@@@@Documenjhsdfjsd@@@@@" + JSON.stringify(AdddocumentList));


            // var addDocuments = AdddocumentList;
            var addDocuments =
            {

                "vehicleId": id,
                "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
                "documentList": documentList

                //    "title":$("#documentTitle").val(),

                //    "fkDocumentCategoryId":parseInt($("#doucmenttype").val()),

                //    "documentDate":createdDate,

                //    "expiryDate":expiredDate,

                //    "imageName":base64file?parseInt(Math.random() * 1000000000):"",

                //    "imageExtension":base64file?"pdf":"",
                //    "imageByteString":base64file?base64file.replace("data:application/pdf;base64,", ""):""
            }


            var requestOptions = requestObject.RequestHeader(addDocuments);
            // services.addDocuments(requestOptions).then((res) => {
            services.addInspectionDocument(requestOptions).then((res) => {

                // setLoading(false);
                if (res.success) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {

                            navigate("/fleetowner/reminder");
                            sessionStorage.removeItem('resolveSignleInspection');
                            sessionStorage.removeItem('removeAll');
                        }
                    });

                } else {

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {


                        }
                    });

                }



            });

        }
    }
    const redirect = (page) => {

        navigate(page);

    }
    return (
        <>
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="col-lg-9 col-md-12 m-auto ">
                        <div class="row">
                            <div class="col-md-12 m-auto mb-2 ">
                                <h4 class="font-weight-bold my-4 text-uppercase">Assign To Garage</h4>
                                <div class="card mb-2">
                                    <VehicleBasicInfo customerId={id} />
                                </div>

                                <div class="card mb-2 pt-2 pb-2">
                                    <div class="col-12 pb-2">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="AssignGarage" id="flexRadioDefault1" value="1" onClick={() => CheckBlock()} />
                                                    <label class="form-check-label" for="flexRadioDefault1">Send to Garage for Inspection</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="AssignGarage" id="flexRadioDefault2" value="2" onClick={() => CheckBlock()} />
                                                    <label class="form-check-label" for="flexRadioDefault2">Vehicle Already Inspected</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <section id="assignGarageBlock">
                                        <form onSubmit={handleSubmit(submitValue)}>
                                            {/* <div class="wo d-flex align-items-center p-3">
                                            <img src="/images/icons/workorder-icon.svg" alt=""/>
                                            <span class="mr-2 ml-2">Workd order Number : </span>
                                            <input type="input" placeholder="Enter W/O"/>
                                        </div> */}
                                            {/* <WorkOrderNumberDiv /> */}

                                            <div class="w-100 px-3">
                                                <div class="row mb-2">
                                                    <div class="col-lg-12 px-0">
                                                        <div class="wo d-flex align-items-center p-3">
                                                            <img src="/images/icons/workorder-icon.svg" alt="" />
                                                            <span class="mr-2 ml-2">Work order number <span class="star">*</span> </span>
                                                            <input type="text" placeholder="Enter W/O" id="workOrderNumber" /><br/>
                                                            <span class="error" id="error-message-workOrderNumber"></span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-12">
                                                {/* <h3 class="font-weight-bold my-4">Select Vendor</h3> */}
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label>Select Vendor <span class="star">*</span></label>
                                                            <AutoSuggest
                                                                suggestions={suggestions}
                                                                onSuggestionsClearRequested={() => setSuggestions([])}
                                                                onSuggestionsFetchRequested={({ value }) => {

                                                                    setValue(value);
                                                                    setSuggestions(getSuggestions(value));
                                                                }}
                                                                onSuggestionSelected={(_, { suggestion }) =>
                                                                    setgarageId(suggestion.id)

                                                                }
                                                                getSuggestionValue={suggestion => suggestion.name}
                                                                renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                                                                inputProps={{
                                                                    placeholder: "",
                                                                    value: value,
                                                                    onChange: (_, { newValue, method }) => {
                                                                        setValue(newValue);
                                                                    }
                                                                }}
                                                                highlightFirstSuggestion={true}

                                                            />
                                                            <span id="error-message-garage" class="error"></span>
                                                            {/* <ReactSearchAutocomplete
                                                                items={listVendors}
                                                                fuseOptions={{ keys: ["id", "name"] }} // Search on both fields
                                                                resultStringKeyName="name" // String to display in the results
                                                                onSearch={handleOnSearch}
                                                                onHover={handleOnHover}
                                                                onSelect={handleOnSelect}
                                                                onFocus={handleOnFocus}

                                                                showIcon={false}
                                                                styling={{
                                                                    height: "34px",
                                                                    // border: "1px solid darkgreen",
                                                                    borderRadius: "4px",
                                                                    // backgroundColor: "white",
                                                                    boxShadow: "none",
                                                                    // hoverBackgroundColor: "lightgreen",
                                                                    // color: "darkgreen",
                                                                    fontSize: "12px",
                                                                    fontFamily: "Montserrat",
                                                                    iconColor: "black",
                                                                    // lineColor: "lightgreen",
                                                                    // placeholderColor: "darkgreen",
                                                                    clearIconMargin: "3px 8px 0 0",
                                                                    zIndex: 2,
                                                                }}
                                                            /> */}

                                                            {/* {errors.vendorId && errors.vendorId.type === "required" && <span class="error">Garage  is required</span>} */}
                                                            <a href="#" style={{ color: "#022e5f", marginTop: "10px", textDecoration: "underline", float: "right", margin: ".25rem 0" }} data-toggle="modal" data-target="#addGarrage"
                                                                onClick={() =>
                                                                    addNewGarage()
                                                                }>Add Vendor</a>
                                                        </div>

                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Assign Date <span class="star">*</span></label>
                                                            <DatePicker dateFormat="MM-dd-yyyy" minDate={moment().toDate()} selected={assignDate} onChange={(date) => setAssignDate(date.getTime())} name="assignDate" class="form-control" id="assignDate" placeholderText="" />
                                                            <span id="error-message" class="error"></span>
                                                        </div>
                                                    </div>



                                                    <div class="col-6 mt-3">
                                                        <label for="exampleInputEmail1">Inspection List <span class="star">*</span></label>


                                                        {/* <div id="resolve">
                                                            <AddDynamicInspectionList inputList={inputList} setInputList={setInputList} />
                                                            <span id="error-messageIn"></span>
                                                        </div> */}

                                                        <div id="resolveAll">
                                                            {

                                                                inspectionList ? inspectionList.map((data, index) => (
                                                                    inspectionNames ? inspectionNames.map((data1, index) => {
                                                                        return (<>
                                                                            {

                                                                                data.name === data1.documentSubCategoryName ? data.name.includes(data1.documentSubCategoryName) ?

                                                                                    <div class="col-12" >
                                                                                        <div class="form-check my-0">
                                                                                            <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} defaultChecked={data.name.replace(/[\p{P}$+<=>^`|~]/gu, '') === data1.documentSubCategoryName.replace(/[\p{P}$+<=>^`|~]/gu, '') ? true : false} />
                                                                                            <span class="form-check-label checkedinspectionNames">{data.name}</span>
                                                                                        </div>
                                                                                        <br />
                                                                                    </div>
                                                                                    : "" : ""}

                                                                        </>)


                                                                    }) : "")) : ""
                                                            }


                                                            {

                                                                inspectionTypes ? inspectionTypes.sort((a, b) => a.documentSubCategoryName.localeCompare(b.documentSubCategoryName)).map((data, index) => (

                                                                    (<>
                                                                        {(() => {



                                                                            if (data.documentSubCategoryName === "WET Test Certification") {
                                                                                if (sessionStorage.getItem("vehicleTypeName") === "Trailer" && sessionStorage.getItem("tankerSubTypeName") === "Tanker") {

                                                                                    return (
                                                                                        <div class="col-12" >
                                                                                            <div class="form-check my-0">
                                                                                                <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} />
                                                                                                <span class="form-check-label checkedinspectionNames">{data.documentSubCategoryName}</span>
                                                                                            </div>
                                                                                            <br />
                                                                                        </div>

                                                                                    )
                                                                                } else {

                                                                                }

                                                                            }
                                                                            else if (data.documentSubCategoryName === "Tractor Preventive Maintenance/Inspection") {
                                                                                if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {

                                                                                    return (
                                                                                        <div class="col-12" >
                                                                                            <div class="form-check my-0">
                                                                                                <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} />
                                                                                                <span class="form-check-label checkedinspectionNames">{data.documentSubCategoryName}</span>
                                                                                            </div>
                                                                                            <br />
                                                                                        </div>

                                                                                    )
                                                                                } else {

                                                                                }

                                                                            }
                                                                            else if (data.documentSubCategoryName === "Driver Vehicle Inspection") {
                                                                                if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {

                                                                                    return (
                                                                                        <div class="col-12" >
                                                                                            <div class="form-check my-0">
                                                                                                <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} />
                                                                                                <span class="form-check-label checkedinspectionNames">{data.documentSubCategoryName}</span>
                                                                                            </div>
                                                                                            <br />
                                                                                        </div>

                                                                                    )
                                                                                } else {

                                                                                }

                                                                            } else {
                                                                                return (
                                                                                    <div class="col-12" >
                                                                                        <div class="form-check my-0">
                                                                                            <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} />
                                                                                            <span class="form-check-label checkedinspectionNames">{data.documentSubCategoryName}</span>
                                                                                        </div>
                                                                                        <br />
                                                                                    </div>

                                                                                )
                                                                            }

                                                                        })()}



                                                                    </>)


                                                                )) : ""
                                                            }



                                                            {/* {
inspectionList?inspectionList.map((data, index)=> (
            
            inspectionNames.find((itemB)=> {
            
                 if(itemB.documentSubCategoryName===data.name){
                    return (<><p>gggg</p></>)
                };
            })
)):""
        }                                                            */}
                                                            {/* <AddDynamicInspectionList inputList={inputList} setInputList={setInputList} /> */}

                                                        </div>

                                                        {/*                                                        
                                                            {resolveSignleInspection?
                                                        <div id="resolveSignleInspection">
                                                          {

                                                                inspectionList ? inspectionList.map((data, index) => (
                                                                    // singleInspectionName
                                                                    <>
                                                                        {data.name.includes(singleInspectionName) ?
                                                                            <div class="col-12" >
                                                                                <div class="form-check my-0">
                                                                                    <input class="form-check-input" type="checkbox" name="inspectionIds" value={data.id} defaultChecked={true} />
                                                                                    <span class="form-check-label checkedinspectionNames">{singleInspectionName}</span>
                                                                                </div>
                                                                                <br />
                                                                            </div>
                                                                            : ""}</>
                                                                   )) : ""
                                                            }
                                                             <AddDynamicInspectionList inputList={inputList} setInputList={setInputList} />

                                                        </div>:""} */}


                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-6  mt-3">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Notes</label>
                                                            <textarea class="form-control" maxLength="300" name="notes" id="notes"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="divider2"></div>
                                            <div class="card" id="submitBlock">
                                                <div class="p-3">
                                                    <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                                    <a onClick={() => redirect("/fleetowner/reminder")} class="btn gray-mid-color mw-120">CANCEL</a>
                                                </div>
                                            </div>
                                        </form>
                                    </section>

                                    <section id="documentBlock">
                                        {/* <div class="wo d-flex align-items-center p-3">
                                            <img src="/images/icons/workorder-icon.svg" alt=""/>
                                            <span class="mr-2 ml-2">Workd order Number : </span>
                                            <h4 class="m-0">102346</h4>
                                        </div> */}
                                        {/* <WorkOrderNumberDiv/> */}
                                        <div class="col-12 pt-3 pb-3">

                                            <AddDocumentVehicleInspected inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                            {/* <table>
                                                <tbody>
                                                    <tr>
                                                        <th style={{width: "30%"}} class="p-2">Document Title</th>
                                                        <th style={{width: "20%"}} class="p-2">Category</th>
                                                        <th style={{width: "20%"}} class="p-2">Created Date</th>
                                                        <th style={{width: "20%"}} class="p-2">Expiry Date</th>
                                                        <th style={{width: "20%"}} class="p-2">Options</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <input type="text" class="form-control custome-input" id="documentTitle" placeholder="Enter Doc Name" aria-describedby="emailHelp"/>
                                                            </div>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                                <select class="form-control custome-select" id="doucmenttype">
                                                                <option value="" selected>Select Inspection</option>
                                                        {
                                                              documentCategoryList?documentCategoryList.map((data,index) => (
                                                                <option value={data.id}>{data.name}</option>

                                                               )):""
                                                         }
                                                            </select>
                                                            </div>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <DatePicker dateFormat="yyyy-MM-dd" selected={createdDate} onChange={(date) => setCreatedDate(date.getTime())} name="createdDate" class="form-control" id="createdDate" placeholderText="YYYY-MM-DD" />

                                                            </div>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <DatePicker dateFormat="yyyy-MM-dd" selected={expiredDate} onChange={(date) => setExpiredDate(date.getTime())} name="expiredDate" class="form-control" id="expiredDate" placeholderText="YYYY-MM-DD" />

                                                            </div>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0 d-flex">
                                                                <img src="/images/icons/pdf-icon.svg" class="pr-2"/>
                                                                <a href="#" class="mr-2"><img src="/images/icons/remove-minus.svg" alt="upload"/></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table> */}
                                            {/* <button type="button" class="btn btn-success ml-2">Add</button> */}
                                        </div>
                                        <div class="divider2"></div>
                                        <div class="card" id="submitBlock">
                                            <div class="p-3">
                                                <button type="button" class="btn btn-primary mw-120 mr-2" onClick={() => addDocument()}>SUBMIT</button>
                                                <a onClick={() => redirect("/fleetowner/reminder")} class="btn gray-mid-color mw-120">CANCEL</a>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                {/* <div class="card" id="submitBlock">
                                    <div class="p-3">
                                        <a href="#" class="btn btn-primary mw-120 mr-2" >SUBMIT</a>
                                        <a href="#" class="btn gray-mid-color mw-120">CANCEL</a>

                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>






                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit Contact</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email ID</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Desk Phone</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Mobile Phone</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Role in Company</label>
                                            <select class="form-control custome-select" id="exampleFormControlSelect1">
                                                <option>One</option>
                                                <option>Two</option>
                                                <option>Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-control custome-select" id="exampleFormControlSelect1">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer px-0">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="addcontact" tabindex="-1" aria-labelledby="addcontact" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Contact</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email ID</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Desk Phone</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Mobile Phone</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Role in Company</label>
                                            <select class="form-control custome-select" id="exampleFormControlSelect1">
                                                <option>One</option>
                                                <option>Two</option>
                                                <option>Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="editcustomer" tabindex="-1" aria-labelledby="editcustomer" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="editcustomer">EDit Company</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Company Name</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><span class="lableUpper">DOT</span> Number</label>
                                            <input type="email" class="form-control custome-input" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" maxLength="20" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddGarage />
            {/* <div class="modal fade" id="addGarrage" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Add New Garage</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                         </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label>Garage Name</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <div class="row g-2">
                                                <div class="col-8">
                                                    <label>Desk Number</label>
                                                    <input type="text" class="form-control custome-input"/>
                                                </div>
                                                <div class="col-4">
                                                    <label>Ext</label>
                                                    <input type="text" class="form-control custome-input"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Mobile Number</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Email Address</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Fax Number</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Address line 1</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Address line 2 (optional)</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>City</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>State</label>
                                            <select class="form-control custome-select" id="exampleFormControlSelect1">
                                        <option>Select</option>
                                     </select>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Zip</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div> */}
        </>
    )
};

FleetAssignToGarrage.propTypes = {};

FleetAssignToGarrage.defaultProps = {};

export default FleetAssignToGarrage;