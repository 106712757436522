import $ from "jquery";
export const faxvalidation=(e)=>
{
   
    if(e.target.value.length<12)
    {
       $("#faxerror").show();
    }
    else{
       $("#faxerror").hide();
    }
}

export const deskvalidation=(e)=>
{
   
    if(e.target.value.length<12)
    {
       $("#deskerror").show();
    }
    else{
       $("#deskerror").hide();
    }
}