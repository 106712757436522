import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";

// const CompletedSearch=(customerId,pageNumber,ITEMS_PER_PAGE,title,vehicleTypeId,pageSize,setTotalItemsCompleted,settotalPagesCompleted,setCurrentPageCompleted,setCompleted,setdisplayPageCompleted,sortBy,sortDir,displayItems)=>
const CompletedSearch=(customerId,pageNumber,ITEMS_PER_PAGE,title,pageSize,setTotalItemsCompleted,settotalPagesCompleted,setCurrentPageCompleted,setCompleted,setdisplayPageCompleted,sortBy,sortDir,displayItems)=>
{
   /////////////get vechile list//////////////////////////

     var vechileReq="";

     if(sortBy==="vehicleNumber")
     {
      sortBy="vehicleNumber";
     }
     else if(sortBy==="purpose")
     {
      sortBy="docSubCategory";
     }
     if(customerId=="")
     {
             vechileReq=
            {
                 
                  // "vehicleTypeId":vehicleTypeId,
                  // "isInspected": true,
                //  "isInspectionActive": false,
                  // "lisencePlateNumber":title,
                  "vinNumber":title,
                  "pageNumber" : pageNumber,
                  "pageSize": pageSize,
                  // "sortBy": sortBy,
                  // "sortDir":sortDir,
                  // "isActive":true,
                      
            };
     }else
	 {
	     vechileReq=
		  {
            
          //   "vehicleTypeId":vehicleTypeId,
          //   "isInspected": true,
          //  "isInspectionActive": false,
          //   "lisencePlateNumber":title,
          "vinNumber":title,
            "pageNumber" : pageNumber,
            "pageSize": pageSize,
            // "sortBy": sortBy,
            // "sortDir":sortDir,
            // "isActive":true,
			 
		  }
	 }
     

    

      // const requestOptions = 
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vechileReq)
      // };
      var requestOptions=requestObject.RequestHeader(vechileReq);
      services.listCompletedVehicles(requestOptions).then((res) => 
      {
        
            if(res.success)
            {

              setTotalItemsCompleted(res.customValues.totalNumberOfRecords);
            
              settotalPagesCompleted(Math.ceil(res.customValues.totalNumberOfPages / ITEMS_PER_PAGE));
              setCurrentPageCompleted(res.customValues.currentPageNumber);
              if(res.rows.length>0)
              {
                 
                setCompleted(res.rows);
              }else{

                
                setCompleted("");
              }
             
              setTotalItemsCompleted(res.customValues.totalNumberOfRecords);
        
              var displaypages=[];
              if(displayItems=="displayItems")
              {
              
                      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
                      {
                       
                        displaypages.push(i*ITEMS_PER_PAGE);
                      }
                      
                      if(displaypages.length>0)
                      {
                        setdisplayPageCompleted(displaypages);
                      }else{
                        setdisplayPageCompleted([0]);
                      }
                     
              
              }
            }else{
            
              //  setCompleted("");
            //  setdisplayPageCompleted([]);
              //alert(res.message);
              setdisplayPageCompleted([0]);
            }
         
      });
   
   



}

export default CompletedSearch;