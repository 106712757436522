import React, { history, useEffect, useState, useMemo } from "react";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import ExternalVisualSectionTwo from "./external-visual-section-two";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import { scrollTop } from "../../../../utility/ScrollTop";
const EditExternalVisualSectionOne = () => {
    const [section1, setSection1] = useState('');
    const [a, setA] = useState([]);

    const { fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [designpressure, setDesignPressure] = useState();
    const [certify, setApproveCertify] = useState();
    var documentLists = [];

    useEffect(() => {

        scrollTop();
        getSection1();
        getInpection(fkAccountId, inspectionId);
       
    }, []);

    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForWet =
        {
            
            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForWet);

        services.getInspectionDetails(requestOptions).then((res) => {

         const workOrderNumber=sessionStorage.getItem("workOrderNumber");
         if(workOrderNumber==null)
         {
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"")
         }else{
         sessionStorage.setItem("workOrderNumber",workOrderNumber);
         }

            setInspectionDetails(res.rows);
            console.log("Annual vehicle inspection" + JSON.stringify(res.rows));

            var obj = res.rows.externalVisualInspection.section1;
            var step1area = obj == null ? '' : obj.corrodedOrAbradedAreas;
            var step1dents = obj == null ? '' : obj.dents;
            var step1distortion = obj == null ? '' : obj.distortions;
            var step1defects = obj == null ? '' : obj.defects;
            var step1anykind = obj == null ? '' : obj.leaksOfAnyKind;
            var step1unsafe = obj == null ? '' : obj.anyConditionThatmightRender;
            var step1Corrosive = obj == null ? '' : obj.beingtransported;

            $('input[name="step1area"][value="' + step1area + '"]').trigger("click");
            $('input[name="step1dents"][value="' + step1dents + '"]').trigger("click");
            $('input[name="step1distortion"][value="' + step1distortion + '"]').trigger("click");
            $('input[name="step1defects"][value="' + step1defects + '"]').trigger("click");
            $('input[name="step1anykind"][value="' + step1anykind + '"]').trigger("click");
            $('input[name="step1unsafe"][value="' + step1unsafe + '"]').trigger("click");
            $('input[name="step1Corrosive"][value="' + step1Corrosive + '"]').trigger("click");

            sectionSubmit();
        });

    }

    function getSection1() {

        JSON.parse(sessionStorage.getItem("section1"));

        console.log("ddd" + JSON.parse(sessionStorage.getItem("section1")));

        var obj = JSON.parse(sessionStorage.getItem("section1"))
        console.log(obj == null ? '' : obj.corrodedOrAbradedAreas);

        setSection1(obj);
        console.log("section Data" + JSON.stringify(section1));

        var step1area = obj == null ? '' : obj.corrodedOrAbradedAreas;
        var step1dents = obj == null ? '' : obj.dents;
        var step1distortion = obj == null ? '' : obj.distortions;
        var step1defects = obj == null ? '' : obj.defects;
        var step1anykind = obj == null ? '' : obj.leaksOfAnyKind;
        var step1unsafe = obj == null ? '' : obj.anyConditionThatmightRender;
        var step1Corrosive = obj == null ? '' : obj.beingtransported;

        $('input[name="step1area"][value="' + step1area + '"]').trigger("click");
        $('input[name="step1dents"][value="' + step1dents + '"]').trigger("click");
        $('input[name="step1distortion"][value="' + step1distortion + '"]').trigger("click");
        $('input[name="step1defects"][value="' + step1defects + '"]').trigger("click");
        $('input[name="step1anykind"][value="' + step1anykind + '"]').trigger("click");
        $('input[name="step1unsafe"][value="' + step1unsafe + '"]').trigger("click");
        $('input[name="step1Corrosive"][value="' + step1Corrosive + '"]').trigger("click");
        
    }

    const sectionSubmit = () => {
        // alert("dfasdf");
        var section1 = {
            "corrodedOrAbradedAreas": $("input[name='step1area']:checked").val() === "1" ? "1" : "0",
            "dents": $("input[name='step1dents']:checked").val() === "1" ? "1" : "0",
            "distortions": $("input[name='step1distortion']:checked").val() === "1" ? "1" : "0",
            "defects": $("input[name='step1defects']:checked").val() === "1" ? "1" : "0",
            "leaksOfAnyKind": $("input[name='step1anykind']:checked").val() === "1" ? "1" : "0",
            "anyConditionThatmightRender": $("input[name='step1unsafe']:checked").val() === "1" ? "1" : "0",
            "beingtransported": $("input[name='step1Corrosive']:checked").val() === "1" ? "1" : "0"
        };
        // console.log("@@@@@@@@@@@@@@"+JSON.stringify(section1));
        sessionStorage.setItem("section1", JSON.stringify(section1));
    }


    const handleSubmit = () => {

        let form_is_valid = true;

        var step1area = $("input[name='step1area']:checked").val();
        var step1dents = $("input[name='step1dents']:checked").val();
        var step1distortion = $("input[name='step1distortion']:checked").val();
        var step1defects = $("input[name='step1defects']:checked").val();
        var step1anykind = $("input[name='step1anykind']:checked").val();
        var step1unsafe = $("input[name='step1unsafe']:checked").val();
        var step1Corrosive = $("input[name='step1Corrosive']:checked").val();

        if (step1area == undefined || step1dents == undefined || step1distortion == undefined || step1defects == undefined || step1anykind == undefined || step1unsafe == undefined || step1Corrosive == undefined) {
            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            //   }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
            form_is_valid = false;

        } else {
            $('#error_message').html('');
        }
        // alert(form_is_valid);
        if (form_is_valid == true) {
            $("#section2").trigger('click');
        }

        return form_is_valid;

    }




    return (
        <>

            <div class="card">

                {/* <div class="divider"></div> */}
                <div class="row p-3">
                    <div class="w-100">
                        <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
                    </div>
                   
                    <WorkOrderNumberDiv />
       
                    <div class="col-lg-12 px-0">
                       <p id="error_message"></p>
                        <div class="inspection-wrap">

                        <div class="step-wrap p-3 mb-3">
                <div class="step-main d-flex">
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2 current-tick">1</div>
                    <div class="labl-section">Tank Shell</div>
                  </div>
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2">2</div>
                    <div class="labl-section">External Piping</div>
                  </div>
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2">3</div>
                    <div class="labl-section">Spill Collar</div>
                  </div>
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2">4</div>
                    <div class="labl-section">Emergency Devices</div>
                  </div>
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2">5</div>
                    <div class="labl-section">Bolts</div>
                  </div>
                  <div class="step-itm mr-3">
                    <div class="no-cl mr-2">6</div>
                    <div class="labl-section">Vehicle Condition</div>
                  </div>
                </div>
              </div>

                            <div class="table-2">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Section 1: Tank Shell / Heads are there any :</th>
                                            <th scope="col">Yes</th>
                                            <th scope="col">No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Corroded or abraded areas</td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1area" value='1' onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1area" value='0' onChange={() => sectionSubmit()} /></td>
                                        </tr>


                                        <tr>
                                            <td>Dents </td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1dents" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1dents" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>

                                        <tr>
                                            <td>Distortion</td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1distortion" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1distortion" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>

                                        <tr>
                                            <td>Defects in Welds </td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1defects" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1defects" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>

                                        <tr>
                                            <td>Leak of any kind</td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1anykind" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1anykind" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>

                                        <tr>
                                            <td>Any condition that might render tank unsafe for transportation service</td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1unsafe" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1unsafe" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>

                                        {/* <tr>
                                                    <td></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1transportationService" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1transportationService" value="0"/></td>
                                                   </tr>     */}

                                        <tr>
                                            <td>Corrosive ladding being transported </td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1Corrosive" value="1" onChange={() => sectionSubmit()} /></td>
                                            <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step1Corrosive" value="0" onChange={() => sectionSubmit()} /></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                           
                        </div>
                    </div>
                </div>



                <div class="divider2"></div>
                <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                    {/* id="section1" */}
                    {/* <a href="#" class="btn btn-secondary mr-1 ">Next</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("draft")}>Cancel </a> */}
                    <a id="section2" class="btn btn-primary mr-1" style={{ display: "none" }}>Next</a>
                    {/* <a id="section2" class="btn btn-primary mr-1" onClick={handleSubmit(onSubmit)}>Next</a> */}
                    <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button>
                </div>
            </div>


        </>
    )
};

EditExternalVisualSectionOne.propTypes = {};

EditExternalVisualSectionOne.defaultProps = {};

export default EditExternalVisualSectionOne;