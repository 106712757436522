import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../utility/requestObject";
import moment from 'moment'
const inspectionOrderListDash = (id,pageNumber,ITEMS_PER_PAGE,setInspectionList,setTotalInspectionList,settotalinspectionPages,setInspectionCurrentPage,setdisplayPageForInspection,inspectionType,statusId,fromDate,toDate) => 
{

   var inspectionRequest=
   {
             "vinNumber":"",
             "pageNumber":pageNumber,
             "pageSize":ITEMS_PER_PAGE,
             "sortBy":"",
             "sortDir":"",
             "statusId":statusId,
             "fromDate":fromDate?moment($("#fromDate").val()).format('YYYY-MM-DD'):'',
             "toDate":toDate?moment($("#toDate").val()).format('YYYY-MM-DD'):'',
            "isActive":true
          
   };
  
   // const requestOptions = 
   // {
   //       method: 'POST',
   //       headers:{'Content-Type': 'application/json'},
   //       body: JSON.stringify(inspectionRequest)
   // };
   $("#loading").show();
   var requestOptions=requestObject.RequestHeader(inspectionRequest);
   services.listInspectionOrders(requestOptions).then((res) => 
   {
      $("#loading").hide();
      if (res.success) {
         
      setInspectionList(res ? res.rows : "");
      setTotalInspectionList(res.customValues.totalNumberOfRecords);
      settotalinspectionPages(res.customValues.totalNumberOfRecords);
      setInspectionCurrentPage(res.customValues.currentPageNumber);
      var displaypages=[];

      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/25));i++)
      {
         displaypages.push(i*25);
      }
    
      setdisplayPageForInspection(displaypages);
     }
     else{
      setInspectionList("");
     }
   });
      
}

export default inspectionOrderListDash;