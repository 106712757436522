import { useState } from "react"
import Swal from "sweetalert2";
import $ from "jquery";
import validator from "validator";
function AddRemoveInputField({ inputList, setInputList})
{
    
  

    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = (e, index) => 
    {
        //const { email} = e.target;
        const list = [...inputList];
        // alert($("#email"+index).val())
        var email = $("#email"+index).val().replace(/^\s+|\s+$/gm,'');
 
        if (validator.isEmail(email.trim()))
        {
            setInputList([...inputList, {}]);
            // $('#emailError').html('');
            // $("#emailErrorCustomerDetails").hide()
            // $("#emailErrorTaskOrder").hide()
        }
        else
        {

          // $('#emailError').html('Please enter one or more emails to send the documents to.');
          // $("#emailErrorCustomerDetails").show()
          // $("#emailErrorTaskOrder").show()
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter a valid email",
                // text: "Please enter a valid email",
                showConfirmButton: true
        
              }).then((result) => {
        
                if (result.isConfirmed) {
        
        
                }
              });
        
        }
        // if ($("#email"+index).val() == "")
        // {
        //     Swal.fire({
        //         position: 'center',
        //         icon: 'error',
        //         title: "",
        //         text: "Please enter a valid email",
        //         showConfirmButton: true
        
        //       }).then((result) => {
        
        //         if (result.isConfirmed) {
        
        
        //         }
        //       });
        //       return false;
        // }else{
        //     setInputList([...inputList, { email: ""}]);
        // }
       
     
    };
    return(
    
        <div className="p-0">
    
        {inputList.map((x, i) => {
          return (
            <div className="addition-email">
              <input
                id={"email" + i}
                name="email"
                placeholder="Enter email"
                value={x.email}
            
                onChange={e => handleInputChange(e, i)}
              />
              
              <div className="btn-box ml-2">
                {inputList.length !== 1 &&    <img src="/images/icons/remove-minus.svg" onClick={() => handleRemoveClick(i)}  className="mr-2" /> 
                 }
                {inputList.length - 1 === i && <img src="/images/icons/add-plus.svg" onClick={(e) => handleAddClick(e, i)} />}
              </div>
            </div>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </div>
    )
}
export default AddRemoveInputField