export const loadingComponent = (
//   <div class="loader-wrapper" id="loading">
//   <div class="truck-wrapper">
//     <div class="truck">
//       <div class="truck-container"></div>
//       <div class="glases"></div>
//       <div class="bonet"></div>

//       <div class="base"></div>

//       <div class="base-aux"></div>
//       <div class="wheel-back"></div>
//       <div class="wheel-front"></div>

//       <div class="smoke"></div>
//     </div>
//   </div>
// </div>

<div class="spin-body" id="loading">
<div class="spinner-wrap">
{/* <div class="spinner"style={{marginLeft:"28px"}}></div> */}
{/* <span style={{marginTop:"10px"}}> */}
  <div class="truck-wrapper-authentication">
    <div class="truck">
      <div class="truck-container"></div>
      <div class="glases"></div>
      <div class="bonet"></div>

      <div class="base"></div>

      <div class="base-aux"></div>
      <div class="wheel-back"></div>
      <div class="wheel-front"></div>

      <div class="smoke"></div>
    </div>
  </div>
{/* </span> */}
{/* <div class="truck-wrapper">
<div class="truck">

<div class="truck-container"></div>


<div class="glases"></div>


<div class="bonet"></div>





<div class="base"></div>





<div class="base-aux"></div>


<div class="wheel-back"></div>


<div class="wheel-front"></div>





<div class="smoke"></div>


</div>


</div> */}
</div>
</div>

  )