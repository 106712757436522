import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function CarouselComponent() {
    return (
        <div class="carousel-wrapper position-relative">
             <div class="banner-textwrap">
                    <div class="container">
                        <div class="col-lg-8 flex-column">
                            <h1>Who Knew Compliance Document Management Could Be This Simple?</h1>
                            <span>DotStow helps truck fleet and repair companies simplify compliance
                                    document management by taking them from paper to digital.</span> <br></br>
                            <a href="/schedule_demo" class="btn btn-primary  mt-3">SCHEDULE A DEMO</a>
                        </div>
                    </div>
                </div>
                <div class="banner-icon-title">
                    <div class="item-icon-title">
                        <span>
                            <img src="img/faster.png" alt=""/>
                        </span>
                        <p>Faster, hassle-free DOT audits.</p>
                    </div>
                    <div class="item-icon-title">
                        <span>
                            <img src="img/track.png" alt=""/>
                        </span>
                        <p>Minimize record-keeping mistakes and hassles.</p>
                    </div>
                    <div class="item-icon-title">
                        <span>
                            <img src="img/minimize.png" alt=""/>
                        </span>
                        <p>Track years of document records without all the boxes.</p>
                    </div>
                </div>
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
                
                <div>
                   <img src="/img/banner1.jpg" class="img-fluid"/>
                  
                </div>
                <div>
                  <img src="/img/banner2.jpg" class="img-fluid"/>
                  
                </div>
                <div>

                   <img src="/img/banner3.jpg" class="img-fluid"/>
                   
                </div>
            </Carousel>
        </div>
    );
}