import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const CargoTankThicknessTest = () => {
    const { taskOrderId,id, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());

    $(document).ready(function () {

        $("#thicknessDesignPressure,#mawp").keypress(function (e) {
            //     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //              return false;
            //   }
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 3) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 3)) {
                return false;
            }
        });
    });


    const redirect = (page) => {

        navigate(page);

    }
    const handleSubmit = () => {

        let form_is_valid = true;

        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        var allowMax = /^[0-9]*$/;
        if (!thicknessDesignPressure) {
            form_is_valid = false;
            $("#thicknessDesignPressure-message").html('*Design pressure is required');

            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            // }, 500);
            $('html,body').animate({ scrollTop: "600px" }, 1000);
            return false

        } else {

            $("#thicknessDesignPressure-message").html('');
            form_is_valid = true;
        }



        var mawp = $('#mawp').val();
        if (!mawp) {
            form_is_valid = false;
            $("#mawp-message").html('*MAWP is required');
            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            // }, 500);
            $('html,body').animate({ scrollTop: "600px" }, 1000);
            return false
        } else {
            $("#mawp-message").html('');
            form_is_valid = true;
        }

       // var corrosionallowance = $("input[name='corrosionallowance']:checked").val();

        // if (corrosionallowance == undefined) {

        //     $('html, body').animate({
        //         scrollTop: $("#inspectionType").offset().top
        //     }, 500);
        //     form_is_valid = false;
        //     $('#error_message').css("color", "#d33232").html("*Please select the checkbox");

        // } else {
        //     $('#error_message').html('');
        // }

        return form_is_valid;

    }
    const save = (status) => {

        // console.log("results=="+JSON.stringify(inputList));


        var documentList = [];

        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
                    extension = "bmp";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                    extension = "word";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
                    extension = "dotm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
                }
                else {

                }


                documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }
        } catch (exception) {
            documentList.push();
            // Swal.fire({
            //     position: 'center',
            //     icon: 'error',
            //     title: "",
            //     text:"Please attach document",
            //     showConfirmButton: true

            //   }).then((result) =>
            //   {

            //         if(result.isConfirmed)
            //         {


            //         }
            //   });
            //   return false;
        }
        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter Test Performed by",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }

        if (status == 'DRAFT') {

            var requestObjectInspection =
            {

                "type": "Cargo Tank Thickness Test",
                "workOrderNumber": $("#workOrderNumber").val(),
                "notes": $("#notes").val(),
                "inspectedBy": $("#inspectedBy").val(),
                "inspectedDate": $("#inspectedDate").val(),
                "verifiedBy": $("#verifiedBy").val(),
                "verifiedDate": $("#verifiedBy").val(),
                "status": status,
                "taskOrderId":taskOrderId,
                "inspectionType": {
                    "id": sessionStorage.getItem("selectedinspectionId"),
                    "name": sessionStorage.getItem("selectedinspectionType")
                },
                "result":
                {
                    "approvedCertify": "0",
                    "inspectionFailed": "0"
                },
                "dispositionOfVehicle":
                {
                    "vehicleReturnedServices": "0",
                    "vehicleWithdrawnFromService": "0",
                    "vehicleSentToAnotherRepairFacility": "0"
                },
                "vehicleDetails":
                {
                    "vinNumber": $("#vin").html(),
                    "lisencePlateNumber": $("#lisencePlateNumber").html(),
                    "id": vid,
                    "unitNumber": $("#lisencePlateNumber").html()
                },
                "cargoTankThicknessTest":
                {
                    "designPressure": $("#thicknessDesignPressure").val(),
                    "mawp": $("#mawp").val(),

                    // "thicknessTestWithoutException" : $("#thicknessTestWithoutException").val(),

                    // "thicknessTestAtVisualInspection" : $("#thicknessTestAtVisualInspection").val(),

                    // "sequencedInspectionFunction" : $("#sequencedInspectionFunction").val(),

                    // "currentThickness" :$("#currentThickness").val(),

                    "minimumCorrissionAllowence": $("input[name='corrosionallowance']:checked").val() === "on" ? "1" : "0"
                },

                "documentList": documentList

            }
            $("#loadingforsubmit").show();
            // setLoading(true);
            // const requestOptions =
            // {
            //   method: 'POST',
            //   headers: { 'Content-Type': 'application/json'
            //             },

            //       body: JSON.stringify(requestObject)
            // };
            var requestOptions = requestObject.RequestHeader(requestObjectInspection);
            services.addInspection(requestOptions).then((res) => {
                // setLoading(false);
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                    }
                });
            });
        } else {
            if (handleSubmit() == true) {
                var requestObjectInspection =
                {

                    "type": "Cargo Tank Thickness Test",
                    "workOrderNumber": $("#workOrderNumber").val(),
                    "notes": $("#notes").val(),
                    "inspectedBy": $("#inspectedBy").val(),
                    "inspectedDate": $("#inspectedDate").val(),
                    "verifiedBy": $("#verifiedBy").val(),
                    "verifiedDate": $("#verifiedBy").val(),
                    "status": status,
                    "taskOrderId":taskOrderId,
                    "inspectionType": {
                        "id": sessionStorage.getItem("selectedinspectionId"),
                        "name": sessionStorage.getItem("selectedinspectionType")
                    },
                    "result":
                    {
                        "approvedCertify": "0",
                        "inspectionFailed": "0"
                    },
                    "dispositionOfVehicle":
                    {
                        "vehicleReturnedServices": "0",
                        "vehicleWithdrawnFromService": "0",
                        "vehicleSentToAnotherRepairFacility": "0"
                    },
                    "vehicleDetails":
                    {
                        "vinNumber": $("#vin").html(),
                        "lisencePlateNumber": $("#lisencePlateNumber").html(),
                        "id":vid,
                        "unitNumber": $("#lisencePlateNumber").html()
                    },
                    "cargoTankThicknessTest":
                    {
                        "designPressure": $("#thicknessDesignPressure").val(),
                        "mawp": $("#mawp").val(),

                        // "thicknessTestWithoutException" : $("#thicknessTestWithoutException").val(),

                        // "thicknessTestAtVisualInspection" : $("#thicknessTestAtVisualInspection").val(),

                        // "sequencedInspectionFunction" : $("#sequencedInspectionFunction").val(),

                        // "currentThickness" :$("#currentThickness").val(),

                        "minimumCorrissionAllowence": $("input[name='corrosionallowance']:checked").val() === "on" ? "1" : "0"
                    },

                    "documentList": documentList

                };
                $("#loadingforsubmit").show();
                // setLoading(true);
                // const requestOptions =
                // {
                //   method: 'POST',
                //   headers: { 'Content-Type': 'application/json'
                //             },

                //       body: JSON.stringify(requestObject)
                // };
                var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                services.addInspection(requestOptions).then((res) => {
                    // setLoading(false);
                    $("#loadingforsubmit").hide();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                        }
                    });
                });
            }
        }




    }

    return (
        <>
{LoadingComponentForSubmit}
            <div class="row mb-2">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">


                        {/* <div class="divider"></div> */}
                        <div class="row px-3 pb-3">
                            <div class="w-100">
                                <h4 class="form-inspection-title text-center">Cargo Tank Thickness Test</h4>
                                <span id="thicknessDesignPressure-message" class="error"></span>
                                <span id="mawp-message" class="error"></span>
                                <p id="error_message" class="center"></p>
                            </div>
                            <WorkOrderNumberDiv />
                            <div >
                                <div class="inspection-wrap">


                                    <div class="table-2 mb-1">
                                        <table class="table">

                                            <tbody>

                                                <tr class="p-2">
                                                    <td style={{ overflowX: "hidden" }}>
                                                        <div class="row">
                                                            <div class="col-md-6 mb-3">
                                                                <div className="unitDzine2">
                                                                    <div> <strong>Design Pressure</strong> <span class="star">*</span></div>
                                                                    <div> <input type="text" class="form-control" id="thicknessDesignPressure" aria-describedby="emailHelp" width="90px" /></div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6 mb-3">
                                                                <div className="unitDzine2">
                                                                    <div> <strong>MAWP</strong><span class="star">*</span></div>
                                                                    <div> <input type="text" class="form-control" id="mawp" aria-describedby="emailHelp" width="90px" /></div>
                                                                </div>
                                                            </div></div>

                                                        {/* <div class="col-md-6">
                                            <div className="unitDzine2">
                                            <lable>Design Pressure</lable>
                                           <input type="text" class="form-control" id="thicknessDesignPressure" aria-describedby="emailHelp" width="90px" />
                                            </div>


                                        </div>
                                        <div class="col-md-6">
                                        <div className="unitDzine2">
                                        <lable>MAWP</lable>
                                        <input type="text" class="form-control" id="mawp" aria-describedby="emailHelp" width="90px" />
                                        </div>

                                    </div> */}

                                                        <div class="row align-items-center justify-content-start mb-3">
                                                            <div class="col-md-10">1. Thickness test must be performed in numerical sequence without exception. </div>
                                                            {/* <div class="col-md-2"> <input type="text" class="form-control" id="thicknessTestWithoutException" aria-describedby="emailHelp" width="90px"/></div> */}
                                                        </div>

                                                        <div class="row align-items-center justify-content-start mb-3">
                                                            <div class="col-md-10">2. Thickness test must be performed at completion of internal visual inspection (when thickness testing is required)</div>
                                                            {/* <div class="col-md-2"> <input type="text" class="form-control" id="thicknessTestAtVisualInspection" aria-describedby="emailHelp" width="90px"/></div> */}
                                                        </div>

                                                        <div class="row align-items-center justify-content-start mb-3">
                                                            <div class="col-md-10">3. Abrated or corroded areas found internally or externally on cargo tank during thickness test must be identified (circle area) on drawing on
                                                                reverse side and must be thoroughly thickness tested ONLY UPON COMPLETION OF SEQUENCED INSPECTION FUNCTION. </div>
                                                            {/* <div class="col-md-2"> <input type="text" class="form-control" id="sequencedInspectionFunction" aria-describedby="emailHelp" width="90px"/></div> */}
                                                        </div>

                                                        <div class="row align-items-center justify-content-start mb-2">
                                                            <div class="col-md-10">4. Bolsters, frames, rings and sub-frames must be thoroughly inspected. Any areas that show corrosion, rust or deterioration must        be thickness tested. Areas found to be less than minimum thickness must be reported in comment section noting location and current thickness. </div>
                                                            {/* <div class="col-md-2"> <input type="text" class="form-control" id="currentThickness" aria-describedby="emailHelp" width="90px"/></div> */}
                                                        </div>


                                                        <div class="d-flex align-items-start mb-3">
                                                            5. <input type="checkbox" aria-label="Checkbox for following text input" name="corrosionallowance" class="mx-2 cb-ins" />
                                                            <div>Check box if tank must be thickness tested annually because last results are within .005" of minimum corrosion allowance.</div>
                                                        </div>
                                                    </td>



                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>
                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                        {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date <span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1 ">Cancel</a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

CargoTankThicknessTest.propTypes = {};

CargoTankThicknessTest.defaultProps = {};

export default CargoTankThicknessTest;
