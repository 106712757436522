import React,{history,useEffect, useState, useMemo,useRef } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import CheckAuthentication from "./checkAuthentication";
import { Controller,useForm } from "react-hook-form";
import $ from "jquery";
import Swal from "sweetalert2";
import services from "../services/services";
import { requestObject } from "./requestObject";
import DatePicker from "react-datepicker";
const AddDocumentVehicleInspected = ({inputList, setInputList,setBase64file,setfileName}) => 
{
  const inputRef = useRef();
      var fileNamesArray=[];
      const [ fileName, setfileNames ] = useState([]);
      const navigate = useNavigate();
      const [documentCategoryList,setDocumentCategoryList] = useState([]);

       const [createdDate, setCreatedDate] = useState();
       const [expiredDate, setExpiredDate] = useState();

       const [inspectionList, setInspectionList] = useState([]);
      
    
    //    const validateDocument = () => {
    //     let form_is_valid = true;

    //     var title = $("#title0").val();
    //     if (title == '') {
    //         $('#title_errorMessage').css("color", "#d33232").html("*Titile is required");
    //         form_is_valid = false;
    //     } else {
    //         $('#title_errorMessage').html('');
    //     }

    //     var type = $("#doucmenttype").val();
    //     if (type == '') {
    //         $('#tye_errorMessage').css("color", "#d33232").html("*Type is required");
    //         form_is_valid = false;
    //     } else {
    //         $('#tye_errorMessage').html('');
    //     }

    //     var createdDate = $("#createdDate").val();
    //     if (createdDate == '') {
    //         $('#createdDate_errorMessage').css("color", "#d33232").html("*created date is required");
    //         form_is_valid = false;
    //     } else {
    //         $('#createdDate_errorMessage').html('');
    //     }

    //     var expiredDate = $("#expiredDate").val();
    //     if (expiredDate == '') {
    //         $('#expiredDate_errorMessage').css("color", "#d33232").html("*expired date is required");
    //         form_is_valid = false;
    //     } else {
    //         $('#expiredDate_errorMessage').html('');
    //     }

    //     return form_is_valid;
    // }
     
      useEffect(() => 
      {
        
      
        if(CheckAuthentication)
        {
        }
        else
        {
          sessionStorage.clear();
          localStorage.clear();
          //navigate("/login");
              window.location.href="/login";
        }
        
        documentCategoryListApi();
      },[]); 

    
      

const documentCategoryListApi=()=>
     {
     
         var getVehicleDocumentSubCategoriesReq=
         {
             
         };
     
      var requestOptions=requestObject.RequestHeader(getVehicleDocumentSubCategoriesReq);
      services.getInspectionDocumentSubCategories(requestOptions).then((res) => 
      {
          console.log("@@@@@vendors list@@@@@@"+JSON.stringify(res.rows));
          setDocumentCategoryList(res.rows);
      });
     }

      // handle input change
   const handleInputChange = (e, index) => 
    {
      const { name, value,filename } = e.target;
      const list = [...inputList];
      setfileNames(value);
      list[index][name] = value;
      list[index][filename] = value;
      setInputList(list);
    };
     
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
     
    // handle click event of the Add button
    const handleAddClick = (e,index) => {
      const { name, value,filename } = e.target;
      const list = [...inputList];
     
  
      if(list[index][filename]==""||list[index][filename]=="undefined"||list[index][filename]==undefined)
      { 
        
        Swal.fire({  
          position: 'center',  
          icon: 'error',  
          title: "",  
          text:"Please Select Document Title",
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                
              
              }
        });
        return false;

      }else{
        setInputList([...inputList, {}]);
      }
      
    };
   

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          cb(null, reader.result)
        }
        reader.onerror = function (error) {
          cb(error, null)
        }
      }
    const onUploadFileChange = ( event,index) => 
    {
        
    
        var target=event.target;
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {

          if (result) {
            const { name, value,fileName } = event.target;
            const list = [...inputList];
            list[index][name] = result;
            list[index]["fileName"] = target.files[0].name;
            setBase64file(result);
           // setFile(result)
       
        
            setInputList(inputList);
     
           // setFileName(target.files[0])
          }
        })
      }
  
   

      return(<>
    
                             {/* <div class="row p-3">
                                <div class="col-lg-12">
                                    <div class="table-1"> */}
                                  {inputList?inputList.map((x, i) => 
                                                {
                                                return (
                                                  
                                                  
                                                      
                                                //    <div class="d-flex justify-content-between">
                                                //         <div className="box">
                                                       
                                                        <table class="fileInputhide">
                                                <tbody>
                                                    <tr>
                                                        <th style={{width: "20%"}} class="p-2">Type<span class="star">*</span></th>
                                                        <th style={{width: "30%"}} class="p-2">Title<span class="star">*</span></th>
                                                        <th style={{width: "20%"}} class="p-2">Created Date<span class="star">*</span></th>
                                                        <th style={{width: "20%"}} class="p-2">Expiry Date<span class="star">*</span></th>
                                                        <th style={{width: "20%"}} class="p-2">Upload<span class="star">*</span></th>
                                                    </tr>
                                                    <tr>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                                <select class="form-control custome-select" id="doucmenttype" name="doucmenttype[]">
                                                                <option value="" selected>Select Inspection</option>
                                                        {
                                                         
                                                              documentCategoryList?documentCategoryList.sort((a, b) => a.name.localeCompare(b.name)).map((data,index) => (
                                                                <option value={data.id}>{data.name}</option>
                                                           
                                                               )):""
                                                         }
                                                        
                                                            </select>
                                                            <span id="type_errorMessage" class="error"></span>
                                                            </div>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <input type="text" class="form-control custome-input"  name="documentName[]" placeholder="" aria-describedby="emailHelp" value={x.title}
                                                            id={"title"+i}
                                                            onChange={e => handleInputChange(e, i)}/>
                                                            </div>
                                                            <span id="title_errorMessage" class="error"></span>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <DatePicker dateFormat="MM-dd-yyyy" selected={createdDate} onChange={(date) => setCreatedDate(date.getTime())} name="documentDate[]" class="form-control" id="createdDate" placeholderText="" />  
                                                            </div>
                                                            <span id="createdDate_errorMessage" class="error"></span>
                                                        </td>
                                                        <td class="p-2">
                                                            <div class="form-group m-0">
                                                            <DatePicker dateFormat="MM-dd-yyyy" selected={expiredDate} onChange={(date) => setExpiredDate(date.getTime())} name="expiredDate[]" class="form-control" id="expiredDate" placeholderText="" />
                                                               
                                                            </div>
                                                            <span id="expiredDate_errorMessage" class="error"></span>
                                                        </td>

                                                        <td class="d-flex p-2">
                                                        {/* <div style={{"color":"red"}} class="fileNameDt mx-0"> {inputList[i].fileName}</div> */}
                                                     {/* <img src="/images/icons/pdf-icon.svg" alt=""/> <input type="file" size="60" />  */}
                                                     <label class="mb-0 tt mr-3" data-bs-toggle="tooltip" data-bs-placement="bottom" title={inputList[i].fileName==null?"upload Document":inputList[i].fileName}> {inputList[i].fileName==null?<img src="/images/icons/upload.svg" alt="" />:<img src="/images/icons/pdf-icon.svg" alt="" />} 
                                                      <input type="file" name="filetobase64" id="file-input"   onChange={e => onUploadFileChange(e, i)}  accept="image/jpeg,image/png,image/bmp,application/pdf,application/msword,application/word,application/msword" /></label>
                                                        {/* <input type="file" name="filetobase64"  onChange={onUploadFileChange} accept="application/pdf" /> */}
                                                        
 
                                                                    <div className="btn-box d-flex">
                                                                        {inputList.length !== 1 && 
                                                                        <img src="/images/icons/remove-minus.svg" className="mr-3"  onClick={() => handleRemoveClick(i)} alt="upload"/>
                                                                        // <button
                                                                        // className="mr10"
                                                                        // onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                        }
                                                                        
                                                                        
                                                                    </div>
                                                                 
                                                 
                                                        </td>
                                                      
                                                    </tr>
                                                    {/* {inputList.length - 1 === i &&  <img src="/images/icons/add-plus.svg"  onClick={(e) =>handleAddClick(e, i)} />} */}
                                                    {/* {inputList.length - 1 === i && <button type="button" class="btn btn-success ml-2" onClick={(e) =>handleAddClick(e, i)}>Add</button>} */}
                                                </tbody>
                                            </table>
                                            
                                                  
 
                                                                   
                                                    //              </div>
                                                    // </div>
                                                                );
                                                                }):""}
                                                 
                                    {/* </div>
                                </div>
                            </div> */}
      </>)
}

AddDocumentVehicleInspected.propTypes = {};

AddDocumentVehicleInspected.defaultProps = {};

export default AddDocumentVehicleInspected;