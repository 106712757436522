import services from "../services/services";
import { requestObject } from "./requestObject";
import { itemperPagetoDisplay } from "../modules/constant";

const employeeDriverList=(securityRoleName,pageNumber,pageSize,ITEMS_PER_PAGE_DRIVER,sortBy,sortDir,setdriverlist,setDriverdisplayPage,displayItems,setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDriverText,activestatusDriver)=>
{

     var request= 
     {

            
      
            "securityRoleName":securityRoleName,
      
            "pageNumber":pageNumber,

            "firstName":searchDriverText,

            "pageSize":pageSize,
      
            "sortBy":sortBy,
      
            "sortDir":sortDir,

            "isActive":activestatusDriver,
  
      }
      var requestOptions=requestObject.RequestHeader(request);
      services.listUsersByRole(requestOptions).then((res)=>
      {
            setdriverlist(res.rows);
            var displaypages=[];
            setTotalDriverItems(res.customValues.totalNumberOfRecords);
            
            settotalDriverPages(res.customValues.totalNumberOfRecords);
           
            setCurrentDriverPage(res.customValues.currentPageNumber);
            if(res.rows.length>0)
            {
               
                  setdriverlist(res.rows);
            }else{

              
                  setdriverlist("");
            }
           
            setTotalDriverRecord(res.customValues.totalNumberOfRecords);
           
            if(displayItems=="displayItems")
            {
            
                    for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/itemperPagetoDisplay));i++)
                    {
                     
                      displaypages.push(i*itemperPagetoDisplay);
                    }
                    setDriverdisplayPage(res.rows.length>0?displaypages:[0]);
                    
            
            }

      });

}

export default employeeDriverList;