import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const AnnualVehicle3Edit = () => {

    const { fkAccountId, inspectionId,vid } = useParams();
    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [ fileName, setfileName ] = useState("");
    const [ inspectionDetails, setInspectionDetails ] = useState("");
    const [ documentList, setDocumentLists ] = useState([]);
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [designpressure, setDesignPressure] = useState();
    const [certify, setApproveCertify] = useState();
    var documentLists=[];


   const [step3_driverDate, setStep3_driverDate] = useState(null);
   const [step3_violationDate, setStep3_violationDate] = useState(null);
   const [step3_damageDate, setStep3_damageDate] = useState(null);
   const [step3_visibleDate, setStep3_visibleDate] = useState(null);
   const [step3_fillerDate, setStep3_fillerDate] = useState(null);
   const [step3_securityDate, setStep3_securityDate] = useState(null);
   const [step3_393Date, setStep3_393Date] = useState(null);
   const [step3_partDate, setStep3_partDate] = useState(null);
   const [step3_protectionDate, setStep3_protectionDate] = useState(null);
   const [step3_CcontainerDate, setStep3_CcontainerDate] = useState(null);

  

   useEffect(() => {
      if(CheckAuthentication) 
      {
         $("input[name='step3_driver_repaired']").attr('disabled', 'true');
         $("input[name='step3_driverDate']").hide();
         $("input[name='step3_driverDateTemp']").attr('disabled', 'true');

         $("input[name='step3_violation_repaired']").attr('disabled', 'true');
         $("input[name='step3_violationDate']").hide();
         $("input[name='step3_violationDateTemp']").attr('disabled', 'true');

         $("input[name='step3_damage_repaired']").attr('disabled', 'true');
         $("input[name='step3_damageDate']").hide();
         $("input[name='step3_damageDateTemp']").attr('disabled', 'true');

         $("input[name='step3_visible_repaired']").attr('disabled', 'true');
         $("input[name='step3_visibleDate']").hide();
         $("input[name='step3_visibleDateTemp']").attr('disabled', 'true');

         $("input[name='step3_filler_repaired']").attr('disabled', 'true');
         $("input[name='step3_fillerDate']").hide();
         $("input[name='step3_fillerDateTemp']").attr('disabled', 'true');

         $("input[name='step3_security_repaired']").attr('disabled', 'true');
         $("input[name='step3_securityDate']").hide();
         $("input[name='step3_securityDateTemp']").attr('disabled', 'true');

         $("input[name='step3_393_repaired']").attr('disabled', 'true');
         $("input[name='step3_393Date']").hide();
         $("input[name='step3_393DateTemp']").attr('disabled', 'true');

         $("input[name='step3_part_repaired']").attr('disabled', 'true');
         $("input[name='step3_partDate']").hide();
         $("input[name='step3_partDateTemp']").attr('disabled', 'true');

         $("input[name='step3_protection_repaired']").attr('disabled', 'true');
         $("input[name='step3_protectionDate']").hide();
         $("input[name='step3_protectionDateTemp']").attr('disabled', 'true');

         $("input[name='step3_Ccontainer_repaired']").attr('disabled', 'true');
         $("input[name='step3_CcontainerDate']").hide();
         $("input[name='step3_CcontainerDateTemp']").attr('disabled', 'true');
         scrollTop();
         getSection3();
         getInpection(fkAccountId,inspectionId);
         
      }else{
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
          window.location.href="/login";
      }
   }, []);

   function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
         const workOrderNumber=sessionStorage.getItem("workOrderNumber");
         if(workOrderNumber==null)
         {
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"")
         }else{
         sessionStorage.setItem("workOrderNumber",workOrderNumber);
         }
        
         setInspectionDetails(res.rows);
         console.log("Annual vehicle inspection"+JSON.stringify(res.rows));

       var  Exhaust=res.rows.annualVehicleInspection.exahustSystem;
       var  Fuel=res.rows.annualVehicleInspection.fuelSystem;
       var  LIGHTING=res.rows.annualVehicleInspection.lightingDevices;
       var  SAFE=res.rows.annualVehicleInspection.safeLoading;

       var step3_driverNF = Exhaust == null ? '' : Exhaust.step3_driverNF;
      var step3_driver = Exhaust == null ? '' : Exhaust.step3_driver;
      var step3_driverDate = Exhaust == null ? '' : Exhaust.step3_driverDate;

      $('input[name="step3_driver_na"][value="' + step3_driverNF + '"]').trigger("click");
      $('input[name="step3_driver_repaired"][value="' + step3_driver + '"]').trigger("click").prop('checked', true);


            var step3_violationNF = Exhaust == null ? '' : Exhaust.step3_violationNF;
            var step3_violation = Exhaust == null ? '' : Exhaust.step3_violation;
            var step3_violationDate = Exhaust == null ? '' : Exhaust.step3_violationDate;
      
            $('input[name="step3_violation_na"][value="' + step3_violationNF + '"]').trigger("click");
            $('input[name="step3_violation_repaired"][value="' + step3_violation + '"]').trigger("click").prop('checked', true);


            var step3_damageNF = Exhaust == null ? '' : Exhaust.step3_damageNF;
            var step3_damage = Exhaust == null ? '' : Exhaust.step3_damage;
            var step3_damageDate = Exhaust == null ? '' : Exhaust.step3_damageDate;
      
            $('input[name="step3_damage_na"][value="' + step3_damageNF + '"]').trigger("click");
            $('input[name="step3_damage_repaired"][value="' + step3_damage + '"]').trigger("click").prop('checked', true);

            

            var step3_visibleNF = Fuel == null ? '' : Fuel.step3_visibleNF;
            var step3_visible = Fuel == null ? '' : Fuel.step3_visible;
            var step3_visibleDate = Fuel == null ? '' : Fuel.step3_visibleDate;
      
            $('input[name="step3_visible_na"][value="' + step3_visibleNF + '"]').trigger("click");
            $('input[name="step3_visible_repaired"][value="' + step3_visible + '"]').trigger("click").prop('checked', true);

     
        
            var step3_fillerNF = Fuel == null ? '' : Fuel.step3_fillerNF;
            var step3_filler = Fuel == null ? '' : Fuel.step3_filler;
            var step3_fillerDate = Fuel == null ? '' : Fuel.step3_fillerDate;
      
            $('input[name="step3_filler_na"][value="' + step3_fillerNF + '"]').trigger("click");
            $('input[name="step3_filler_repaired"][value="' + step3_filler + '"]').trigger("click").prop('checked', true);

     

         var step3_securityNF = Fuel == null ? '' : Fuel.step3_securityNF;
            var step3_security = Fuel == null ? '' : Fuel.step3_security;
            var step3_securityDate = Fuel == null ? '' : Fuel.step3_securityDate;
      
            $('input[name="step3_security_na"][value="' + step3_securityNF + '"]').trigger("click");
            $('input[name="step3_security_repaired"][value="' + step3_security + '"]').trigger("click").prop('checked', true);


        var step3_393NF = LIGHTING == null ? '' : LIGHTING.step3_393NF;
            var step3_393 = LIGHTING == null ? '' : LIGHTING.step3_393;
            var step3_393Date = LIGHTING == null ? '' : LIGHTING.step3_393Date;
      
            $('input[name="step3_393_na"][value="' + step3_393NF + '"]').trigger("click");
            $('input[name="step3_393_repaired"][value="' + step3_393 + '"]').trigger("click").prop('checked', true);
     

            var step3_partNF = SAFE == null ? '' : SAFE.step3_partNF;
            var step3_part = SAFE == null ? '' : SAFE.step3_part;
            var step3_partDate = SAFE == null ? '' : SAFE.step3_partDate;
      
            $('input[name="step3_part_na"][value="' + step3_partNF + '"]').trigger("click");
            $('input[name="step3_part_repaired"][value="' + step3_part + '"]').trigger("click").prop('checked', true);
            
          

            var step3_protectionNF = SAFE == null ? '' : SAFE.step3_protectionNF;
            var step3_protection = SAFE == null ? '' : SAFE.step3_protection;
            var step3_protectionDate = SAFE == null ? '' : SAFE.step3_protectionDate;
      
            $('input[name="step3_protection_na"][value="' + step3_protectionNF + '"]').trigger("click");
            $('input[name="step3_protection_repaired"][value="' + step3_protection + '"]').trigger("click").prop('checked', true);
   

            var step3_CcontainerNF = SAFE == null ? '' : SAFE.step3_CcontainerNF;
            var step3_Ccontainer = SAFE == null ? '' : SAFE.step3_Ccontainer;
            var step3_CcontainerDate = SAFE == null ? '' : SAFE.step3_CcontainerDate;
      
            $('input[name="step3_Ccontainer_na"][value="' + step3_CcontainerNF + '"]').trigger("click");
            $('input[name="step3_Ccontainer_repaired"][value="' + step3_Ccontainer + '"]').trigger("click").prop('checked', true);

            $("#step3_driverDate").val(step3_driverDate);
            $("#step3_violationDate").val(step3_violationDate);
            $("#step3_damageDate").val(step3_damageDate);
            $("#step3_visibleDate").val(step3_visibleDate);
            $("#step3_fillerDate").val(step3_fillerDate);
            $("#step3_securityDate").val(step3_securityDate);
            $("#step3_393Date").val(step3_393Date);
            $("#step3_partDate").val(step3_partDate);
            $("#step3_protectionDate").val(step3_protectionDate);
            $("#step3_CcontainerDate").val(step3_CcontainerDate);
            
            if (step3_driverNF || step3_violationNF || step3_damageNF || step3_visibleNF || step3_fillerNF || step3_securityNF || step3_393NF || step3_partNF ||step3_protectionNF ||step3_CcontainerNF == 'verified') {
               checkVerified();

               setStep3_driverDate(step3_driverDate?new Date(step3_driverDate):'');
               setStep3_violationDate(step3_violationDate?new Date(step3_violationDate):'');
               setStep3_damageDate(step3_damageDate?new Date(step3_damageDate):'');
               setStep3_visibleDate(step3_visibleDate?new Date(step3_visibleDate):'');
               setStep3_fillerDate(step3_fillerDate?new Date(step3_fillerDate):'');
               setStep3_securityDate(step3_securityDate?new Date(step3_securityDate):'');
               setStep3_393Date(step3_393Date?new Date(step3_393Date):'');
               setStep3_partDate(step3_partDate?new Date(step3_partDate):'');
               setStep3_protectionDate(step3_protectionDate?new Date(step3_protectionDate):'');
               setStep3_CcontainerDate(step3_CcontainerDate?new Date(step3_CcontainerDate):'');
              
            }
      
       
      });
      // handleSubmit();
   }

   function getSection3() {

      JSON.parse(sessionStorage.getItem("section3Exhaust"));

      console.log("section3Exhaust" + JSON.parse(sessionStorage.getItem("section3Exhaust")));

      var Exhaust = JSON.parse(sessionStorage.getItem("section3Exhaust"))
      var Fuel = JSON.parse(sessionStorage.getItem("section3Fuel"))
      var LIGHTING = JSON.parse(sessionStorage.getItem("section3LIGHTING"))
      var SAFE = JSON.parse(sessionStorage.getItem("section3SAFE"))



      var step3_driverNF = Exhaust == null ? '' : Exhaust.step3_driverNF;
      var step3_driver = Exhaust == null ? '' : Exhaust.step3_driver;
      var step3_driverDate = Exhaust == null ? '' : Exhaust.step3_driverDate;

      $('input[name="step3_driver_na"][value="' + step3_driverNF + '"]').trigger("click");
      $('input[name="step3_driver_repaired"][value="' + step3_driver + '"]').trigger("click").prop('checked', true);


            var step3_violationNF = Exhaust == null ? '' : Exhaust.step3_violationNF;
            var step3_violation = Exhaust == null ? '' : Exhaust.step3_violation;
            var step3_violationDate = Exhaust == null ? '' : Exhaust.step3_violationDate;
      
            $('input[name="step3_violation_na"][value="' + step3_violationNF + '"]').trigger("click");
            $('input[name="step3_violation_repaired"][value="' + step3_violation + '"]').trigger("click").prop('checked', true);


            var step3_damageNF = Exhaust == null ? '' : Exhaust.step3_damageNF;
            var step3_damage = Exhaust == null ? '' : Exhaust.step3_damage;
            var step3_damageDate = Exhaust == null ? '' : Exhaust.step3_damageDate;
      
            $('input[name="step3_damage_na"][value="' + step3_damageNF + '"]').trigger("click");
            $('input[name="step3_damage_repaired"][value="' + step3_damage + '"]').trigger("click").prop('checked', true);

            

            var step3_visibleNF = Fuel == null ? '' : Fuel.step3_visibleNF;
            var step3_visible = Fuel == null ? '' : Fuel.step3_visible;
            var step3_visibleDate = Fuel == null ? '' : Fuel.step3_visibleDate;
      
            $('input[name="step3_visible_na"][value="' + step3_visibleNF + '"]').trigger("click");
            $('input[name="step3_visible_repaired"][value="' + step3_visible + '"]').trigger("click").prop('checked', true);

     
        
            var step3_fillerNF = Fuel == null ? '' : Fuel.step3_fillerNF;
            var step3_filler = Fuel == null ? '' : Fuel.step3_filler;
            var step3_fillerDate = Fuel == null ? '' : Fuel.step3_fillerDate;
      
            $('input[name="step3_filler_na"][value="' + step3_fillerNF + '"]').trigger("click");
            $('input[name="step3_filler_repaired"][value="' + step3_filler + '"]').trigger("click").prop('checked', true);

     

         var step3_securityNF = Fuel == null ? '' : Fuel.step3_securityNF;
            var step3_security = Fuel == null ? '' : Fuel.step3_security;
            var step3_securityDate = Fuel == null ? '' : Fuel.step3_securityDate;
      
            $('input[name="step3_security_na"][value="' + step3_securityNF + '"]').trigger("click");
            $('input[name="step3_security_repaired"][value="' + step3_security + '"]').trigger("click").prop('checked', true);


        var step3_393NF = LIGHTING == null ? '' : LIGHTING.step3_393NF;
            var step3_393 = LIGHTING == null ? '' : LIGHTING.step3_393;
            var step3_393Date = LIGHTING == null ? '' : LIGHTING.step3_393Date;
      
            $('input[name="step3_393_na"][value="' + step3_393NF + '"]').trigger("click");
            $('input[name="step3_393_repaired"][value="' + step3_393 + '"]').trigger("click").prop('checked', true);
     

            var step3_partNF = SAFE == null ? '' : SAFE.step3_partNF;
            var step3_part = SAFE == null ? '' : SAFE.step3_part;
            var step3_partDate = SAFE == null ? '' : SAFE.step3_partDate;
      
            $('input[name="step3_part_na"][value="' + step3_partNF + '"]').trigger("click");
            $('input[name="step3_part_repaired"][value="' + step3_part + '"]').trigger("click").prop('checked', true);
            
          

            var step3_protectionNF = SAFE == null ? '' : SAFE.step3_protectionNF;
            var step3_protection = SAFE == null ? '' : SAFE.step3_protection;
            var step3_protectionDate = SAFE == null ? '' : SAFE.step3_protectionDate;
      
            $('input[name="step3_protection_na"][value="' + step3_protectionNF + '"]').trigger("click");
            $('input[name="step3_protection_repaired"][value="' + step3_protection + '"]').trigger("click").prop('checked', true);
   

            var step3_CcontainerNF = SAFE == null ? '' : SAFE.step3_CcontainerNF;
            var step3_Ccontainer = SAFE == null ? '' : SAFE.step3_Ccontainer;
            var step3_CcontainerDate = SAFE == null ? '' : SAFE.step3_CcontainerDate;
      
            $('input[name="step3_Ccontainer_na"][value="' + step3_CcontainerNF + '"]').trigger("click");
            $('input[name="step3_Ccontainer_repaired"][value="' + step3_Ccontainer + '"]').trigger("click").prop('checked', true);


            if (step3_driverNF || step3_violationNF || step3_damageNF || step3_visibleNF || step3_fillerNF || step3_securityNF || step3_393NF || step3_partNF ||step3_protectionNF ||step3_CcontainerNF == 'verified') {
               checkVerified();
               $("#step3_driverDate").val(step3_driverDate);
               $("#step3_violationDate").val(step3_violationDate);
               $("#step3_damageDate").val(step3_damageDate);
               $("#step3_visibleDate").val(step3_visibleDate);
               $("#step3_fillerDate").val(step3_fillerDate);
               $("#step3_securityDate").val(step3_securityDate);
               $("#step3_393Date").val(step3_393Date);
               $("#step3_partDate").val(step3_partDate);
               $("#step3_protectionDate").val(step3_protectionDate);
               $("#step3_CcontainerDate").val(step3_CcontainerDate);

               // setStep3_driverDate(step3_driverNF);
               // setStep3_violationDate(step3_violationDate);
               // setStep3_damageDate(step3_damageDate);
               // setStep3_visibleDate(step3_visibleDate);
               // setStep3_fillerDate(step3_fillerDate);
               // setStep3_securityDate(step3_securityDate);
               // setStep3_393Date(step3_393Date);
               // setStep3_partDate(step3_partDate);
               // setStep3_protectionDate(step3_protectionDate);
               // setStep3_CcontainerDate(step3_CcontainerDate);
            }
     

   }



   const checkVerified = () => {

      //       step3_driver

      var step3_driverValue = $("input[name='step3_driver_na']:checked").val();

      if (step3_driverValue == 'na') {
         $("input[name='step3_driver_repaired']").attr('disabled', 'true');
         $("input[name='step3_driverDateTemp']").show();
         $("input[name='step3_driverDateTemp']").attr('disabled', 'true');
         $("input[name='step3_driverDate']").hide();
         $("input[name='step3_driver_repaired']").prop("checked", false);
         setStep3_driverDate('');

      } if (step3_driverValue == 'verified') {

         $("input[name='step3_driver_repaired']").removeAttr('disabled');
         $("input[name='step3_driverDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_driver_repaired']").is(':checked')) {
            $("input[name='step3_driverDate']").show();
            $("input[name='step3_driverDateTemp']").hide();
         } else {
            $("input[name='step3_driverDate']").hide();
            $("input[name='step3_driverDateTemp']").show();
         }

      }

      // step3_violation

      var step3_violationValue = $("input[name='step3_violation_na']:checked").val();


      if (step3_violationValue == 'na') {
         $("input[name='step3_violation_repaired']").attr('disabled', 'true');
         $("input[name='step3_violationDateTemp']").show();
         $("input[name='step3_violationDateTemp']").attr('disabled', 'true');
         $("input[name='step3_violationDate']").hide();
         $("input[name='step3_violation_repaired']").prop("checked", false);
         setStep3_violationDate('');

      } if (step3_violationValue == 'verified') {

         $("input[name='step3_violation_repaired']").removeAttr('disabled');
         $("input[name='step3_violationDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_violation_repaired']").is(':checked')) {
            $("input[name='step3_violationDate']").show();
            $("input[name='step3_violationDateTemp']").hide();
         } else {
            $("input[name='step3_violationDate']").hide();
            $("input[name='step3_violationDateTemp']").show();
         }

      }

      // step3_damage


      var step3_damageValue = $("input[name='step3_damage_na']:checked").val();


      if (step3_damageValue == 'na') {
         $("input[name='step3_damage_repaired']").attr('disabled', 'true');
         $("input[name='step3_damageDateTemp']").show();
         $("input[name='step3_damageDateTemp']").attr('disabled', 'true');
         $("input[name='step3_damageDate']").hide();
         $("input[name='step3_damage_repaired']").prop("checked", false);
         setStep3_damageDate('');

      } if (step3_damageValue == 'verified') {

         $("input[name='step3_damage_repaired']").removeAttr('disabled');
         $("input[name='step3_damageDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_damage_repaired']").is(':checked')) {
            $("input[name='step3_damageDate']").show();
            $("input[name='step3_damageDateTemp']").hide();
         } else {
            $("input[name='step3_damageDate']").hide();
            $("input[name='step3_damageDateTemp']").show();
         }

      }
      // step3_visible

      var step3_visibleValue = $("input[name='step3_visible_na']:checked").val();

      if (step3_visibleValue == 'na') {
         $("input[name='step3_visible_repaired']").attr('disabled', 'true');
         $("input[name='step3_visibleDateTemp']").show();
         $("input[name='step3_visibleDateTemp']").attr('disabled', 'true');
         $("input[name='step3_visibleDate']").hide();
         $("input[name='step3_visible_repaired']").prop("checked", false);
         setStep3_visibleDate('');

      } if (step3_visibleValue == 'verified') {

         $("input[name='step3_visible_repaired']").removeAttr('disabled');
         $("input[name='step3_visibleDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_visible_repaired']").is(':checked')) {
            $("input[name='step3_visibleDate']").show();
            $("input[name='step3_visibleDateTemp']").hide();
         } else {
            $("input[name='step3_visibleDate']").hide();
            $("input[name='step3_visibleDateTemp']").show();
         }

      }

      // step3_filler

      var step3_fillerValue = $("input[name='step3_filler_na']:checked").val();

      if (step3_fillerValue == 'na') {
         $("input[name='step3_filler_repaired']").attr('disabled', 'true');
         $("input[name='step3_fillerDateTemp']").show();
         $("input[name='step3_fillerDateTemp']").attr('disabled', 'true');
         $("input[name='step3_fillerDate']").hide();
         $("input[name='step3_filler_repaired']").prop("checked", false);
         setStep3_fillerDate('');

      } if (step3_fillerValue == 'verified') {

         $("input[name='step3_filler_repaired']").removeAttr('disabled');
         $("input[name='step3_fillerDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_filler_repaired']").is(':checked')) {
            $("input[name='step3_fillerDate']").show();
            $("input[name='step3_fillerDateTemp']").hide();
         } else {
            $("input[name='step3_fillerDate']").hide();
            $("input[name='step3_fillerDateTemp']").show();
         }

      }

      // step3_security

      var step3_securityValue = $("input[name='step3_security_na']:checked").val();

      if (step3_securityValue == 'na') {
         $("input[name='step3_security_repaired']").attr('disabled', 'true');
         $("input[name='step3_securityDateTemp']").show();
         $("input[name='step3_securityDateTemp']").attr('disabled', 'true');
         $("input[name='step3_securityDate']").hide();
         $("input[name='step3_security_repaired']").prop("checked", false);
         setStep3_securityDate('');

      } if (step3_securityValue == 'verified') {

         $("input[name='step3_security_repaired']").removeAttr('disabled');
         $("input[name='step3_securityDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_security_repaired']").is(':checked')) {
            $("input[name='step3_securityDate']").show();
            $("input[name='step3_securityDateTemp']").hide();
         } else {
            $("input[name='step3_securityDate']").hide();
            $("input[name='step3_securityDateTemp']").show();
         }

      }
      // step3_393

      var step3_393Value = $("input[name='step3_393_na']:checked").val();

      if (step3_393Value == 'na') {
         $("input[name='step3_393_repaired']").attr('disabled', 'true');
         $("input[name='step3_393DateTemp']").show();
         $("input[name='step3_393DateTemp']").attr('disabled', 'true');
         $("input[name='step3_393Date']").hide();
         $("input[name='step3_393_repaired']").prop("checked", false);
         setStep3_393Date('');

      } if (step3_393Value == 'verified') {

         $("input[name='step3_393_repaired']").removeAttr('disabled');
         $("input[name='step3_393DateTemp']").removeAttr('disabled');

         if ($("input[name='step3_393_repaired']").is(':checked')) {
            $("input[name='step3_393Date']").show();
            $("input[name='step3_393DateTemp']").hide();
         } else {
            $("input[name='step3_393Date']").hide();
            $("input[name='step3_393DateTemp']").show();
         }

      }

      // step3_part
      var step3_partValue = $("input[name='step3_part_na']:checked").val();

      if (step3_partValue == 'na') {
         $("input[name='step3_part_repaired']").attr('disabled', 'true');
         $("input[name='step3_partDateTemp']").show();
         $("input[name='step3_partDateTemp']").attr('disabled', 'true');
         $("input[name='step3_partDate']").hide();
         $("input[name='step3_part_repaired']").prop("checked", false);
         setStep3_partDate('');

      } if (step3_partValue == 'verified') {

         $("input[name='step3_part_repaired']").removeAttr('disabled');
         $("input[name='step3_partDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_part_repaired']").is(':checked')) {
            $("input[name='step3_partDate']").show();
            $("input[name='step3_partDateTemp']").hide();
         } else {
            $("input[name='step3_partDate']").hide();
            $("input[name='step3_partDateTemp']").show();
         }

      }


      // step3_protection
      var step3_protectionValue = $("input[name='step3_protection_na']:checked").val();

      if (step3_protectionValue == 'na') {
         $("input[name='step3_protection_repaired']").attr('disabled', 'true');
         $("input[name='step3_protectionDateTemp']").show();
         $("input[name='step3_protectionDateTemp']").attr('disabled', 'true');
         $("input[name='step3_protectionDate']").hide();
         $("input[name='step3_protection_repaired']").prop("checked", false);
         setStep3_protectionDate('');

      } if (step3_protectionValue == 'verified') {

         $("input[name='step3_protection_repaired']").removeAttr('disabled');
         $("input[name='step3_protectionDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_protection_repaired']").is(':checked')) {
            $("input[name='step3_protectionDate']").show();
            $("input[name='step3_protectionDateTemp']").hide();
         } else {
            $("input[name='step3_protectionDate']").hide();
            $("input[name='step3_protectionDateTemp']").show();
         }

      }

      // step3_Ccontainer
      var step3_CcontainerValue = $("input[name='step3_Ccontainer_na']:checked").val();

      if (step3_CcontainerValue == 'na') {
         $("input[name='step3_Ccontainer_repaired']").attr('disabled', 'true');
         $("input[name='step3_CcontainerDateTemp']").show();
         $("input[name='step3_CcontainerDateTemp']").attr('disabled', 'true');
         $("input[name='step3_CcontainerDate']").hide();
         $("input[name='step3_Ccontainer_repaired']").prop("checked", false);
         setStep3_CcontainerDate('');

      } if (step3_CcontainerValue == 'verified') {

         $("input[name='step3_Ccontainer_repaired']").removeAttr('disabled');
         $("input[name='step3_CcontainerDateTemp']").removeAttr('disabled');

         if ($("input[name='step3_Ccontainer_repaired']").is(':checked')) {
            $("input[name='step3_CcontainerDate']").show();
            $("input[name='step3_CcontainerDateTemp']").hide();
         } else {
            $("input[name='step3_CcontainerDate']").hide();
            $("input[name='step3_CcontainerDateTemp']").show();
         }

      }

      


   }

   //  $(document).ready(function () {


   //    $('#annualVehicle4').on('click', function () {

   //    });
   // });
   const handleSubmit = () => {

      var section3Exhaust = {
         "step3_driverNF": $("input[name='step3_driver_na']:checked").val() === "na" ? "na" : "verified",
         "step3_driver": $("input[name='step3_driver_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_driverDate": $("input[name='step3_driverDate']").val(),
         "step3_violationNF": $("input[name='step3_violation_na']:checked").val() === "na" ? "na" : "verified",
         "step3_violation": $("input[name='step3_violation_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_violationDate": $("input[name='step3_violationDate']").val(),
         "step3_damageNF": $("input[name='step3_damage_na']:checked").val() === "na" ? "na" : "verified",
         "step3_damage": $("input[name='step3_damage_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_damageDate": $("input[name='step3_damageDate']").val(),

      };
      console.log(JSON.stringify(section3Exhaust));

      sessionStorage.setItem("section3Exhaust", JSON.stringify(section3Exhaust));

      var section3Fuel = {
         "step3_visibleNF": $("input[name='step3_visible_na']:checked").val() === "na" ? "na" : "verified",
         "step3_visible": $("input[name='step3_visible_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_visibleDate": $("input[name='step3_visibleDate']").val(),
         "step3_fillerNF": $("input[name='step3_filler_na']:checked").val() === "na" ? "na" : "verified",
         "step3_filler": $("input[name='step3_filler_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_fillerDate": $("input[name='step3_fillerDate']").val(),
         "step3_securityNF": $("input[name='step3_security_na']:checked").val() === "na" ? "na" : "verified",
         "step3_security": $("input[name='step3_security_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_securityDate": $("input[name='step3_securityDate']").val(),

      };
      console.log(JSON.stringify(section3Fuel));

      sessionStorage.setItem("section3Fuel", JSON.stringify(section3Fuel));

      var section3LIGHTING = {
         "step3_393NF": $("input[name='step3_393_na']:checked").val() === "na" ? "na" : "verified",
         "step3_393": $("input[name='step3_393_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_393Date": $("input[name='step3_393Date']").val(),
      };
      console.log(JSON.stringify(section3LIGHTING));

      sessionStorage.setItem("section3LIGHTING", JSON.stringify(section3LIGHTING));

      var section3SAFE = {
         "step3_partNF": $("input[name='step3_part_na']:checked").val() === "na" ? "na" : "verified",
         "step3_part": $("input[name='step3_part_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_partDate": $("input[name='step3_partDate']").val(),
         "step3_protectionNF": $("input[name='step3_protection_na']:checked").val() === "na" ? "na" : "verified",
         "step3_protection": $("input[name='step3_protection_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_protectionDate": $("input[name='step3_protectionDate']").val(),
         "step3_CcontainerNF": $("input[name='step3_Ccontainer_na']:checked").val() === "na" ? "na" : "verified",
         "step3_Ccontainer": $("input[name='step3_Ccontainer_repaired']:checked").val() === "1" ? "1" : "0",
         "step3_CcontainerDate": $("input[name='step3_CcontainerDate']").val(),
      };
      console.log(JSON.stringify(section3SAFE));

      sessionStorage.setItem("section3SAFE", JSON.stringify(section3SAFE));

      let form_is_valid = true;

      var step3_driver_na = $("input[name='step3_driver_na']:checked").val();
      var step3_violation_na = $("input[name='step3_violation_na']:checked").val();
      var step3_damage_na = $("input[name='step3_damage_na']:checked").val();
      var step3_visible_na = $("input[name='step3_visible_na']:checked").val();
      var step3_filler_na = $("input[name='step3_filler_na']:checked").val();
      var step3_security_na = $("input[name='step3_security_na']:checked").val();
      var step3_393_na = $("input[name='step3_393_na']:checked").val();
      var step3_part_na = $("input[name='step3_part_na']:checked").val();
      var step3_protection_na = $("input[name='step3_protection_na']:checked").val();
      var step3_Ccontainer_na = $("input[name='step3_Ccontainer_na']:checked").val();


      if (step3_driver_na == undefined || step3_violation_na == undefined || step3_damage_na == undefined || step3_visible_na == undefined || step3_filler_na == undefined || step3_security_na == undefined || step3_393_na == undefined || step3_part_na == undefined || step3_protection_na == undefined || step3_Ccontainer_na == undefined) {
         // $('html, body').animate({
         //    scrollTop: $("#inspectionType").offset().top
         //  }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
         form_is_valid = false;

      } else {
         $('#error_message').html('');
      }

      if (form_is_valid == true) {
         $("#annualVehicle4").trigger('click');
      }

      return form_is_valid;

   }



   return (
      <>


         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div>
                  
                           <div class="divider"></div> */}
                  <div class="row p-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">ANNUAL VEHICLE COMPONENTS INSPECTED</h4>
                        <p id="error_message"></p>
                     </div>
                     <div >
                     <WorkOrderNumberDiv />
                        <div class="inspection-wrap">
                           <div class="step-wrap p-3 mb-2">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""></div> --> */}
                                    <div class="labl-section">Brake </div>
                                 </div>
                                 <div class="step-itm mr-3 ">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Coupling </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 current-tick">3</div>
                                    <div class="labl-section">Exhaust-Fuel-Lighting-Safe</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">4</div>
                                    <div class="labl-section">Steering-Suspension</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">5</div>
                                    <div class="labl-section">Wheels-Glazing</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>EXHAUST SYSTEM</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td >a. Exhaust system leaking forward of or directly below the driver/sleeper compartment</td>
                                          <td ><input type="radio" name="step3_driver_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_driver_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_driver_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_driverDate} onChange={(date) => setStep3_driverDate(date)} name="step3_driverDate" class="form-control" id="step3_driverDate" placeholderText="MM-DD-YYYY" />
                                             <input type="text" class="form-control" name="step3_driverDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Bus exhaust system leaking or discharging in violation of standard.</td>
                                          <td ><input type="radio" name="step3_violation_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_violation_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_violation_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_violationDate} onChange={(date) => setStep3_violationDate(date)} name="step3_violationDate" class="form-control" id="step3_violationDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_violationDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Exhaust system likely to burn, char, or damage the electrical wiring,fuel supply,or any combustible part of the motor cycle.</td>
                                          <td ><input type="radio" name="step3_damage_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_damage_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_damage_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_damageDate} onChange={(date) => setStep3_damageDate(date)} name="step3_damageDate" class="form-control" id="step3_damageDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_damageDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>FUEL SYSTEM</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>


                                       <tr className="annuaul-tb-5cl p-2">
                                          <td >a. Visible leak</td>
                                          <td ><input type="radio" name="step3_visible_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_visible_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_visible_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_visibleDate} onChange={(date) => setStep3_visibleDate(date)} name="step3_visibleDate" class="form-control" id="step3_visibleDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_visibleDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Fuel tank filler cap missing</td>
                                          <td ><input type="radio" name="step3_filler_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_filler_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_filler_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_fillerDate} onChange={(date) => setStep3_fillerDate(date)} name="step3_fillerDate" class="form-control" id="step3_fillerDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_fillerDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Fuel tank securely attached</td>
                                          <td ><input type="radio" name="step3_security_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_security_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_security_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_securityDate} onChange={(date) => setStep3_securityDate(date)} name="step3_securityDate" class="form-control" id="step3_securityDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_securityDateTemp" /></td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>LIGHTING DEVICES </h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>


                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. All lighting devices and reflectors required by Part 393 shall be operable</td>
                                          <td ><input type="radio" name="step3_393_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_393_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_393_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_393Date} onChange={(date) => setStep3_393Date(date)} name="step3_393Date" class="form-control" id="step3_393Date" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_393DateTemp" /></td>
                                       </tr>

                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>SAFE LOADING</h5>
                              <div class="border mb-3">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Part(s) of vehicle or condition of loading such that the spare tire or any part of the load or dunnage can fall onto the roadway.                                                    </td>
                                          <td ><input type="radio" name="step3_part_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_part_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_part_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_partDate} onChange={(date) => setStep3_partDate(date)} name="step3_partDate" class="form-control" id="step3_partDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_partDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Protection against shifting cargo.</td>
                                          <td ><input type="radio" name="step3_protection_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_protection_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_protection_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_protectionDate} onChange={(date) => setStep3_protectionDate(date)} name="step3_protectionDate" class="form-control" id="step3_protectionDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_protectionDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Container securement devices on intermodal equipment.</td>
                                          <td ><input type="radio" name="step3_Ccontainer_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step3_Ccontainer_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="step3_Ccontainer_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step3_CcontainerDate} onChange={(date) => setStep3_CcontainerDate(date)} name="step3_CcontainerDate" class="form-control" id="step3_CcontainerDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step3_CcontainerDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                              
                           </div>

                        </div>
                     </div>
                  </div>



                  <div class="divider2"></div>
                  <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>

                     {/* <a id="annualVehicle2" class="btn btn-primary mr-1">Back</a>
                              <a id="annualVehicle4" class="btn btn-primary mr-1" >Next</a> */}

<button type="button" id="annualVehicle2" class="btn btn-primary mr-1" >Back</button>
<button type="button" id="annualVehicle4" class="btn btn-primary mr-1" style={{ display: "none" }}>Next</button>
                     <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
};

AnnualVehicle3Edit.propTypes = {};
AnnualVehicle3Edit.defaultProps = {};

export default AnnualVehicle3Edit;
