import React,{history,useEffect, useState, useMemo } from "react";
import $, { parseJSON } from 'jquery';
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const ExternalVisualSectionTwo = () => 
{

  // var section1= {
  //   "corrodedOrAbradedAreas":$("input[name='step1area']:checked").val()==="1"?"1":"0",
  //   "dents": $("input[name='step1dents']:checked").val()==="1"?"1":"0",
  //   "distortions":$("input[name='step1distortion']:checked").val()==="1"?"1":"0",
  //   "defects":$("input[name='step1defects']:checked").val()==="1"?"1":"0",
  //   // "defectsInWelds":$("input[name='step1defects']:checked").val()==="1"?"1":"0",
  //   "leaksOfAnyKind":$("input[name='step1anykind']:checked").val()==="1"?"1":"0",
  //   "anyConditionThatmightRender":$("input[name='step1unsafe']:checked").val()==="1"?"1":"0",
  //   // "transportationService":$("input[name='step1transportationService']:checked").val()==="1"?"1":"0",
  //   "beingtransported":$("input[name='step1Corrosive']:checked").val()==="1"?"1":"0"
    
  // };
  // console.log(JSON.stringify(section1));

  // sessionStorage.setItem("section1",JSON.stringify(section1));
  // console.log(sessionStorage.getItem(JSON.stringify(section1.corrodedOrAbradedAreas)));

  useEffect(() => {

    scrollTop();
    getSection2();

  }, []);

  function getSection2() {

    JSON.parse(sessionStorage.getItem("section2"));

    console.log("ddd" + JSON.parse(sessionStorage.getItem("section2")));

    var obj = JSON.parse(sessionStorage.getItem("section2"))
    console.log(obj == null ? '' : obj.corrodedOrAbradedAreas);

    // setSection1(obj);
    // console.log("section Data" + JSON.stringify(section1));

    var corroded = obj == null ? '' : obj.corroded;
    var welds = obj == null ? '' : obj.welds;
    var gaskets = obj == null ? '' : obj.gaskets;
    var defectmanifold = obj == null ? '' : obj.defectmanifold;
    var defectindustCap = obj == null ? '' : obj.defectindustCap;
    var defectinsafety = obj == null ? '' : obj.defectinsafety;
    var defectinremoteControl = obj == null ? '' : obj.defectinremoteControl;
    var defectfusibleLink = obj == null ? '' : obj.defectfusibleLink;
    var defectinpumpOffline = obj == null ? '' : obj.defectinpumpOffline;
    var leaksOfAnyKind = obj == null ? '' : obj.leaksOfAnyKind;
    var transportionService = obj == null ? '' : obj.transportionService;

    $('input[name="step2areas"][value="' + corroded + '"]').trigger("click");
    $('input[name="step2Welds"][value="' + welds + '"]').trigger("click");
    $('input[name="step2gaskets"][value="' + gaskets + '"]').trigger("click");
    $('input[name="step2manifold"][value="' + defectmanifold + '"]').trigger("click");
    $('input[name="step2dust"][value="' + defectindustCap + '"]').trigger("click");
    $('input[name="step2safety"][value="' + defectinsafety + '"]').trigger("click");
    $('input[name="step2remoteControl"][value="' + defectinremoteControl + '"]').trigger("click");
    $('input[name="step2linkplug"][value="' + defectfusibleLink + '"]').trigger("click");
    $('input[name="step2offlinepump"][value="' + defectinpumpOffline + '"]').trigger("click");
    $('input[name="step2leaksanyKind"][value="' + leaksOfAnyKind + '"]').trigger("click");
    $('input[name="step2Transportation"][value="' + transportionService + '"]').trigger("click");

  }

  const sectionSubmit = () => {
    var section2= {
      "corroded": $("input[name='step2areas']:checked").val()==="1"?"1":"0",
      "welds": $("input[name='step2Welds']:checked").val()==="1"?"1":"0",
      "gaskets":$("input[name='step2gaskets']:checked").val()==="1"?"1":"0",
      "defectmanifold":$("input[name='step2manifold']:checked").val()==="1"?"1":"0",
      "defectindustCap":$("input[name='step2dust']:checked").val()==="1"?"1":"0",
      "defectinsafety":$("input[name='step2safety']:checked").val()==="1"?"1":"0",
      "defectinremoteControl":$("input[name='step2remoteControl']:checked").val()==="1"?"1":"0",
      "defectfusibleLink":$("input[name='step2linkplug']:checked").val()==="1"?"1":"0",
      // "defectinremoteControl":$("input[name='step2remoteControl']:checked").val()==="on"?"1":"0",
      "defectinpumpOffline":$("input[name='step2offlinepump']:checked").val()==="1"?"1":"0",
      "leaksOfAnyKind":$("input[name='step2leaksanyKind']:checked").val()==="1"?"1":"0",
      "transportionService":$("input[name='step2Transportation']:checked").val()==="1"?"1":"0",  
  };
  console.log(JSON.stringify(section2));

  sessionStorage.setItem("section2",JSON.stringify(section2));

  }


  const handleSubmit=()=>
  {

let form_is_valid = true;

var step2areas=$("input[name='step2areas']:checked").val();
var step2Welds=$("input[name='step2Welds']:checked").val();
var step2gaskets=$("input[name='step2gaskets']:checked").val();
var step2manifold=$("input[name='step2manifold']:checked").val();
var step2dust=$("input[name='step2dust']:checked").val();
var step2safety=$("input[name='step2safety']:checked").val();
var step2remoteControl=$("input[name='step2remoteControl']:checked").val();
var step2linkplug=$("input[name='step2linkplug']:checked").val();
var step2offlinepump=$("input[name='step2offlinepump']:checked").val();
var step2leaksanyKind=$("input[name='step2leaksanyKind']:checked").val();
var step2Transportation=$("input[name='step2Transportation']:checked").val();

if(step2areas==undefined||step2Welds==undefined||step2gaskets==undefined||step2manifold==undefined||step2dust==undefined||step2safety==undefined||step2remoteControl==undefined||step2linkplug==undefined||step2offlinepump==undefined||step2leaksanyKind==undefined||step2Transportation==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
// alert(form_is_valid);
if(form_is_valid==true){
$("#section3").trigger('click');
}

return form_is_valid;

}

  return(
  <>


                        <div class="card">

                          {/* <div class="divider"></div> */}
                            <div class="row px-3">
                            <div class="w-100">
                              <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
                              <p id="error_message"></p>
                            </div>
                           <WorkOrderNumberDiv />
                                <div class="col-lg-12 px-0">
                                    
                                    <div class="inspection-wrap">

                                        <div class="step-wrap p-3 mb-3">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Tank Shell</div>
                                                 </div>
      
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 current-tick">2</div>
                                                     <div class="labl-section">External Piping</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">3</div>
                                                     <div class="labl-section">Spill Collar</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">4</div>
                                                     <div class="labl-section">Emergency Devices</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">5</div>
                                                     <div class="labl-section">Bolts</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">6</div>
                                                     <div class="labl-section">Vehicle Condition</div>
                                                 </div>
                                             </div>
                                        </div>

                                        <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 2: External Piping / Valves are there any :</th>                                                 
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>                                              
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>Corroded or abraded areas</td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2areas" value="1" onChange={() => sectionSubmit()}/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2areas" value="0" onChange={() => sectionSubmit()}/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>Defects in Welds  </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2Welds" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2Welds" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in valves or gaskets </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2gaskets" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2gaskets" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in manifold</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2manifold" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2manifold" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in dust cap </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2dust" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2dust" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in safety valve operator </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2safety" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2safety" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>    

                                                   <tr>
                                                    <td>Defects in remote control  </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2remoteControl" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2remoteControl" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>Defects in fusible link/plug </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2linkplug" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2linkplug" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Defects in pump offline</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2offlinepump" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2offlinepump" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>Leaks of any kind</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2leaksanyKind" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2leaksanyKind" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   {/* <tr>
                                                    <td>Any condition that might render tank unsafe for</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2tankunsafe" /></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2tankunsafe"/></td>
                                                   </tr>  */}

                                                   <tr>
                                                    <td>Any condition that might render tank unsafe for transportation service </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2Transportation" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step2Transportation" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  
                                                </tbody>
                                              </table>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>


                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                                <a id="section1" class="btn btn-secondary mr-1 ">Back</a>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("draft")}>Cancel </a> */}
                                <a id="section3" class="btn btn-primary mr-1" style={{display:"none"}}>Next</a>
                                <a  class="btn btn-primary mr-1"  onClick={() => handleSubmit()}>Next</a>
                            </div>
                           </div>
                           
  </>
)};

ExternalVisualSectionTwo.propTypes = {};

ExternalVisualSectionTwo.defaultProps = {};

export default ExternalVisualSectionTwo;