import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
const vehicle_api=(customerId,pageNumber,ITEMS_PER_PAGE,vehicleId,pageSize,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortBy,sortDir,displayItems,activeStatus)=>
{
  
   /////////////get vechile list//////////////////////////
     var displaypages=[];
     var vechileReq="";
     var vechileReq="";
     
     if(sortBy==="Vin number")
     {

      sortBy="vinNumber";
     }
     else if(sortBy==="Unit number")
     {
      sortBy="unitNumber";
     } else if(sortBy==="Vehicle number")
     {

      sortBy="lisencePlateNumber";
     }
     else if(sortBy==="Vehicle type")
     {
      sortBy="vehicleTypeName";
     } else if(sortBy==="Customer Name")
     {
      sortBy="customerName";
     }
     else if(sortBy==="Email")
     {
      sortBy="customerEmail";
     }
    
     if(customerId=="")
     {
     
             vechileReq=
            {
                  
                  "lisencePlateNumber":vehicleId.trim(),
                  "pageNumber" : pageNumber,
                  "vehicleTypeId":"",
                  "pageSize": pageSize,
                  "sortBy":sortBy,
                  "sortDir":sortDir,
                  "isActive" :activeStatus, 
            
            };
     }else
	 {
  
	     vechileReq=
		  {
				
				"fkCustomerId":customerId,
				"lisencePlateNumber":vehicleId.trim(),
				"pageNumber" : pageNumber,
				"pageSize": pageSize,
				"sortBy":sortBy,
				"sortDir":sortDir,
        "isActive" :activeStatus, 
		  }
	 }
     

      //console.log("results="+JSON.stringify(vechileReq));

      // const requestOptions = 
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vechileReq)
      // };
      var requestOptions=requestObject.RequestHeader(vechileReq);

      services.vechile_list(requestOptions).then((res) => 
      {

            $("#loading").hide();

            if(res.success)
            {
              if(res.rows.length>0)
              {
                setTotalItems(res.rows.length>0?res.customValues.totalNumberOfRecords:"0");
                
                settotalPages(res.rows.length>0?res.customValues.totalNumberOfRecords:"0");
              
                setCurrentPage(res.rows.length>0?res.customValues.currentPageNumber:1);
                setVehicleList(res.rows.length>0?res.rows:"");
                setTotalRecord(res.rows.length>0?res.customValues.totalNumberOfRecords:"0");
              
                if(displayItems==="diplayItems")
                {
                  for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
                  {
                    displaypages.push(i*ITEMS_PER_PAGE);
                  }
                  
                  setdisplayPage(res.rows.length>0?displaypages:[0]);

                }
              }else
              {

                setTotalItems("0");
                
                settotalPages("0");
              
                setCurrentPage(1);
                setVehicleList("");
                setTotalRecord("0");
                setdisplayPage([0]);
               
                  
              }
           
            }else{
            
          
              setdisplayPage([0]);
              setTotalItems(0);
            
              settotalPages("1");
             
              setCurrentPage("1");
              setVehicleList("");
              setTotalRecord(0);
            
             
            }
         
      });
   
   



}

export default vehicle_api;