import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import services from "../../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import EditExternalVisualSectionOne from "./edit-external-visual-section-one";
import EditExternalVisualSectionTwo from "./edit-external-visual-section-two";
import EditExternalVisualSectionThree from "./edit-external-visual-section-three";
import EditExternalVisualSectionFour from "./edit-external-visual-section-four";
import EditExternalVisualSectionFive from "./edit-external-visual-section-five";
import EditExternalVisualSectionSix from "./edit-external-visual-section-six";


const EditExternalVisual = () => {

  const { taskOrderId, fkAccountId, cid, vid } = useParams();
  const navigate = useNavigate();
  const [inspectionType, setInspectionType] = useState();
  const [inspectionTypes, setInspectionTypes] = useState();
  const [inspectionSteps, setInspectionSteps] = useState("section1");
  const [itemName, setFieldName] = useState('');
  var vehicleType = "";
  useEffect(() => {
    // setInspectionAnnualSteps("annualVehicle2");

    getInspectionType();
    if (CheckAuthentication) {
      localStorage.setItem("customerId", cid);
      localStorage.setItem("vehicleId", vid);


    }
    else {
      navigate("/login");
    }
    $(document).ready(function () {
      console.log("ready!");
    });

    ///inspection type check///////////////////////////////
    $('#inspectionType').on('change', function () {

      const res = this.value.replace(/ /g, '');

      if (res.match("External/Visual Inspection")) {

        setInspectionTypes("External/Visual Inspection");
      }
      else {
        setInspectionTypes(this.value.toString().trim());

      }

    });

  }, []);

  const redirect = (page) => {

    navigate(page);

  }
  const getInspectionType = () => {


    var vehicle_request =
    {

    };

    //   const requestOptions = 
    //   {
    //         method: 'POST',
    //         headers:{'Content-Type': 'application/json'},
    //         body: JSON.stringify(vehicle_request)
    //   };

    var requestOptions = requestObject.RequestHeader(vehicle_request);
    //  vehicleType=document.getElementById("vehicleType").innerHTML;

    services.getInspectionTypeS(requestOptions).then((res) => {
      console.log("results=" + JSON.stringify(res.rows));

      setInspectionType(res.rows);

    });
  }


  $(document).on("click", "#section1", function () {
    setInspectionSteps("section1");
  });

  $(document).on("click", "#section2", function () {
    setInspectionSteps("section2");
  });

  $(document).on("click", "#section3", function () {
    setInspectionSteps("section3");
  });

  $(document).on("click", "#section4", function () {
    setInspectionSteps("section4");
  });


  $(document).on("click", "#section5", function () {
    setInspectionSteps("section5");
  });

  $(document).on("click", "#section6", function () {
    setInspectionSteps("section6");
  });



  return (
    <>
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block" }}>
          <div class="row mb-0">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <div class="d-flex justify-content-between align-items-center my-4">
              <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto ">
              <HeaderBlock cids={fkAccountId} />
            </div>
          </div>



          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <VehicleBasicInfo customerId={vid} />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-11 m-auto">


              <div class="white-color p-3">
                {(() => {
                  if (inspectionSteps == "section1") {
                    return <> <EditExternalVisualSectionOne /></>
                  }
                  else if (inspectionSteps == "section2") {
                    return <><EditExternalVisualSectionTwo /></>
                  }
                  else if (inspectionSteps === "section3") {
                    return <><EditExternalVisualSectionThree /></>
                  }
                  else if (inspectionSteps === "section4") {
                    return <><EditExternalVisualSectionFour /></>
                  }
                  else if (inspectionSteps === "section5") {
                    return <><EditExternalVisualSectionFive /></>
                  }
                  else if (inspectionSteps === "section6") {
                    return <><EditExternalVisualSectionSix /></>
                  }
                  else {



                  }

                })()
                }
              </div>

            </div>

          </div>
        </div>



      </div>
    </>
  )
};

EditExternalVisual.propTypes = {};

EditExternalVisual.defaultProps = {};

export default EditExternalVisual;
