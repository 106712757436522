import React,{history,useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
const SignupGarage = () => {

    // const [itemName, setFieldName] = useState('');
    const navigate = useNavigate();
   const {
     register,
     formState: { errors },
     handleSubmit
   } = useForm({
     criteriaMode: "all",mode: "onBlur" 
   });
   const submitValue=()=>
   {
        var emailId=$('#email').val();
        // alert(emailId);
    if(emailId=="admin@gmail.com"){
        navigate('/vehicles');
        window.location.reload();
    }else if(emailId=="employee@gmail.com"){
        navigate('/fleetowner/vehicle');
        window.location.reload();
    }else{
        navigate('/login');
    }
    
     
   }

  return(
      <>
   <div class="login-wrap">
        <div class="login-item login-img">
            <div class="register-title">
                <h2>
                    {/* <!-- Your Next Step for Business <span class="freet">Explore.</span> --> */}
                    Your Next step Helps to <br/> Business <span class="freet">Expansion</span>.
                </h2>
            </div>
            <div class="login-logo">
                <img src="/images/dotstow-logo.svg" alt=""/>
            </div>
            <img src="/images/garage.jpg" alt=""/>
        </div>
        <div class="login-item ">
            <div class="login-logo">
                <img src="images/dotstow-logo.svg" alt=""/>
            </div>
            <div class="text-center">
                <h3 class="m-auto pt-3 pb-1">Log in to DOTStow</h3>
                <p>Don't have an account? Start a <b><a href="#" class="freet">FREE TRIAL</a></b> </p>
            </div>

            <form action="">
                <div class="form-group">
                    <label for="exampleInputEmail1">User Name <span class="star">*</span></label>
                    <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">Company Name <span class="star">*</span></label>
                    <input type="email" class="form-control custome-input" id="exampleInput Email1" aria-describedby="emailHelp"/>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">Email Address <span class="star">*</span></label>
                    <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">Phone Number <span class="star">*</span></label>
                    <input type="tel" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>

                {/* <!-- <small>
                    How many vehicles, trailers and/or heavy equipment (e.g. bulldozers, skid steers, etc.) are in your fleet?
                </small> --> */}

                <div class="form-group mt-2">
                    <label for="exampleInputEmail1">Number of Technicians: <span class="star">*</span></label>
                    <select class="form-control custome-select" id="exampleFormControlSelect1">
                        <option selected>Select</option>
                        <option value="1">Lessthan 10 </option>
                        <option value="2">10 to 50</option>
                        <option value="3">50 to 100</option>
                        <option value="3">100 and above</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">Number of Technicians: <span class="star">*</span></label>
                    <input type="tel" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>



                <div class="mt-3">
                    <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">SUBMIT</a>
                </div>
            </form>

        </div>
    </div>
  </>
)};

SignupGarage.propTypes = {};

SignupGarage.defaultProps = {};

export default SignupGarage;
