import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../utility/requestObject";
import ListOfContacts from "../customer/CustomerContactList";
const vehicle_details_api = (id,setVehicleDetails,setCustomerId,setLoading,setCustomerContactList) => 
{

  
   var vehicle_request=
   {
             
             "id":id
          
   };
  
   // const requestOptions = 
   // {
   //       method: 'POST',
   //       headers:{'Content-Type': 'application/json'},
   //       body: JSON.stringify(vehicle_request)
   // };
   var requestOptions=requestObject.RequestHeader(vehicle_request);

   services.getVehicle_Details(requestOptions).then((res) => 
   {
      
      $("#loading").hide();
  //  alert(res.customValues.vehicleImageFileByte);
      // $("#vehicleImage").attr("src",res.customValues.vehicleImageFileByte?"data:image/png;base64,"+res.customValues.vehicleImageFileByte:"/images/transport-logo.jpg");
      if(res.customValues)
      {
         $("#vehicleImage").attr("src",res.customValues.vehicleImageFileByte?"data:image/png;base64,"+res.customValues.vehicleImageFileByte:"/images/no-vehicle-image.png");
         // $("#posterURL").attr("src",res.customValues?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg");
         
         $("#customerImage").attr("src",res.customValues.customerLogoFileByte?"data:image/png;base64,"+res.customValues.customerLogoFileByte:"/images/transport-logo.jpg");
      }else{
         
      }
      setVehicleDetails(res.rows);
      
      //sessionStorage.setItem("loggedinUser",res.rows?res.rows.createUser:"");
      
      // console.log("customer ID"+res.rows.fkCustomerId)
      
      if(setCustomerContactList==="dontcallcontactsAPI"||setCustomerContactList==undefined||setCustomerContactList=="undefined")
      {
          
      }else
      {
         ListOfContacts(res.rows.fkCustomerId, setCustomerContactList);
      }
      
      localStorage.setItem("customerId",res.rows.fkCustomerId);
      localStorage.setItem("vehicleId",id);
      sessionStorage.setItem("tankerSubTypeName",res.rows.trailerSubTypeName);
      // sessionStorage.setItem("tankerSubTypeName",res.rows.tankerSubTypeName);
      sessionStorage.setItem("vehicleTypeName",res.rows.vehicleTypeName);
      sessionStorage.setItem("nofcompartments",res.rows.numberOfCompartments);
      sessionStorage.setItem("compartmentCapacityInGallons",res.rows.compartmentCapacityInGallons);
      sessionStorage.setItem("isInstalledWithVaporCollectionSys",res.rows.isInstalledWithVaporCollectionSys);
      // sessionStorage.setItem("isInstalledWithVaporCollectionSys",res.rows.isInstalledWithVaporCollectionSys);
 
      try
      {
         setCustomerId(res.rows.fkCustomerId);
         setLoading(true);
      }catch(Exception)
      {

      }
      
      
   });
      
}

export default vehicle_details_api;