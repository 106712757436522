// import React,{history,useEffect, useState, useMemo } from "react";
// import {useNavigate , useParams } from "react-router-dom";
// import { keycloak } from "./config";
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
var token=cookies.get("_AK");

var validate=false;
if(token !==null) 
{
   validate=true;

}else{
 
}
const CheckAuthentication=validate;
export default CheckAuthentication;