import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";

const AnnualVehicle4Edit = () => {

    const { fkAccountId, inspectionId,vid } = useParams();
    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [ fileName, setfileName ] = useState("");
    const [ inspectionDetails, setInspectionDetails ] = useState("");
    const [ documentList, setDocumentLists ] = useState([]);
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [designpressure, setDesignPressure] = useState();
    const [certify, setApproveCertify] = useState();
    var documentLists=[];

   const [step4_wheelDate, setStep4_wheelDate] = useState(null);
   const [step4_columnDate, setStep4_columnDate] = useState(null);
   const [step4_frontDate, setStep4_frontDate] = useState(null);
   const [step4_gearDate, setStep4_gearDate] = useState(null);
   const [step4_pitmanDate, setStep4_pitmanDate] = useState(null);
   const [step4_powerDate, setStep4_powerDate] = useState(null);
   const [step4_ballDate, setStep4_ballDate] = useState(null);
   const [step4_tieDate, setStep4_tieDate] = useState(null);
   const [step4_nutsDate, setStep4_nutsDate] = useState(null);
   const [step4_systemDate, setStep4_systemDate] = useState(null);
   const [step4_positioningDate, setStep4_positioningDate] = useState(null);
   const [step4_assemblyDate, setStep4_assemblyDate] = useState(null);
   const [step4_componentsDate, setStep4_componentsDate] = useState(null);
   const [step4_membersDate, setStep4_membersDate] = useState(null);
   const [step4_clearanceDate, setStep4_clearanceDate] = useState(null);
   const [step4_assembliesDate, setStep4_assembliesDate] = useState(null);
   const [step4_powerUnitDate, setStep4_powerUnitDate] = useState(null);
   const [step4_motorCarrierDate, setStep4_motorCarrierDate] = useState(null);
   const [step4_allOtherDate, setStep4_allOtherDate] = useState(null);


   useEffect(() => {
      if(CheckAuthentication) 
      {
            $("input[name='step4_wheel_repaired']").attr('disabled', 'true');
            $("input[name='step4_wheelDate']").hide();
            $("input[name='step4_wheelDateTemp']").attr('disabled', 'true');

            $("input[name='step4_column_repaired']").attr('disabled', 'true');
            $("input[name='step4_columnDate']").hide();
            $("input[name='step4_columnDateTemp']").attr('disabled', 'true');

            $("input[name='step4_front_repaired']").attr('disabled', 'true');
            $("input[name='step4_frontDate']").hide();
            $("input[name='step4_frontDateTemp']").attr('disabled', 'true');

            $("input[name='step4_gear_repaired']").attr('disabled', 'true');
            $("input[name='step4_gearDate']").hide();
            $("input[name='step4_gearDateTemp']").attr('disabled', 'true');

            $("input[name='step4_pitman_repaired']").attr('disabled', 'true');
            $("input[name='step4_pitmanDate']").hide();
            $("input[name='step4_pitmanDateTemp']").attr('disabled', 'true');

            $("input[name='step4_power_repaired']").attr('disabled', 'true');
            $("input[name='step4_powerDate']").hide();
            $("input[name='step4_powerDateTemp']").attr('disabled', 'true');

            $("input[name='step4_ball_repaired']").attr('disabled', 'true');
            $("input[name='step4_ballDate']").hide();
            $("input[name='step4_ballDateTemp']").attr('disabled', 'true');

            $("input[name='step4_tie_repaired']").attr('disabled', 'true');
            $("input[name='step4_tieDate']").hide();
            $("input[name='step4_tieDateTemp']").attr('disabled', 'true');

            $("input[name='step4_nuts_repaired']").attr('disabled', 'true');
            $("input[name='step4_nutsDate']").hide();
            $("input[name='step4_nutsDateTemp']").attr('disabled', 'true');

            $("input[name='step4_system_repaired']").attr('disabled', 'true');
            $("input[name='step4_systemDate']").hide();
            $("input[name='step4_systemDateTemp']").attr('disabled', 'true');


            $("input[name='step4_positioning_repaired']").attr('disabled', 'true');
            $("input[name='step4_positioningDate']").hide();
            $("input[name='step4_positioningDateTemp']").attr('disabled', 'true');

            $("input[name='step4_assembly_repaired']").attr('disabled', 'true');
            $("input[name='step4_assemblyDate']").hide();
            $("input[name='step4_assemblyDateTemp']").attr('disabled', 'true');

            $("input[name='step4_components_repaired']").attr('disabled', 'true');
            $("input[name='step4_componentsDate']").hide();
            $("input[name='step4_componentsDateTemp']").attr('disabled', 'true');

            $("input[name='step4_members_repaired']").attr('disabled', 'true');
            $("input[name='step4_membersDate']").hide();
            $("input[name='step4_membersDateTemp']").attr('disabled', 'true');

            $("input[name='step4_clearance_repaired']").attr('disabled', 'true');
            $("input[name='step4_clearanceDate']").hide();
            $("input[name='step4_clearanceDateTemp']").attr('disabled', 'true');

            $("input[name='step4_assemblies_repaired']").attr('disabled', 'true');
            $("input[name='step4_assembliesDate']").hide();
            $("input[name='step4_assembliesDateTemp']").attr('disabled', 'true');

            $("input[name='step4_powerUnit_repaired']").attr('disabled', 'true');
            $("input[name='step4_powerUnitDate']").hide();
            $("input[name='step4_powerUnitDateTemp']").attr('disabled', 'true');

            $("input[name='step4_allOther_repaired']").attr('disabled', 'true');
            $("input[name='step4_allOtherDate']").hide();
            $("input[name='step4_allOtherDateTemp']").attr('disabled', 'true');

            $("input[name='step4_motorCarrier_repaired']").attr('disabled', 'true');
            $("input[name='step4_motorCarrierDate']").hide();
            $("input[name='step4_motorCarrierDateTemp']").attr('disabled', 'true');
            scrollTop();
            getSection4();
            getInpection(fkAccountId,inspectionId);
           
      }
      else{
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
          window.location.href="/login";
      }
   }, []);

   function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {

         const workOrderNumber=sessionStorage.getItem("workOrderNumber");
         if(workOrderNumber==null)
         {
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"")
         }else{
         sessionStorage.setItem("workOrderNumber",workOrderNumber);
         }
        
         setInspectionDetails(res.rows);
         console.log("Annual vehicle inspection"+JSON.stringify(res.rows));

       var  STEERING=res.rows.annualVehicleInspection.steeringMechanism;
       var  SUSPENSION=res.rows.annualVehicleInspection.suspension;
       var  FRAME=res.rows.annualVehicleInspection.frame;
       var  TIRES=res.rows.annualVehicleInspection.tires;

       var step4_wheelNF = STEERING == null ? '' : STEERING.step4_wheelNF;
       var step4_wheel = STEERING == null ? '' : STEERING.step4_wheel;
       var step4_wheelDate = STEERING == null ? '' : STEERING.step4_wheelDate;
 
       $('input[name="step4_wheel_na"][value="' + step4_wheelNF + '"]').trigger("click");
       $('input[name="step4_wheel_repaired"][value="' + step4_wheel + '"]').trigger("click").prop('checked', true);
 
 
 
       var step4_columnNF = STEERING == null ? '' : STEERING.step4_columnNF;
       var step4_column = STEERING == null ? '' : STEERING.step4_column;
       var step4_columnDate = STEERING == null ? '' : STEERING.step4_columnDate;
 
       $('input[name="step4_column_na"][value="' + step4_columnNF + '"]').trigger("click");
       $('input[name="step4_column_repaired"][value="' + step4_column + '"]').trigger("click").prop('checked', true);
 
 
       var step4_frontNF = STEERING == null ? '' : STEERING.step4_frontNF;
       var step4_front = STEERING == null ? '' : STEERING.step4_front;
       var step4_frontDate = STEERING == null ? '' : STEERING.step4_frontDate;
 
       $('input[name="step4_front_na"][value="' + step4_frontNF + '"]').trigger("click");
       $('input[name="step4_front_repaired"][value="' + step4_front + '"]').trigger("click").prop('checked', true);
 
 
       var step4_gearNF = STEERING == null ? '' : STEERING.step4_gearNF;
       var step4_gear = STEERING == null ? '' : STEERING.step4_gear;
       var step4_gearDate = STEERING == null ? '' : STEERING.step4_gearDate;
 
       $('input[name="step4_gear_na"][value="' + step4_gearNF + '"]').trigger("click");
       $('input[name="step4_gear_repaired"][value="' + step4_gear + '"]').trigger("click").prop('checked', true);
 
 
       var step4_pitmanNF = STEERING == null ? '' : STEERING.step4_pitmanNF;
       var step4_pitman = STEERING == null ? '' : STEERING.step4_pitman;
       var step4_pitmanDate = STEERING == null ? '' : STEERING.step4_pitmanDate;
 
       $('input[name="step4_pitman_na"][value="' + step4_pitmanNF + '"]').trigger("click");
       $('input[name="step4_pitman_repaired"][value="' + step4_pitman + '"]').trigger("click").prop('checked', true);
 
 
       var step4_powerNF = STEERING == null ? '' : STEERING.step4_powerNF;
       var step4_power = STEERING == null ? '' : STEERING.step4_power;
       var step4_powerDate = STEERING == null ? '' : STEERING.step4_powerDate;
 
       $('input[name="step4_power_na"][value="' + step4_powerNF + '"]').trigger("click");
       $('input[name="step4_power_repaired"][value="' + step4_power + '"]').trigger("click").prop('checked', true);
 
 
       var step4_ballNF = STEERING == null ? '' : STEERING.step4_ballNF;
       var step4_ball = STEERING == null ? '' : STEERING.step4_ball;
       var step4_ballDate = STEERING == null ? '' : STEERING.step4_ballDate;
 
       $('input[name="step4_ball_na"][value="' + step4_ballNF + '"]').trigger("click");
       $('input[name="step4_ball_repaired"][value="' + step4_ball + '"]').trigger("click").prop('checked', true);
 
 
 
       var step4_tieNF = STEERING == null ? '' : STEERING.step4_tieNF;
       var step4_tie = STEERING == null ? '' : STEERING.step4_tie;
       var step4_tieDate = STEERING == null ? '' : STEERING.step4_tieDate;
 
       $('input[name="step4_tie_na"][value="' + step4_tieNF + '"]').trigger("click");
       $('input[name="step4_tie_repaired"][value="' + step4_tie + '"]').trigger("click").prop('checked', true);
 
 
 
       var step4_nutsNF = STEERING == null ? '' : STEERING.step4_nutsNF;
       var step4_nuts = STEERING == null ? '' : STEERING.step4_nuts;
       var step4_nutsDate = STEERING == null ? '' : STEERING.step4_nutsDate;
 
       $('input[name="step4_nuts_na"][value="' + step4_nutsNF + '"]').trigger("click");
       $('input[name="step4_nuts_repaired"][value="' + step4_nuts + '"]').trigger("click").prop('checked', true);
 
       var step4_systemNF = STEERING == null ? '' : STEERING.step4_systemNF;
       var step4_system = STEERING == null ? '' : STEERING.step4_system;
       var step4_systemDate = STEERING == null ? '' : STEERING.step4_systemDate;
 
       $('input[name="step4_system_na"][value="' + step4_systemNF + '"]').trigger("click");
       $('input[name="step4_system_repaired"][value="' + step4_system + '"]').trigger("click").prop('checked', true);
 
 
 
       var step4_positioningNF = SUSPENSION == null ? '' : SUSPENSION.step4_positioningNF;
       var step4_positioning = SUSPENSION == null ? '' : SUSPENSION.step4_positioning;
       var step4_positioningDate = SUSPENSION == null ? '' : SUSPENSION.step4_positioningDate;
 
       $('input[name="step4_positioning_na"][value="' + step4_positioningNF + '"]').trigger("click");
       $('input[name="step4_positioning_repaired"][value="' + step4_positioning + '"]').trigger("click").prop('checked', true);
 
 
       var step4_assemblyNF = SUSPENSION == null ? '' : SUSPENSION.step4_assemblyNF;
       var step4_assembly = SUSPENSION == null ? '' : SUSPENSION.step4_assembly;
       var step4_assemblyDate = SUSPENSION == null ? '' : SUSPENSION.step4_assemblyDate;
 
       $('input[name="step4_assembly_na"][value="' + step4_assemblyNF + '"]').trigger("click");
       $('input[name="step4_assembly_repaired"][value="' + step4_assembly + '"]').trigger("click").prop('checked', true);
 
 
       var step4_componentsNF = SUSPENSION == null ? '' : SUSPENSION.step4_componentsNF;
       var step4_components = SUSPENSION == null ? '' : SUSPENSION.step4_components;
       var step4_componentsDate = SUSPENSION == null ? '' : SUSPENSION.step4_componentsDate;
 
       $('input[name="step4_components_na"][value="' + step4_componentsNF + '"]').trigger("click");
       $('input[name="step4_components_repaired"][value="' + step4_components + '"]').trigger("click").prop('checked', true);
 
 
 
 
       var step4_membersNF = FRAME == null ? '' : FRAME.step4_membersNF;
       var step4_members = FRAME == null ? '' : FRAME.step4_members;
       var step4_membersDate = FRAME == null ? '' : FRAME.step4_membersDate;
 
       $('input[name="step4_members_na"][value="' + step4_membersNF + '"]').trigger("click");
       $('input[name="step4_members_repaired"][value="' + step4_members + '"]').trigger("click").prop('checked', true);
 
 
       var step4_clearanceNF = FRAME == null ? '' : FRAME.step4_clearanceNF;
       var step4_clearance = FRAME == null ? '' : FRAME.step4_clearance;
       var step4_clearanceDate = FRAME == null ? '' : FRAME.step4_clearanceDate;
 
       $('input[name="step4_clearance_na"][value="' + step4_clearanceNF + '"]').trigger("click");
       $('input[name="step4_clearance_repaired"][value="' + step4_clearance + '"]').trigger("click").prop('checked', true);
 
 
       var step4_assembliesNF = FRAME == null ? '' : FRAME.step4_assembliesNF;
       var step4_assemblies = FRAME == null ? '' : FRAME.step4_assemblies;
       var step4_assembliesDate = FRAME == null ? '' : FRAME.step4_assembliesDate;
 
       $('input[name="step4_assemblies_na"][value="' + step4_assembliesNF + '"]').trigger("click");
       $('input[name="step4_assemblies_repaired"][value="' + step4_assemblies + '"]').trigger("click").prop('checked', true);
 
 
 
 
 
 
       var step4_powerUnitNF = TIRES == null ? '' : TIRES.step4_powerUnitNF;
       var step4_powerUnit = TIRES == null ? '' : TIRES.step4_powerUnit;
       var step4_powerUnitDate = TIRES == null ? '' : TIRES.step4_powerUnitDate;
 
       $('input[name="step4_powerUnit_na"][value="' + step4_powerUnitNF + '"]').trigger("click");
       $('input[name="step4_powerUnit_repaired"][value="' + step4_powerUnit + '"]').trigger("click").prop('checked', true);
 
 
       var step4_allOtherNF = TIRES == null ? '' : TIRES.step4_allOtherNF;
       var step4_allOther = TIRES == null ? '' : TIRES.step4_allOther;
       var step4_allOtherDate = TIRES == null ? '' : TIRES.step4_allOtherDate;
 
       $('input[name="step4_allOther_na"][value="' + step4_allOtherNF + '"]').trigger("click");
       $('input[name="step4_allOther_repaired"][value="' + step4_allOther + '"]').trigger("click").prop('checked', true);
 
 
       var step4_motorCarrierNF = TIRES == null ? '' : TIRES.step4_motorCarrierNF;
       var step4_motorCarrier = TIRES == null ? '' : TIRES.step4_motorCarrier;
       var step4_motorCarrierDate = TIRES == null ? '' : TIRES.step4_motorCarrierDate;
 
       $('input[name="step4_motorCarrier_na"][value="' + step4_motorCarrierNF + '"]').trigger("click");
       $('input[name="step4_motorCarrier_repaired"][value="' + step4_motorCarrier + '"]').trigger("click").prop('checked', true);
 
 
       if (step4_wheelNF || step4_columnNF || step4_frontNF || step4_gearNF || step4_pitmanNF || step4_powerNF || step4_ballNF || step4_tieNF || step4_nutsNF || step4_systemNF || step4_positioningNF || step4_assemblyNF || step4_componentsNF || step4_membersNF || step4_clearanceNF || step4_assembliesNF || step4_powerUnitNF || step4_allOtherNF || step4_motorCarrierNF == 'verified') {
          checkVerified();
 
          $("#step4_wheelDate").val(step4_wheelDate);
          $("#step4_columnDate").val(step4_columnDate);
          $("#step4_frontDate").val(step4_frontDate);
          $("#step4_gearDate").val(step4_gearDate);
          $("#step4_pitmanDate").val(step4_pitmanDate);
          $("#step4_powerDate").val(step4_powerDate);
          $("#step4_ballDate").val(step4_ballDate);
          $("#step4_tieDate").val(step4_tieDate);
          $("#step4_nutsDate").val(step4_nutsDate);
          $("#step4_systemDate").val(step4_systemDate);
          $("#step4_positioningDate").val(step4_positioningDate);
          $("#step4_assemblyDate").val(step4_assemblyDate);
          $("#step4_componentsDate").val(step4_componentsDate);
          $("#step4_membersDate").val(step4_membersDate);
          $("#step4_clearanceDate").val(step4_clearanceDate);
          $("#step4_assembliesDate").val(step4_assembliesDate);
          $("#step4_powerUnitDate").val(step4_powerUnitDate);
          $("#step4_allOtherDate").val(step4_allOtherDate);
          $("#step4_motorCarrierDate").val(step4_motorCarrierDate);

   setStep4_wheelDate(step4_wheelDate?new Date(step4_wheelDate):'');
   setStep4_columnDate(step4_columnDate?new Date(step4_columnDate):'');
   setStep4_frontDate(step4_frontDate?new Date(step4_frontDate):'');
   setStep4_gearDate(step4_gearDate?new Date(step4_gearDate):'');
   setStep4_pitmanDate(step4_pitmanDate?new Date(step4_pitmanDate):'');
   setStep4_powerDate(step4_powerDate?new Date(step4_powerDate):'');
   setStep4_ballDate(step4_ballDate?new Date(step4_ballDate):'');
   setStep4_tieDate(step4_tieDate?new Date(step4_tieDate):'');
   setStep4_nutsDate(step4_nutsDate?new Date(step4_nutsDate):'');
   setStep4_systemDate(step4_systemDate?new Date(step4_systemDate):'');
   setStep4_positioningDate(step4_positioningDate?new Date(step4_positioningDate):'');
   setStep4_assemblyDate(step4_assemblyDate?new Date(step4_assemblyDate):'');
   setStep4_componentsDate(step4_componentsDate?new Date(step4_componentsDate):'');
   setStep4_membersDate(step4_membersDate?new Date(step4_membersDate):'');
   setStep4_clearanceDate(step4_clearanceDate?new Date(step4_clearanceDate):'');
   setStep4_assembliesDate(step4_assembliesDate?new Date(step4_assembliesDate):'');
   setStep4_powerUnitDate(step4_powerUnitDate?new Date(step4_powerUnitDate):'');
   setStep4_motorCarrierDate(step4_motorCarrierDate?new Date(step4_motorCarrierDate):'');
   setStep4_allOtherDate(step4_allOtherDate?new Date(step4_allOtherDate):'');
 
       }
 
      
       
      });
      // handleSubmit();
   }

   function getSection4() {

      // JSON.parse(sessionStorage.getItem("section3Exhaust"));

      // console.log("section3Exhaust" + JSON.parse(sessionStorage.getItem("section3Exhaust")));

      var STEERING = JSON.parse(sessionStorage.getItem("section4STEERING"))
      var SUSPENSION = JSON.parse(sessionStorage.getItem("section4SUSPENSION"))
      var FRAME = JSON.parse(sessionStorage.getItem("section4FRAME"))
      var TIRES = JSON.parse(sessionStorage.getItem("section4TIRES"))



      var step4_wheelNF = STEERING == null ? '' : STEERING.step4_wheelNF;
      var step4_wheel = STEERING == null ? '' : STEERING.step4_wheel;
      var step4_wheelDate = STEERING == null ? '' : STEERING.step4_wheelDate;

      $('input[name="step4_wheel_na"][value="' + step4_wheelNF + '"]').trigger("click");
      $('input[name="step4_wheel_repaired"][value="' + step4_wheel + '"]').trigger("click").prop('checked', true);



      var step4_columnNF = STEERING == null ? '' : STEERING.step4_columnNF;
      var step4_column = STEERING == null ? '' : STEERING.step4_column;
      var step4_columnDate = STEERING == null ? '' : STEERING.step4_columnDate;

      $('input[name="step4_column_na"][value="' + step4_columnNF + '"]').trigger("click");
      $('input[name="step4_column_repaired"][value="' + step4_column + '"]').trigger("click").prop('checked', true);


      var step4_frontNF = STEERING == null ? '' : STEERING.step4_frontNF;
      var step4_front = STEERING == null ? '' : STEERING.step4_front;
      var step4_frontDate = STEERING == null ? '' : STEERING.step4_frontDate;

      $('input[name="step4_front_na"][value="' + step4_frontNF + '"]').trigger("click");
      $('input[name="step4_front_repaired"][value="' + step4_front + '"]').trigger("click").prop('checked', true);


      var step4_gearNF = STEERING == null ? '' : STEERING.step4_gearNF;
      var step4_gear = STEERING == null ? '' : STEERING.step4_gear;
      var step4_gearDate = STEERING == null ? '' : STEERING.step4_gearDate;

      $('input[name="step4_gear_na"][value="' + step4_gearNF + '"]').trigger("click");
      $('input[name="step4_gear_repaired"][value="' + step4_gear + '"]').trigger("click").prop('checked', true);


      var step4_pitmanNF = STEERING == null ? '' : STEERING.step4_pitmanNF;
      var step4_pitman = STEERING == null ? '' : STEERING.step4_pitman;
      var step4_pitmanDate = STEERING == null ? '' : STEERING.step4_pitmanDate;

      $('input[name="step4_pitman_na"][value="' + step4_pitmanNF + '"]').trigger("click");
      $('input[name="step4_pitman_repaired"][value="' + step4_pitman + '"]').trigger("click").prop('checked', true);


      var step4_powerNF = STEERING == null ? '' : STEERING.step4_powerNF;
      var step4_power = STEERING == null ? '' : STEERING.step4_power;
      var step4_powerDate = STEERING == null ? '' : STEERING.step4_powerDate;

      $('input[name="step4_power_na"][value="' + step4_powerNF + '"]').trigger("click");
      $('input[name="step4_power_repaired"][value="' + step4_power + '"]').trigger("click").prop('checked', true);


      var step4_ballNF = STEERING == null ? '' : STEERING.step4_ballNF;
      var step4_ball = STEERING == null ? '' : STEERING.step4_ball;
      var step4_ballDate = STEERING == null ? '' : STEERING.step4_ballDate;

      $('input[name="step4_ball_na"][value="' + step4_ballNF + '"]').trigger("click");
      $('input[name="step4_ball_repaired"][value="' + step4_ball + '"]').trigger("click").prop('checked', true);



      var step4_tieNF = STEERING == null ? '' : STEERING.step4_tieNF;
      var step4_tie = STEERING == null ? '' : STEERING.step4_tie;
      var step4_tieDate = STEERING == null ? '' : STEERING.step4_tieDate;

      $('input[name="step4_tie_na"][value="' + step4_tieNF + '"]').trigger("click");
      $('input[name="step4_tie_repaired"][value="' + step4_tie + '"]').trigger("click").prop('checked', true);



      var step4_nutsNF = STEERING == null ? '' : STEERING.step4_nutsNF;
      var step4_nuts = STEERING == null ? '' : STEERING.step4_nuts;
      var step4_nutsDate = STEERING == null ? '' : STEERING.step4_nutsDate;

      $('input[name="step4_nuts_na"][value="' + step4_nutsNF + '"]').trigger("click");
      $('input[name="step4_nuts_repaired"][value="' + step4_nuts + '"]').trigger("click").prop('checked', true);

      var step4_systemNF = STEERING == null ? '' : STEERING.step4_systemNF;
      var step4_system = STEERING == null ? '' : STEERING.step4_system;
      var step4_systemDate = STEERING == null ? '' : STEERING.step4_systemDate;

      $('input[name="step4_system_na"][value="' + step4_systemNF + '"]').trigger("click");
      $('input[name="step4_system_repaired"][value="' + step4_system + '"]').trigger("click").prop('checked', true);



      var step4_positioningNF = SUSPENSION == null ? '' : SUSPENSION.step4_positioningNF;
      var step4_positioning = SUSPENSION == null ? '' : SUSPENSION.step4_positioning;
      var step4_positioningDate = SUSPENSION == null ? '' : SUSPENSION.step4_positioningDate;

      $('input[name="step4_positioning_na"][value="' + step4_positioningNF + '"]').trigger("click");
      $('input[name="step4_positioning_repaired"][value="' + step4_positioning + '"]').trigger("click").prop('checked', true);


      var step4_assemblyNF = SUSPENSION == null ? '' : SUSPENSION.step4_assemblyNF;
      var step4_assembly = SUSPENSION == null ? '' : SUSPENSION.step4_assembly;
      var step4_assemblyDate = SUSPENSION == null ? '' : SUSPENSION.step4_assemblyDate;

      $('input[name="step4_assembly_na"][value="' + step4_assemblyNF + '"]').trigger("click");
      $('input[name="step4_assembly_repaired"][value="' + step4_assembly + '"]').trigger("click").prop('checked', true);


      var step4_componentsNF = SUSPENSION == null ? '' : SUSPENSION.step4_componentsNF;
      var step4_components = SUSPENSION == null ? '' : SUSPENSION.step4_components;
      var step4_componentsDate = SUSPENSION == null ? '' : SUSPENSION.step4_componentsDate;

      $('input[name="step4_components_na"][value="' + step4_componentsNF + '"]').trigger("click");
      $('input[name="step4_components_repaired"][value="' + step4_components + '"]').trigger("click").prop('checked', true);




      var step4_membersNF = FRAME == null ? '' : FRAME.step4_membersNF;
      var step4_members = FRAME == null ? '' : FRAME.step4_members;
      var step4_membersDate = FRAME == null ? '' : FRAME.step4_membersDate;

      $('input[name="step4_members_na"][value="' + step4_membersNF + '"]').trigger("click");
      $('input[name="step4_members_repaired"][value="' + step4_members + '"]').trigger("click").prop('checked', true);


      var step4_clearanceNF = FRAME == null ? '' : FRAME.step4_clearanceNF;
      var step4_clearance = FRAME == null ? '' : FRAME.step4_clearance;
      var step4_clearanceDate = FRAME == null ? '' : FRAME.step4_clearanceDate;

      $('input[name="step4_clearance_na"][value="' + step4_clearanceNF + '"]').trigger("click");
      $('input[name="step4_clearance_repaired"][value="' + step4_clearance + '"]').trigger("click").prop('checked', true);


      var step4_assembliesNF = FRAME == null ? '' : FRAME.step4_assembliesNF;
      var step4_assemblies = FRAME == null ? '' : FRAME.step4_assemblies;
      var step4_assembliesDate = FRAME == null ? '' : FRAME.step4_assembliesDate;

      $('input[name="step4_assemblies_na"][value="' + step4_assembliesNF + '"]').trigger("click");
      $('input[name="step4_assemblies_repaired"][value="' + step4_assemblies + '"]').trigger("click").prop('checked', true);






      var step4_powerUnitNF = TIRES == null ? '' : TIRES.step4_powerUnitNF;
      var step4_powerUnit = TIRES == null ? '' : TIRES.step4_powerUnit;
      var step4_powerUnitDate = TIRES == null ? '' : TIRES.step4_powerUnitDate;

      $('input[name="step4_powerUnit_na"][value="' + step4_powerUnitNF + '"]').trigger("click");
      $('input[name="step4_powerUnit_repaired"][value="' + step4_powerUnit + '"]').trigger("click").prop('checked', true);


      var step4_allOtherNF = TIRES == null ? '' : TIRES.step4_allOtherNF;
      var step4_allOther = TIRES == null ? '' : TIRES.step4_allOther;
      var step4_allOtherDate = TIRES == null ? '' : TIRES.step4_allOtherDate;

      $('input[name="step4_allOther_na"][value="' + step4_allOtherNF + '"]').trigger("click");
      $('input[name="step4_allOther_repaired"][value="' + step4_allOther + '"]').trigger("click").prop('checked', true);


      var step4_motorCarrierNF = TIRES == null ? '' : TIRES.step4_motorCarrierNF;
      var step4_motorCarrier = TIRES == null ? '' : TIRES.step4_motorCarrier;
      var step4_motorCarrierDate = TIRES == null ? '' : TIRES.step4_motorCarrierDate;

      $('input[name="step4_motorCarrier_na"][value="' + step4_motorCarrierNF + '"]').trigger("click");
      $('input[name="step4_motorCarrier_repaired"][value="' + step4_motorCarrier + '"]').trigger("click").prop('checked', true);

      $("#step4_wheelDate").val(step4_wheelDate);
      $("#step4_columnDate").val(step4_columnDate);
      $("#step4_frontDate").val(step4_frontDate);
      $("#step4_gearDate").val(step4_gearDate);
      $("#step4_pitmanDate").val(step4_pitmanDate);
      $("#step4_powerDate").val(step4_powerDate);
      $("#step4_ballDate").val(step4_ballDate);
      $("#step4_tieDate").val(step4_tieDate);
      $("#step4_nutsDate").val(step4_nutsDate);
      $("#step4_systemDate").val(step4_systemDate);
      $("#step4_positioningDate").val(step4_positioningDate);
      $("#step4_assemblyDate").val(step4_assemblyDate);
      $("#step4_componentsDate").val(step4_componentsDate);
      $("#step4_membersDate").val(step4_membersDate);
      $("#step4_clearanceDate").val(step4_clearanceDate);
      $("#step4_assembliesDate").val(step4_assembliesDate);
      $("#step4_powerUnitDate").val(step4_powerUnitDate);
      $("#step4_allOtherDate").val(step4_allOtherDate);
      $("#step4_motorCarrierDate").val(step4_motorCarrierDate);

      if (step4_wheelNF || step4_columnNF || step4_frontNF || step4_gearNF || step4_pitmanNF || step4_powerNF || step4_ballNF || step4_tieNF || step4_nutsNF || step4_systemNF || step4_positioningNF || step4_assemblyNF || step4_componentsNF || step4_membersNF || step4_clearanceNF || step4_assembliesNF || step4_powerUnitNF || step4_allOtherNF || step4_motorCarrierNF == 'verified') {
         checkVerified();

      }

   }



   const checkVerified = () => {

      //       step4_wheel

      var step4_wheelValue = $("input[name='step4_wheel_na']:checked").val();

      if (step4_wheelValue == 'na') {
         $("input[name='step4_wheel_repaired']").attr('disabled', 'true');
         $("input[name='step4_wheelDateTemp']").show();
         $("input[name='step4_wheelDateTemp']").attr('disabled', 'true');
         $("input[name='step4_wheelDate']").hide();
         $("input[name='step4_wheel_repaired']").prop("checked", false);
         setStep4_wheelDate('');

      } if (step4_wheelValue == 'verified') {

         $("input[name='step4_wheel_repaired']").removeAttr('disabled');
         $("input[name='step4_wheelDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_wheel_repaired']").is(':checked')) {
            $("input[name='step4_wheelDate']").show();
            $("input[name='step4_wheelDateTemp']").hide();
         } else {
            $("input[name='step4_wheelDate']").hide();
            $("input[name='step4_wheelDateTemp']").show();
         }

      }

      // step4_column

      var step4_columnValue = $("input[name='step4_column_na']:checked").val();


      if (step4_columnValue == 'na') {
         $("input[name='step4_column_repaired']").attr('disabled', 'true');
         $("input[name='step4_columnDateTemp']").show();
         $("input[name='step4_columnDateTemp']").attr('disabled', 'true');
         $("input[name='step4_columnDate']").hide();
         $("input[name='step4_column_repaired']").prop("checked", false);
         setStep4_columnDate('');

      } if (step4_columnValue == 'verified') {

         $("input[name='step4_column_repaired']").removeAttr('disabled');
         $("input[name='step4_columnDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_column_repaired']").is(':checked')) {
            $("input[name='step4_columnDate']").show();
            $("input[name='step4_columnDateTemp']").hide();
         } else {
            $("input[name='step4_columnDate']").hide();
            $("input[name='step4_columnDateTemp']").show();
         }

      }

      // step4_front


      var step4_frontValue = $("input[name='step4_front_na']:checked").val();


      if (step4_frontValue == 'na') {
         $("input[name='step4_front_repaired']").attr('disabled', 'true');
         $("input[name='step4_frontDateTemp']").show();
         $("input[name='step4_frontDateTemp']").attr('disabled', 'true');
         $("input[name='step4_frontDate']").hide();
         $("input[name='step4_front_repaired']").prop("checked", false);
         setStep4_frontDate('');
      } if (step4_frontValue == 'verified') {

         $("input[name='step4_front_repaired']").removeAttr('disabled');
         $("input[name='step4_frontDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_front_repaired']").is(':checked')) {
            $("input[name='step4_frontDate']").show();
            $("input[name='step4_frontDateTemp']").hide();
         } else {
            $("input[name='step4_frontDate']").hide();
            $("input[name='step4_frontDateTemp']").show();
         }

      }
      // step4_gear

      var step4_gearValue = $("input[name='step4_gear_na']:checked").val();

      if (step4_gearValue == 'na') {
         $("input[name='step4_gear_repaired']").attr('disabled', 'true');
         $("input[name='step4_gearDateTemp']").show();
         $("input[name='step4_gearDateTemp']").attr('disabled', 'true');
         $("input[name='step4_gearDate']").hide();
         $("input[name='step4_gear_repaired']").prop("checked", false);
         setStep4_gearDate('');
      } if (step4_gearValue == 'verified') {

         $("input[name='step4_gear_repaired']").removeAttr('disabled');
         $("input[name='step4_gearDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_gear_repaired']").is(':checked')) {
            $("input[name='step4_gearDate']").show();
            $("input[name='step4_gearDateTemp']").hide();
         } else {
            $("input[name='step4_gearDate']").hide();
            $("input[name='step4_gearDateTemp']").show();
         }

      }

      // step4_pitman

      var step4_pitmanValue = $("input[name='step4_pitman_na']:checked").val();

      if (step4_pitmanValue == 'na') {
         $("input[name='step4_pitman_repaired']").attr('disabled', 'true');
         $("input[name='step4_pitmanDateTemp']").show();
         $("input[name='step4_pitmanDateTemp']").attr('disabled', 'true');
         $("input[name='step4_pitmanDate']").hide();
         $("input[name='step4_pitman_repaired']").prop("checked", false);
         setStep4_pitmanDate('');
      } if (step4_pitmanValue == 'verified') {

         $("input[name='step4_pitman_repaired']").removeAttr('disabled');
         $("input[name='step4_pitmanDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_pitman_repaired']").is(':checked')) {
            $("input[name='step4_pitmanDate']").show();
            $("input[name='step4_pitmanDateTemp']").hide();
         } else {
            $("input[name='step4_pitmanDate']").hide();
            $("input[name='step4_pitmanDateTemp']").show();
         }

      }

      // step4_power

      var step4_powerValue = $("input[name='step4_power_na']:checked").val();

      if (step4_powerValue == 'na') {
         $("input[name='step4_power_repaired']").attr('disabled', 'true');
         $("input[name='step4_powerDateTemp']").show();
         $("input[name='step4_powerDateTemp']").attr('disabled', 'true');
         $("input[name='step4_powerDate']").hide();
         $("input[name='step4_power_repaired']").prop("checked", false);
         setStep4_powerDate('');
      } if (step4_powerValue == 'verified') {

         $("input[name='step4_power_repaired']").removeAttr('disabled');
         $("input[name='step4_powerDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_power_repaired']").is(':checked')) {
            $("input[name='step4_powerDate']").show();
            $("input[name='step4_powerDateTemp']").hide();
         } else {
            $("input[name='step4_powerDate']").hide();
            $("input[name='step4_powerDateTemp']").show();
         }

      }
      // step4_ball

      var step4_ballValue = $("input[name='step4_ball_na']:checked").val();

      if (step4_ballValue == 'na') {
         $("input[name='step4_ball_repaired']").attr('disabled', 'true');
         $("input[name='step4_ballDateTemp']").show();
         $("input[name='step4_ballDateTemp']").attr('disabled', 'true');
         $("input[name='step4_ballDate']").hide();
         $("input[name='step4_ball_repaired']").prop("checked", false);
         setStep4_ballDate('');
      } if (step4_ballValue == 'verified') {

         $("input[name='step4_ball_repaired']").removeAttr('disabled');
         $("input[name='step4_ballDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_ball_repaired']").is(':checked')) {
            $("input[name='step4_ballDate']").show();
            $("input[name='step4_ballDateTemp']").hide();
         } else {
            $("input[name='step4_ballDate']").hide();
            $("input[name='step4_ballDateTemp']").show();
         }

      }

      // step4_tie
      var step4_tieValue = $("input[name='step4_tie_na']:checked").val();

      if (step4_tieValue == 'na') {
         $("input[name='step4_tie_repaired']").attr('disabled', 'true');
         $("input[name='step4_tieDateTemp']").show();
         $("input[name='step4_tieDateTemp']").attr('disabled', 'true');
         $("input[name='step4_tieDate']").hide();
         $("input[name='step4_tie_repaired']").prop("checked", false);
         setStep4_tieDate('');
      } if (step4_tieValue == 'verified') {

         $("input[name='step4_tie_repaired']").removeAttr('disabled');
         $("input[name='step4_tieDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_tie_repaired']").is(':checked')) {
            $("input[name='step4_tieDate']").show();
            $("input[name='step4_tieDateTemp']").hide();
         } else {
            $("input[name='step4_tieDate']").hide();
            $("input[name='step4_tieDateTemp']").show();
         }

      }


      // step4_nuts
      var step4_nutsValue = $("input[name='step4_nuts_na']:checked").val();

      if (step4_nutsValue == 'na') {
         $("input[name='step4_nuts_repaired']").attr('disabled', 'true');
         $("input[name='step4_nutsDateTemp']").show();
         $("input[name='step4_nutsDateTemp']").attr('disabled', 'true');
         $("input[name='step4_nutsDate']").hide();
         $("input[name='step4_nuts_repaired']").prop("checked", false);
         setStep4_nutsDate('');
      } if (step4_nutsValue == 'verified') {

         $("input[name='step4_nuts_repaired']").removeAttr('disabled');
         $("input[name='step4_nutsDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_nuts_repaired']").is(':checked')) {
            $("input[name='step4_nutsDate']").show();
            $("input[name='step4_nutsDateTemp']").hide();
         } else {
            $("input[name='step4_nutsDate']").hide();
            $("input[name='step4_nutsDateTemp']").show();
         }

      }

      // step4_system
      var step4_systemValue = $("input[name='step4_system_na']:checked").val();

      if (step4_systemValue == 'na') {
         $("input[name='step4_system_repaired']").attr('disabled', 'true');
         $("input[name='step4_systemDateTemp']").show();
         $("input[name='step4_systemDateTemp']").attr('disabled', 'true');
         $("input[name='step4_systemDate']").hide();
         $("input[name='step4_system_repaired']").prop("checked", false);
         setStep4_systemDate('');
      } if (step4_systemValue == 'verified') {

         $("input[name='step4_system_repaired']").removeAttr('disabled');
         $("input[name='step4_systemDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_system_repaired']").is(':checked')) {
            $("input[name='step4_systemDate']").show();
            $("input[name='step4_systemDateTemp']").hide();
         } else {
            $("input[name='step4_systemDate']").hide();
            $("input[name='step4_systemDateTemp']").show();
         }

      }


      // step4_positioning
      var step4_positioningValue = $("input[name='step4_positioning_na']:checked").val();

      if (step4_positioningValue == 'na') {
         $("input[name='step4_positioning_repaired']").attr('disabled', 'true');
         $("input[name='step4_positioningDateTemp']").show();
         $("input[name='step4_positioningDateTemp']").attr('disabled', 'true');
         $("input[name='step4_positioningDate']").hide();
         $("input[name='step4_positioning_repaired']").prop("checked", false);
         setStep4_positioningDate('');
      } if (step4_positioningValue == 'verified') {

         $("input[name='step4_positioning_repaired']").removeAttr('disabled');
         $("input[name='step4_positioningDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_positioning_repaired']").is(':checked')) {
            $("input[name='step4_positioningDate']").show();
            $("input[name='step4_positioningDateTemp']").hide();
         } else {
            $("input[name='step4_positioningDate']").hide();
            $("input[name='step4_positioningDateTemp']").show();
         }

      }

      // step4_assembly
      var step4_assemblyValue = $("input[name='step4_assembly_na']:checked").val();

      if (step4_assemblyValue == 'na') {
         $("input[name='step4_assembly_repaired']").attr('disabled', 'true');
         $("input[name='step4_assemblyDateTemp']").show();
         $("input[name='step4_assemblyDateTemp']").attr('disabled', 'true');
         $("input[name='step4_assemblyDate']").hide();
         $("input[name='step4_assembly_repaired']").prop("checked", false);
         setStep4_assemblyDate('');
      } if (step4_assemblyValue == 'verified') {

         $("input[name='step4_assembly_repaired']").removeAttr('disabled');
         $("input[name='step4_assemblyDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_assembly_repaired']").is(':checked')) {
            $("input[name='step4_assemblyDate']").show();
            $("input[name='step4_assemblyDateTemp']").hide();
         } else {
            $("input[name='step4_assemblyDate']").hide();
            $("input[name='step4_assemblyDateTemp']").show();
         }

      }

      // step4_system
      var step4_componentsValue = $("input[name='step4_components_na']:checked").val();

      if (step4_componentsValue == 'na') {
         $("input[name='step4_components_repaired']").attr('disabled', 'true');
         $("input[name='step4_componentsDateTemp']").show();
         $("input[name='step4_componentsDateTemp']").attr('disabled', 'true');
         $("input[name='step4_componentsDate']").hide();
         $("input[name='step4_components_repaired']").prop("checked", false);
         setStep4_componentsDate('');
      } if (step4_componentsValue == 'verified') {

         $("input[name='step4_components_repaired']").removeAttr('disabled');
         $("input[name='step4_componentsDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_components_repaired']").is(':checked')) {
            $("input[name='step4_componentsDate']").show();
            $("input[name='step4_componentsDateTemp']").hide();
         } else {
            $("input[name='step4_componentsDate']").hide();
            $("input[name='step4_componentsDateTemp']").show();
         }

      }


      // step4_system
      var step4_membersValue = $("input[name='step4_members_na']:checked").val();

      if (step4_membersValue == 'na') {
         $("input[name='step4_members_repaired']").attr('disabled', 'true');
         $("input[name='step4_membersDateTemp']").show();
         $("input[name='step4_membersDateTemp']").attr('disabled', 'true');
         $("input[name='step4_membersDate']").hide();
         $("input[name='step4_members_repaired']").prop("checked", false);
         setStep4_membersDate('');
      } if (step4_membersValue == 'verified') {

         $("input[name='step4_members_repaired']").removeAttr('disabled');
         $("input[name='step4_membersDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_members_repaired']").is(':checked')) {
            $("input[name='step4_membersDate']").show();
            $("input[name='step4_membersDateTemp']").hide();
         } else {
            $("input[name='step4_membersDate']").hide();
            $("input[name='step4_membersDateTemp']").show();
         }

      }

      // step4_clearance
      var step4_clearanceValue = $("input[name='step4_clearance_na']:checked").val();

      if (step4_clearanceValue == 'na') {
         $("input[name='step4_clearance_repaired']").attr('disabled', 'true');
         $("input[name='step4_clearanceDateTemp']").show();
         $("input[name='step4_clearanceDateTemp']").attr('disabled', 'true');
         $("input[name='step4_clearanceDate']").hide();
         $("input[name='step4_clearance_repaired']").prop("checked", false);
         setStep4_clearanceDate('');
      } if (step4_clearanceValue == 'verified') {

         $("input[name='step4_clearance_repaired']").removeAttr('disabled');
         $("input[name='step4_clearanceDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_clearance_repaired']").is(':checked')) {
            $("input[name='step4_clearanceDate']").show();
            $("input[name='step4_clearanceDateTemp']").hide();
         } else {
            $("input[name='step4_clearanceDate']").hide();
            $("input[name='step4_clearanceDateTemp']").show();
         }

      }

      // step4_system
      var step4_assembliesValue = $("input[name='step4_assemblies_na']:checked").val();

      if (step4_assembliesValue == 'na') {
         $("input[name='step4_assemblies_repaired']").attr('disabled', 'true');
         $("input[name='step4_assembliesDateTemp']").show();
         $("input[name='step4_assembliesDateTemp']").attr('disabled', 'true');
         $("input[name='step4_assembliesDate']").hide();
         $("input[name='step4_assemblies_repaired']").prop("checked", false);
         setStep4_assembliesDate('');
      } if (step4_assembliesValue == 'verified') {

         $("input[name='step4_assemblies_repaired']").removeAttr('disabled');
         $("input[name='step4_assembliesDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_assemblies_repaired']").is(':checked')) {
            $("input[name='step4_assembliesDate']").show();
            $("input[name='step4_assembliesDateTemp']").hide();
         } else {
            $("input[name='step4_assembliesDate']").hide();
            $("input[name='step4_assembliesDateTemp']").show();
         }

      }


      // step4_powerUnit
      var step4_powerUnitValue = $("input[name='step4_powerUnit_na']:checked").val();

      if (step4_powerUnitValue == 'na') {
         $("input[name='step4_powerUnit_repaired']").attr('disabled', 'true');
         $("input[name='step4_powerUnitDateTemp']").show();
         $("input[name='step4_powerUnitDateTemp']").attr('disabled', 'true');
         $("input[name='step4_powerUnitDate']").hide();
         $("input[name='step4_powerUnit_repaired']").prop("checked", false);
         setStep4_powerUnitDate('');
      } if (step4_powerUnitValue == 'verified') {

         $("input[name='step4_powerUnit_repaired']").removeAttr('disabled');
         $("input[name='step4_powerUnitDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_powerUnit_repaired']").is(':checked')) {
            $("input[name='step4_powerUnitDate']").show();
            $("input[name='step4_powerUnitDateTemp']").hide();
         } else {
            $("input[name='step4_powerUnitDate']").hide();
            $("input[name='step4_powerUnitDateTemp']").show();
         }

      }

      // step4_allOther
      var step4_allOtherValue = $("input[name='step4_allOther_na']:checked").val();

      if (step4_allOtherValue == 'na') {
         $("input[name='step4_allOther_repaired']").attr('disabled', 'true');
         $("input[name='step4_allOtherDateTemp']").show();
         $("input[name='step4_allOtherDateTemp']").attr('disabled', 'true');
         $("input[name='step4_allOtherDate']").hide();
         $("input[name='step4_allOther_repaired']").prop("checked", false);
         setStep4_allOtherDate('');
      } if (step4_allOtherValue == 'verified') {

         $("input[name='step4_allOther_repaired']").removeAttr('disabled');
         $("input[name='step4_allOtherDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_allOther_repaired']").is(':checked')) {
            $("input[name='step4_allOtherDate']").show();
            $("input[name='step4_allOtherDateTemp']").hide();
         } else {
            $("input[name='step4_allOtherDate']").hide();
            $("input[name='step4_allOtherDateTemp']").show();
         }

      }

      // step4_motorCarrier
      var step4_motorCarrierValue = $("input[name='step4_motorCarrier_na']:checked").val();

      if (step4_motorCarrierValue == 'na') {
         $("input[name='step4_motorCarrier_repaired']").attr('disabled', 'true');
         $("input[name='step4_motorCarrierDateTemp']").show();
         $("input[name='step4_motorCarrierDateTemp']").attr('disabled', 'true');
         $("input[name='step4_motorCarrierDate']").hide();
         $("input[name='step4_motorCarrier_repaired']").prop("checked", false);
         setStep4_motorCarrierDate('');
      } if (step4_motorCarrierValue == 'verified') {

         $("input[name='step4_motorCarrier_repaired']").removeAttr('disabled');
         $("input[name='step4_motorCarrierDateTemp']").removeAttr('disabled');

         if ($("input[name='step4_motorCarrier_repaired']").is(':checked')) {
            $("input[name='step4_motorCarrierDate']").show();
            $("input[name='step4_motorCarrierDateTemp']").hide();
         } else {
            $("input[name='step4_motorCarrierDate']").hide();
            $("input[name='step4_motorCarrierDateTemp']").show();
         }

      }

     


   }

   const handleSubmit = () => {

      var section4STEERING = {
         "step4_wheelNF": $("input[name='step4_wheel_na']:checked").val() === "na" ? "na" : "verified",
         "step4_wheel": $("input[name='step4_wheel_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_wheelDate": $("input[name='step4_wheelDate']").val(),

         "step4_columnNF": $("input[name='step4_column_na']:checked").val() === "na" ? "na" : "verified",
         "step4_column": $("input[name='step4_column_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_columnDate": $("input[name='step4_columnDate']").val(),

         "step4_frontNF": $("input[name='step4_front_na']:checked").val() === "na" ? "na" : "verified",
         "step4_front": $("input[name='step4_front_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_frontDate": $("input[name='step4_frontDate']").val(),

         "step4_gearNF": $("input[name='step4_gear_na']:checked").val() === "na" ? "na" : "verified",
         "step4_gear": $("input[name='step4_gear_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_gearDate": $("input[name='step4_gearDate']").val(),

         "step4_pitmanNF": $("input[name='step4_pitman_na']:checked").val() === "na" ? "na" : "verified",
         "step4_pitman": $("input[name='step4_pitman_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_pitmanDate": $("input[name='step4_pitmanDate']").val(),

         "step4_powerNF": $("input[name='step4_power_na']:checked").val() === "na" ? "na" : "verified",
         "step4_power": $("input[name='step4_power_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_powerDate": $("input[name='step4_powerDate']").val(),

         "step4_ballNF": $("input[name='step4_ball_na']:checked").val() === "na" ? "na" : "verified",
         "step4_ball": $("input[name='step4_ball_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_ballDate": $("input[name='step4_ballDate']").val(),

         "step4_tieNF": $("input[name='step4_tie_na']:checked").val() === "na" ? "na" : "verified",
         "step4_tie": $("input[name='step4_tie_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_tieDate": $("input[name='step4_tieDate']").val(),

         "step4_nutsNF": $("input[name='step4_nuts_na']:checked").val() === "na" ? "na" : "verified",
         "step4_nuts": $("input[name='step4_nuts_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_nutsDate": $("input[name='step4_nutsDate']").val(),

         "step4_systemNF": $("input[name='step4_system_na']:checked").val() === "na" ? "na" : "verified",
         "step4_system": $("input[name='step4_system_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_systemDate": $("input[name='step4_systemDate']").val(),
      };
      console.log(JSON.stringify(section4STEERING));

      sessionStorage.setItem("section4STEERING", JSON.stringify(section4STEERING));

      var section4SUSPENSION = {
         "step4_positioningNF": $("input[name='step4_positioning_na']:checked").val() === "na" ? "na" : "verified",
         "step4_positioning": $("input[name='step4_positioning_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_positioningDate": $("input[name='step4_positioningDate']").val(),

         "step4_assemblyNF": $("input[name='step4_assembly_na']:checked").val() === "na" ? "na" : "verified",
         "step4_assembly": $("input[name='step4_assembly_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_assemblyDate": $("input[name='step4_assemblyDate']").val(),

         "step4_componentsNF": $("input[name='step4_components_na']:checked").val() === "na" ? "na" : "verified",
         "step4_components": $("input[name='step4_components_repaired']:checked").val() == "1" ? "1" : "0",
         "step4_componentsDate": $("input[name='step4_componentsDate']").val(),
      };
      console.log(JSON.stringify(section4SUSPENSION));

      sessionStorage.setItem("section4SUSPENSION", JSON.stringify(section4SUSPENSION));

      var section4FRAME = {
         "step4_membersNF": $("input[name='step4_members_na']:checked").val() === "na" ? "na" : "verified",
         "step4_members": $("input[name='step4_members_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_membersDate": $("input[name='step4_membersDate']").val(),

         "step4_clearanceNF": $("input[name='step4_clearance_na']:checked").val() === "na" ? "na" : "verified",
         "step4_clearance": $("input[name='step4_clearance_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_clearanceDate": $("input[name='step4_clearanceDate']").val(),

         "step4_assembliesNF": $("input[name='step4_assemblies_na']:checked").val() === "na" ? "na" : "verified",
         "step4_assemblies": $("input[name='step4_assemblies_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_assembliesDate": $("input[name='step4_assembliesDate']").val(),
      };
      console.log(JSON.stringify(section4FRAME));

      sessionStorage.setItem("section4FRAME", JSON.stringify(section4FRAME));


      var section4TIRES = {
         "step4_powerUnitNF": $("input[name='step4_powerUnit_na']:checked").val() === "na" ? "na" : "verified",
         "step4_powerUnit": $("input[name='step4_powerUnit_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_powerUnitDate": $("input[name='step4_powerUnitDate']").val(),
         "step4_allOtherNF": $("input[name='step4_allOther_na']:checked").val() === "na" ? "na" : "verified",
         "step4_allOther": $("input[name='step4_allOther_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_allOtherDate": $("input[name='step4_allOtherDate']").val(),
         "step4_motorCarrierNF": $("input[name='step4_motorCarrier_na']:checked").val() === "na" ? "na" : "verified",
         "step4_motorCarrier": $("input[name='step4_motorCarrier_repaired']:checked").val() === "1" ? "1" : "0",
         "step4_motorCarrierDate": $("input[name='step4_motorCarrierDate']").val(),

      };

      console.log(JSON.stringify(section4TIRES));

      sessionStorage.setItem("section4TIRES", JSON.stringify(section4TIRES));

      
      let form_is_valid = true;

      var step4_wheel_na = $("input[name='step4_wheel_na']:checked").val();
      var step4_column_na = $("input[name='step4_column_na']:checked").val();
      var step4_front_na = $("input[name='step4_front_na']:checked").val();
      var step4_gear_na = $("input[name='step4_gear_na']:checked").val();
      var step4_pitman_na = $("input[name='step4_pitman_na']:checked").val();
      var step4_power_na = $("input[name='step4_power_na']:checked").val();
      var step4_ball_na = $("input[name='step4_ball_na']:checked").val();
      var step4_tie_na = $("input[name='step4_tie_na']:checked").val();
      var step4_nuts_na = $("input[name='step4_nuts_na']:checked").val();
      var step4_system_na = $("input[name='step4_system_na']:checked").val();

      var step4_positioning_na = $("input[name='step4_positioning_na']:checked").val();
      var step4_assembly_na = $("input[name='step4_assembly_na']:checked").val();
      var step4_components_na = $("input[name='step4_components_na']:checked").val();

      var step4_members_na = $("input[name='step4_members_na']:checked").val();
      var step4_clearance_na = $("input[name='step4_clearance_na']:checked").val();
      var step4_assemblies_na = $("input[name='step4_assemblies_na']:checked").val();


      var step4_powerUnit_na = $("input[name='step4_powerUnit_na']:checked").val();
      var step4_allOther_na = $("input[name='step4_allOther_na']:checked").val();
      var step4_motorCarrier_na = $("input[name='step4_motorCarrier_na']:checked").val();


      if (step4_wheel_na == undefined || step4_column_na == undefined || step4_front_na == undefined || step4_gear_na == undefined || step4_pitman_na == undefined || step4_power_na == undefined || step4_ball_na == undefined || step4_tie_na == undefined || step4_nuts_na == undefined || step4_system_na == undefined || step4_positioning_na == undefined || step4_assembly_na == undefined || step4_components_na == undefined || step4_members_na == undefined || step4_clearance_na == undefined || step4_assemblies_na == undefined || step4_powerUnit_na == undefined || step4_allOther_na == undefined || step4_motorCarrier_na == undefined) {
         // $('html, body').animate({
         //    scrollTop: $("#inspectionType").offset().top
         //  }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
         form_is_valid = false;

      } else {
         $('#error_message').html('');
      }

      if (form_is_valid == true) {
         $("#annualVehicle5").trigger('click');
      }


      return form_is_valid;
   }

   $(document).ready(function () {


      $('#annualVehicle5').on('click', function () {

      });
   });

   return (
      <>

         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div>

                  <div class="divider"></div> */}
                  <div class="row p-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">ANNUAL VEHICLE COMPONENTS INSPECTED</h4>
                        <p id="error_message"></p>
                     </div>
                     <div>
                     <WorkOrderNumberDiv />
                        <div class="inspection-wrap">
                           <div class="step-wrap p-3 mb-2">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""/></div> --> */}
                                    <div class="labl-section">Brake </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Coupling </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Exhaust-Fuel-Lighting-Safe</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 current-tick">4</div>
                                    <div class="labl-section">Steering-Suspension</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">5</div>
                                    <div class="labl-section">Wheels-Glazing</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mb-4">
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>STEERING MECHANISM</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Steering Wheel Free Play</td>
                                          <td ><input type="radio" name="step4_wheel_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_wheel_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_wheel_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_wheelDate} onChange={(date) => setStep4_wheelDate(date)} name="step4_wheelDate" class="form-control" id="step4_wheelDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_wheelDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Steering Column</td>
                                          <td ><input type="radio" name="step4_column_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_column_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_column_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_columnDate} onChange={(date) => setStep4_columnDate(date)} name="step4_columnDate" class="form-control" id="step4_columnDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_columnDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Front Axle Beam and All Steering Components Other Than Steering Column</td>
                                          <td ><input type="radio" name="step4_front_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_front_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_front_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_frontDate} onChange={(date) => setStep4_frontDate(date)} name="step4_frontDate" class="form-control" id="step4_frontDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_frontDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>d. Steering Gear Box</td>
                                          <td ><input type="radio" name="step4_gear_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_gear_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_gear_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_gearDate} onChange={(date) => setStep4_gearDate(date)} name="step4_gearDate" class="form-control" id="step4_gearDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_gearDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>e. Pitman Arm</td>
                                          <td ><input type="radio" name="step4_pitman_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_pitman_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_pitman_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_pitmanDate} onChange={(date) => setStep4_pitmanDate(date)} name="step4_pitmanDate" class="form-control" id="step4_pitmanDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_pitmanDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>f. Power Steering</td>
                                          <td ><input type="radio" name="step4_power_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_power_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_power_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_powerDate} onChange={(date) => setStep4_powerDate(date)} name="step4_powerDate" class="form-control" id="step4_powerDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_powerDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>g. Ball and Socket Joints</td>
                                          <td ><input type="radio" name="step4_ball_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_ball_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_ball_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_ballDate} onChange={(date) => setStep4_ballDate(date)} name="step4_ballDate" class="form-control" id="step4_ballDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_ballDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>h. Tie Rods and Drag Links</td>
                                          <td ><input type="radio" name="step4_tie_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_tie_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_tie_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_tieDate} onChange={(date) => setStep4_tieDate(date)} name="step4_tieDate" class="form-control" id="step4_tieDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_tieDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>i. Nuts</td>
                                          <td ><input type="radio" name="step4_nuts_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_nuts_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_nuts_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_nutsDate} onChange={(date) => setStep4_nutsDate(date)} name="step4_nutsDate" class="form-control" id="step4_nutsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_nutsDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>j. Steering System</td>
                                          <td ><input type="radio" name="step4_system_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_system_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_system_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_systemDate} onChange={(date) => setStep4_systemDate(date)} name="step4_systemDate" class="form-control" id="step4_systemDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_systemDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>SUSPENSION</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Any U-bolt(s), spring  hanger(s), or other axle positioning part(s) cracked,broken, loose or missing resulting in shifting of an axle from its normal position</td>
                                          <td ><input type="radio" name="step4_positioning_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_positioning_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_positioning_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_positioningDate} onChange={(date) => setStep4_positioningDate(date)} name="step4_positioningDate" class="form-control" id="step4_positioningDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_positioningDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Spring Assembly                                                   </td>
                                          <td ><input type="radio" name="step4_assembly_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_assembly_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_assembly_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_assemblyDate} onChange={(date) => setStep4_assemblyDate(date)} name="step4_assemblyDate" class="form-control" id="step4_assemblyDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_assemblyDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Torque, Radius or Tracking Components                                                   </td>
                                          <td ><input type="radio" name="step4_components_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_components_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_components_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_componentsDate} onChange={(date) => setStep4_componentsDate(date)} name="step4_componentsDate" class="form-control" id="step4_componentsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_componentsDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>FRAME </h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Frame Members</td>
                                          <td ><input type="radio" name="step4_members_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_members_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_members_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_membersDate} onChange={(date) => setStep4_membersDate(date)} name="step4_membersDate" class="form-control" id="step4_membersDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_membersDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Tire and Wheel Clearance</td>
                                          <td ><input type="radio" name="step4_clearance_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_clearance_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_clearance_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_clearanceDate} onChange={(date) => setStep4_clearanceDate(date)} name="step4_clearanceDate" class="form-control" id="step4_clearanceDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_clearanceDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Adjustable Axle Assemblies (Sliding Subframes)</td>
                                          <td ><input type="radio" name="step4_assemblies_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_assemblies_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_assemblies_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_assembliesDate} onChange={(date) => setStep4_assembliesDate(date)} name="step4_assembliesDate" class="form-control" id="step4_assembliesDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_assembliesDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>TIRES  </h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Tires on any steering axle of a power unit</td>
                                          <td ><input type="radio" name="step4_powerUnit_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_powerUnit_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_powerUnit_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_powerUnitDate} onChange={(date) => setStep4_powerUnitDate(date)} name="step4_powerUnitDate" class="form-control" id="step4_powerUnitDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_powerUnitDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. All other tires. </td>
                                          <td ><input type="radio" name="step4_allOther_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_allOther_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_allOther_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_allOtherDate} onChange={(date) => setStep4_allOtherDate(date)} name="step4_allOtherDate" class="form-control" id="step4_allOtherDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_allOtherDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Installation of speed-restricted tires unless specifically designated by motor carrier
                                          </td>
                                          <td ><input type="radio" name="step4_motorCarrier_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step4_motorCarrier_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="step4_motorCarrier_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step4_motorCarrierDate} onChange={(date) => setStep4_motorCarrierDate(date)} name="step4_motorCarrierDate" class="form-control" id="step4_motorCarrierDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step4_motorCarrierDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                            
                           </div>


                        </div>
                     </div>
                  </div>



                  <div class="divider2"></div>
                  <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>

                     {/* <a id="annualVehicle3" class="btn btn-primary mr-3">Back</a>
                     <a id="annualVehicle5" class="btn btn-primary mr-5" >Next</a> */}
                     <button type="button" id="annualVehicle3" class="btn btn-primary mr-1">Back</button>
                     <button type="button" id="annualVehicle5" class="btn btn-primary mr-1" style={{ display: "none" }}>Next</button>
                     <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button>

                  </div>
               </div>
            </div>
         </div>

      </>
   )
};

AnnualVehicle4Edit.propTypes = {};

AnnualVehicle4Edit.defaultProps = {};

export default AnnualVehicle4Edit;
