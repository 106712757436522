import React, { useEffect, useState } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import Pagination from "../../../utility/pagination";
import Tableheader from "../../../utility/tableheader";
import AutoSuggest from "react-autosuggest";
import $ from "jquery";
import Search from "../../../utility/search";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import UpcomingSearch from "./reminder_utility/upcoming_search";
import AtGarageSearch from "./reminder_utility/atgarage_search";
import DueSearch from "./reminder_utility/due_search";
import CompletedSearch from "./reminder_utility/completed_search";
import { loadingComponent } from "../../../utility/Loading";
import moment from 'moment';
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { SelectAllinspections } from "../customer/CustomerContactList";
import _ from 'lodash';
import UpdateUpcomingAndoverdue from "./reminder_utility/UpcomingOverdue";
import vehicle_details_api from "../vehicle/vehicle_details_api";

function arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (_.isEqual(itm, itm2)) unique = false;
        });
        if (unique) cleaned.push(itm);
    });
    return cleaned;
}

const Reminder = () => {

    var roles = localStorage.getItem("Userroles");
    const [selectedValue, setSelectedValue] = useState('');
    const [inactiveStatus, setInactiveStatus] = useState(true);
    const [value, setValue] = useState("");
    const [duestatus, setduestatus] = useState(false);
    const [originalDate, setOriginalDate] = useState(new Date());
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");
    const [orderId, setOrderId] = useState([]);
    const [customerId, setCustomerIds] = useState();
    const [cid, setCustomerId] = useState([]);
    const [vehicleId, setVehicleId] = useState();
    const [vendorId, setvendorId] = useState()
    const [Id, setIspectionId] = useState()
    const [suggestions, setSuggestions] = useState([]);
    const [vechileDetails, setVehicleDetails] = useState([]);
    // const ITEMS_PER_PAGE = 10;
    const navigate = useNavigate();
    const [assingedVehicleList, SetassingedVehicles] = useState("");
    const [upcoming, setupcoming] = useState();
    const [dueList, setDue] = useState([]);
    const [completedList, setCompleted] = useState();
    const [notes, setNotes] = useState();
    const [atGarageSearchValue, setSearchValueGarage] = useState("");
    const [dueSearchValue, setSearchValueDue] = useState("");
    const [upSearchValue, setSearchValueUp] = useState("");
    const[isCancelled,setisCancelled]= useState("false");
    const [completedSearchValue, setSearchValueCompleted] = useState("");
    const [ITEMS_PER_PAGE, setItemPerPage] = useState(5);
    const [ITEMS_PER_PAGE_DUE, setItemPerDuePage] = useState(5);
    const [inspectiondisable, setInspecchecked] = useState([]);
    const [inspectionStatus, setInspecStatus] = useState([]);
    const [inspectionTypes, setInspectionTypes] = useState();
    const [inspectionNames, setInspecName] = useState([]);
    const [garageId, setgarageId] = useState([{}]);
    const [InspectionTypesStatus, setInspectionTypesStatus] = useState([]);
    const [inspectionNamearray, setInspecNamearray] = useState([]);

    const [docslist, setdocslist] = useState([]);
    const thStyle = '';
    const [listVendors, setListVendors] = useState([]);
    // const [vid, setvehicleId] = useState([]);
    var inspectionArray = [];
    var inspectionData = [];
    var inspect = {};
    var inspectionJson = [];
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });
    //////addnew garage//////////
    const addNewGarage = () => {

        $("#firstName").val("")
        $("#email").val("")
        $("#deskNumber").val("")
        $("#ext").val("")
        $("#mobileNumber").val("")
        $("#address1").val("")
        $("#address2").val("")
        $("#city").val("")
        $("#state").val("")
        $("#zipcode").val("")
    }
    function getSuggestions(value) {
        return lowerCasedCompanies.filter(company =>
            company.name.includes(value.trim().toLowerCase())
        );
    }
    const lowerCasedCompanies = listVendors.map(company => {
        return {
            id: company.id,
            name: company.name.toLowerCase()
        };
    });
    const inlineCss =
    {
        textAlign: "left"
    }
    const inlineWidthFix =
    {
        minWidth: "178px"
    }

    const inlineUo =
    {
        minWidth: "140px",
        paddingRight: "15px"
    }

    const inlineWidth90 =
    {
        minWidth: "90px"
    }
    var array = [];
    var arrayinspection = [];
    function sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    const cancel = () => {
        setInspecchecked([]);
    }
    const cancelassign_Garage = () => {
        document.getElementById("closeModal").click();
        $("#loadingforsubmit").show();
        var vechileReq =
        {
            "id": Id,


        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.cancelAssignToGarage(requestOptions).then((res) => {
            $("#loadingforsubmit").hide();
            if (res.success) {

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res.message

                }).then((result) => {

                    if (result.isConfirmed) {
                    }
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: res.message

                }).then((result) => {

                    if (result.isConfirmed) {
                    }
                });
            }

        });
    }
    function unique(obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) { return a - b });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        return uniques;
    }
    const closeModalPopup = () => {

        document.getElementById("closeModal").click();
    }

    ////////// unchecked function/////////

    //var k = 0;
    // const Unchecked = (inspectionId, inspectionName) => {

    //     if (inspectionNames[k] == inspectionName) {
    //         // alert(inspectionNames[k]);
    //         inspect.id = inspectionId;
    //         inspect.name = inspectionName;
    //         inspect.status = 'checked'
    //         inspectionJson.push({ ...inspect });
    //     } else {

    //         if (inspectionNames[k] == undefined) {
    //             inspect.id = inspectionId;
    //             inspect.name = inspectionNames[k];
    //             inspect.status = 'unchecked'
    //             inspectionJson.push({ ...inspect });
    //         } else {

    //             inspect.id = inspectionId;
    //             inspect.name = inspectionName;
    //             inspect.status = 'unchecked'
    //             inspectionJson.push({ ...inspect });
    //         }
    //         // inspect.id = inspectionId;
    //         // inspect.name = inspectionName;
    //         // inspect.status = 'false'
    //         // inspectionJson.push({ ...inspect });
    //     }
    //     k++;
    // }

    ////////// exit unchecked function/////////
    const validateDate = () => {
        let form_is_valid = true;



        var garage = $(".react-autosuggest__input").val();
        if (garage == '') {
            $('#error-message-garage').css("color", "#d33232").html("*Garage is required");
            form_is_valid = false;
        } else {
            $('#error-message-garage').html('');
        }

        var assignDate = $("#assignDate").val();
        if (assignDate == '') {
            $('#error-message').css("color", "#d33232").html("*Assign date is required");
            form_is_valid = false;
        } else {
            $('#error-message').html('');
        }



        // $('input:checkbox[name=inspectionIds]:checked').each(function () {
        //     // alert($(this).next('span').text());
        //     inspectionArray.push($(this).val());
        //     inspectionData.push($(this).next('span').text());
        //     // inspectionData.push($(this).text().toString())
        //     inspect.id = $(this).val();
        //     inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
        //     // inspect.name = $(this).next("label").text().split(' ').join(' ').toString();
        //     inspectionJson.push({ ...inspect });

        // });

        /////////////////////Canceled by customer function/////////////////
        var count = 0;
        $('input:checkbox[name=inspectionIds]:checked').each(function () {

            // console.log(inspectiondisable)
            // console.log(JSON.stringify(inspectiondisable))
            // console.log(inspectionNames[count]);
            // console.log("compare -"+ $(this).next('span').text());

            inspectionArray.push($(this).val());
            inspectionData.push($(this).next('span').text());
            // inspectionData.push($(this).text().toString())
            // if(inspectiondisable[count].name.match($(this).next('span').text())){
            if (inspectionNames[count] == $(this).next('span').text()) {

                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
                // inspect.Cancelled = 'true'
                inspectionJson.push({ ...inspect });
            } else if (inspectionNames[count] != $(this).next('span').text()) {
                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text();
                // inspect.Cancelled = 'checked'
                inspectionJson.push({ ...inspect });
            }
            else {
                if (inspectionNames[count] == undefined) {
                    inspect.id = $(this).val();
                    inspect.name = $(this).next('span').text();
                    inspect.Cancelled = 'checked'
                    inspectionJson.push({ ...inspect });
                } else {
                    inspect.id = $(this).val();
                    inspect.name = inspectionNames[count];
                    // inspect.Cancelled = 'unchecked'
                    inspectionJson.push({ ...inspect });
                }
            }
            count++;
        });
        /////////////////////Ending Canceled by customer function/////////////////






        if (inspectionArray.length > 0) {

            // $('#error-messageIn').html('');
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please choose the atleast one inspection",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                }
            });
            // $('#error-messageIn').css("color", "#d33232").html("*Inspection is required");
            form_is_valid = false;
        }



        return form_is_valid;
    }
    const submitValue = () => {

        if (validateDate()) {


            closeModalPopup();
            $("#loadingforsubmit").show();

            var vechileReq =
            {
                "id": Id,

                "vendorId": vendorId,
                "vehicleId": vehicleId,
                // "inspectionIdList":[1],
                "inspectionIdList": inspectionArray,
                "inspectionData": JSON.stringify(inspectionJson),
                // "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
                "assignDate": $("#assignDate").val() != '' ? moment($("#assignDate").val()).format('YYYY-MM-DD') : '',
                "notes": $("#notes").val() != '' ? $("#notes").val() : ''



                //    "imageName":cropper.getCroppedCanvas()?parseInt(Math.random() * 1000000000):"",
                //    "imageExtension":cropper.getCroppedCanvas()?"png":"",
                //    "imageByteString":cropper.getCroppedCanvas()?cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", ""):"",
                //    "documentList": documentList
            }

            var requestOptions = requestObject.RequestHeader(vechileReq);
            services.Updateassigntogarage(requestOptions).then((res) => {
                $("#loadingforsubmit").hide();
                if (res.message == "Successfully assigned Vehicle") {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                        }
                    });

                }

            });

        }

    }
    const updateTask = (workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes) => 
    {

        var vehicle_request=
        {
             
                    "id":vehicleId
                
        };
        
       var requestOptions=requestObject.RequestHeader(vehicle_request);

        services.getVehicle_Details(requestOptions).then((res) => 
        {
            sessionStorage.setItem("tankerSubTypeName",res.rows.trailerSubTypeName);
            sessionStorage.setItem("vehicleTypeName",res.rows.vehicleTypeName);
  
           updateData(workorder, JSON.parse(res.rows.inspectionData), assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes)
              
        });
    
    }

    const updateData=(workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes)=>
    {
       
        $("#notes").val(notes);
        $("#garageName").val(vendorName);
         $("#workOrderNumber").val(workorder);
        // vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId);
        setInspecStatus(inspectionStatus);
        setvendorId(vendorId)
        setVehicleId(vehicleId);
        setIspectionId(Inspectionid);
        setNotes(notes ? notes : "");
        setInspectionTypes("");
        setInspecName("");
        setValue(vendorName);
   
        $("#updateworkorder").val(workorder ? workorder : "")
        for (var key in inspectionData) {
            array.push(inspectionData[key].name)
            setInspecName(array);
        }
  
  
        arrayinspection.push(sort_by_key(inspectionData), 'name');
  
  
  
  
        // setInspecStatus(inspectionStatus);
        // setCustomerName(customerName);
        var request =
        {
  
        };
  
  
        var requestOptions = requestObject.RequestHeader(request);
  
        var arrayPay = [];
        var inspectionNames = new Array();
        services.getInspectionTypeS(requestOptions).then((res) => {
  
  
            setInspectionTypesStatus(res.rows);
            var dummayarray = res.rows;
  
            var tempArray = [];
  
            for (var i = 0; i < dummayarray.length; i++) {
  
                tempArray.push({
                    "id": dummayarray[i].id, "name": dummayarray[i].name,
                    "status": null
                })
  
            }
  
  
  
  
  
            var confirmedList = tempArray.filter((a) => {
  
                return !inspectionData.find((p) => p.documentSubCategoryName === a.name);
  
  
            });
  
  
            setInspecNamearray(confirmedList);
  
  
  
            setInspectionTypesStatus(confirmedList);
            // for(var i=0;i<res.rows.length;i++)
            // {
            //    var booktemp = {
            //       "id" : res.rows[i].id,
  
            //       "name" :  res.rows[i].name,
            //       "status":
            //    };
            // }
  
            for (var i = 0; i < sort_by_key(res.rows, 'name').length; i++) {
                for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {
  
                    if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
                        var inspection =
                        {
                            "id": res.rows[i].id,
  
                            "name": res.rows[i].name,
                            "status": inspectionData[j].inspectionStatus
                        }
  
                        inspectionNames.push(inspection);
                    } else {
  
                    }
  
                }
  
                // arrayinspection.push(inspectionData[i].inspectionStatus)
  
  
  
            }
            var dummayarray = res.rows;
            var tempArray = [];
            for (var i = 0; i < dummayarray.length; i++) {
  
                // const rr=dummayarray[i].name;
                for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {
  
                    if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
  
                        tempArray.push({ "id": dummayarray[i].id, "name": dummayarray[i].name, "status": inspectionData[j].inspectionStatus })
                    }
                    else {
  
                        //tempArray.push({"id":dummayarray[i].id,"name":dummayarray[i].name, "status":null})
  
                    }
  
                }
  
  
            }
  
            // tempArray.push(confirmedList);
            var output = confirmedList.concat(tempArray);
  
            const set = new Set(output.map(item => JSON.stringify(item)));
            const dedup = [...set].map(item => JSON.parse(item));
            //  console.log(`Removed ${tempArray.length - dedup.length} elements`);
            console.log("Inspection list"+JSON.stringify(dedup));
  
            setInspecchecked(unique(dedup));
            dedup.filter((obj, pos, arr) => {
                return arr.map(mapObj =>
                    mapObj.name).indexOf(obj.name) == pos;
            });
  
            // removeDupliactes(standardsList)
  
  
        });
  
  
        //getInspectionType(setInspectionTypes);
  
        setOriginalDate(assignedDate ? new Date(assignedDate) : "");
        // setVehicleId(vehicleId);
        setCustomerId(customerId);
        setOrderId(orderId);
    }
    //At GARAGE

    const [totalItemsAt, setTotalItemsAt] = useState();
    const [currentPageAt, setCurrentPageAt] = useState(1);
    const [totalpagesAt, settotalPagesAt] = useState();
    const [searchAt, setSearchAt] = useState("");
    const [sortingAt, setSortingAt] = useState({ field: "", order: "" });
    const [displayPageAt, setdisplayPageAt] = useState([]);


    //Upcoming

    const [totalItems, setTotalItems] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, settotalPages] = useState();
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [displayPage, setdisplayPage] = useState([]);

    const headersAt =
        [
            // { name: "Vehicle Number", field: "lisencePlateNumber", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
            // { name: "Purpose", field: "documentSubCategoryName", sortable: true, inlineStyle: inlineCss },
            // { name: "Assigned Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            // { name: "View" }

            //Unit Number, VIN Number, Purpose, Garage Name, Assigned Date


            { name: "Unit Number", field: "Unit Number", sortable: true, inlineStyle: inlineCss },
            { name: "VIN Number", field: "VIN Number", sortable: true, inlineStyle: inlineCss },
            { name: "Inspection", field: "purpose", sortable: true, inlineStyle: inlineCss },
            { name: "Garage Name", field: "Garage Name", sortable: false, inlineStyle: inlineCss },
            { name: "Assigned Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            { name: "Actions" },
            // { name: "Actions" }
        ];

    const headDue =
        [
            { name: "Unit Number", field: "Unit Number", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineUo },
            { name: "VIN Number", field: "VIN Number", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineUo },
            // { name: "Category", field: "Document Category", sortable: true, inlineStyle: inlineCss },
            { name: "Inspection", field: "documentSubCategoryName", sortable: true, inlineStyle: inlineCss },
            // { name: "Document Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            { name: "Expiry Date", field: "expiryDate", sortable: false, inlineStyle: inlineCss },
            { name: "Actions", inlineStyle: inlineWidthFix }
        ];

    //Duo

    const [totalItemsDue, setTotalItemsDue] = useState();
    const [currentPageDue, setCurrentPageDue] = useState(1);
    const [totalpagesDue, settotalPagesDue] = useState();
    const [searchDue, setSearchDue] = useState("");
    const [sortingDue, setSortingDue] = useState({ field: "", order: "" });
    // const ITEMS_PER_PAGEAt = 25;
    const [displayPageDue, setdisplayPageDue] = useState([]);
    const [documentLists, setdocuments] = useState([]);
    const [inspectionLists, setinspectionList] = useState([]);
    const headersDue =
        [
            { name: "Vehicle Number", field: "lisencePlateNumber", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
            { name: "Purpose", field: "documentCategoryName", sortable: true, inlineStyle: inlineCss },
            // { name: "Due Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            { name: "View", inlineStyle: inlineWidthFix },
            { name: "Actions" }
        ];

    //Completed

    const [totalItemsCompleted, setTotalItemsCompleted] = useState();
    const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
    const [totalpagesCompleted, settotalPagesCompleted] = useState();
    const [searchCompleted, setSearchCompleted] = useState("");
    const [sortingCompleted, setSortingCompleted] = useState({ field: "", order: "" });
    // const ITEMS_PER_PAGEAt = 25;
    const [displayPageCompleted, setdisplayPageCompleted] = useState([]);

    const headersCompleted =
        [
            { name: "Unit Number", field: "unitNumber", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
            { name: "VIN Number", field: "vinNumber", sortable: true, inlineStyle: inlineCss },
            // { name: "Category", field: "Document Category", sortable: true, inlineStyle: inlineCss },
            { name: "Inspection", field: "docSubCategory", sortable: true, inlineStyle: inlineCss },
            // { name: "Document Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            { name: "Expiry Date", field: "expiryDate", sortable: false, inlineStyle: inlineCss },
            { name: "Actions" }
        ];

    const redirect = (page, workOrderNumber) => 
    {

        navigate(page);
        sessionStorage.setItem("reminderWorkOrderNumber", workOrderNumber);
    }

    const listVendorsApi = () => 
    {
        var listVendorsReq =
        {
            "pageNumber": '1',
            "pageSize": '1000',
            "isActive": true
        };

        var requestOptions = requestObject.RequestHeader(listVendorsReq);
        services.listVendors(requestOptions).then((res) => {

            setListVendors(res.rows);
        });
    }
    useEffect(() => {

        sessionStorage.removeItem("reminderWorkOrderNumber");

        sessionStorage.removeItem("resolveAll");
        sessionStorage.removeItem("resolveSignleInspection");
        sessionStorage.removeItem("reminderDocumentId");
        listVendorsApi();
        // dueListApi();
        // atGarage();
        //  inspectionReminder();

        setSelectedValue(sessionStorage.getItem("selected"));
        $("#loadingforsubmit").hide();
        var vechileReq =
        {
            "pageNumber": "1",
            "pageSize": ITEMS_PER_PAGE_DUE,
            "vinNumber": "",
            // "lisencePlateNumber": "",
            // "vehicleTypeId": "",
            // "overDue": duestatus,
            // "sortBy": "",
            // "sortDir": "",
            "isActive": true,
            "isCancelled":isCancelled

        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.listReminderVehicles(requestOptions).then((res) => {
            $("#loading").hide()


            setTotalItemsDue(res.customValues ? res.customValues.totalNumberOfRecords : 0);

            settotalPagesDue(Math.ceil(res.customValues ? res.customValues.totalNumberOfPages : '' / ITEMS_PER_PAGE));
            setCurrentPageDue(res.customValues ? res.customValues.currentPageNumber : '');
            let temarray = [];
            let parentJson = [];
            if (res.rows) {
                if (res.rows.length > 0) {

                    // setDue(res.rows);



                    for (var i = 0; i < res.rows.length; i++) {

                        // setinspectionList(res.rows[i].inspectionList);
                        // setdocslist(res.rows[i].documentList);
                        //    for(var j=0;j<res.rows[i].documentList.length;j++)
                        //    {

                        //       for(var k=0;k<res.rows[i].inspectionList.length;k++)
                        //         {
                        //             if(res.rows[i].documentList[j].documentSubCategoryName===res.rows[i].inspectionList[k].documentSubCategoryName)
                        //             {
                        //                 console.log("if");

                        //             }else{
                        //                 temarray.push({"documentSubCategoryName":res.rows[i].documentList[j].documentSubCategoryName,"expiryDate":res.rows[i].documentList[j].expiryDate,"id":res.rows[i].documentList[j].id})
                        //             }
                        //         }

                        //    }

                        // const finalData1 = res.rows[i].documentList.concat(res.rows[i].inspectionList).reduce(function (index, obj) {
                        //     index[obj.id] = Object.assign({}, obj, index[obj.id]);
                        //     return index;
                        // }, []).filter(function (res, obj) {
                        //     return obj;
                        // });
                        //     let hashData = new Map();

                        //     res.rows[i].documentList.concat(res.rows[i].inspectionList).forEach(function (obj) {
                        //         hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                        //     });

                        //     const finalData2 = Array.from(hashData.values());
                        //    // setdocuments(finalData2);
                        //     temarray.push(finalData2);

                        //     parentJson.push({"assignedDate":res.rows[i].assignedDate,"id":res.rows[i].id,"unitNumber":res.rows[i].unitNumber,"vehicleId":res.rows[i].vehicleId,"vendorId":res.rows[i].vendorId,"vendorName":res.rows[i].vendorName,"vinNumber":res.rows[i].vinNumber,"workOrderNumber":res.rows[i].workOrderNumber,"mergedocumentList":finalData2,"documentList":res.rows[i].documentList,"inspectionList":res.rows[i].inspectionList})

                        //     }
                        let hashData = new Map();

                        if (res.rows[i].documentList === null || res.rows[i].documentList.length == 0) {
                       
                            res.rows[i].inspectionList.concat(res.rows[i].inspectionList).forEach(function (obj) 
                            {
                                hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                            });
                            const finalinspectionData = Array.from(hashData.values());
                            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalinspectionData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status })

                        } else if (res.rows[i].inspectionList === null || res.rows[i].inspectionList.length == 0) 
                        {

                            res.rows[i].documentList.concat(res.rows[i].documentList).forEach(function (obj) {
                                hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                            });
                            const finalDocumentData = Array.from(hashData.values());
                            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalDocumentData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status })
                        }
                        else {


                            res.rows[i].documentList.concat(res.rows[i].inspectionList).forEach(function (obj) {
                                hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                            });
                            const finalData2 = Array.from(hashData.values());
                            // setdocuments(finalData2);
                            temarray.push(finalData2);

                            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalData2, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status })
                        }

                        //  var arr = inspectionLists.concat(docslist);
                    //   console.log("results=="+JSON.stringify(parentJson))
                        // var sorted_arr = arr.sort();
                        // var results = [];
                        // for (var i = 0; i < arr.length - 1; i++) {
                        //     if (sorted_arr[i + 1] == sorted_arr[i]) {
                        //         results.push({"documentSubCategoryName":arr[i].documentSubCategoryName,"expiryDate":arr[i].expiryDate,"id":arr[i].id});
                        //     }
                        // }
                        // console.log("results==" + JSON.stringify(parentJson));
                        //const myTimeout = setTimeout( setdocuments(temarray), 5000);
                        //    setdocuments(temarray);
                        //   setdocuments(temarray);
                        setDue(parentJson);
                    }
                } else {


                    setDue("");
                }
            }
            //setTotalItemsDue(res.customValues.totalNumberOfRecords);

            var displaypages = [];

            if (res.customValues) {
                for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE)); i++) {

                    displaypages.push(i * ITEMS_PER_PAGE);
                }
                if (displaypages.length > 0) {
                    setdisplayPageDue(displaypages);
                } else {
                    setdisplayPageDue([0]);
                }
            }


            //setDue(res.rows);

            // DueSearch("1", "1", ITEMS_PER_PAGE, "", "", ITEMS_PER_PAGE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems");
        });

        if (sessionStorage.getItem("selected") == "overDue") {
            setduestatus(true);
            // $('#dueSlected').prop("checked", true).trigger("change");

            $('#dueSlected').trigger('click');
            overDueChecked();
            //  dueListApi();
            //    $('#dueSlected input[type="checkbox"]').prop("checked", true);
            //    $('input[name=check1]').attr('checked', true).triggerHandler('click');
            //    DueSearch("1", "1", ITEMS_PER_PAGE_DUE, "", "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems",true);
            sessionStorage.removeItem("overDue");

        } else if (sessionStorage.getItem("selected") === "inprocess") {
           // sessionStorage.removeItem("inprocess");
            //document.getElementById("pills-garage-tab").click();
        }
        else if (sessionStorage.getItem("selected") === "completed") {
            sessionStorage.removeItem("completed");
            document.getElementById("pills-completed-tab").click();
        }


        $('#display_pages_due_search').on('change', function () {

            setItemPerDuePage($("#display_pages_due_search").val());
            DueSearch("", "1", $("#display_pages_due_search").val(), dueSearchValue, "", $("#display_pages_due_search").val(), setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "", duestatus,isCancelled);

        });

        $('#display_pages_in_items2').on('change', function () {

            setItemPerPage($("#display_pages_in_items2").val());
            UpcomingSearch("1", "1", $("#display_pages_in_items2").val(), "", "", $("#display_pages_in_items2").val(), setTotalItems, settotalPages, setCurrentPage, setupcoming, setdisplayPage, sortingField, sortingOrder, "");
        });


        $('#display_pages_in_itemsCompleted').on('change', function () {
            setItemPerPage($("#display_pages_in_itemsCompleted").val());
            CompletedSearch("", "1", $("#display_pages_in_itemsCompleted").val(), completedSearchValue, $("#display_pages_in_itemsCompleted").val(), setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, sortingField, sortingOrder, "");
        });

        $(document).on("click", "#dueSlected", function () {
            setduestatus($(this).is(':checked'));
            setSearchValueDue($("#searchValue").val());
            DueSearch("1", "1", ITEMS_PER_PAGE_DUE, $("#searchValue").val(), "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems", $(this).is(':checked'),isCancelled);

        });



    }, []);



    const searchListAt = (atGrageSearchValue) => {
        // alert(atGrageSearchValue);
        setSearchValueGarage(atGrageSearchValue);
        // alert(atGrageSearchValue);
        // AtGarageSearch();
        AtGarageSearch("1", "1", ITEMS_PER_PAGE, atGrageSearchValue, '', ITEMS_PER_PAGE, setTotalItemsAt, settotalPagesAt, setCurrentPageAt, SetassingedVehicles, setdisplayPageAt, "", sortingOrder, "displayItems");
    }

    const onSortingChangeAtGarage = (field) => {
        var vehicleId = "";
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        AtGarageSearch("", "1", ITEMS_PER_PAGE, atGarageSearchValue, vehicleId, ITEMS_PER_PAGE, setTotalItemsAt, settotalPagesAt, setCurrentPageAt, SetassingedVehicles, setdisplayPageAt, field, order, "displayItems");

    };



    const searchListDue = (dueSearchValue) => {

        setSearchValueDue(dueSearchValue);

        DueSearch("1", "1", ITEMS_PER_PAGE_DUE, dueSearchValue, "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems", duestatus,isCancelled);
    }

    const searchListCompleted = (completedSearchValue) => {
        setSearchValueCompleted(completedSearchValue);
        CompletedSearch("", "1", ITEMS_PER_PAGE, completedSearchValue, ITEMS_PER_PAGE, setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, sortingField, sortingOrder, "displayItems");
    }

    const onSortingChangeCompleted = (field) => {

        var vehicleId = "";
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        CompletedSearch("1", "1", ITEMS_PER_PAGE, completedSearchValue, ITEMS_PER_PAGE, setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, field, order, "displayItems");

    };


    const vehicleInfo = (id, workOrderNumber) => {
        sessionStorage.setItem("resolveAll", "true");
        sessionStorage.setItem("reminderWorkOrderNumber", workOrderNumber);
        localStorage.setItem("vehicleId", id);
        navigate("/fleetowner/AssignToGarrage/" + id);
        // sessionStorage.setItem("resolveSignleInspection", "true");
        // sessionStorage.setItem("selectedinspectionName", inspectionName);
        // sessionStorage.setItem("selectedinspectionNameId", inspectionName);
        // sessionStorage.setItem("reminderDocumentId", documentId);
    }


    const atGarage = () => {

        $('#display_pages_in_itemsAtGarage').on('change', function () {
            // alert($("#display_pages_in_itemsAtGarage").val());
            setItemPerPage(this.value);
            // setItemPerPage($("#display_pages_in_itemsAtGarage").val());
            AtGarageSearch("", "1", this.value, atGarageSearchValue, "", this.value, setTotalItemsAt, settotalPagesAt, setCurrentPageAt, SetassingedVehicles, setdisplayPageAt, sortingField, sortingOrder, "");
        });


        var vechileReq =
        {
            "pageNumber": "1",
            "pageSize": "10",
            "lisencePlateNumber": "",
            "vehicleTypeId": "",
            // "isInspected": "false",
            "isInspectionActive": true,
            "isActive": true,
            "sortBy": "",
            "sortDir": "",


        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.listOfAssginedVehicles(requestOptions).then((res) => {




            SetassingedVehicles(res.rows);

            setTotalItemsAt(res.customValues ? res.customValues.totalNumberOfRecords : '');
            settotalPagesAt(Math.ceil(res.customValues ? res.customValues.totalNumberOfPages : '' / ITEMS_PER_PAGE));
            setCurrentPageAt(res.customValues ? res.customValues.currentPageNumber : null);
            AtGarageSearch("1", "1", ITEMS_PER_PAGE, "", "", ITEMS_PER_PAGE, setTotalItemsAt, settotalPagesAt, setCurrentPageAt, SetassingedVehicles, setdisplayPageAt, sortingField, sortingOrder, "displayItems");
        });

    }


    const completedListApi = () => {
        var vechileReq =
        {
            "pageNumber": "1",
            "pageSize": ITEMS_PER_PAGE,
            "vinNumber": "",
            // "lisencePlateNumber": "",
            // "vehicleTypeId": "",
            // "isInspected": true,
            // "isInspectionActive": true,
            // "isActive": true,
            // "sortBy": "",
            // "sortDir": ""


        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.listCompletedVehicles(requestOptions).then((res) => {

            setCompleted(res.rows);


            setTotalItemsCompleted(res.customValues ? res.customValues.totalNumberOfRecords : '');
            settotalPagesCompleted(Math.ceil(res.customValues ? res.customValues.totalNumberOfPages : '' / ITEMS_PER_PAGE));
            setCurrentPageCompleted(res.customValues ? res.customValues.currentPageNumber : 1);
            CompletedSearch("1", "1", ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, sortingField, sortingOrder, "displayItems");
        });

    }


    const handlePageChangeAtGarage = (offset, triggerevent) => {

        AtGarageSearch("", offset, ITEMS_PER_PAGE, atGarageSearchValue, "", ITEMS_PER_PAGE, setTotalItemsAt, settotalPagesAt, setCurrentPageAt, SetassingedVehicles, setdisplayPageAt, sortingField, sortingOrder, "");
    }

    const handlePageChangeDue = (offset, triggerevent) => {

        DueSearch("", offset, ITEMS_PER_PAGE_DUE, dueSearchValue, "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "", duestatus,isCancelled);
    }


    const handlePageChangeCompleted = (offset, triggerevent) => {
        CompletedSearch("", offset, ITEMS_PER_PAGE, completedSearchValue, ITEMS_PER_PAGE, setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, sortingField, sortingOrder, "");
    }


    const onSortingChangeDue = (field) => {
        // alert(duestatus);
        var vehicleId = "";
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        DueSearch("1", "1", ITEMS_PER_PAGE_DUE, dueSearchValue, vehicleId, ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, field, order, "displayItems", duestatus,isCancelled);

    };

    function overDueChecked() {

        setduestatus(true);
        setSearchValueDue($("#searchValue").val());
        // DueSearch("1", "1", ITEMS_PER_PAGE_DUE, $("#searchValue").val(), "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems", true);
    }

    // const onSortingChangeUpcoming = (field) => {

    //     var vehicleId = "";
    //     const order =
    //         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    //     setSortingField(field);
    //     setSortingOrder(order);
    //     // UpcomingSearch("1", "1", ITEMS_PER_PAGE, "", vehicleId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setupcoming, setdisplayPage, field, order, "displayItems");
    //     DueSearch("1", "1", ITEMS_PER_PAGE, "", vehicleId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setupcoming, setdisplayPage, field, order, "displayItems",duestatus);

    // };

    const CompletedListAPICall = () => {
        // completedListApi();
        CompletedSearch("1", "1", ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItemsCompleted, settotalPagesCompleted, setCurrentPageCompleted, setCompleted, setdisplayPageCompleted, sortingField, sortingOrder, "displayItems");
    }

    var activestatus = "false";
    const canceledworkorders = (event) => {

        if (event.target.checked) {
            activestatus = "true";
        } else {
           
        }

        setisCancelled(activestatus);

        DueSearch("1", "1", ITEMS_PER_PAGE_DUE, dueSearchValue, vehicleId, ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, "", "", "displayItems", duestatus,activestatus);

        //setInactiveStatus(activestatus);
     
    }
    return (
        <>
            {loadingComponent}
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <h4 class="font-weight-bold my-4 text-uppercase">Reminders</h4>
                        </div>
                    </div>
                    <div class="row mb-2 ">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div>
                                <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-upcoming-tab" data-toggle="pill" href="#pills-upcoming" role="tab" aria-controls="pills-upcoming" aria-selected="false">Upcoming/Overdue</a>
                                    </li>
                                    {/* <li class="nav-item">
                                        <a class="nav-link " id="pills-garage-tab" data-toggle="pill" href="#pills-garage" role="tab" aria-controls="pills-garage" aria-selected="false">Inprocess</a>
                                    </li> */}
                                    {/* <li class="nav-item">
                                        <a class="nav-link" id="pills-due-tab" data-toggle="pill" href="#pills-due" role="tab" aria-controls="pills-due" aria-selected="false">Due</a>
                                    </li> */}
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-completed-tab" onClick={() => CompletedListAPICall()} data-toggle="pill" href="#pills-completed" role="tab" aria-controls="pills-completed" aria-selected="false">Completed</a>
                                    </li>

                                </ul>
                                <div class="tab-content" id="pills-tabContent">

                                    <div class="tab-pane show active" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div className="d-flex justify-content-start align-items-center p-3">
                                                        <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                            <Search placeholderName="Search by VIN Number"
                                                                onSearch={value => {
                                                                    searchListDue(value);
                                                                }} id="upcomingSearchValue" />
                                                            <a class="search-icon">
                                                                <img src="/images/icons/search.svg" alt="" />
                                                            </a>
                                                        </div>
                                                        {roles.match("Driver") || roles.match("Dispatcher") ? "" : <><input type="checkbox"
                                                            onChange={(e) => canceledworkorders(e)}
                                                            id="inactive"
                                                            name="inactive"

                                                            style={{ marginLeft: "21px", marginTop: "4px" }} /> <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                                                Show Cancelled Work Orders
                                                            </span></>}
                                                    </div>
                                                    <div className="card-body px-0 pt-0 pb-0">
                                                        <div class="border-top border-bottom pt-3">
                                                            <table className="w-100 hr-pop">
                                                                {/* <tr className="border-bottom py-3">
                                                            <th style={{width:"33%", padding:"8px 8px 8px 24px"}}>Unit Number</th>
                                                            <th style={{width:"33%", padding:"8px"}}>VIN Number</th>

                                                            <th style={{width:"14%", padding:"8px"}}>Assigned Date</th>
                                                            <th style={{width:"150px", padding:"8px"}}>Actions</th>

                                                        </tr> */}
                                                                {
                                                                    dueList ? dueList.map((data, index) => (
                                                                        <tr>
                                                                            <td colSpan={5} className="px-3">
                                                                                <div className="w-100 bg-rem-grd">
                                                                                    <div className="top-rem-unit-vin">
                                                                                        <div className="d-flex">
                                                                                            <label htmlFor="" className="text-uppercase mb-0" style={{ width: "40px" }}>Unit: </label>
                                                                                            <span>{data.unitNumber ? data.unitNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <label htmlFor="" className="text-uppercase mb-0" style={{ width: "34px" }}>Vin: </label>
                                                                                            <span>{data.vinNumber ? data.vinNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div style={{ width: "150px" }} className="ml-auto">
                                                                                            <a onClick={() => redirect("/fleetowner/VehicleInformation/" + data.vehicleId, data.workOrderNumber)} class="btn btn-outline-primary  text-uppercase">View</a>
                                                                                            {/* <a href="" class="btn disable-btn text-uppercase ml-2">Resolve</a> */}
                                                                                            {roles.match("Driver") ? "" : data.inspectionList === null || data.inspectionList.length == 0 ? isCancelled=="true"?"":<a onClick={() => vehicleInfo(data.vehicleId, data.workOrderNumber)} class="btn btn-primary text-uppercase ml-2">Resolve</a> : isCancelled=="true"?"":<a class="btn disable-btn text-uppercase ml-2">Resolve</a>}
                                                                                        </div>
                                                                                    </div>

                                                                                    {data.inspectionList === null || data.inspectionList.length == 0 ? "" : <div className="wopopven">
                                                                                        <div className="wo">
                                                                                            <span>WO: </span>
                                                                                            <span>{data.workOrderNumber ? data.workOrderNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn mx-3">
                                                                                            <label htmlFor="">Vendor: </label>
                                                                                            <span>{data.vendorName ? data.vendorName : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn ml-3">
                                                                                            <label htmlFor="">Date: </label>
                                                                                            <span>{data.assignedDate ? moment(data.assignedDate).format('MM-DD-YYYY') : "--"}</span>
                                                                                        </div>

                                                                                        {roles.match("Driver") ? "" :
                                                                                            data.inspectionList === null || data.inspectionList.length == 0 ? "" : isCancelled=="true"?"":<a href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" class="btn btn-primary text-uppercase ml-auto" data-target="#updateModal" onClick={() => updateTask(data.workOrderNumber, data.inspectionList, data.assignedDate, data.id, data.vendorName, data.vehicleId, data.vendorId,data.notes)}>EDIT</a>}


                                                                                    </div>}
                                                                                    <div className="innertabDv">
                                                                                        <table className="w-100">
                                                                                            <tr>
                                                                                                <th style={{ width: "33%", padding: "8px 8px 8px 24px" }}>Inspection Type</th>
                                                                                                <th style={{ width: "20%", padding: "8px" }}>Expiry Date</th>
                                                                                                <th style={{ width: "auto", padding: "8px" }}>Status</th>
                                                                                            </tr>
                                                                                            {

                                                                                                data.mergedocumentList ? data.mergedocumentList.map((data1, index) => {
                                                                                                    return <tr>
                                                                                                        <td style={{ width: "auto", padding: "14px 8px 14px 24px" }}>{data1.documentSubCategoryName ? data1.documentSubCategoryName : "--"}</td>
                                                                                                        <td style={{ width: "20%", padding: "14px 8px" }}>{data1.expiryDate ? moment(data1.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                                                        <td style={{ width: "15%", padding: "14px 8px" }} className="approve-pending font-weight-bold">

                                                                                                            {(() => {
                                                                                                                var today = new Date();
                                                                                                                var dd = String(today.getDate()).padStart(2, '0');
                                                                                                                var mm = String(today.getMonth() + 1).padStart(2, '0');
                                                                                                                var yyyy = today.getFullYear();

                                                                                                                today = mm + '/' + dd + '/' + yyyy;
                                                                                                                const currentDate = new Date(today);
                                                                                                                const expiredDate = new Date(moment(data1.expiryDate).format('MM-DD-YYYY'));
                                                                                                                if (currentDate <= expiredDate) {
                                                                                                                    return ("Upcoming");
                                                                                                                }
                                                                                                                else if (currentDate > expiredDate) {
                                                                                                                    return ("Overdue");
                                                                                                                } else {
                                                                                                                    return (data1.status);
                                                                                                                }


                                                                                                            })()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }) : ""
                                                                                            }
                                                                                            {/* {

                                                                                                data.inspectionList ? data.inspectionList.map((inspection, index) => {
                                                                                                    return <tr>
                                                                                                        <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>{inspection.documentSubCategoryName ? inspection.documentSubCategoryName : "--"}</td>
                                                                                                        <td style={{ width: "20%", padding: "14px 8px" }}>{inspection.expiryDate ? moment(inspection.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                                                        <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approve-pending font-weight-bold">InProcess</td>
                                                                                                    </tr>
                                                                                                }) : ""
                                                                                                } */}
                                                                                            {/* <tr>
                                                                                                <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>Pressure Test</td>
                                                                                                <td style={{ width: "20%", padding: "14px 8px" }}>22-12-2022</td>
                                                                                                <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approve-pending font-weight-bold">Inprocess</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>Leakage Test</td>
                                                                                                <td style={{ width: "20%", padding: "14px 8px" }}>29-12-2022</td>
                                                                                                <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approved font-weight-bold">Completed</td>
                                                                                            </tr> */}
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )) : ""
                                                                }







                                                            </table>
                                                            {dueList ? dueList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                            </div>}
                                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                                <div class="d-flex">
                                                                    <div class="dropdown show mr-3 ">
                                                                        <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_due_search" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {
                                                                                displayPageDue != null ? displayPageDue.map((data, index) => (
                                                                                    <option value={data}>{data}</option>
                                                                                )) : <option value='0'>0</option>
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                    <span class="d-flex align-items-center pagenation-mob">{currentPageDue * ITEMS_PER_PAGE_DUE <= totalItemsDue ? currentPageDue * ITEMS_PER_PAGE_DUE : totalItemsDue} out of {totalItemsDue} Records Displayed</span>
                                                                </div>

                                                                <nav aria-label="Page navigation example">
                                                                    {totalItemsDue > 0 ? <Pagination

                                                                        total={totalItemsDue}
                                                                        itemsPerPage={ITEMS_PER_PAGE_DUE}
                                                                        currentPage={currentPageDue}
                                                                        onPageChange={handlePageChangeDue}
                                                                    /> : ""}
                                                                </nav>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="tab-pane" id="pills-completed" role="tabpanel" aria-labelledby="pills-completed-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div className="d-flex justify-content-start align-items-center p-3">
                                                        <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                            <Search placeholderName="Search by VIN Number"
                                                                onSearch={value => {
                                                                    searchListCompleted(value);
                                                                }} />
                                                            <a class="search-icon">
                                                                <img src="/images/icons/search.svg" alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="card-body px-0 pt-0 pb-0">
                                                        <div class="border-top border-bottom p-3">
                                                            <table className="w-100">
                                                                {/* <tr className="border-bottom py-3">
                                                            <th style={{width:"33%", padding:"8px 8px 8px 24px"}}>Unit Number</th>
                                                            <th style={{width:"33%", padding:"8px"}}>VIN Number</th>

                                                            <th style={{width:"14%", padding:"8px"}}>Assigned Date</th>
                                                            <th style={{width:"150px", padding:"8px"}}>Actions</th>

                                                        </tr> */}
                                                                {
                                                                    completedList ? completedList.map((data, index) => (
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <div className="w-100 bg-rem-grd">
                                                                                    <div className="top-rem-unit-vin">
                                                                                        <div>
                                                                                            <label htmlFor="" className="text-uppercase mb-0">Unit: </label>
                                                                                            <span>{data.unitNumber ? data.unitNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label htmlFor="" className="text-uppercase mb-0">Vin: </label>
                                                                                            <span>{data.vinNumber ? data.vinNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div style={{ width: "150px" }} className="ml-auto">
                                                                                            <a onClick={() => redirect("/fleetowner/VehicleInformation/" + data.id, data.workOrderNumber)} class="btn btn-outline-primary  text-uppercase">View</a>
                                                                                            {/* <a href="vehicle_at_garage.html" class="btn disable-btn text-uppercase ml-2">Resolve</a> */}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="wopopven">
                                                                                        <div className="wo">
                                                                                            <span>WO: </span>
                                                                                            <span>{data.workOrderNumber ? data.workOrderNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn mx-3">
                                                                                            <label htmlFor="">Vendor: </label>
                                                                                            <span>{data.vendorName ? data.vendorName : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn ml-3">
                                                                                            <label htmlFor="">Date: </label>
                                                                                            <span>{data.assignedDate ? moment(data.assignedDate).format('MM-DD-YYYY') : "--"}</span>
                                                                                        </div>

                                                                                        {/* <a href="vehicle_at_garage.html" class="btn btn-primary text-uppercase ml-auto">EDIT</a> */}
                                                                                    </div>
                                                                                    <div className="innertabDv">
                                                                                        {(() => {
                                                                                            if (data.inspectionList.length > 0) {
                                                                                                return (
                                                                                                    <table className="w-100">
                                                                                                        <tr>
                                                                                                            <th style={{ width: "auto", padding: "8px 8px 8px 24px" }}>Inspection Type</th>
                                                                                                            {/* <th style={{ width: "20%", padding: "8px" }}>Expiry Date</th> */}
                                                                                                            <th style={{ width: "10%", padding: "8px" }}>Status</th>
                                                                                                        </tr>
                                                                                                        {

                                                                                                            data.inspectionList != null ? data.inspectionList.map((data1, index) => {
                                                                                                                return <tr>
                                                                                                                    <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>{data1.documentSubCategoryName ? data1.documentSubCategoryName : "--"}</td>
                                                                                                                    {/* <td style={{ width: "20%", padding: "14px 8px" }}>{data1.expiryDate ? moment(data1.expiryDate).format('MM-DD-YYYY') : "--"}</td> */}
                                                                                                                    <td style={{ width: "auto", padding: "14px 8px" }} className="approved font-weight-bold">Completed</td>
                                                                                                                </tr>
                                                                                                            }) : ""
                                                                                                        }
                                                                                                        {/* <tr>
                                                                            <td style={{width:"33%", padding:"14px 8px 14px 24px"}}>Leakage Test</td>
                                                                            <td style={{width:"20%", padding:"14px 8px" }}>29-12-2022</td>
                                                                            <td style={{width:"auto", padding:"14px 8px"}} className="text-uppercase approved font-weight-bold">Completed</td>
                                                                        </tr> */}
                                                                                                    </table>
                                                                                                )
                                                                                            } else {
                                                                                                return (<div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Inspection Data Available to Display</span>
                                                                                                </div>)
                                                                                            }
                                                                                        })()}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )) : ""
                                                                }

                                                            </table>
                                                            {completedList ? completedList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                            </div>}

                                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                                <div class="d-flex">
                                                                    <div class="dropdown show mr-3 ">
                                                                        <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_itemsCompleted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {
                                                                                displayPageCompleted ? displayPageCompleted.map((data, index) => (
                                                                                    <option value={data}>{data}</option>
                                                                                )) : ""
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                    <span class="d-flex align-items-center pagenation-mob">{currentPageCompleted * ITEMS_PER_PAGE <= totalItemsCompleted ? currentPageCompleted * ITEMS_PER_PAGE : totalItemsCompleted} out of {totalItemsCompleted} Records Displayed</span>
                                                                </div>
                                                                <nav aria-label="Page navigation example">
                                                                    {totalItemsCompleted > 0 ? <Pagination

                                                                        total={totalItemsCompleted}
                                                                        itemsPerPage={ITEMS_PER_PAGE}
                                                                        currentPage={currentPageCompleted}
                                                                        onPageChange={handlePageChangeCompleted}
                                                                    /> : ""}
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              <UpdateUpcomingAndoverdue Id={Id} vendorId={vendorId} vehicleId={vehicleId} originalDate={originalDate} setOriginalDate={setOriginalDate} inspectiondisable={inspectiondisable} notes={notes} inspectionNames={inspectionNames} setInspecchecked={setInspecchecked}/>
            
                {/* <div id="updateModal" class="modal fade" role="dialog">
                    <div class="modal-dialog">

                        <form onSubmit={handleSubmit(submitValue)}>
                            <div class="modal-content" style={{ maxHeight: "550px" }}>

                                <div class="modal-header">
                                    <h5 class="modal-title">Update</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => (closeModalPopup())}><span aria-hidden="true">×</span></button>
                                </div>

                                <div class="modal-body scroll-auto">

                                    <div className="row">
                                        <div class="col-md-12">

                                            <div class="form-group mb-3">

                                                <img src="images/icons/workorder-icon.svg" alt="" />
                                                {/* <span class="mr-2 ml-2">Work order Number : </span> 
                                                <label for="wqes" class="w-100">Work order Number : </label>
                                                <input type="input" class="form-control" placeholder="Enter W/O" id="updateworkorder" />

                                            </div>
                                            <div class="form-group mb-3">

                                                <label>Select Garage </label>
                                                {/* <AutoSuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsClearRequested={() => setSuggestions([])}
                                                    onSuggestionsFetchRequested={({ value }) => {

                                                        setValue(value ? value : "");
                                                        setSuggestions(getSuggestions(value));
                                                    }}
                                                    onSuggestionSelected={(_, { suggestion }) =>
                                                        setgarageId(suggestion.id)

                                                    }
                                                    getSuggestionValue={suggestion => suggestion.name}
                                                    renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                                                    inputProps={{
                                                        placeholder: "",
                                                        value: value,
                                                        onChange: (_, { newValue, method }) => {
                                                            setValue(newValue);
                                                        }
                                                    }}
                                                    highlightFirstSuggestion={true}
                                                    readOnly={true}
                                                /> 
                                                <input type="input" class="form-control" placeholder="Garage Name" id="garageName" disabled />

                                                <span id="error-message-garage"></span>
                                                {/* <ReactSearchAutocomplete
                                                                items={listVendors}
                                                                fuseOptions={{ keys: ["id", "name"] }} // Search on both fields
                                                                resultStringKeyName="name" // String to display in the results
                                                                onSearch={handleOnSearch}
                                                                onHover={handleOnHover}
                                                                onSelect={handleOnSelect}
                                                                onFocus={handleOnFocus}

                                                                showIcon={false}
                                                                styling={{
                                                                    height: "34px",
                                                                    // border: "1px solid darkgreen",
                                                                    borderRadius: "4px",
                                                                    // backgroundColor: "white",
                                                                    boxShadow: "none",
                                                                    // hoverBackgroundColor: "lightgreen",
                                                                    // color: "darkgreen",
                                                                    fontSize: "12px",
                                                                    fontFamily: "Montserrat",
                                                                    iconColor: "black",
                                                                    // lineColor: "lightgreen",
                                                                    // placeholderColor: "darkgreen",
                                                                    clearIconMargin: "3px 8px 0 0",
                                                                    zIndex: 2,
                                                                }}
                                                            /> 

                                                {errors.vendorId && errors.vendorId.type === "required" && <span class="error">Garage  is required</span>}
                                                {/* <a href="#" style={{ color: "#022e5f", marginTop: "10px", textDecoration: "underline", float: "right", margin: ".25rem 0" }} data-toggle="modal" data-target="#addGarrage"
                                                                onClick={() =>
                                                                    addNewGarage()
                                                                }>Add New Garage</a> 

                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="wqes" class="w-100">Assign Date </label>
                                                {/* <input type="text" class="form-control" id="qwes"  defaultValue={orderDate}/> 
                                                <DatePicker id="assignDate" minDate={moment(originalDate ? originalDate : "").toDate()} selected={originalDate ? originalDate : new Date()} onChange={(date) => setOriginalDate(date)} name="originalDate" class="form-control" id="originalDate" placeholderText="MM/DD/YYYY" />
                                                {/* <span class="error" id="originDateError">Assign date is required</span>

                                                <br />
                                            </div>
                                            <div class="form-group">
                                                <label for="wqes" class="w-100">Inspection List <span class="star">*</span></label>
                                                <div class="col-md-12" style={{ marginTop: "16px" }}><input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllinspections(e, "")} />
                                                    <label style={{ marginLeft: "26px", fontWeight: "bold", marginBottom: "0px" }}   >Select All</label></div>

                                                {

                                                    inspectiondisable ? inspectiondisable.sort((a, b) => a.name.localeCompare(b.name)).map((inspection, index) => {

                                                        return (<>

                                                            {
                                                                inspectionNames.includes(inspection.name) ?
                                                                    <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" class="form-check-input " defaultChecked={true} value={inspection.id} name="inspectionIds" onClick={() => Unchecked(inspection.id, inspection.name)} /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div> </div> :
                                                                    <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" defaultChecked={false} class="form-check-input " name="inspectionIds" value={inspection.id} onClick={() => Unchecked(inspection.id, inspection.name)} /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div></div>
                                                            }
                                                        </>)

                                                    }) : ""}
                                                <span id="error-messageIn"></span>


                                                <br />
                                                <label for="addnotes" className="mt-2">Add note :</label>

                                                <textarea id="notes" name="addnotes" rows="4" cols="50" maxLength="300" defaultValue={notes}>

                                                </textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="modal-footer px-3">
                                    <button type="button" class="btn btn-danger border mr-auto" onClick={cancelassign_Garage}>Cancel Order</button>
                                    <button type="button" class="btn btn-secondary border" data-dismiss="modal" id="closeModal" onClick={cancel} >Cancel</button>
                                    <button type="submit" class="btn btn-primary"  >Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div> */}
            </div>

        </>
    )
};

Reminder.propTypes = {};

Reminder.defaultProps = {};

export default Reminder;
