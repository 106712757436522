import React, { history, useEffect, useState, useMemo, useRef } from "react";
import services from "../../../services/services";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import $ from "jquery"
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import vehicle_details_api from "../vehicle/vehicle_details_api";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
import moment from 'moment';
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
const EditDocument = () => {
   const [docsCategory, setDocsCategory] = useState([]);
   const [loading, setLoading] = useLoading("Please wait...");
   const [itemName, setFieldName] = useState('');
   const [base64file, setBase64file] = useState('');
   const [fileName, setfileName] = useState("");
   const [extension, setfileExtension] = useState("");
   const [imageName, setImageName] = useState("");
   // const [fileName, setfileName] = useState('');
   const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const { customerId, vehicleId, documentId } = useParams();
   const [vechileDetails, setVehicleDetails] = useState([]);
   const [cid, setCustomerId] = useState([]);
   const [documentDetails, setDocumentDetails] = useState([]);
   const [attachedPdf, setattachPdf] = useState("");
   const [selectdocsCategory, setslectedDocCategory] = useState("");
   const [navigationfrom, setNavigationFrom] = useState("");

   const navigate = useNavigate();
   // var navigationfrom='';

   $(document).ready(function () {
      $('#created_date,#expiryDate').keypress(function( e ) {
         if(e.which === 32){
             return false;
         }
     });

   });

   useEffect(() => {
      $("#loadingfordocs").hide();
      // navigationfrom = sessionStorage.getItem("naviationfrom");
      // sessionStorage.setItem("naviationfrom","adddocuments");
      setNavigationFrom(sessionStorage.getItem("naviationfrom"));
      if (CheckAuthentication) {
         setCustomerId(customerId);
         getDocumentCategorie();
         vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId, "dontcallcontactsAPI");
         document_details(documentId, false);
      }
      else {
         sessionStorage.clear();
         localStorage.clear();
         //navigate("/login");
         window.location.replace("login");
      }

   }, []);

   const redirect = () => {

      if (navigationfrom === "AddDocument") {
         navigate("/documents");
         // sessionStorage.removeItem("naviationfrom");
      } else if (navigationfrom === "adddocuments") {
         navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"))
         // sessionStorage.removeItem("naviationfrom");
      } else if (navigationfrom === "customerDocuments") {
         navigate("/customerDetails/" + localStorage.getItem("customerId"))
         // sessionStorage.removeItem("naviationfrom");
      }

   }
   //////////////submitvalues///////////////////////////
   const onChangeValue = (event) => {


      if (event.target.value === "others") {
         getVehicle_Docs();

      } else {
         getInspectionDocument();
      }

   }
   ////////////////Update Customer/////////////////////////////
   const getVehicle_Docs = () => {


      var inspectionDocs =
      {

      };


      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vehicle_request)
      // };
      var requestOptions = requestObject.RequestHeader(inspectionDocs);

      services.getVehicleDocumentSubCategories(requestOptions).then((res) => {


         setDocsCategory(res.rows);

      });

   }

   ////////////////Update Customer/////////////////////////////
   const getInspectionDocument = () => {


      var inspectionDocs =
      {

      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vehicle_request)
      // };
      var requestOptions = requestObject.RequestHeader(inspectionDocs);

      services.getInspectionDocumentSubCategories(requestOptions).then((res) => {


         setDocsCategory(res.rows);

      });

   }
   const document_details = (documentId, btn_call) => {



      var document_request =
      {

         "id": documentId

      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(document_request)
      // };
      var requestOptions = requestObject.RequestHeader(document_request);
      $("#loadingfordocs").show();
      services.getDocumentDetails(requestOptions).then((res) => {


         $("#loadingfordocs").hide();
         setDocumentDetails(res.rows);

         $("#documentTitle").val(res.rows.title);


         setslectedDocCategory(res.rows.fkDocumentCategoryId);
         // $("#doucmenttype").val(res.rows.documentCategoryName);
         // $("#documentDate").val(res.rows.documentDate);
         // $("#expiryDate").val(res.rows.expiryDate);

         setfileExtension(res.rows.imageName ? res.rows.imageName.split('.').pop() : "");
         
         setfileName(res.rows.title ? res.rows.imageName.split('/').pop() : "");
         setEndDate(new Date(res.rows.expiryDate));
         setStartDate(new Date(res.rows.documentDate));
         reset({
            createdDate: res.rows ? new Date(res.rows.documentDate) : "", // Set another date
            expiryDate: res.rows ? new Date(res.rows.expiryDate) : "", // Set another date
            doctype: res.rows ? res.rows.fkDocumentCategoryId : "",
         });
         setattachPdf(res.customValues ? res.customValues.fileByte : "");
         if (res.rows.documentCategoryName === "Inspection Documents") {
            $("#inspection").attr('checked', true);
            getInspectionDocument();
            setslectedDocCategory(res.rows.documentSubCategoryId);

         } else {
            $("#others").attr('checked', true);
            getVehicle_Docs();
            setslectedDocCategory(res.rows.documentSubCategoryId);

         }
         if (btn_call) {
            if (res.customValues.fileByte === "") {
               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: "File is not attached",

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            } else {
               //   var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");

               //   var byteNumbers = new Array(byteCharacters.length);
               //   for (var i = 0; i < byteCharacters.length; i++) {
               //     byteNumbers[i] = byteCharacters.charCodeAt(i);
               //   }
               //   var byteArray = new Uint8Array(byteNumbers);
               //   var file = new Blob([byteArray], { type: 'application/pdf;base64' });
               //   var fileURL = URL.createObjectURL(file);
               //   window.open(fileURL);

               var file;

               if (extension == "pdf") {
                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/pdf;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               else if (extension == "jpg") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "jpeg") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
              }
               else if (extension == "png") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/png;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "bmp") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/bmp;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "word") {

                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/msword;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/msword;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
            }
         }




      });

   }
   const {
      register,
      control,
      reset,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });




   const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
         cb(null, reader.result)
      }
      reader.onerror = function (error) {
         cb(error, null)
      }
   }

   const onUploadFileChange = ({ target }) => {
      const MIN_FILE_SIZE = 51200 // 50MB
      const fileSizeKiloBytes=Math.floor(target.files[0].size/1024); //
  
      if(fileSizeKiloBytes <= MIN_FILE_SIZE){
      var allowedExtensions = /(\jpg|\png|\jpeg|\pdf)$/i;
      var fileInput =
         document.getElementById('file-input');

      var filePath = fileInput.value;

      if (!allowedExtensions.exec(filePath)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               //e.target.value = '';
               return false;
            }
         });

      } else {
         if (target.files < 1 || !target.validity.valid) {
            return
         }
         fileToBase64(target.files[0], (err, result) => {
            if (result) {
               setBase64file(result);
               // setFile(result)
               var fname = target.files[0].name.substring(target.files[0].name.lastIndexOf('/') + 1, target.files[0].name.lastIndexOf('.'));
               setfileName(fname);
               setfileExtension(filePath.split('.').pop())
               if (filePath.split('.').pop() == "jpg" || filePath.split('.').pop() == "jpeg") {

                  setattachPdf(result.replace("data:image/jpg;base64,", ""));
                  setattachPdf(result.replace("data:image/jpeg;base64,", ""));
               }

               else if (filePath.split('.').pop() == "png") {
                  setattachPdf(result.replace("data:image/png;base64,", ""));
               }
               else if (filePath.split('.').pop() == "pdf") {
                  setattachPdf(result.replace("data:application/pdf;base64,", ""));
               }
               else {
                  setattachPdf(result.replace("data:application/pdf;base64,", ""));
               }
               // setattachPdf(result.replace("data:application/pdf;base64,", ""));
               setImageName(target.files[0].name.replace(/\.[^/.]+$/, ""))
               // setFileName(target.files[0])
            }
         })
      }
   }else{
      Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text: "Max file size is 50MB",
         showConfirmButton: true

      }).then((result) => {
      });   
   }
   }

   //  const onUploadFileChange = ({ target }) => {
   //    if (target.files < 1 || !target.validity.valid) {
   //      return
   //    }
   //    fileToBase64(target.files[0], (err, result) => {
   //      if (result) {

   //        setBase64file(result);
   //       // setFile(result)
   //       setattachPdf(result.replace("data:application/pdf;base64,", ""));
   //       setfileName(target.files[0]);
   //       // setFileName(target.files[0])
   //      }
   //    })
   //  }
   ////////////////Update Customer/////////////////////////////
   const getDocumentCategorie = () => {


      var vehicle_request =
      {

      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vehicle_request)
      // };
      var requestOptions = requestObject.RequestHeader(vehicle_request);

      services.getDocumentCategories(requestOptions).then((res) => {


         setDocsCategory(res.rows);

      });

   }
   //////////////submitvalues///////////////////////////

   const submitValue = () => {

      if ($("#doucmenttype").val() === "") {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please select sub Category",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }

      var requestObjectEdit =
      {
         "id": documentId,
         "fkVehicleId": vehicleId,
         "title": $("#documentTitle").val().trim(),
         "fkDocumentCategoryId": $('input:radio[name="others"]:checked').val() === "others" ? "2" : "1",
         "documentSubCategoryId": parseInt($("#doucmenttype").val()),
         // "documentDate":startDate,
         // "expiryDate":endDate,
         "documentDate": $("#created_date").val() != '' ? moment($("#created_date").val()).format('YYYY-MM-DD') : '',
         "expiryDate": $("#expiryDate").val() != '' ? moment($("#expiryDate").val()).format('YYYY-MM-DD') : '',
         "imageName": attachedPdf ? imageName : "",
         "imageExtension": extension ? extension : "",
         "imageByteString": attachedPdf
      }

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(requestObject)
      // };
      $("#loadingforsubmit").show();

      var requestOptions = requestObject.RequestHeader(requestObjectEdit);

      services.updatedocument(requestOptions).then((res) => {


         $("#loadingforsubmit").hide();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {

                  //   navigate("/vehicleDetails/"+vehicleId);
                  if (navigationfrom === "AddDocument") {
                     navigate("/documents");
                  } else if (navigationfrom === "adddocuments" || navigationfrom === "vehicles") {
                     navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"))
                  } else if (navigationfrom === "customerDocuments") {
                     navigate("/customerDetails/" + localStorage.getItem("customerId"))
                  }
                  else {
                     navigate("/documents");
                  }

               }
            });

         } else {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {


               }
            });

         }



      });
   }

   const cancelButton = () => {

     if (navigationfrom === "AddDocument") {
         navigate("/documents")

      }else {
         navigate("/vehicleDetails/" + vehicleId)
      }
      // if (navigationfrom === "customerDocuments") {
      //    navigate("/customerDetails/"+ customerId)

      // } 
      
   }
   const createdDate=(field,date)=>
   {
 
    field.onChange(date);
    setStartDate(date);

   }
   return (
      <>
       {LoadingComponentfordocuments}
        {LoadingComponentForSubmit}
         <div class="main-panel">
        
            <div class="content-wrapper" style={{ display: "block" }}>
              <div class="row mb-2 ">
                  <div class="col-lg-9 col-md-12 m-auto ">
                     <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold"></h4>
                        <a onClick={() => redirect()} class="btn btn-white font-weight-bold text-uppercase" style={{ marginBottom: "10px" }}><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                        {/* <a onClick={() => redirect("/vehicleDetails/"+localStorage.getItem("vehicleId"))}class="btn btn-white" style={{marginBottom:"10px"}}><span style={{ size: "20px" }}>&lArr;</span>&nbsp;Back</a> */}
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-9 col-md-12 m-auto ">
                     <div class="card p-3">
                        <div class="banner-owner">
                           <div class="owner-logo">
                              <img id="customerImage" />
                              <div class="owner-name">
                                 <h4 >{vechileDetails.customerName ? vechileDetails.customerName : ""}</h4>
                                 <span>DOT Number: </span> <span>{vechileDetails ? vechileDetails.dotNumber : ""}</span>
                              </div>
                           </div>
                           <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
                              <div class="row clearfix">
                                 <div class="col-md-5">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                       <div style={{ minWidth: "240px" }}>
                                          <span>{vechileDetails ? vechileDetails.addressLine1 : ""}{vechileDetails ? vechileDetails.addressLine1 ? "," : "" : ""}<span>
                                             &nbsp;</span>{vechileDetails ? vechileDetails.addressLine2 : ""}{vechileDetails ? vechileDetails.addressLine2 ? "," : "" : ""}<span>
                                                &nbsp;</span><br />{vechileDetails ? vechileDetails.city : ""}{vechileDetails ? vechileDetails.city ? "," : "" : ""}<span> <span>
                                                   {vechileDetails ? vechileDetails.stateName : ""}{vechileDetails ? vechileDetails.stateName ? "," : "" : ""}</span>
                                                &nbsp;</span>{vechileDetails ? vechileDetails.zip : ""}{vechileDetails ? vechileDetails.zip ? "." : "" : ""}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                                       <span>{vechileDetails.customerNumber ? vechileDetails.customerNumber : "N/A"}</span>
                                    </div>
                                 </div>
                                 <div class="col-md-4">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                       <span>{vechileDetails.customerEmail ? vechileDetails.customerEmail : "N/A"}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row mb-2 ">
                  <div class="col-lg-9 col-md-12 m-auto ">
                     <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold"></h4>
                        {/* <a onClick={() => redirect()} class="btn btn-white" style={{ marginBottom: "10px" }}><span style={{ size: "20px" }}>&lArr;</span>&nbsp;Back</a> */}
                        {/* <a onClick={() => redirect("/vehicleDetails/"+localStorage.getItem("vehicleId"))}class="btn btn-white" style={{marginBottom:"10px"}}><span style={{ size: "20px" }}>&lArr;</span>&nbsp;Back</a> */}
                     </div>
                     <VehicleBasicInfo customerId={cid} />
                  </div>
               </div>

               <div class="row mb-2">

                  <div class="col-lg-9 col-md-12 m-auto">
                     <form onSubmit={handleSubmit(submitValue)} id="form_reset">
                        <div class="card">
                           <div class=" p-3 mt-2 mb-2">
                              <h4>Edit Document</h4>
                              <br />
                              <span>Category :</span>
                              <div onChange={onChangeValue} class=" p-6 mt-3  d-flex align-items-center">

                                 <input type="radio" value="inspections" name="others" class="mr-2" id="inspection" /> Inspection
                                 <input type="radio" value="others" name="others" id="others" class="mr-2" style={{ "margin-left": "20px" }} /> Others
                              </div>
                           </div>
                           <div class="card-body pt-0 pb-6">



                              <div >
                                 <table>
                                    <tbody>
                                       <tr>
                                          <td>Type<span class="star">*</span></td>
                                          <td>Title<span class="star">*</span></td>
                                          <td>Created Date<span class="star">*</span></td>
                                          <td>Expiry Date</td>
                                          <td>Upload</td>
                                       </tr>
                                       <tr class="mb-2 pb-2">

                                          <td class="pr-4 ">

                                             <div class="form-group mt-2">
                                                <select class="form-control custome-select" id="doucmenttype" onChange={e => setFieldName(e.target.value)}   {...register('doctype', { required: true })} >
                                                   <option value="">--Select--</option>
                                                   {
                                                      docsCategory ? docsCategory.sort((a, b) => a.name.localeCompare(b.name)).map((docs, index) => {

                                                         if (docs.name === "WET Test Certification") {

                                                            if (sessionStorage.getItem("vehicleTypeName") === "Trailer" && sessionStorage.getItem("tankerSubTypeName") === "Tanker") {

                                                               return (<option value={docs.id} selected={docs.id == selectdocsCategory ? "selected" : ''}>{docs.name}</option>)
                                                            } else {

                                                            }
                                                         }
                                                         else if (docs.name === "Tractor Preventive Maintenance/Inspection") {

                                                            if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {

                                                               return (<option value={docs.id} selected={docs.id == selectdocsCategory ? "selected" : ''}>{docs.name}</option>)
                                                            } else {

                                                            }
                                                         }
                                                         else if (docs.name === "Driver Vehicle Inspection") {

                                                            if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {
                                                               return (<option value={docs.id} selected={docs.id == selectdocsCategory ? "selected" : ''}>{docs.name}</option>)
                                                            } else {

                                                            }
                                                         } else {
                                                            return (<option value={docs.id} selected={docs.id == selectdocsCategory ? "selected" : ''}>{docs.name}</option>)
                                                         }

                                                         // <option value={docs.id} selected={docs.id == selectdocsCategory ? "selected" : ''} >{docs.name}</option>

                                                   }) : <option value=""></option>
                                                   }
                                                </select>
                                                {errors.doctype && errors.doctype.type === "required" && <span class="error">Type is required</span>}
                                             </div>
                                          </td>
                                          <td class="pr-4 ">
                                             <div class="form-group mt-2">
                                                <input type="text" class="form-control custome-input" defaultValue="Doc Title" id="documentTitle" aria-describedby="emailHelp" {...register('documentTitle', { required: true })} maxLength="50"/>
                                                {errors.documentTitle && errors.documentTitle.type === "required" && <span class="error">Title is required</span>}
                                             </div>
                                          </td>
                                          <td class="pr-4 ">
                                             <div class="form-group mt-2">
                                                {/* <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} id="documentDate"/>
                                             {errors.created_date && errors.created_date.type === "required" && <span class="error">Created date  is required</span>} */}
                                                <Controller
                                                   name="createdDate"
                                                   control={control}
                                                   rules={{ required: true }}
                                                   render={({ field }) => (
                                                      <DatePicker
                                                         minDate={moment(startDate).toDate()}
                                                         onChange={(date) => createdDate(field,date)}
                                                    
                                                         selected={field.value}
                                                         inputProps={{
                                                            placeholder: "",
                                                         }}

                                                         id="created_date"

                                                      />
                                                   )}
                                                />
                                                {errors.createdDate && <span class="error">Created date is required</span>}
                                             </div>
                                          </td>
                                          <td class="pr-4 ">
                                             <div class="form-group mt-2">
                                                {/* <DatePicker selected={endDate} dateFormat="dd/MM/yyyy" defaultValue={endDate} onChange={(date) => setEndDate(date)} id="expiryDate" class="form-control" /> */}
                                                {/* <input type="text" class="form-control custome-input" id="expiredate" placeholder="Enter Doc Name" aria-describedby="emailHelp"/> */}
                                                <Controller
                                                   name="expiryDate"
                                                   control={control}
                                                   rules={{ required: true }}
                                                   render={({ field }) => (
                                                      <DatePicker
                                                         minDate={moment(startDate).toDate()}
                                                         onChange={(date) => field.onChange(date)}
                                                         selected={field.value}
                                                         inputProps={{
                                                            placeholder: "",
                                                         }}

                                                         id="expiryDate"

                                                      />
                                                   )}
                                                />
                                                {errors.expiryDate && <span class="error">Expiry date is required</span>}
                                             </div>
                                          </td>
                                          <td class="pr-4 ">
                                             <div class="form-group mt-2 d-flex">
                                                {/* <div className="upload-area">

                                          </div> */}
                                                <div class="image-upload mr-4">
                                                   <label for="file-input">
                                                      <img src="/images/icons/upload.svg" style={{ "pointer-events": "none", "margin-bottom": "0px" }} />
                                                   </label>
                                                   {/* { fileName && <p className="filename">{fileName.name}</p> } */}
                                                   <input type="file" name="filetobase64" id="file-input" style={{ "position": "absolute" }} onChange={onUploadFileChange} />
                                                   {/* <input id="file-input" type="file" /> */}
                                                </div>

                                                {/* {attachedPdf?<img src="/images/icons/pdf-icon.svg" alt=""  onClick={() => document_details(documentId,true)}></img>:""}  */}
                                                {/* <input type="file" name="file" onClick={(event) => handleUploadFile(event)}  accept=".pdf"/> */}
                                                {/* <a href="#" class="mr-2" type="file" name="file"><img src="images/icons/upload.svg" alt="upload" onClick={(event) => handleUploadFile(event)} /></a> */}
                                             </div>
                                          </td>
                                       </tr>
                                       {/* { fileName? <tr>
                                      <td colSpan={6}>
                                            <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                <img src="" class="mr-2"/>
                                                <span>{ fileName && <p  className="filename mb-0"><img src="/images/icons/pdf-icon.svg" class="mr-2" onClick={() => document_details(documentId,true)} ></img> {fileName.name?fileName.name:fileName+"."+ extension} </p> }</span>
                                             </div>
                                      </td>
                                   </tr>:""} */}
                                       {fileName ? <tr>
                                          <td colSpan={6}>
                                             <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                <img src="" class="mr-2" />
                                                <span>{fileName && <p className="filename mb-0">
                                                   {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                   {(() => {
                                                      if (extension == 'pdf') {
                                                         return (
                                                            <img style={{ width: "42px !important" }} src="/images/icons/pdf-icon.svg" class="mr-2 w-42" onClick={() => document_details(documentId, true)} />
                                                         )
                                                      } else if (extension == 'jpg' || extension == 'png' || extension == 'jpeg' || extension == 'bmp') {

                                                         return (
                                                            <img style={{ width: "42px !important" }} src="/images/icons/image-icon.svg" class="mr-2 w-42" onClick={() => document_details(documentId, true)} />
                                                         )
                                                      } else if (extension == 'doc' || extension == 'word' || extension == 'docx' || extension == 'dot' || extension == 'dotm' || extension == 'dotx') {

                                                         return (
                                                            <img style={{ width: "42px !important" }} src="/images/icons/word-icon.svg" class="mr-2 w-42" onClick={() => document_details(documentId, true)} />
                                                         )
                                                      } else {
                                                         return (
                                                            // <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                            <img src="/images/icons/upload.svg" alt="" ></img>
                                                         )
                                                      }
                                                   })()}
                                                   {fileName} </p>}</span>
                                             </div>
                                          </td>
                                       </tr> : ""}




                                    </tbody></table>
                              </div>
                              <div class="col-md-6 text-left mb-3">
                                                <div class="instruction">
                                                    <span>Instruction</span>
                                                    <p>Max File Size : 50MB</p>
                                                    {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                    <p>Formats: JPG/PNG/PDF</p>
                                                 
                                                </div>
                                </div>

                           </div>
                           <div class="border-top">
                              <div class="white-color p-3 d-flex">
                                 <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                 {/* <a onClick={() => redirect(`/vehicleDetails/` + vehicleId)} class="btn gray-mid-color mw-120">CANCEL</a> */}
                                 <a onClick={() => cancelButton()} class="btn gray-mid-color mw-120">CANCEL</a>
                              </div>
                           </div>
                        </div>

                     </form>
                  </div>

               </div>



            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Contact</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">First Name</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Last Name</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Email ID</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Desk Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Mobile Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Role in Company</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>One</option>
                                    <option>Two</option>
                                    <option>Three</option>
                                 </select>
                              </div>
                           </div>

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Status</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>Active</option>
                                    <option>Inactive</option>

                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   )
};

EditDocument.propTypes = {};

EditDocument.defaultProps = {};

export default EditDocument;
