import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import documentDetails_ from "./documentDetails_";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import removePdf from "../../../../utility/removePdf";
import VerifiedBy from "../../../../utility/verfifiedBy";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const WetTestCertificationEdit = () => {
  const {taskOrderId,fkAccountId, inspectionId, vid } = useParams();
  const [inputList, setInputList] = useState([{}]);
  const [base64file, setBase64file] = useState('');
  const [loading, setLoading] = useLoading("Please wait...");
  const [fileName, setfileName] = useState("");
  const [inspectionDetails, setInspectionDetails] = useState("");
  const [documentList, setDocumentLists] = useState([]);
  const [inspectionTypeId, setinspectionTypeId] = useState("");
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState(new Date());
  const [compartments, setcompartments] = useState();
  const [expiryDate, setexpiryDate] = useState(new Date());
  var documentLists = [];

  const [roles, setRoles] = useState("");
  const [statusInspection, setStatus] = useState("");
  const [verifiedDATE, setverifiedDate] = useState(new Date());
  useEffect(() => {
    setRoles(localStorage.getItem("Userroles"));

    getInpection(fkAccountId, inspectionId)

  }, []);
  function getInpection(fkAccountId, inspectionId) {
    var requestObjectForWet =
    {

      "id": inspectionId
    }

    var requestOptions = requestObject.RequestHeader(requestObjectForWet);

    services.getInspectionDetails(requestOptions).then((res) => {

      setInspectionDetails(res.rows);
      setinspectionTypeId(res.rows.inspectionType.id);
      setDocumentLists(res.rows.documentList);
      sessionStorage.setItem("inspectionId", res.rows.id);
      sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
      setcompartments(res.rows.wetTestCertification.compartmentList);
      $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
      $("#notes").val(res.rows ? res.rows.notes : "");
      $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
      setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
      documentLists.push(res.rows.documentList);
      setStatus(res.rows.status);
      if (res.rows) {
        if (res.rows.verifiedDate) {
          setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
        }
        if(res.rows.documentEntity)
         {
               setexpiryDate(new Date(res.rows ? res.rows.documentEntity.expiryDate : ""))
         }
      }

    });

  }
  const save = (status) => {

    // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
    // console.log("results=="+JSON.stringify(inputList));

    var jsonStr = [];


    try {
      for (var i = 0; i < inputList.length; i++) {
        console.log("filebase64==" + inputList[i].filetobase64);
        var extension = "";
        var byteString = "";
        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
          extension = "pdf";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
          extension = "jpg";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
          extension = "png";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
          extension = "bmp";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
          extension = "word";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
          extension = "docx";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
          extension = "doc";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
          extension = "docx";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
          extension = "docm";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
          extension = "docx";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
          extension = "dotx";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
          extension = "dotx";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
          extension = "dotm";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
        }
        else {

        }


        documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }
    } catch (exception) {

      //documentList.push();
    }
    try {
      if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

        var i;
        var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
        for (i = 0; i < storedArray.length; i++) {

          documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
        }

      } else {

        documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }

    } catch (ex) {

    }
    if ($("#inspectedBy").val() === "") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "",
        text: "Please enter Test Performed by",
        showConfirmButton: true

      }).then((result) => {

        if (result.isConfirmed) {


        }
      });
      return false;
    }


    for (var i = 0; i < compartments.length; i++) {

      jsonStr.push({
        "working": $("input[name=sec" + (i) + "Distortion]:checked").val() === "yes" ? "1" : "0",
        "safeFillAmountInGals": $("#comp" + (i) + "gallons").val() ? $("#comp" + (i) + "gallons").val() : "0",
      })
    }


    var requestObjectInspection =
    {
      "id": inspectionId,
      "expiryDate": $("#expiryDate").val(),
      "type": "WET Test Certification",
      "workOrderNumber": $("#workOrderNumber").val(),
      "notes": $("#notes").val(),
      "inspectedBy": $("#inspectedBy").val(),
      "inspectedDate": $("#inspectedDate").val(),
      "verifiedBy": $("#verifiedBy").val(),
      "verifiedDate": $("#verifiedByBate").val(),
      "status": status,
      "taskOrderId":taskOrderId,
      "inspectionType": {
        "id": inspectionTypeId,
        "name": "WET Test Certification"
      },
      "result":
      {
        "approvedCertify": "0",
        "inspectionFailed": "0"
      },
      "dispositionOfVehicle":
      {
        "vehicleReturnedServices": "0",
        "vehicleWithdrawnFromService": "0",
        "vehicleSentToAnotherRepairFacility": "0"
      },
      //   "result": {
      //     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
      //     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
      //  },
      //  "dispositionOfVehicle": {
      //     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
      //     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
      //     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
      //  },
      "vehicleDetails":
      {
        "vinNumber": $("#vin").html(),
        "lisencePlateNumber": $("#lisencePlateNumber").html(),
        "id": localStorage.getItem("vehicleId"),
        "unitNumber": $("#lisencePlateNumber").html()
      },
      "wetTestCertification": { "compartmentList": jsonStr },

      "documentList": documentLists

    };

    // console.log("reobject=" + JSON.stringify(requestObjectInspection));
    // setLoading(true);
    // const requestOptions =
    // {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json'
    //             },

    //       body: JSON.stringify(requestObject)
    // };
    var requestOptions = requestObject.RequestHeader(requestObjectInspection);
    if (statusInspection == "DRAFT") {
      // setLoading(true);
      $("#loadingforsubmit").show();
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        $("#loadingforsubmit").hide();
        // alert(res.success);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "",
          text: "Your changes have been successfully saved!",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {
            navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
          }
        });
      });
    } else {

      if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please select disposition of tank",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {

            // alert("hhhhd")
          }
        });
        return false;
      }
      if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please select inspection result",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {


          }
        });
        return false;
      }
      if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

      } else {

        status = "Rejected";
      }

      var requestObjectInspection =
      {
        "id": inspectionId,
        "expiryDate": $("#expiryDate").val(),
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedByBate").val(),
        "status": status,
        "taskOrderId":taskOrderId,
        "result": {
          "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
          "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
        },
        "dispositionOfVehicle": {
          "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
          "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
          "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
        }

      };
      $("#loadingforsubmit").show();
      // setLoading(true);
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        if (res.success) {

          //certify inspection
          var requestOptions = requestObject.RequestHeader(requestObjectInspection);
          services.certifyInspection(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "",
              text: res.message,
              showConfirmButton: true

            }).then((result) => {

              if (result.isConfirmed) {
                navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

              }
            });
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: "Your changes have been successfully saved!",
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {
              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
            }
          });

        }
      });



    }

  }
  const redirect = (page) => {

    navigate(page);

  }
  const NumericOnly = (e) => { //angka only
    const reg = /^[0-9\b]+$/
    let preval = e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0, (preval.length - 1))
  }
  return (
    <>
    {LoadingComponentForSubmit}
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block" }}>
        <div class="row mb-0">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <div class="d-flex justify-content-between align-items-center my-4 ">
              <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto ">
            <HeaderBlock cids={fkAccountId} />
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto ">
              <div class="card">
                <div class="row mb-2">
                  <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                    <VehicleBasicInfo customerId={vid} />
                  </div>
                </div>

                <div class="divider"></div>

                {/* <div class="divider"></div> */}
                <div class="row p-3">
                  <div class="w-100">
                    <h4 class="mb-4 form-inspection-title text-center">WET Test Certification Form</h4>
                  </div>
                  <div class="col-lg-12"><WorkOrderNumberDiv /></div>

                  <div class="w-100">
                    <div class="inspection-wrap">

                      <div class="table-2 p-3">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" style={{ "width": "50%" }}>Probes Tested and Set Properly </th>
                              <th scope="col" style={{ "width": "40%" }}>Safe fill amount(GALS)</th>
                              <th scope="col">Yes</th>
                              <th scope="col">No</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              compartments ? compartments.map((data, index) => (
                                <tr>
                                  <td colSpan={2}>
                                    <div class="row align-items-center">
                                      <div class="col-md-3">Compartment #{index + 1} </div>
                                      {/* <div class="col-md-3">Capacity</div> */}
                                      <div class="col-md-4 d-flex align-items-center"><lable style={{ "margin-right": "10px" }}> Capacity </lable><input type="text" class="form-control mr-2" id={"comp" + index + "gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} /><lable>Gallons</lable></div>

                                    </div>
                                  </td>

                                  {(() => {
                                    if (data.working === "1") {

                                      return (<><td class="text-center"> <input type="radio" name={"sec" + index + "Distortion"} value="yes" aria-label="Radio button for following text input" defaultChecked={"true"} /></td>
                                        <td class="text-center">  <input type="radio" name={"sec" + index + "Distortion"} value="no" aria-label="Radio button for following text input" /></td></>)
                                    } else {
                                      return (<><td class="text-center"> <input type="radio" name={"sec" + index + "Distortion"} value="yes" aria-label="Radio button for following text input" /></td>
                                        <td class="text-center">  <input type="radio" name={"sec" + index + "Distortion"} value="no" aria-label="Radio button for following text input" defaultChecked={"true"} /></td></>)
                                    }
                                  })()}

                                </tr>
                              )) : ""

                            }


                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="divider2"></div>
                <div class="row p-3">

                  <div class="col-lg-12">
                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                    <div class="row">
                      {
                        documentList ? documentList.map((data, index) => (
                          <div class="col-md-12" style={{marginTop:"-18px"}}>
                            <div class="align-items-center">
                              <img src="images/icons/pdf-icon.svg" alt="" />

                                {/* <b>{data.title}.{data.extension}</b> */}
                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                  <img src="" class="mr-2" />
                                  <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                  <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                      if (data.extension == 'pdf') {
                                        return (
                                          <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                        )
                                      } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                        return (
                                          <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                        )
                                      } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                        return (
                                          <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                        )
                                      } else {
                                        return (
                                          <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                        )
                                      }
                                    })()}
                                    {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                    {data.name ? data.name : "--"} </a></p></span>
                                  <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>

                              {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                            </div>
                          </div>


                        )) : ""

                      }
                    </div>
                  </div><br />


                  <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />

                </div>
                <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}

                {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                {(() => {
                  if (statusInspection == "DRAFT") {
                    <></>
                  } else {
                    if (!roles.match("Technician")) {
                      if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                        return (
                          <>
                            <div class="result-didspos">
                              <div class="p-4">
                                <span>Result</span>
                                <div class="d-flex align-items-center">
                                  {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                </div>
                                <div class="d-flex align-items-center">
                                  {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                  <b class="mb-0 ml-2">Inspection Failed</b>
                                </div>
                              </div>
                              <div class="p-4">
                                <span>Disposition of Tank</span>
                                <div class="d-flex align-items-center">
                                  {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                  <b class="mb-0 ml-2">Tank Returned to Service</b>
                                </div>
                                <div class="d-flex align-items-center">
                                  {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                </div>
                                <div class="d-flex align-items-center">
                                  {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                </div>
                              </div>
                            </div>
                            <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                            {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                          </>
                        )
                      } else {
                        return (
                          <><div class="result-didspos ">
                            <div class="p-4">
                              <span>Result</span>
                              <div class="d-flex align-items-center">
                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                              </div>
                              <div class="d-flex align-items-center">
                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                <b class="mb-0 ml-2">Inspection Failed</b>
                              </div>
                            </div>
                            <div class="p-4">
                              <span>Disposition of Tank</span>
                              <div class="d-flex align-items-center">
                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                <b class="mb-0 ml-2">Tank returned to service</b>
                              </div>
                              <div class="d-flex align-items-center">
                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                              </div>
                              <div class="d-flex align-items-center">
                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                              </div>
                            </div>
                          </div>
                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
                            {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                          </>
                        )
                      }
                    }
                  }
                })()}

                <div class="divider2"></div>
                <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-0px" }}>
                  <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                  {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                  {statusInspection == "DRAFT"?<button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>:""}

                  {(() => {
                    if (statusInspection == "DRAFT") {
                      return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                    } else {
                      if (roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                        return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                      }
                      else {
                        return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                      }
                    }
                  })()}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
};

WetTestCertificationEdit.propTypes = {};

WetTestCertificationEdit.defaultProps = {};

export default WetTestCertificationEdit;
