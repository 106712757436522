
   
import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import moment from "moment";
import getDateAndTimeStamp from "../../../../utility/getTimeStamp";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const GasolineDeliveryTankPressureVacuumTestEdit = () => {
   const { taskOrderId,fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const [pressureTest, setPressureTest] = useState([]);
 
   // const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [inspectionTypeId, setinspectionTypeId] = useState("");
   // const [initialReadingVaccume, setvaccumeTestIntial] = useState([]);
   const [startTime, setpressureTestStartTime] = useState(new Date());
   // const [vaccumestartTime, setvaccumeTestStartTime] = useState([]);

   // const [finalReading, setfinalReading] = useState([]);
   
   // const [repairmode, setrepairMode] = useState([]);
   // const [finishTime, setfinishTime] = useState([]);

   // const [vaccumefinish, setvaccumefinishTime] = useState([]);

   // const [vaccumerepair, setvaccumerepairemode] = useState([]);
   const [vaccumeTestResults, setvaccumeTestResults] = useState([]);
   const [internalVaporValueTest, setinternalVaporValueTest] = useState([]);

   $(document).ready(function () {

      $("#pressurestartingTime").attr("readonly", "readonly");
      $("#pressurestartingTime2").attr("readonly", "readonly");
      $("#pressurestartingTime3").attr("readonly", "readonly");
      $("#pressurefinishTime1").attr("readonly", "readonly");
      $("#pressurefinishTime2").attr("readonly", "readonly");
      $("#pressurefinishTime3").attr("readonly", "readonly");

      $("#vaccumeTeststartingTime").attr("readonly", "readonly");
      $("#vaccumeTeststartingTime2").attr("readonly", "readonly");
      $("#vaccumeTeststartingTime3").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime2").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime3").attr("readonly", "readonly");

      $("#internalVaporValueTeststartingTime").attr("readonly", "readonly");
      $("#internalVaporValueTeststartingTime2").attr("readonly", "readonly");
      $("#internalVaporValueTeststartingTime3").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime2").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime3").attr("readonly", "readonly");
    
      $("#pressureinitialReading1,#pressureinitialReading2,#pressureinitialReading3,#pressuresfinalReading1,#pressuresfinalReading2,#pressuresfinalReading3,#vaccumeTestinitialReading1,#vaccumeTestinitialReading2,#vaccumeTestinitialReading3,#vaccumeTestfinalReading1,#vaccumeTestfinalReading2,#vaccumeTestfinalReading3,#internalVaporinitialReading,#internalVaporinitialReading2,#internalVaporinitialReading3,#internalVaporValueTeststartingTime,#internalVaporValueTestfinalReading2,#internalVaporValueTestfinalReading3").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
         //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                  return false;
       }
      });
   });

    const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);
    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);
    const [vaccumeTest3startTime, setVaccumeTest3startTime] = useState(null);
    const [vaccumeTest3finishTime, setVaccumeTest3finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);
    const [vaporValveTest2startTime, setVaporValveTest2startTime] = useState(null);
    const [vaporValveTest2finishTime, setVaporValveTest2finishTime] = useState(null);
    const [vaporValveTest3startTime, setVaporValveTest3startTime] = useState(null);
    const [vaporValveTest3finishTime, setVaporValveTest3finishTime] = useState(null);



   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   var documentLists=[];
   useEffect(() => {

      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForWet =
      {
         
         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => {
         var array=[];
         setPressureTest([res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults]);
        // array.push(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults);
         
         setvaccumeTestResults([res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults]);
         setinternalVaporValueTest([res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest]);
         setinspectionTypeId(res.rows.inspectionType.id);
         //   for (let index = 0; index < pressureTest.length; index++) {
         //    setPressureTest1startTime(pressureTest.initialReading[index]);x]);
         //   }
         
         // setpressureTestIntial(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.initialReading);
         // setvaccumeTestIntial(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.initialReading);
         // alert(new Date(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0]))
         // setpressureTestStartTime(new Date(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime));
         // setPressureTest1startTime(new Date(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0]));
            
         // ================================1===============
         setPressureTest1startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0])):"");
         setPressureTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[1])):"");
         setPressureTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[2])):"");
         
         setPressureTest1finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[0])):"");
         setPressureTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[1])):"");
         setPressureTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[2])):"");
       
           // ================================2===============

         setVaccumeTest1startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[0])):"");
         setVaccumeTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[1])):"");
         setVaccumeTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[2])):"");
         
         setVaccumeTest1finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[0])):"");
         setVaccumeTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[1])):"");
         setVaccumeTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[2])):"");
        
          // ================================3===============

         setVaporValveTeststartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[0])):"");
         setVaporValveTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[1])):"");
         setVaporValveTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[2])):"");
         
         setVaporValveTestfinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[0])):"");
         setVaporValveTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[1])):"");
         setVaporValveTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[2])):"");

         // setvaccumeTestStartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime);

         // setfinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finalReading);
         // setvaccumefinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finalReading);


         // setfinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime);
         // setvaccumefinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime);


         // setrepairMode(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.reapirsMade);
         // setvaccumerepairemode(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.reapirsMade);
         sessionStorage.setItem("documentList",JSON.stringify(res.rows.documentList));
         documentLists.push(res.rows.documentList);
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList);
         
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         $("#notes").val(res.rows?res.rows.notes:"");
         $("#inspectedBy").val(res.rows?res.rows.inspectedBy:"");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
      });

   }


   
   const redirect=(page)=>
   {

      navigate(page);
   
   }
   const save=(status)=>
   {
 
   // console.log("results=="+JSON.stringify(inputList));
   var documentList=[];
 
   try
   {
     for(var i=0;i<inputList.length;i++)
     {
         // console.log("filebase64=="+inputList[i].filetobase64);
         var extension="";
         var byteString="";
         if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
         {
             extension="pdf";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
         }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
         {
             extension="jpg";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:image/png;base64,"))
         {
             extension="png";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
         {
             extension="word";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
         {
             extension="bmp";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
         {
             extension="docm";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
         {
             extension="docx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
         {
             extension="dotx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
         {
             extension="dotx";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
         }
         else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
         {
             extension="dotm";
             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
         }
         else{
             
         }
        documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(), "extension": extension });
     }
   }catch(exception)
   {
    // documentList.push();
     // Swal.fire({  
     //     position: 'center',  
     //     icon: 'error',  
     //     title: "",  
     //     text:"Please attach document",
     //     showConfirmButton: true 
         
     //   }).then((result) => 
     //   {
             
     //         if(result.isConfirmed) 
     //         {
               
             
     //         }
     //   });
     //   return false;
   }
   try {
      if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

          var i;
          var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
          for (i = 0; i < storedArray.length; i++) {

              documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
          }

      } else {

           documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
      }

  } catch (ex) {

  }
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Inspected by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
         
      var requestObjectInspection=
      {
         "id": inspectionId,
         "expiryDate": $("#expiryDate").val(),
         "type":"Gassoline Delivery Tank - Pressure Vaccum Test",
         "inspectedBy":  $("#inspectedBy").val(),
         "inspectedDate":$("#inspectedDate").val(),
         "verifiedBy": $("#verifiedBy").val(),
         "verifiedDate": $("#verifiedBy").val(),
         "workOrderNumber":$("#workOrderNumber").val(),
         "notes":$("#notes").val(),
         "status": status,
         "taskOrderId":taskOrderId,
         "inspectionType": {
            "id":inspectionTypeId,
            "name": "Gassoline Delivery Tank - Pressure Vaccum Test"
         },
         "result":
         {
            "approvedCertify": "0",
            "inspectionFailed": "0"
         },
         "dispositionOfVehicle":
         {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
         },
         "vehicleDetails":
         {
               "vinNumber": $("#vin").html(),
               "lisencePlateNumber": $("#lisencePlateNumber").html(),
               "id":localStorage.getItem("vehicleId"),
               "unitNumber": $("#lisencePlateNumber").html()
         },
         "gasolineDeliveryTankPressureVacuumTest":
         {
            "pressureTestResults":
            {
               "initialReading": [$("#pressureinitialReading1").val(),$("#pressureinitialReading2").val(),$("#pressureinitialReading3").val()],
             //  "startingTime": [pressureTest1startTime,pressureTest2startTime,pressureTest3startTime],
                "startingTime": [$("#pressurestartingTime").val(),$("#pressurestartingTime2").val(),$("#pressurestartingTime3").val()],
               "finalReading":[$("#pressuresfinalReading1").val(),$("#pressuresfinalReading2").val(),$("#pressuresfinalReading3").val()],
               //"finishTime":[pressureTest1finishTime,pressureTest2finishTime,pressureTest3finishTime],
               "finishTime":[$("#pressurefinishTime1").val(),$("#pressurefinishTime2").val(),$("#pressurefinishTime3").val()],
               "reapirsMade":[$("#pressurereapirsMade").val(),$("#pressurereapirsMade2").val(),$("#pressurereapirsMade3").val()]
               },
               "vaccumeTestResults":{
               "initialReading": [$("#vaccumeTestinitialReading1").val(),$("#vaccumeTestinitialReading2").val(),$("#vaccumeTestinitialReading3").val()],
               //"startingTime": [vaccumeTest1startTime,vaccumeTest2startTime,vaccumeTest3startTime],
               "startingTime": [$("#vaccumeTeststartingTime").val(),$("#vaccumeTeststartingTime2").val(),$("#vaccumeTeststartingTime3").val()],
               "finalReading":[$("#vaccumeTestfinalReading1").val(),$("#vaccumeTestfinalReading2").val(),$("#vaccumeTestfinalReading3").val()],
              // "finishTime":[vaccumeTest1finishTime,vaccumeTest2finishTime,vaccumeTest3finishTime],
                "finishTime":[$("#vaccumeTestfinishTime").val(),$("#vaccumeTestfinishTime2").val(),$("#vaccumeTestfinishTime3").val()],
               "reapirsMade":[$("#vaccumeTestreapirsMade1").val(),$("#vaccumeTestreapirsMade2").val(),$("#vaccumeTestreapirsMade3").val()]
               },
               
               "internalVaporValueTest":{
               "initialReading": [$("#internalVaporinitialReading").val(),$("#internalVaporinitialReading2").val(),$("#internalVaporinitialReading3").val()],
               //"startingTime": [vaporValveTeststartTime,vaporValveTest2startTime,vaporValveTest3startTime],
                "startingTime": [$("#internalVaporValueTeststartingTime").val(),$("#internalVaporValueTeststartingTime2").val(),$("#internalVaporValueTeststartingTime3").val()],
               "finalReading":[$("#internalVaporValueTestfinalReading").val(),$("#internalVaporValueTestfinalReading2").val(),$("#internalVaporValueTestfinalReading3").val()],
               //"finishTime":[vaporValveTestfinishTime,vaporValveTest2finishTime,vaporValveTest3finishTime],
                "finishTime":[$("#internalVaporValueTestfinishTime").val(),$("#internalVaporValueTestfinishTime2").val(),$("#internalVaporValueTestfinishTime3").val()],
               "reapirsMade":[$("#internalVaporValueTestreapirsMade").val(),$("#internalVaporValueTestreapirsMade2").val(),$("#internalVaporValueTestreapirsMade3").val()]
               },
            },  
      "documentList": documentLists
      
   }; 

          
         
    
 
 
      //  setLoading(true);
      $("#loadingforsubmit").show();
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.addInspection(requestOptions).then((res) => 
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:"Your changes have been successfully saved!",
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
 
   }
   
   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>

               <div class="row mb-4">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold">Vehicle Inspection</h4>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white">Back</a>
                     </div>
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="form-inspection-title text-center">Gasoline Delivery tank - Pressure Vacuum test results</h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv/></div>
                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Pressure Test Results</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressureinitialReading1" aria-describedby="emailHelp" defaultValue={data.initialReading[index]}/></td>
                                            <td class="text-center">
                                           
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={pressureTest1startTime}  onChange={(date) => setPressureTest1startTime(date)} type="text" id="pressureTest1startTime" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurestartingTime" aria-describedby="emailHelp" defaultValue={data.startingTime[index]}/> */}
                                           </td>
                                            <td class="text-center">
                                               <input type="input" class="form-control" id="pressuresfinalReading1" aria-describedby="emailHelp" defaultValue={data.finalReading[index]}/>
                                               
                                               </td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                      selected={pressureTest1finishTime} onChange={(date) => setPressureTest1finishTime(date)} type="text" id="pressurefinishTime1" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurefinishTime1" aria-describedby="emailHelp" defaultValue={data.finishTime[index]}/> */}
                                           </td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressurereapirsMade" aria-describedby="emailHelp" defaultValue={data.reapirsMade[index]}/></td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressureinitialReading2" aria-describedby="emailHelp" defaultValue={data.initialReading[1]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={pressureTest2startTime}  onChange={(date) => setPressureTest2startTime(date)} type="text" id="pressurestartingTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurestartingTime2" aria-describedby="emailHelp" defaultValue={data.startingTime[1]}/> */}
                                               </td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressuresfinalReading2" aria-describedby="emailHelp"  defaultValue={data.finalReading[1]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={pressureTest2finishTime} onChange={(date) => setPressureTest2finishTime(date)} type="text" id="pressurefinishTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurefinishTime2" aria-describedby="emailHelp"  defaultValue={data.finishTime[1]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressurereapirsMade2" aria-describedby="emailHelp"  defaultValue={data.reapirsMade[1]}/></td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressureinitialReading3" aria-describedby="emailHelp"  defaultValue={data.initialReading[2]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                      selected={pressureTest3startTime} onChange={(date) => setPressureTest3startTime(date)}  type="text" id="pressurestartingTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurestartingTime3" aria-describedby="emailHelp"  defaultValue={data.startingTime[2]} /> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressuresfinalReading3" aria-describedby="emailHelp"  defaultValue={data.finalReading[2]} /></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={pressureTest3finishTime}  onChange={(date) => setPressureTest3finishTime(date)} type="text" id="pressurefinishTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="pressurefinishTime3" aria-describedby="emailHelp" defaultValue={data.finishTime[2]} /> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="pressurereapirsMade3" aria-describedby="emailHelp" defaultValue={data.reapirsMade[2]}/></td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Vacuum Test Results</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                          vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestinitialReading1" aria-describedby="emailHelp" defaultValue={data.initialReading[index]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                      selected={vaccumeTest1startTime} onChange={(date) => setVaccumeTest1startTime(date)}  type="text" id="vaccumeTeststartingTime" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTeststartingTime" aria-describedby="emailHelp" defaultValue={data.startingTime[index]} /> */}
                                             </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestfinalReading1" aria-describedby="emailHelp" defaultValue={data.finalReading [index]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaccumeTest1finishTime}  onChange={(date) => setVaccumeTest1finishTime(date)} type="text" id="vaccumeTestfinishTime" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTestfinishTime" aria-describedby="emailHelp" defaultValue={data.finishTime [index]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestreapirsMade1" aria-describedby="emailHelp" defaultValue={data.reapirsMade [index]}/></td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                             vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestinitialReading2" aria-describedby="emailHelp" defaultValue={data.initialReading[1]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaccumeTest2startTime} onChange={(date) => setVaccumeTest2startTime(date)}  type="text" id="vaccumeTeststartingTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTeststartingTime2" aria-describedby="emailHelp" defaultValue={data.startingTime[1]} /> */}
                                               </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestfinalReading2" aria-describedby="emailHelp" defaultValue={data.finalReading[1]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaccumeTest2finishTime} onChange={(date) => setVaccumeTest2finishTime(date)} type="text" id="vaccumeTestfinishTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTestfinishTime2" aria-describedby="emailHelp"   defaultValue={data.finishTime[1]}/> */}
                                          </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestreapirsMade2" aria-describedby="emailHelp"  defaultValue={data.reapirsMade[1]}/></td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                          vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestinitialReading3" aria-describedby="emailHelp"  defaultValue={data.initialReading[2]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaccumeTest3startTime}  onChange={(date) => setVaccumeTest3startTime(date)} type="text" id="vaccumeTeststartingTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTeststartingTime3" aria-describedby="emailHelp"  defaultValue={data.startingTime[2]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestfinalReading3" aria-describedby="emailHelp" defaultValue={data.finalReading[2]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaccumeTest3finishTime} onChange={(date) => setVaccumeTest3finishTime(date)} type="text" id="vaccumeTestfinishTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="vaccumeTestfinishTime3" aria-describedby="emailHelp" defaultValue={data.finishTime[2]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="vaccumeTestreapirsMade3" aria-describedby="emailHelp" defaultValue={data.reapirsMade[2]}/></td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Internal vapor valve Test</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                          internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporinitialReading" aria-describedby="emailHelp" defaultValue={data.initialReading[index]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTeststartTime} onChange={(date) => setVaporValveTeststartTime(date)} type="text" id="internalVaporValueTeststartingTime" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime" aria-describedby="emailHelp" defaultValue={data.startingTime[index]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestfinalReading" aria-describedby="emailHelp" defaultValue={data.finalReading[index]} /></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTestfinishTime} onChange={(date) => setVaporValveTestfinishTime(date)} type="text" id="internalVaporValueTestfinishTime" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime" aria-describedby="emailHelp" defaultValue={data.finishTime[index]} /> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestreapirsMade" aria-describedby="emailHelp" defaultValue={data.reapirsMade[index]} /></td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                             internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporinitialReading2" aria-describedby="emailHelp" defaultValue={data.initialReading[1]}/></td>
                                            <td class="text-center">
                                             <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTest2startTime} onChange={(date) => setVaporValveTest2startTime(date)}  type="text" id="internalVaporValueTeststartingTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime2" aria-describedby="emailHelp" defaultValue={data.startingTime[1]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestfinalReading2" aria-describedby="emailHelp" defaultValue={data.finalReading[1]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTest2finishTime}  onChange={(date) => setVaporValveTest2finishTime(date)} type="text" id="internalVaporValueTestfinishTime2" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime2" aria-describedby="emailHelp" defaultValue={data.finishTime[1]} /> */}
                                             </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestreapirsMade2" aria-describedby="emailHelp" defaultValue={data.reapirsMade[1]} /></td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                          internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporinitialReading3" aria-describedby="emailHelp" defaultValue={data.initialReading[2]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTest3startTime}  type="text" id="internalVaporValueTeststartingTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime3" aria-describedby="emailHelp" defaultValue={data.startingTime[2]}/> */}
                                             </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestfinalReading3" aria-describedby="emailHelp" defaultValue={data.finalReading[2]}/></td>
                                            <td class="text-center">
                                            <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm"
                                                     selected={vaporValveTest3finishTime} onChange={(date) => setVaporValveTest3finishTime(date)}  type="text" id="internalVaporValueTestfinishTime3" defaultValue="dsdddd" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                               {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime3" aria-describedby="emailHelp"  defaultValue={data.finishTime[2]}/> */}
                                            </td>
                                            <td class="text-center"><input type="input" class="form-control" id="internalVaporValueTestreapirsMade3" aria-describedby="emailHelp"  defaultValue={data.reapirsMade[2]}/></td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>



                              </div>
                           </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12" style={{marginTop:"-18px"}}>
                                             <div class="align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />
                                                
                                                   {/* <b>{data.name}.{data.extension}</b> */}
                                                   <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                    <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">   {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </a></p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                   
                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />
                                   
                                </div>
                           </div>
                        {/* <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                   <div class="col-lg-12">
                                      <h4 class="mb-4">Documents</h4>
                                      <div class="row">
                                      {
                                                                                                documentList?documentList.map((data, index) => (
                                         <div class="col-md-4">
                                            <div class="d-flex align-items-center">
                                               <img src="images/icons/pdf-icon.svg" alt=""/>
                                               <a onClick={() => documentDetails_(data.filePath,data.extension)} class="ml-2"><b>{data.name}.{data.extension}</b></a>
                                            </div>
                                         </div>
                                        
                                       
                                         )):""
                                                                                               
                                       }
                                      </div>
                                   </div><br/>
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
    
                        </div> */}
                          <div class="row p-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>                      
                            <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-7px"}}>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                     </div>
                  </div>
               </div>

            </div>

         </div>
      </>
   )
};

GasolineDeliveryTankPressureVacuumTestEdit.propTypes = {};

GasolineDeliveryTankPressureVacuumTestEdit.defaultProps = {};

export default GasolineDeliveryTankPressureVacuumTestEdit;

