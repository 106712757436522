import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const WetTestCertification = () => {
  const {taskOrderId,fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [ fileName, setfileName ] = useState("");
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [compartments, setcompartments] = useState();
    var compartmentReq;
     
    
    useEffect(() => {
      compartmentReq = sessionStorage.getItem("compartmentCapacityInGallons").toString().replace(/,(?=\s*$)/, '').split(',');
      

      setcompartments(compartmentReq);
      
   }, []);
   const redirect=(page)=>
   {
   
      navigate(page);
   
   }
    const save=(status)=>
    {
  
      
    // console.log("results=="+JSON.stringify(inputList));
    
  
  
    var jsonStr=[];
     var documentList=[];
  
     try
     {
       for(var i=0;i<inputList.length;i++)
       {
           console.log("filebase64=="+inputList[i].filetobase64);
           var extension="";
           var byteString="";
           if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
           {
               extension="pdf";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
           }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
           {
               extension="jpg";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/png;base64,"))
           {
               extension="png";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
           {
               extension="word";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
           {
               extension="bmp";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
           {
               extension="dotm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
           }
           else{
              
           }


             documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
       }
     }catch(exception)
     {
      //  Swal.fire({  
      //      position: 'center',  
      //      icon: 'error',  
      //      title: "",  
      //      text:"Please attach document",
      //      showConfirmButton: true 
           
      //    }).then((result) => 
      //    {
               
      //          if(result.isConfirmed) 
      //          {
                 
               
      //          }
      //    });
      //    return false;
      documentList.push();
     }
     if($("#inspectedBy").val()==="")
     {
       Swal.fire({  
           position: 'center',  
           icon: 'error',  
           title: "",  
           text:"Please enter Test Performed by",
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 
               
               }
         });
         return false;
     }
        
     
          for(var i=0;i<compartments.length;i++)
          {
          
            jsonStr.push({"working":$("input[name=sec"+(i)+"Distortion]:checked").val()==="1"?"1":"0",
              "safeFillAmountInGals":$("#comp"+(i)+"gallons").val()?$("#comp"+(i)+"gallons").val():"0",
            })
                if ($("input[name=sec"+(i)+"Distortion]:checked").length == 0) 
                {
                  // $('html, body').animate({
                  //   scrollTop: $("#inspectionType").offset().top
                  // }, 500);
                  $('html,body').animate({ scrollTop: "600px" }, 1000);
                  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
                    return false; 
                } 
               else 
               {
                $('#error_message').html('');
               }
          }

          
          var requestObjectInspection=
          {
            
            "type":"WET Test Certification",
            "workOrderNumber":$("#workOrderNumber").val(),
            "notes":$("#notes").val(),
            "inspectedBy":  $("#inspectedBy").val(),
            "inspectedDate":$("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType": {
              "id": sessionStorage.getItem("selectedinspectionId"),
              "name":sessionStorage.getItem("selectedinspectionType")
            },
            "result":
            {
              "approvedCertify": "0",
              "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
              "vehicleReturnedServices": "0",
              "vehicleWithdrawnFromService": "0",
              "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id":localStorage.getItem("vehicleId"),
                "unitNumber": $("#lisencePlateNumber").html()
            },
            "wetTestCertification":{"compartmentList":jsonStr},
             
            "documentList": documentList
          
        }; 
        $("#loadingforsubmit").show();
        // console.log("reobject="+JSON.stringify(requestObjectInspection));
        // setLoading(true);
        // const requestOptions = 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },
                    
        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions=requestObject.RequestHeader(requestObjectInspection);
        services.addInspection(requestOptions).then((res) => 
        {
          $("#loadingforsubmit").hide();
          // setLoading(false);
          Swal.fire({  
            position: 'center',  
            icon: 'success',  
            title: "",  
            text:res.message,
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
                
                }
          });
        });
  
    } 
    const NumericOnly= (e) => { //angka only
      const reg = /^[0-9\b]+$/
      let preval=e.target.value
      if (e.target.value === '' || reg.test(e.target.value)) return true
      else e.target.value = preval.substring(0,(preval.length-1))
 }
  return(
  <>

                
           {LoadingComponentForSubmit}      
                 

                  <div class="row mb-2">
                     <div class="col-lg-12 col-md-12 m-auto ">
                        <div class="card">

                                               
                            {/* <div class="divider"></div> */}
                            <div class="row px-3">
                                <div class="w-100">
                               
                                    <h4 class="form-inspection-title text-center">WET Test Certification Form</h4>
                                    <p id="error_message"></p>
                                </div>
                                <div class="col-lg-12 px-0">
                                
                                  <WorkOrderNumberDiv/></div>
                                
                                <div class="w-100">
                                <center> <h1 style={{fontSize:"16px"}}> PROBES TESTED AND SET PROPERLY</h1></center>
                                    <div class="inspection-wrap">

                                        <div class="table-2 py-3">
                                        
                                            <table class="table">
                                              
                                                <thead>
                                                
                                                  <tr>
                                                    <th scope="col" style={{"width":"50%"}}> </th>
                                                    <th scope="col" style={{"width":"40%"}}>Safe fill amount(GALS)</th>
                                                    <th scope="col" className="text-center">Yes</th>
                                                    <th scope="col" className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                  compartments ? compartments.map((data, index) => (
                                                    <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                            <div class="col-md-4">Compartment #<span>{index+1}</span> </div>
                                                        
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div class="row align-items-center">
                                                           <div class="col-md-3">Capacity</div>
                                                            <div class="col-md-4"><input type="text" class="form-control" id={"comp"+index+"gallons"} aria-describedby="emailHelp" placeholder="" defaultValue={data} onChange={NumericOnly}/> </div>
                                                            Gallons
                                                    </div>
                                                    </td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name={"sec"+index+"Distortion"} value="1"/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name={"sec"+index+"Distortion"} value="0"/></td>
                                                  </tr>
                                                  )) : ""

                                               }
                                                  

                                                  {/* <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #2 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4"><input type="text" class="form-control" id="comp2gallons" aria-describedby="emailHelp" placeholder="Safe fill amount(GALS)"/></div>
                                                        </div>
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Distortion" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Distortion" value="0"/></td>
                                                   </tr>

                                                  

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #3 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4"><input type="text" class="form-control" id="comp3gallons" aria-describedby="emailHelp" placeholder="Safe fill amount(GALS)"/></div>
                                                        </div>
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec3Distortion" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec3Distortion" value="0"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #4 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4"><input type="text" class="form-control" id="comp4gallons" aria-describedby="emailHelp" placeholder="Safe fill amount(GALS)"/></div>
                                                        </div>
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec4Distortion" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec4Distortion" value="0"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #5 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4"><input type="email" class="form-control" id="comp5gallons" aria-describedby="emailHelp" placeholder="Safe fill amount(GALS)"/></div>
                                                        </div>
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec5Distortion" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec5Distortion" value="0"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #6 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4"><input type="email" class="form-control" id="comp6gallons" aria-describedby="emailHelp" placeholder="Safe fill amount(GALS)"/></div>
                                                        </div>
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec6Distortion" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec6Distortion" value="0"/></td>
                                                   </tr> */}

                                                

                                                  
                                                 </tbody>
                                              </table>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider2"></div>
                            <div class="row ">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            
                            </div>                      
                            <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                            {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                              <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"   onClick={() => save("DRAFT")}>Save as Draft</button>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"  onClick={() => save("APPROVED PENDING")}>Save</button>
                            </div>
                           </div>
                        </div>
                  </div>
                 
              
  </>
)};

WetTestCertification.propTypes = {};

WetTestCertification.defaultProps = {};

export default WetTestCertification;
