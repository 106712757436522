import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../services/services";
import "cropperjs/dist/cropper.css";
import Getstates from "../../../utility/getstates";
import getCroppedImg from "../../../utility/cropImage";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import GetTruckType from "../../../utility/getTruckTypes";
import { requestObject } from "../../../utility/requestObject";
import Cropper from "react-cropper";
import UsNumberValidate from "../../../utility/usNumberValidate";
import UploadImage from "../../../utility/uploadImage";
import AdddocsDispatcher from "../../../utility/AdddocsDispatcher";
import removePdf from "../../../utility/removePdf";
import DatePicker from "react-datepicker";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
const EditUser = () => {

    const { id } = useParams();
    var userId=id;
    const navigate = useNavigate();
    const [dispatcherDetails, setDispatcherDetails] = useState([]);
    var documentLists = [];

    const dummyImge = window.location.origin + "/images/avatar.png";
    const [imgsrc, setImageSrc] = useState()
    const [image, setImage] = useState()
    const [states, setState] = useState([])
    const [cropper, setCropper] = useState()
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [cropData, setCropData] = useState("")
    const [itemName, setFieldName] = useState('')
    const [loading, setLoading] = useLoading("Please wait...");
    const [croppedImage, setCroppedImage] = useState(null);
    const [fileName, setfileName] = useState("");

    const [cropLiecenceData, setCropLiecenceData] = useState("")
    const [issuedDate, setIssuedDate] = useState((new Date()));
    const [expDate, setExpDate] = useState((new Date()));
    const [inputList, setInputList] = useState([{}]);
    const [inputLists, setInputLists] = useState([]);
    const [base64file, setBase64file] = useState('');
    const [DriverDetails, setDriverDetails] = useState('')
    const [documentList, setDocumentLists] = useState([]);

    const [roles, setListRoles] = useState([])
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", defaultValues: { state: "hawai", role: "manager" },
    });

    //  mode: "onKeyup"

    $(document).ready(function () {

        $('#zipcode,#deskNumber,#mobileNumber').keypress(function( e ) {
            if(e.which === 32){
                return false;
            }
        });

        $("#deskNumber,#ext,#zipcode").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
            }
        });
    });

    useEffect(() => {
        Getstates(setState);
        $("#loadingfordocs").hide();
        $('#mobileNumber').usPhoneFormat();
        $('#deskNumber').usPhoneFormat();
        dispatcherDetailsApi();
        listRoles();
    }, []);

    const redirect=(page)=>
    {

        navigate(page);
        sessionStorage.setItem("naviationfrom","users");
    }
    const listRoles = () => {
        var listRolesReq =
        {

        };

        var requestOptions = requestObject.RequestHeader(listRolesReq);
        services.listRoles(requestOptions).then((res) => {
            // console.log("@@@@@listRoles list@@@@@@" + res.rows);
            setListRoles(res.rows);
        });
    }

    const dispatcherDetailsApi = () => {

        var vechileReq =
        {
            "id": userId
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.getUserDetails(requestOptions).then((res) => {

            setDispatcherDetails(res.rows);
            // alert(res.rows.deskNumber);
            $("#firstName").val(res.rows ? res.rows.firstName ? res.rows.firstName : "" : "");
            $("#lastName").val(res.rows ? res.rows.lastName ? res.rows.lastName : "" : "");
            $("#email").val(res.rows ? res.rows.email ? res.rows.email : "" : "");
            $("#deskNumber").val(res.rows ? res.rows.deskNumber : "");
            $("#ext").val(res.rows ? res.rows.extnNo ? res.rows.extnNo : "" : "");
            $("#mobileNumber").val(res.rows ? res.rows.phoneNumber ? res.rows.phoneNumber : "" : "");
            $("#address1").val(res.rows ? res.rows.addressLine1 ? res.rows.addressLine1 : "" : "");
            $("#address2").val(res.rows ? res.rows.addressLine1 ? res.rows.addressLine2 : "" : "");
            $("#city").val(res.rows ? res.rows.city ? res.rows.city : "" : "");
            $("#zipcode").val(res.rows ? res.rows.zip ? res.rows.zip : "" : "");
            if (res.rows) {
                try {

                    if (res.rows.documents) {

                        documentLists.push(res.rows.documents);
                        sessionStorage.setItem("documentList", JSON.stringify(res.rows.documents));
                        setDocumentLists(res.rows.documents ? res.rows.documents : "");

                        for (var i = 0; i < res.rows.documents.length; i++) {

                            if (res.rows.documents[i].name === "drivingLisence") {
                                $("#licenceNumber").val(res.rows.documents[i].documentNumber);

                                setIssuedDate(new Date(res.rows ? res.rows.documents[i].issuedDate : ""));
                                setExpDate(new Date(res.rows ? res.rows.documents[i].expiryDate : ""));
                                setFieldName(res.rows ? res.rows.documents[i].name : "");
                            }
                            else {

                                //   setfileNames(res.rows.documents[i].name);
                                //  $("#docName"+i).val(res.rows.documents[i].name);
                                //  $("#filename"+i).val(res.rows.documents[i].name);
                            }




                        }

                    }
                    else {

                    }

                } catch (ex) {

                }



            }

           try{
            setCropData(res.customValues.userImageFileByte ? "data:image/jpeg;base64," + res.customValues.userImageFileByte : "/images/avatar.png");
           }catch(error)
           {

           }
            //        setInputList(res.rows?res.rows.documents?res.rows.documents:"":"");

            // $("#vehicleImage").attr("src", res.customValues.userImageFileByte ? "data:image/jpeg;base64," + res.customValues.userImageFileByte : "/images/transport-logo.jpg");

           // console.log("Dispatcher Details" + JSON.stringify(res.rows));
           setImage(res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png");
        });

    }


    // const onChange = (e) => {
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //         files = e.dataTransfer.files;
    //     } else if (e.target) {
    //         files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setImage(reader.result);
    //     };
    //     reader.readAsDataURL(files[0]);
    // };
    const onChange = (e) => {
        const MIN_FILE_SIZE = 5120 // 5MB
        const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //
    
        if(fileSizeKiloBytes <= MIN_FILE_SIZE){
        e.preventDefault();
        let files;
         var ext =  e.target.value.split('.').pop();
          var allowedExtensions =/(\jpg|\png|\jpeg)$/i;

          if (!allowedExtensions.exec(ext))
          {

              Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text:"Invalid file type",
                showConfirmButton: true

              }).then((result) =>
              {

                    if(result.isConfirmed)
                    {

                      e.target.value = '';
                      return false;
                    }
              });

          }else{
            $("#DB_Image").hide();
         $('#selectedImage').show();
         $('#uploadImageDisable').prop('disabled', false);
             if (e.dataTransfer) {
                files = e.dataTransfer.files;
              } else if (e.target) {
                files = e.target.files;
              }
              const reader = new FileReader();
              reader.onload = () => {
                setImage(reader.result);
              };
              reader.readAsDataURL(files[0]);

          }
        }else{
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Max file size is 5MB",
               showConfirmButton: true
      
            }).then((result) => {
            });   
         }

  };
    function handleChange(event) {
        setImageSrc(URL.createObjectURL(event.target.files[0]))

    }
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg'));
            document.getElementById("btnclose").click();
        }
    };

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgsrc,
                croppedAreaPixels,
                rotation
            )

            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        showCroppedImage()
    }, [])

    const document_details = (id, filePath) => {
        //  alert(id);
        var extension = filePath.match('[^.]+$');

        var document_request =
        {

            "id": id

        };

        var requestOptions = requestObject.RequestHeader(document_request);
        $("#loadingfordocs").show();
        services.getUserDocumentDetails(requestOptions).then((res) => {
            $("#loadingfordocs").hide();
            if (res.customValues.fileByte === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "File is not attached",

                    showConfirmButton: true

                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
            else {

                var file;

                if (extension == "pdf") {
                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (extension == "jpg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "jpeg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "png") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpeg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                //  else if(extension=="bmp")
                //  {
                //     var image = new Image();
                //     image.src = "data:image/bmp;base64,"+ res.customValues.fileByte;

                //     var w = window.open("");
                //     w.document.write(image.outerHTML);
                //  }
                //  else if(extension=="word")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
                //  else if(extension=="docx"||extension=="doc"||extension=="docm"||extension=="dotx"||extension=="dotm")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
            }

        });

    }

    // function validateState() {
    //     var isCheck = true;

    //     var checkedValues = $("input[name='category']:checked");
    //     var lenghtCheck=checkedValues.length;
    //     if (lenghtCheck==0) {
    //       isCheck = false;
    //       $("#categoryError").html("Category field is required");
    //     } else {
    //       isCheck = true;
    //       $("#categoryError").html("");
    //     }

    //     return isCheck;
    //   }

    const submitValue = () => {

        // var documentList = [];
        // try {
        //     for (var i = 0; i < inputList.length; i++) {
        //         var extension = "";
        //         var byteString = "";
        //         if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
        //             extension = "pdf";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        //         } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
        //             extension = "jpg";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        //         }
        //         else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
        //             extension = "png";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        //         }
        //         else {

        //         }


        //         documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "extension": extension });
        //     }
        // } catch (exception) {
        //     documentList.push();

        // }

        try {

            for (var i = 0; i < inputList.length; i++) {

                var extension = "";
                var byteString = "";

                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else {

                }
                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });

            }
        } catch (exception) {
            documentLists.push();

        }
        try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {

                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }

            } else {

                 documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
            }

        } catch (ex) {

        }
        // try {
        //     if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

        //         var i;
        //         var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
        //         for (i = 0; i < storedArray.length; i++) {

        //             documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
        //         }

        //     } else {

        //     }

        // } catch (ex) {

        // }
        $("#loadingforsubmit").show();
//    setLoading(true);
        var vechileReq =
        {
            "id": userId,
            "firstName": $("#firstName").val() != '' ? $("#firstName").val().trim() : '',
            "lastName": $("#lastName").val() != '' ? $("#lastName").val().trim() : '',
            "email": $("#email").val() != '' ? $("#email").val().trim() : '',
            "deskNumber": $("#deskNumber").val() != '' ? $("#deskNumber").val().trim() : '',
            "extnNo": $("#ext").val() != '' ? $("#ext").val().trim() : '',
            "phoneNumber": $("#mobileNumber").val() != '' ? $("#mobileNumber").val().trim() : '',
            "addressLine1": $("#address1").val() != '' ? $("#address1").val().trim() : '',
            "addressLine2": $("#address2").val() != '' ? $("#address2").val().trim() : '',
            "city": $("#city").val() != '' ? $("#city").val().trim() : '',
            "securityRoleId":$("#role").val()!=''?$("#role").val():'',
            "securityRoleName":$("#role option:selected" ).text()!=''?$("#role option:selected").text():'',
            "fkStateId": $("#state").val() != '' ? $("#state").val() : '',
            "zip": $("#zipcode").val() != '' ? $("#zipcode").val().trim() : '',

            "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : cropData==="/images/no-vehicle-image.png"?"":parseInt(Math.random() * 1000000000),
            "imageExtension": cropper.getCroppedCanvas() ? "jpeg" : cropData==="/images/no-vehicle-image.png"?"":"jpeg",
            // "imageByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : "",
            // "imageByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : cropData==="/images/no-vehicle-image.png"?"":cropData.replace("data:image/jpeg;base64,", ""),
            "imageByteString": cropData.replace("data:image/jpeg;base64,", "")=="/images/avatar.png"?"":cropData.replace("data:image/jpeg;base64,", ""),
            // "imageName":"",
            // "imageExtension":"",
            // "imageByteString":"",
            "documents": documentLists
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.updateUser(requestOptions).then((res) => {
            // alert(res.message);
            // setLoading(false);
            $("#loadingforsubmit").hide();
            if (res.message === "Successfully updated user") {
                //   setLoading(false);
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true
                }).then((result) => {

                    if (result.isConfirmed) {
                        sessionStorage.setItem("naviationfrom","users");
                        navigate("/users");

                    }
                })
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: res.message,
                    showConfirmButton: true
                }).then((result) => {

                    if (result.isConfirmed) {

                    }
                })
            }


        });
        // }

    }

    $(document).on("click", "#EditImage", function () {
        $('#selectedImage').hide();
        $('#DB_Image').show();
        $('#uploadImageDisable').prop('disabled', true);
      });

    return (
        <>
        {LoadingComponentfordocuments}
        {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <form onSubmit={handleSubmit(submitValue)}>
                        <div class="col-lg-9 col-md-12 m-auto ">
                            <div class="row">
                                <div class="col-md-12 m-auto mb-2 ">
                                    <h4 class="font-weight-bold my-4 text-uppercase">Edit Employee</h4>

                                    <div class="card mb-2 px-4">
                                        <div class="col-md-12 pt-4 pr-4 px-0 mb-2">
                                            <h3>Basic Information</h3>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>First Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="firstName"  maxLength="20" minLength="2" defaultValue="FirstName"  {...register('firstName', { required: true })} />
                                                {errors.firstName && errors.firstName.type === "required" && <span class="error">First name is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Last Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="lastName" maxLength="20" minLength="2" defaultValue="lastName" {...register('lastName', { required: true })} />
                                                {errors.lastName && errors.lastName.type === "required" && <span class="error">Last name is required</span>}
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 1<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" defaultValue="address1" maxLength="50" id="address1"  {...register('address1', { required: true })} />
                                                {errors.address1 && errors.address1.type === "required" && <span class="error">Address line 1 is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 2</label>
                                                <input type="text" class="form-control custome-input" maxLength="50" id="address2" />
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>City<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" defaultValue="city" id="city"  {...register('city', { required: true,minLength:"2"})} maxLength="50" minLength="2" />
                                                {errors.city && errors.city.type === "required" && <span class="error">City is required</span>}
                                                {errors.city && errors.city.type === "minLenght" && <span class="error">City atleast 2 characters</span>}
                                                {/* {errors.city && errors.city.type === "pattern" && <span class="error">City is allow only character's</span>} */}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>State<span class="star">*</span></label>
                                                <select class="form-control custome-select" id="state"    {...register('state', { required: true })}>
                                                    {/* <option value='' >Select</option> */}
                                                    {
                                                        states ? states.map((state, index) => (
                                                            <option key={state} selected={dispatcherDetails.fkStateId == state.id ? "selected" : ""} value={state.id} >{state.name}</option>
                                                        )) : <option value=""></option>
                                                    }
                                                </select>
                                                {errors.state && errors.state.type === "required" && <span class="error">State is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Zip code<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" defaultValue="12345" maxLength="5"  id="zipcode"  {...register('zipcode', { required: true,pattern:/^[0-9]{5}(-[0-9]{1})?$/})} maxLength="5"/>
                                                {errors.zipcode && errors.zipcode.type === "required" && <span class="error">Zip code is required</span>}
                                                {errors.zipcode && errors.zipcode.type === "pattern" && <span class="error">Zip code should be 5 digits</span>}
                                                {/* {errors.zipcode && errors.zipcode.type === "minLength" && <span class="error">Zip code should min 4 digits</span>} */}
                                                {/* {errors.zipcode && errors.zipcode.type === "maxLength" && <span class="error">Zipcode should max 6 digits</span>} */}
                                            </div>

                                        </div>
                                            <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <div class="row g-2">
                                                    <div class="col-8">
                                                        <label>Desk Number<span class="star">*</span></label>
                                                        <input type="text" class="form-control custome-input" defaultValue="111-111-1111" id="deskNumber"  {...register('deskNumber', { required: true,maxLength: 12,minLength:12 })} />
                                                        {errors.deskNumber && errors.deskNumber.type === "required" && <span class="error">Desk number is required</span>}
                                                        {errors.deskNumber && errors.deskNumber.type === "maxLength" && <p class="error">Desk number should be 10 digits</p>}
                                                        {errors.deskNumber && errors.deskNumber.type === "minLength" && <p class="error">Desk number should be 10 digits</p>}
                                                    </div>
                                                    <div class="col-4">
                                                        <label style={{fontSize:"10px"}}>Extn no</label>
                                                        <input type="text" class="form-control custome-input" defaultValue="ext" id="ext" />
                                                        {/* {...register('ext', { required: true})} */}
                                                        {/* {errors.ext && errors.ext.type === "required" && <span class="error">Ext is required</span>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" class="form-control custome-input" defaultValue="mobileNumber" id="mobileNumber"  {...register('mobileNumber', { maxLength: 12,minLength:12 })} />
                                                {/* {errors.mobileNumber && errors.mobileNumber.type === "required" && <span class="error">Mobile number is required</span>} */}
                                                {errors.mobileNumber && errors.mobileNumber.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                                {errors.mobileNumber && errors.mobileNumber.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>Email<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="email" defaultValue="test@mailnator.com" {...register('email', {
                                                    required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                                })} readOnly/>
                                                {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                                {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                            </div>

                                        </div>
                                        

                                        <div class="row">
                                        <div class="col-md-6 mb-3">
                                                <label>Role<span class="star">*</span></label>
                                                <select class="form-control custome-select" id="role" {...register('role', { required: true })}>
                                                    <option value='' >Select</option>
                                                    {
                                                        roles ? roles.sort((a, b) => a.name.localeCompare(b.name)).map((role, index) => (
                                                            <option value={role.id} selected={dispatcherDetails.securityRoleId == role.id ? "selected" : ""}>{role.name}</option>
                                                        )) : <option value=""></option>
                                                    }
                                                </select>
                                                {errors.role && errors.role.type === "required" && <span class="error">Role is required</span>}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="card mb-2">
                                        <div class="card-body pt-0 pb-2 p-3">
                                            <div class="row d-flex align-items-center">
                                                {/* <div class="col-auto text-center">
                                                <div class="avatar">
                                                    <img src="/images/nia.jpg" alt=""/>
                                                </div>
                                            </div> */}

                                                <div class="col-md-2">
                                                    <b>Picture</b>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="avatar">
                                                        <img src={cropData ? cropData : dummyImge} id="vehicleImage" />


                                                    </div>




                                                </div>

                                                <div class="col-md-4"><a href="#" data-backdrop="static" data-keyboard="false" class="btn btn-outline-dark btn-fw" id="EditImage" data-toggle="modal" data-target="#exampleModal">
                                                    Upload Photo</a>
                                                </div>

                                                <div class="col-md-3 text-left">
                                                    <div class="instruction">
                                                        <span>Instruction</span>
                                                        <p>Max File Size : 5MB</p>
                                                        <p>Formats: JPG/PNG</p>
                                                        <p>Ratio: 1:1 (width and height must be equal)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-footer-comon-wrap">
                                        {/* <div class="row documents p-3">
                                            <div class="col-lg-12">
                                                {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}

                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-flex align-items-center">

                                                                    <a onClick={() => document_details(data.id, data.filePath)} class="ml-2">

                                                                        <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                            <img src="" class="mr-2" />
                                                                            <span><p className="filename mb-0" style={{ cursor: "pointer", color: "#3f88c5" }}><img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> {data.name ? data.name : "--"} </p></span>
                                                                            <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                                    </a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : ""

                                                }

                                            </div><br />

                                        </div> */}
                                        <div class="card mb-2 p-3">
                                            <h3 class="mb-4 pl-1">IDs & Other Documents</h3>
                                            <div class="col-md-6 text-left mb-3">
                                                <div class="instruction">
                                                    <span>Instruction</span>
                                                    <p>Max File Size : 50MB</p>
                                                    {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                    <p>Formats: JPG/PNG/PDF</p>
                                                    <p>Ratio: 1:1 (width and height must be equal)</p>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-12">
                                                            {data.name == "drivingLisence" ? "" :
                                                                <div class="align-items-center">

                                                                    {/* <a onClick={() => document_details(data.id, data.filePath)} class="ml-2"><b>{data.name}.{data.extension}</b></a> */}
                                                                    
                                                                    {/* {data.name}.{data.extension} */}
                                                                    <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                            <img src="" class="mr-2" />
                                                            <span style={{ width: "calc(100% - 50px)"}}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                                <a onClick={() => document_details(data.id, data.filePath)} class="ml-2 w-100">
                                                                {(() => {
                                                                            if (data.filePath.split(".")[1] == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            }else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                {data.name ? data.name : "--"}
                                                                </a>
                                                                </p>
                                                                </span>
                                                                <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />
                                                        </div>
                                                                   
                                                                   
                                                                </div>}
                                                        </div>


                                                    )) : ""

                                                }
                                            </div><br />
                                            <AdddocsDispatcher inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                            {/* <table>
                                        <tbody>
                                            <tr>
                                                <th style={{width: "80%"}} class="p-2">Document Title</th>
                                                <th style={{width: "20%"}} class="p-2">Options</th>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <img src="/images/icons/pdf-icon.svg" class="pr-2"/>
                                                        <a href="#" class="mr-2"><img src="/images/icons/remove-minus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <a href="#" class="mr-2"><img src="/images/icons/upload.svg" alt=""/></a>
                                                        <a href="#"><img src="/images/icons/add-plus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="p-3">
                                            <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                            <a onClick={() => redirect("/users")}  class="btn gray-mid-color mw-120">CANCEL</a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </form>





                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                                <button type="button" class="close" data-dismiss="modal" id="btnclose" aria-label="Close">
                                    ×
                                </button>
                            </div>
                            <div class="modal-body">
                            <input type="file" class="w-100 p-3" onChange={onChange} accept="image/png, image/jpg, image/jpeg" />
                                <div class="row mb-2" id="selectedImage">
                                    <div class="col-12">

                                        <div class="image-upload-plg">
                                           


                                            {/* <Cropper
                                                style={{ height: 320, width: "100%" }}
                                                zoomTo={0.5}
                                                initialAspectRatio={4 / 4}
                                                preview=".img-preview"
                                                src={image}
                                                viewMode={3}

                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            /> */}
                                             <Cropper
            style={{ height: 300, width: "100%" }}
            aspectRatio={1}
            guides={false}
            dragMode={"move"}
            src={image}
            onInitialized={(instance) => {
                setCropper(instance);
             }}
          />

                                        </div>


                                    </div>

                                    <div>
                                        <div className="box" style={{ width: "240px",height: "240px", float: "right", display: "none" }}>
                                            <h1>Preview</h1>
                                            <div
                                                className="img-preview"

                                            />
                                        </div>
                                        {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                                    </div>
                                </div>
                                <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "240px", height: "240px", marginTop:"20px" }} ></img>

                        </div>
                     </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnclose">Close</button>
                                <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={getCropData}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

EditUser.propTypes = {};

EditUser.defaultProps = {};

export default EditUser;
