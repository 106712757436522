import logo from './logo.svg';
import './App.css';
// import { ReactKeycloakProvider } from '@react-keycloak/web';
// import Keycloak from 'keycloak-js';
//import { keycloak } from './utility/keycloakConfig';
import Layout from './layouts/layout';
import $, { parseJSON } from 'jquery';
import services from './services/services';
import { requestObject } from './utility/requestObject';

import "@popperjs/core";

//import accountType from "../src/utility/checkaccountType";

// var realmName="";

//  if(localStorage.getItem("realm")!==null)
//  {

//     realmName=localStorage.getItem("realm");

//   }

//   else
//   {

//     //realmName="Dev";
//     realmName="Bradshaw";
//   }
    /// qa//////////////////
    // export const keycloak = new Keycloak({
    //     url: "http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth",
    //     realm: realmName,
    //     clientId: "dotstow_ui"
    //   })
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
//   realm: "Dev",
//   clientId: "dotstow_ui"
//   // username:"mahud@nexasi.com"
// })

//////////DEV FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
//   realm: "Dev",
//   clientId: "dotstow_ui"
// })

//////////UAT FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-uat-alb-127029246.us-east-2.elb.amazonaws.com/auth",
//   realm: "dotstow",
//   clientId: "dotstow_ui"
// })


function App() 
{

  const initOptions = { pkceMethod: 'S256' }


  
  // var keycloak="";
 
  // var realmName="";
 
  // if(localStorage.getItem("realm")=="dotstow")
  // {
  //   realmName="dotstow";
  // }else{
  //   realmName="Dev";
  // }
  // switch(localStorage.getItem("realm"))
  // {
  //   case "Dev":
      
  //     keycloak = new Keycloak({
  //       url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
  //       realm: realmName,
  //       clientId: "dotstow_ui"
  //     })
  //     break;
  //     case "dotstow":
       
  //       keycloak = new Keycloak({
  //         url: "http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth",
  //         realm: "dotstow",
  //         clientId: "dotstow_ui"
  //       })
  //       break;
  //       default:
         
  //         keycloak =new Keycloak({
  //           url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
  //           realm: "Dev",
  //           clientId: "dotstow_ui"
  //         })
  //         break;
/////////Production client setup////////////////////
  // var url = new URL("http://www.client1.dotstow.com");
  // var realmName = url.hostname.split('.')[1];
  //  const keycloak = new Keycloak({
  //  url: "http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth",
  //  realm: realmName,
  //  clientId: "dotstow_ui"
  // })


        // }
  //const initOptions = { pkceMethod: 'S256' }
 
  // const handleOnEvent = async (event, error) =>
  // {

  //   if (event === 'onAuthSuccess') 
  //   {
       
  //     if (keycloak.authenticated) 
  //     {
       
  //       if(keycloak.token!="")
  //       {
          
  //         localStorage.setItem("accToken",keycloak.token);
  //         sessionStorage.setItem("fkAccountId","1");
          
  //       }
      
  //     }else{
         
  //     }
  //   }else{
   
  //   }
  // }
  return (
   
    // <ReactKeycloakProvider
    //   authClient={keycloak}
    //   LoadingComponent={loadingComponent}
    //   initOptions={initOptions}
    //   onEvent={(event, error) => handleOnEvent(event, error)}>
       
       <Layout /> 

    // </ReactKeycloakProvider>
  );
}

export default App;