import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
import services from "../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../utility/Adddocs";
import CheckAuthentication from "../../../utility/checkAuthentication";
import AddInspectionName from "../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const InspectionVaporTightnessCertificate4 = () => {

    const { cid, id } = useParams();
    const [fileName, setfileName] = useState("")
    const [endDate, setEndDate] = useState(new Date());
    var customerid = localStorage.getItem("customerId");
    const [noOfRows, setNoOfRows] = useState(1);
    const [loading, setLoading] = useLoading("Please wait...");
    const [inputList, setInputList] = useState([{}]);
    const navigate = useNavigate();
    const [base64file, setBase64file] = useState('');

    const section2VaporRun1={
        "pi":"",
        "pf":"",
        "a":"",

        "ti":"",
        "tf":"",
        "titf":"",
      };

      const section2VaporRun2={
        "pi":"",
        "pf":"",
        "a":"",

        "ti":"",
        "tf":"",
        "titf":"",
      };

      const section2VaporRun3={
        "pi":"",
        "pf":"",
        "a":"",

        "ti":"",
        "tf":"",
        "titf":"",
      };

      const waterGauge= {
        "pf":"",
        "pfpi":"",
        "ti":"",
        "tf":"",
        "titf":"",
      }

    useEffect(() => {

        getSection4();

    }, []);

    function getSection4() {

        var run4 = JSON.parse(sessionStorage.getItem("section4Run4"));

        var run4_vi = run4 == null ? '' : run4.vi;
        var run4_ti = run4 == null ? '' : run4.ti;

        $("input[name='run4_vi']").val(run4_vi);
        $("input[name='run4_ti']").val(run4_ti);
        // =================
        var run5 = JSON.parse(sessionStorage.getItem("section4Run5"));

        var run5_vi = run5 == null ? '' : run5.vi;
        var run5_ti = run5 == null ? '' : run5.ti;

        $("input[name='run5_vi']").val(run5_vi);
        $("input[name='run5_ti']").val(run5_ti);

        // =================
        var run6 = JSON.parse(sessionStorage.getItem("section4Run6"));

        var run6_vi = run6 == null ? '' : run6.vi;
        var run6_ti = run6 == null ? '' : run6.ti;

        $("input[name='run6_vi']").val(run6_vi);
        $("input[name='run6_ti']").val(run6_ti);


    }
    const sectionSubmit = () => {
        var section4Run4 = {
            "vi": $("input[name='run4_vi']").val() != "" ? $("input[name='run4_vi']").val() : "",
            "ti": $("input[name='run4_ti']").val() != "" ? $("input[name='run4_ti']").val() : "",
        };
        console.log(JSON.stringify(section4Run4));
        sessionStorage.setItem("section4Run4", JSON.stringify(section4Run4));

        var section4Run5 = {
            "vi": $("input[name='run5_vi']").val() != "" ? $("input[name='run5_vi']").val() : "",
            "ti": $("input[name='run5_ti']").val() != "" ? $("input[name='run5_ti']").val() : "",
        };
        console.log(JSON.stringify(section4Run5));
        sessionStorage.setItem("section4Run5", JSON.stringify(section4Run5));

        var section4Run6 = {
            "vi": $("input[name='run6_vi']").val() != "" ? $("input[name='run6_vi']").val() : "",
            "ti": $("input[name='run6_ti']").val() != "" ? $("input[name='run6_ti']").val() : "",
        };
        console.log(JSON.stringify(section4Run6));
        sessionStorage.setItem("section4Run6", JSON.stringify(section4Run6));


    }


    $(document).ready(function () {
        $("input[name='run4_vi'],input[name='run4_ti'],input[name='run5_vi'],input[name='run5_ti'],input[name='run6_ti'],input[name='run6_vi'],input[name='run6_vi']").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                return false;
            }
        });
    });

    const save = (status) => {

        var documentList = [];

        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                    extension = "word";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                }
                else {

                }


                documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(), "extension": extension });
            }
        } catch (exception) {
            documentList.push();
            //  Swal.fire({  
            //      position: 'center',  
            //      icon: 'error',  
            //      title: "",  
            //      text:"Please attach document",
            //      showConfirmButton: true 

            //    }).then((result) => 
            //    {

            //          if(result.isConfirmed) 
            //          {


            //          }
            //    });
            //    return false;
        }

        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter inspectedBy",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }

        var section1Vapor =

        {
            "tankCapacityInGallons":
            {
                "lessthan999Gallons": sessionStorage.getItem("lessthan999Gallons") == null ? "0" : sessionStorage.getItem("lessthan999Gallons"),
                "g1000to1499Gallons": sessionStorage.getItem("1000to1499Gallons") == null ? "0" : sessionStorage.getItem("1000to1499Gallons"),
                "g1500to2499Gallons": sessionStorage.getItem("1500to2499Gallons") == null ? "0" : sessionStorage.getItem("1500to2499Gallons"),
                "greaterthan2499Gallons": sessionStorage.getItem("greaterthan2499Gallons") == null ? "0" : sessionStorage.getItem("greaterthan2499Gallons")
            },
            "typeOfOverProtectionSystem":
            {
                "optic": sessionStorage.getItem("optic") == null ? "" : sessionStorage.getItem("optic"),
                // "thermister": sessionStorage.getItem("thermister") == null ? "0" : sessionStorage.getItem("thermister"),
                // "other": sessionStorage.getItem("other") == null ? "0" : sessionStorage.getItem("other"),
                "other":sessionStorage.getItem("otherData") == null ? "" : sessionStorage.getItem("otherData"),

            },
            "otherProperties":
            {
                "connect": sessionStorage.getItem("connect") == null ? "0" : sessionStorage.getItem("connect"),
                "liquid": sessionStorage.getItem("liquid") == null ? "0" : sessionStorage.getItem("liquid"),
                "compartments": sessionStorage.getItem("compartments") == null ? "0" : sessionStorage.getItem("compartments"),
                "stablization": sessionStorage.getItem("stablization") == null ? "0" : sessionStorage.getItem("stablization"),
                "location": sessionStorage.getItem("location") == null ? "0" : sessionStorage.getItem("location"),
                "cove": sessionStorage.getItem("cove") == null ? "0" : sessionStorage.getItem("cove"),
                "internally": sessionStorage.getItem("internally") == null ? "0" : sessionStorage.getItem("internally"),
                "recovery": sessionStorage.getItem("recovery") == null ? "0" : sessionStorage.getItem("recovery"),
                "pressure": sessionStorage.getItem("pressure") == null ? "0" : sessionStorage.getItem("pressure"),
                "manometer": sessionStorage.getItem("manometer") == null ? "0" : sessionStorage.getItem("manometer"),
            },
            "overfillProtectionSystemRepaired": sessionStorage.getItem("overfiils") !=''?sessionStorage.getItem("overfiils"):'',
            "purgedTankCompartmentsOfVapour": {
                "loadOfNonVolatile": sessionStorage.getItem("loadOfNonVolatile") == null ? "0" : sessionStorage.getItem("loadOfNonVolatile"),
                "steamCleaned": sessionStorage.getItem("steamCleaned") == null ? "0" : sessionStorage.getItem("loadOfNonVolatile"),
                "purgeEachCompartmentWithAirFor20Minutes": sessionStorage.getItem("purgeEachCompartmentWithAirFor20Minutes") == null ? "0" : sessionStorage.getItem("loadOfNonVolatile")
            },
            "waterGaugeReading":JSON.parse(sessionStorage.getItem("waterGauge"))==null?waterGauge:JSON.parse(sessionStorage.getItem("waterGauge")),
            "run1": JSON.parse(sessionStorage.getItem("section2VaporRun1"))==null?section2VaporRun1:JSON.parse(sessionStorage.getItem("section2VaporRun1")),
            "run2": JSON.parse(sessionStorage.getItem("section2VaporRun2"))==null?section2VaporRun2:JSON.parse(sessionStorage.getItem("section2VaporRun2")),
            "run3": JSON.parse(sessionStorage.getItem("section2VaporRun3"))==null?section2VaporRun3:JSON.parse(sessionStorage.getItem("section2VaporRun3")),
            "run4": {
                "vi": $("input[name='run4_vi']").val() != "" ? $("input[name='run4_vi']").val() : "",
                "ti": $("input[name='run1_ti']").val() != "" ? $("input[name='run4_ti']").val() : "",

            },
            "run5": {
                "vi": $("input[name='run5_vi']").val() != "" ? $("input[name='run5_vi']").val() : "",
                "ti": $("input[name='run5_ti']").val() != "" ? $("input[name='run5_ti']").val() : "",

            },
            "run6": {
                "vi": $("input[name='run6_vi']").val() != "" ? $("input[name='run6_vi']").val() : "",
                "ti": $("input[name='run6_ti']").val() != "" ? $("input[name='run6_ti']").val() : "",

            }
            //    "run1": {
            //       "pi": $("input[name='run1_pi']").val(),
            //       "pf": $("input[name='run1_pf']").val(),
            //       "a": $("input[name='run1_a']").val(),
            //       "ti": $("input[name='run1_ti']").val(),
            //       "tf": $("input[name='run1_tf']").val(),
            //       "titf": $("input[name='run1_titf']").val()
            //    },
            //    "run2": {
            //       "pi": $("input[name='run2_pi']").val(),
            //       "pf": $("input[name='run2_pf']").val(),
            //       "a": $("input[name='run2_a']").val(),
            //       "ti": $("input[name='run2_ti']").val(),
            //       "tf": $("input[name='run2_tf']").val(),
            //       "titf": $("input[name='run2_titf']").val()
            //    },
            //    "run3": {
            //       "pi": $("input[name='run3_pi']").val(),
            //       "pf": $("input[name='run3_pf']").val(),
            //       "a": $("input[name='run3_a']").val(),
            //       "ti": $("input[name='run3_ti']").val(),
            //       "tf": $("input[name='run3_tf']").val(),
            //       "titf": $("input[name='run3_titf']").val()
            //    }
        }

        console.log(JSON.stringify(section1Vapor));

        sessionStorage.setItem("section1Vapor", JSON.stringify(section1Vapor));

        var requestObjectForInsp =
        {
           
            "type": "Vapor Tightness Certification",
            "workOrderNumber": $("#workOrderNumber").val(),
            "notes": $("#notes").val(),
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "status": status,
            "inspectionType":
            {
                "id": sessionStorage.getItem("selectedinspectionId"),
                "name":sessionStorage.getItem("selectedinspectionType")
            },
            "result":
            {
                "approvedCertify": "0",
                "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
                "vehicleReturnedServices": "0",
                "vehicleWithdrawnFromService": "0",
                "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id": id,
                "unitNumber": $("#lisencePlateNumber").html()
            },

            "vaporTightnessCertification": section1Vapor,
            "documentList": documentList
        }

        // setLoading(true);
        $("#loadingforsubmit").show();
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
            $("#loadingforsubmit").hide();
            // setLoading(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: res.message,
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {

                sessionStorage.removeItem("greaterthan2499Gallons");
                sessionStorage.removeItem("1500to2499Gallons");
                sessionStorage.removeItem("1000to1499Gallons");
                sessionStorage.removeItem("lessthan999Gallons");
                sessionStorage.removeItem("overfiils");
                sessionStorage.removeItem("1500to2499Gallons");
                sessionStorage.removeItem("optic");
                sessionStorage.removeItem("thermister");
                sessionStorage.removeItem("other");
                sessionStorage.removeItem("connect");
                sessionStorage.removeItem("liquid");
                sessionStorage.removeItem("compartments");
                sessionStorage.removeItem("stablization");
                sessionStorage.removeItem("cove");
                sessionStorage.removeItem("internally");
                sessionStorage.removeItem("recovery");
                sessionStorage.removeItem("pressure");
                sessionStorage.removeItem("manometer");
                sessionStorage.removeItem("loadOfNonVolatile");
                sessionStorage.removeItem("steamCleaned");
                sessionStorage.removeItem("purgeEachCompartmentWithAirFor20Minutes");
                sessionStorage.removeItem("section2VaporRun1");
                sessionStorage.removeItem("section2VaporRun2");
                sessionStorage.removeItem("section2VaporRun3");
                sessionStorage.removeItem("waterGauge");
                sessionStorage.removeItem("section4Run4");
                sessionStorage.removeItem("section4Run5");
                sessionStorage.removeItem("section4Run6");
                sessionStorage.removeItem("location");
                sessionStorage.removeItem("otherData");

                    navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                }
            });
        });

    }
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {}]);
    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }
    const onUploadFileChange = (event, index) => {


        var target = event.target;
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                const { name, value } = event.target;
                const list = [...inputList];
                list[index][name] = result;
                setBase64file(result);
                // setFile(result)
                setfileName(target.files[0]);
                // setFileName(target.files[0])
            }
        })
    }

    return (
        <>
     {LoadingComponentForSubmit}
            <div class="row mb-2">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">

                        {/* <div class="divider"></div> */}
                        <div class="row px-3 mb-3">

                            <div class="w-100">
                                <h4 class="form-inspection-title text-center">Vapor Tightness Certification Form</h4>
                            </div>
                            <div class="col-lg-12 px-0"><WorkOrderNumberDiv /></div>
                            <div class="w-100">
                                <div class="inspection-wrap mb-4">

                                    <div class="step-wrap p-3 mb-3">
                                        <div class="step-main d-flex">
                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                                {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""></div> --> */}
                                                <div class="labl-section">Annual Certification</div>
                                            </div>
                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                                <div class="labl-section">Pressure Test</div>
                                            </div>

                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                                <div class="labl-section">Internal Vapor Valve Test</div>
                                            </div>

                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 current-tick">4</div>
                                                <div class="labl-section">Vaccum Test</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="text-center mb-2 mt-2">
                                    <b>EPA METHOD 27 Vacuum Test</b>
                                    <div class="p-3 mb-0 mt-3 text-left" style={{ backgroundColor: "#ccc" }}>
                                        <p>Draw vacuum to 6 inches (maximum of 10.0) Water gauge (Manometer); Indicate vacuum Vi at the start and Vf at the end of the 5 minute time frame. Record initial (Ti) and final time (Tf) of test or duration
                                            if stop watch is used.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div class="table-5">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">Run 1</td>
                                                        <td style={{ width: "100%", display: "grid" }}>
                                                            <div>
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                </div>
                                                                <div class="d-flex justify-content-center border-bottom">
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3.00</div>
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2">3.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Vi =</label>
                                                                    <input type="input" name="run4_vi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Ti =</label>
                                                                    <input type="input" name="run4_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div class="table-5">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">Run 2</td>
                                                        <td style={{ width: "100%", display: "grid" }}>
                                                            <div>
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                </div>
                                                                <div class="d-flex justify-content-center border-bottom">
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3.00</div>
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2">3.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Vi =</label>
                                                                    <input type="input" name="run5_vi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Ti =</label>
                                                                    <input type="input" name="run5_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div class="table-5">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">Run 3</td>
                                                        <td style={{ width: "100%", display: "grid" }}>
                                                            <div>
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Water Gauge Readings
                                                                </div>
                                                                <div class="d-flex justify-content-center border-bottom">
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2 border-right">3.00</div>
                                                                    <div class="d-flex justify-content-center w-half m-auto p-2">3.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Total Inches Water</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">6.00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Vi =</label>
                                                                    <input type="input" name="run6_vi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="border-bottom">
                                                                <div class="border-bottom p-2" style={{ minHeight: "55px" }}>Start Time (Ti) Finishing Time (Tf)</div>
                                                                <div class="d-flex justify-content-center">
                                                                    <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2 border-left">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <label for="">Ti =</label>
                                                                    <input type="input" name="run6_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()} />
                                                                </div>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="divider2"></div>
                        <div class="row p-0">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                        


                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>

                            <button class="btn btn-primary mr-1" id="certificate3">Back</button>
                            <button class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </button>
                            <button class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

InspectionVaporTightnessCertificate4.propTypes = {};

InspectionVaporTightnessCertificate4.defaultProps = {};

export default InspectionVaporTightnessCertificate4;
