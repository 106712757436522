import React,{history,useEffect, useState, useMemo } from "react";
import employeeList from "../../../../utility/employeeLists";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../../utility/pagination";
import Search from "../../../../utility/search";
import $ from "jquery";
import employeeDriverList from "../../../../utility/employeeDriverList";
import { loadingComponent } from "../../../../utility/Loading";
import { requestObject } from "../../../../utility/requestObject";
import services from "../../../../services/services";
const Employees = () => {

     const [profileImage, setNavImagesrc] = useState("");
     const[customerDetails,setcustomerDetails]=useState();

    const [roles, setRoles] = useState("");
  const[dispatcherList,setdispathcerlist]=useState();
  const [displayPage, setdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalRecors,setTotalRecord] = useState([]);
  const [totalpages, settotalPages] = useState();
  const [searchtxt, setsearchText] = useState("");

  const navigate = useNavigate();
  const[driverList,setdriverlist]=useState();
  const [displayDriverPage, setDriverdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE_DRIVER, setitemperpagedriver] = useState(25);
  const [currentDriverPage, setCurrentDriverPage] = useState(1);
  const [totalDriverItems, setTotalDriverItems] = useState(0);
  const [totalDriverRecors,setTotalDriverRecord] = useState([]);
  const [totalDriverpages, settotalDriverPages] = useState();
  const [searchDrivertxt, setsearchDriverText] = useState("");
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const [inactiveDriverStatus, setInactiveDriverStatus] = useState(true);
  var securityRoleName=sessionStorage.getItem("securityRoleName");
    useEffect(() =>
    {
        setRoles(localStorage.getItem("Userroles"));
         employeeList("Dispatcher","1",ITEMS_PER_PAGE,ITEMS_PER_PAGE,"","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchtxt,inactiveStatus);
         employeeDriverList("Driver","1",ITEMS_PER_PAGE_DRIVER,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDrivertxt,inactiveDriverStatus);

        $('#display_driver_pages_in_items').on('change', function() {


            setitemperpagedriver(this.value);
            employeeDriverList("Driver","1",this.value,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDrivertxt,inactiveDriverStatus);


        });

           var navigationFrom=sessionStorage.getItem("naviationfrom");

            if(navigationFrom === "drivers"){
                document.getElementById("pills-profile-tab").click();
                sessionStorage.removeItem("naviationfrom");
            }else{
                document.getElementById("pills-home-tab").click();
                sessionStorage.removeItem("naviationfrom");
                // $("#profileDropdown").load("");

            }




    GetUserDetails();

    },[]);

    const GetUserDetails=()=>{
        var customerListReq=
        {

        };
        var requestOptions=requestObject.RequestHeader(customerListReq);
    services.getUserDetails(requestOptions).then((res) =>
    {


      sessionStorage.setItem("userId",res.rows?res.rows.id:"");
      $("#posterURL").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/avatar.png":"/images/avatar.png");

      setNavImagesrc(res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/avatar.png":"/images/avatar.png")
      $("#profileDropdown").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/avatar.png":"/images/avatar.png");
      setcustomerDetails(res.rows);
      sessionStorage.setItem("securityRoleId",res.rows?res.rows.securityRoleId:"");

      $("#customerNameH").html(res.rows?res.rows.firstName+" "+res.rows.lastName:"");
      sessionStorage.setItem("customerName",res.rows?res.rows.firstName+" "+res.rows.lastName:"");
      sessionStorage.setItem("securityRoleName",res.rows?res.rows.securityRoleName:"");
    });

    }

    /////////////staff changes///////////////////////////////////
    const  staffChanges=()=>
    {
         var triggerVallue= $('#display_pages_in_items').val();

         setitemperpage(triggerVallue);
         employeeList("Dispatcher","1",triggerVallue,"10","","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchtxt,inactiveStatus);


    }
      /////////////////emp pagination////////////////////////////
  const handlePageChange = (offset,triggerevent) =>
  {


    employeeList("Dispatcher",offset,ITEMS_PER_PAGE,ITEMS_PER_PAGE,"","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchtxt,inactiveStatus);

  }

     /////////////////emp pagination////////////////////////////
     const handleDriverPageChange = (offset,triggerevent) =>
     {


        employeeDriverList("Driver",offset,ITEMS_PER_PAGE_DRIVER,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDrivertxt,inactiveDriverStatus);

     }

    /////////////////////////////emp search/////////////////////////
    const searchList = (searchText) =>
    {
        setsearchText(searchText);
        employeeList("Dispatcher","1",ITEMS_PER_PAGE,ITEMS_PER_PAGE,"","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchText,inactiveStatus);

    }
     /////////////////////////////emp search/////////////////////////
     const searchDriverList = (searchDriverText) =>
     {
         setsearchDriverText(searchDriverText);
         employeeDriverList("Driver","1",ITEMS_PER_PAGE_DRIVER,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDriverText,inactiveDriverStatus);

     }

     const redirect=(page)=>
     {

         navigate(page);

     }

     var activestatus="false";
    const activeInctive = (event) => 
    {
        
        if(event.target.checked)
        {

        }else{
            activestatus="true";
        }
        // customerVehicle(customerId, "1", ITEMS_PER_PAGE1, searchVehicle, vehicleId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems", activestatus);
        employeeList("Dispatcher","1",ITEMS_PER_PAGE,ITEMS_PER_PAGE,"","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchtxt,activestatus);
        setInactiveStatus(activestatus);
    }

    var activestatusDriver="false";
    const activeInctiveDriver = (event) => 
    {
        
        if(event.target.checked)
        {

        }else{
            activestatusDriver="true";
        }
        employeeDriverList("Driver","1",ITEMS_PER_PAGE_DRIVER,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDrivertxt,activestatusDriver);
        setInactiveDriverStatus(activestatusDriver);
    }

    function uncheckStaf(){
        $('#inactive').prop({checked: false}).trigger("change");
        employeeList("Dispatcher","1",ITEMS_PER_PAGE,ITEMS_PER_PAGE,"","",setdispathcerlist,setdisplayPage,"displayItems",setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchtxt,true);
    };

    function uncheckDriver(){
        $('#inactiveDriver').prop({checked: false}).trigger("change");
        employeeDriverList("Driver","1",ITEMS_PER_PAGE_DRIVER,ITEMS_PER_PAGE_DRIVER,"","",setdriverlist,setDriverdisplayPage,"displayItems",setCurrentDriverPage,setTotalDriverRecord,setTotalDriverItems,settotalDriverPages,searchDrivertxt,true);
    };

  return(
  <>
    {loadingComponent}
<div class="main-panel">
                <div class="content-wrapper" style={{display: "block"}}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <h4 class="font-weight-bold my-4 text-uppercase">Users</h4>
                            {/* text-uppercase */}
                        </div>
                    </div>
                    <div class="row mb-2 ">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div>
                                <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-home-tab" onClick={() => uncheckDriver()} data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Staff </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-profile-tab" onClick={() => uncheckStaf()} data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Drivers</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        {/* <div class="search position-relative align-self-start mb-1 mt-1 mr-4"> */}
                                                            {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a> */}
                                                             <div class="d-flex justify-content-start">
                                                             <div class="search position-relative">
                                                                        <Search
                                                                        placeholderName="Search by Emp ID / Name / Role"
                                                                        onSearch={value => {
                                                                            searchList(value);
                                                                        }}

                                                                        />
                                                                         <a href="#" class="search-icon">
                                                                    <img src="/images/icons/search.svg" alt=""/>
                                                                </a>
                                                        </div>
                                                    
                                                        {roles.match("Driver")||roles.match("Dispatcher")?"": <><input type="checkbox"
                                                                           onChange={(e) => activeInctive(e) }
                                                                            id="inactive"
                                                                            name="inactive"
                                                                           
                                                                            style={{marginLeft:"21px",marginTop:"4px"}} /> <span style={{ marginLeft: "10px", marginTop: "15px" }}>
                                                                           Inactive records only
                                                                          </span></>}
                                                                          </div>
                                                        <div class="d-flex align-self-start mb-1 mt-1">
                                                        {roles.match("Driver")||roles.match("Dispatcher")?"": <a  onClick={() => redirect("/fleetowner/AddDispatcher")} type="button" class="btn btn-success">Add Employee</a>}
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive border-top border-bottom">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Emp ID</th>
                                                                                <th>Name</th>
                                                                                <th>Email ID</th>
                                                                                <th>Contact Number</th>
                                                                                <th>Role</th>
                                                                                <th style={{width:"99px"}}>Actions</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                           {
                                                                                dispatcherList?dispatcherList.map((data, index) => (
                                                                                <tr>
                                                                                <td>{data.id?data.id:"--"}</td>
                                                                                <td class="text-capitalize text-left" style={{maxWidth:"150px", whiteSpace:"break-spaces", paddingRight:"10px", lineHeight:"18px"}}>{data.firstName?data.firstName:""}{"  "}{data.lastName?data.lastName:""} </td>

                                                                                <td style={{maxWidth:"200px", whiteSpace:"break-spaces", paddingRight:"10px", lineHeight:"18px"}}>{data.email?data.email:"--"}</td>
                                                                                <td>{data.phoneNumber?data.phoneNumber:"--"}</td>
                                                                                <td style={{ paddingRight:"10px"}}>{data.securityRoleName?data.securityRoleName:"--"}</td>
                                                                                <td><a  onClick={() => redirect("/fleetowner/DispatcherDetails/"+data.id)} class="btn btn-outline-primary text-uppercase">View</a>&nbsp;
                                                                                {/* {roles.match("Driver")||roles.match("Dispatcher")?"": securityRoleName=="Manager"?data.securityRoleName=="Owner"?"":<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>:<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>} */}
                                                                                {(() => {
        if (securityRoleName == "Manager") {
            if (data.securityRoleName == "Owner") {
                return (
                    ""
                )
            } else {
                if(data.securityRoleName == "Driver" || data.securityRoleName == "Dispatcher"){
                    return(<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>)
                }else{
                if (sessionStorage.getItem('userId') == data.id) { return (<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>) } else {
                    return (''
                    )
                }
            }
            }
        }else {

            if (securityRoleName == "Driver" || securityRoleName == "Dispatcher") {
                return ("")
            } else {
                if (data.securityRoleName == "Owner") {
                    if (sessionStorage.getItem('userId') == data.id) {  return (<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>
                ) }else{return ("")}
                   }else{
                return (<a onClick={() => redirect("/fleetowner/EditDispatcher/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>
                )
                    }
            }
        }

      })()}
                                                                                 </td>
                                                                                </tr>
                                                                                )):""
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    {dispatcherList?"":<div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                                                </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {dispatcherList?<div class="d-flex align-items-center justify-content-between p-3">
                                                        <div class="d-flex">
                                                            <div class="dropdown show mr-3 ">
                                                                                  <select class="form-control custome-select btn gray-mid-color dropdown-toggle"  href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"    onChange={() =>

                                                                    staffChanges() }>
                                                                                        {
                                                                                                    displayPage.map((data, index) => (
                                                                                                        <option value={data}>{data}</option>
                                                                                                    ))
                                                                                        }
                                                                                  </select>
                                                                {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div> */}
                                                            </div>
                                                            <span class="d-flex align-items-center pagenation-mob">{currentPage*ITEMS_PER_PAGE<=totalRecors?currentPage*ITEMS_PER_PAGE:totalRecors} out of {totalRecors} Records Displayed</span>
                                                        </div>
                                                        <nav aria-label="Page navigation example">
                                                            <Pagination
                                                                total={totalRecors}
                                                                itemsPerPage={ITEMS_PER_PAGE}
                                                                currentPage={currentPage}
                                                                //onPageChange={page => setCurrentPage(page)}
                                                                onPageChange={handlePageChange}
                                                            />

                                                        </nav>
                                                        {/* <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                                                    </div>:""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        {/* <div class="search position-relative align-self-start mb-1 mt-1 mr-4"> */}
                                                        <div class="d-flex justify-content-start">
                                                                <div class="search position-relative">
                                                                   <Search
                                                                        placeholderName="Search by EMP ID / Name"
                                                                        onSearch={value => {
                                                                            searchDriverList(value);
                                                                        }}

                                                                   />
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a>
                                                        </div>
                                                        {roles.match("Driver")||roles.match("Dispatcher")?"": <><input type="checkbox"
                                                                           onChange={(e) => activeInctiveDriver(e) }
                                                                            id="inactiveDriver"
                                                                            name="inactive"
                                                                           
                                                                            style={{marginLeft:"21px",marginTop:"4px"}} /> <span style={{ marginLeft: "10px", marginTop: "15px" }}>
                                                                           Inactive records only
                                                                          </span></>}
                                                                          </div>
                                                        <div class="d-flex align-self-start mb-1 mt-1">
                                                        {roles.match("Driver")||roles.match("Dispatcher")?"": <a  onClick={() => redirect("/fleetowner/AddDriver")} type="button" class="btn btn-success">Add Driver</a>}
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive border-top border-bottom">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Emp ID</th>
                                                                                <th>Name</th>
                                                                                <th>Email ID</th>
                                                                                <th>Contact Number</th>
                                                                                <th style={{width:"99px"}}>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                                driverList?driverList.map((data, index) => (
                                                                                <tr>
                                                                                <td>{data.id?data.id:"--"}</td>
                                                                                <td class="text-capitalize">{data.firstName?data.firstName:""}{"  "}{data.lastName?data.lastName:""} </td>

                                                                                <td>{data.email?data.email:"--"}</td>
                                                                                <td>{data.phoneNumber?data.phoneNumber:"--"}</td>
                                                                                <td><a onClick={() => redirect("/fleetowner/DriverDetails/"+data.id)}  class="btn btn-outline-primary text-uppercase">View</a>&nbsp;
                                                                                {roles.match("Driver")||roles.match("Dispatcher")?"":<a  onClick={() => redirect("/fleetowner/EditDriver/"+data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>}
                                                                                </td>
                                                                                </tr>
                                                                                )):""
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    {driverList?"":<div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                                                </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {driverList?<div class="d-flex align-items-center justify-content-between p-3">
                                                        <div class="d-flex">
                                                            <div class="dropdown show mr-3 ">
                                                                                <select class="form-control custome-select btn gray-mid-color dropdown-toggle"  href="#" role="button" id="display_driver_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {
                                                                                                    displayDriverPage.map((data, index) => (
                                                                                                        <option value={data}>{data}</option>
                                                                                                    ))
                                                                                        }
                                                                                  </select>
                                                            </div>
                                                            <span class="d-flex align-items-center pagenation-mob">{currentDriverPage*ITEMS_PER_PAGE_DRIVER<=totalDriverRecors?currentDriverPage*ITEMS_PER_PAGE_DRIVER:totalDriverRecors} out of {totalDriverRecors} Records Displayed</span>
                                                        </div>

                                                        <nav aria-label="Page navigation example">
                                                            <Pagination
                                                                total={totalDriverItems}
                                                                itemsPerPage={ITEMS_PER_PAGE_DRIVER}
                                                                currentPage={currentDriverPage}
                                                                //onPageChange={page => setCurrentPage(page)}
                                                                onPageChange={handleDriverPageChange}
                                                            />

                                                        </nav>
                                                        {/* <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                                                    </div>:""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div class="modal fade" id="assignVehicle" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign Vehicle</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search vehicle number" class="pl-5 w-100"/>
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt=""/></a>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label class="form-check-label" for="flexRadioDefault1">ERS 8547</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                            <label class="form-check-label" for="flexRadioDefault2">584 SGX</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                            <label class="form-check-label" for="flexRadioDefault3">ERS 8547</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                            <label class="form-check-label" for="flexRadioDefault4">584 SGX</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
                                            <label class="form-check-label" for="flexRadioDefault5">ERS 8547</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6"/>
                                            <label class="form-check-label" for="flexRadioDefault6">584 SGX</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="assignVehicleSuccessful" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <svg viewBox="0 0 50 50" class="mb-3" style={{width:"64px", borderRadius: "100%"}}>
                                    <g id="vehicle-icon" transform="translate(399 -183)">
                                      <rect width="50" height="50" transform="translate(-399 183)" fill="#ddd"/>
                                      <g transform="translate(-795.866 9.024)">
                                        <path id="Path_530" data-name="Path 530" d="M438.078,199.107a14.08,14.08,0,0,1,.261,1.731q.1,1.012.1,1.763v3.331a2.509,2.509,0,0,1-2.514,2.514h-1.273a3.332,3.332,0,0,1-5.78,0h-1.306a2.373,2.373,0,0,1-1.453-.473,2.544,2.544,0,0,1-.9-1.192H416.69a3.331,3.331,0,0,1-6.661,0h-2.514A2.508,2.508,0,0,1,405,204.266V190.878a2.508,2.508,0,0,1,2.514-2.514h16.718a2.508,2.508,0,0,1,2.514,2.514v.979a2.461,2.461,0,0,1,.816-.13h6.694a2.956,2.956,0,0,1,2.808,2.318l.359,1.7.653,3.331Zm-21.812,5.975h8.784v-14.2a.837.837,0,0,0-.816-.817H407.514a.819.819,0,0,0-.6.245.784.784,0,0,0-.245.571v13.388a.784.784,0,0,0,.245.571.82.82,0,0,0,.6.245h2.939a3.516,3.516,0,0,1,1.224-1.208,3.322,3.322,0,0,1,3.363,0A3.525,3.525,0,0,1,416.265,205.082Zm-1.73,2.874a1.6,1.6,0,0,0,.49-1.176,1.643,1.643,0,0,0-.49-1.208,1.656,1.656,0,0,0-2.351,0,1.643,1.643,0,0,0-.49,1.208,1.662,1.662,0,0,0,2.841,1.176Zm21.387-1.176a.837.837,0,0,0,.849-.849v-2.514h-.849a.837.837,0,0,1-.816-.817.82.82,0,0,1,.245-.6.783.783,0,0,1,.571-.245h.817q-.033-.392-.082-.833t-.082-.833h-5.649a.837.837,0,0,1-.849-.849v-3.331a.836.836,0,0,1,.849-.849h4.637l-.13-.686a1.116,1.116,0,0,0-.425-.686,1.173,1.173,0,0,0-.751-.294h-6.694a.783.783,0,0,0-.571.245.821.821,0,0,0-.245.6v11.69a.821.821,0,0,0,.245.6.784.784,0,0,0,.571.245h.849a3.245,3.245,0,0,1,.979-2.384,3.206,3.206,0,0,1,2.351-.979,3.344,3.344,0,0,1,3.363,3.363Zm-3,1.176a1.6,1.6,0,0,0,.49-1.176,1.642,1.642,0,0,0-.49-1.208,1.655,1.655,0,0,0-2.351,0,1.642,1.642,0,0,0-.49,1.208,1.662,1.662,0,0,0,2.841,1.176Zm3.331-9.535-.327-1.7h-4.18v1.7Z" transform="translate(0 0)" fill="#000"/>
                                      </g>
                                    </g>
                                  </svg>
                                        <p class="mb-0">Vehicle number <b>485 JKM</b><br/>Successfully Assigned to <b>David Boom</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </>
)};

Employees.propTypes = {};

Employees.defaultProps = {};

export default Employees;
