import React, { history, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import { Controller, useForm } from "react-hook-form";
import services from "../../../../services/services";
import "cropperjs/dist/cropper.css";
import Getstates from "../../../../utility/getstates";
import getCroppedImg from "../../../../utility/cropImage";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import Cropper from "react-cropper";
import UsNumberValidate from "../../../../utility/usNumberValidate";
import uploadImage from "../../../../utility/uploadImage";
import AdddocsDispatcher from "../../../../utility/AdddocsDispatcher";
import DatePicker from "react-datepicker";
import moment from "moment";
import AdddocsDriver from "../../../../utility/adddocsdriver";
import documentDetails_ from "../../inspection/wet-test-certification/documentDetails_";
import Adddocs from "../../../../utility/Adddocs";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../../utility/LoadingForDoc";
const FleetEditDriver = () => {
    const inputRef = useRef();
    const navigate = useNavigate();
    const [issuedDate, setIssuedDate] = useState(null);
    // (new Date())
    const [expDate, setExpDate] = useState(null);
    const { userId } = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const dummyImge = window.location.origin + "/images/avatar.png";
    var documentLists = [];
    const [imgsrc, setImageSrc] = useState()
    const [image, setImage] = useState("/images/avatar.png")
    const [liecenceimage, setLiecenceImage] = useState("/images/avatar.png")
    const [states, setState] = useState([])
    const [cropper, setCropper] = useState()
    const [fileName, setfileName] = useState("");
    const [cropperLiecence, setCropperLiecence] = useState()
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [cropData, setCropData] = useState("")
    const [cropLiecenceData, setCropLiecenceData] = useState("")
    const [itemName, setFieldName] = useState('')
    const [loading, setLoading] = useLoading("Please wait...");
    const [croppedImage, setCroppedImage] = useState(null);

    const [inputList, setInputList] = useState([{}]);

    const [base64file, setBase64file] = useState('');
    const [DriverDetails, setDriverDetails] = useState('')
    const [documentList, setDocumentLists] = useState([]);
    const [licenseStateId, setLincenseStateId] = useState([]);

    const {
        control,
        reset,
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all"
    });

    $(document).ready(function () {

        $("#deskNumber,#ext,#zipcode").keypress(function (e) {
            // #licenceNumber
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
            }
        });
    });

    useEffect(() => {
        $("#loadingfordocs").hide();
        sessionStorage.setItem("naviationfrom", "drivers");
        Getstates(setState);
        $('#mobileNumber').usPhoneFormat();
        $('#deskNumber').usPhoneFormat();
        GetUserDetails(userId);
    }, []);

    const GetUserDetails = (userId) => {
        $('#loadingforsubmit').show();
        var vechileReq =
        {
            "id": userId
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.getUserDetails(requestOptions).then((res) => {
            $('#loadingforsubmit').hide();
            try {


                $("#firstName").val(res.rows ? res.rows.firstName ? res.rows.firstName : "" : "");
                $("#lastName").val(res.rows ? res.rows.lastName ? res.rows.lastName : "" : "");
                $("#email").val(res.rows ? res.rows.email ? res.rows.email : "" : "");
                $("#deskNumber").val(res.rows ? res.rows.deskNumber ? res.rows.deskNumber : "" : "");
                $("#ext").val(res.rows ? res.rows.extnNo ? res.rows.extnNo : "" : "");
                $("#mobileNumber").val(res.rows ? res.rows.phoneNumber ? res.rows.phoneNumber : "" : "");
                $("#address1").val(res.rows ? res.rows.addressLine1 ? res.rows.addressLine1 : "" : "");
                $("#address2").val(res.rows ? res.rows.addressLine1 ? res.rows.addressLine2 : "" : "");
                $("#city").val(res.rows ? res.rows.city ? res.rows.city : "" : "");
                $("#zipcode").val(res.rows ? res.rows.zip ? res.rows.zip : "" : "");

                if (res.rows) {
                    try {

                        if (res.rows.documents.length > 0) {

                            documentLists.push(res.rows.documents);
                            sessionStorage.setItem("documentList", JSON.stringify(res.rows.documents));
                            setDocumentLists(res.rows.documents ? res.rows.documents : "");

                            for (var i = 0; i < res.rows.documents.length; i++) {

                                if (res.rows.documents[i].name === "DrivingLisence" || res.rows.documents[i].name === "drivingLisence") {
                                    $("#licenceNumber").val(res.rows.documents[i].documentNumber);
                                    reset({
                                        issuedDate: res.rows ? new Date(res.rows.documents[i].issuedDate) : "", // Set another date
                                        expDate: res.rows ? new Date(res.rows.documents[i].expiryDate) : "", // Set another date
                                    });
                                    setIssuedDate(res.rows ? new Date(res.rows.documents[i].issuedDate) : "");
                                    $("#issuedDate").val(res.rows.documents[i].issuedDate);
                                    $("#expDate").val(res.rows.documents[i].expiryDate);
                                    console.log("Date=======" + res.rows ? new Date(res.rows.documents[i].issuedDate) : "")
                                    // setIssuedDate(new Date(res.rows ? res.rows.documents[i].issuedDate : ""));
                                    setExpDate(res.rows ? new Date(res.rows.documents[i].expiryDate) : "");
                                    setFieldName(res.rows ? res.rows.documents[i].name : "");
                                }
                                else {

                                    //   setfileNames(res.rows.documents[i].name);
                                    //  $("#docName"+i).val(res.rows.documents[i].name);
                                    //  $("#filename"+i).val(res.rows.documents[i].name);
                                }




                            }

                        }
                        else {

                        }

                    } catch (ex) {

                    }



                }

                setDriverDetails(res.rows);
                for (var i = 0; i < res.rows.documents.length; i++) {
                    if (res.rows.documents[i].dlState != null) {
                        setLincenseStateId(res.rows.documents[i].dlState);

                    }
                }

                setCropData(res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png");
                setCropLiecenceData(res.customValues.licenseImageFileByte ? "data:image/png;base64," + res.customValues.licenseImageFileByte : "/images/avatar.png");
                //  $("#driverImage").attr("src",res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg");

                //
                setImage(res.customValues?res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png":"/images/avatar.png");
                setLiecenceImage(res.customValues?res.customValues.licenseImageFileByte ? "data:image/png;base64," + res.customValues.licenseImageFileByte : "/images/avatar.png":"/images/avatar.png");

            } catch (ex) {

            }
            //console.log("Dispatcher Details"+JSON.stringify(res.rows));
        });

    }

    // const onChange = (e) => {
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //         files = e.dataTransfer.files;
    //     } else if (e.target) {
    //         files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setImage(reader.result);
    //     };
    //     reader.readAsDataURL(files[0]);
    // };
    const onChange = (e) => {
        const MIN_FILE_SIZE = 5120 // 5MB
        const fileSizeKiloBytes = Math.floor(e.target.files[0].size / 1024); //

        if (fileSizeKiloBytes <= MIN_FILE_SIZE) {
        e.preventDefault();
        let files;
        var ext = e.target.value.split('.').pop();
        var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

        if (!allowedExtensions.exec(ext)) {

            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Invalid file type",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {

                    e.target.value = '';
                    return false;
                }
            });

        } else {
            $("#DB_Image").hide();
            $('#selectedImage').show();
            $('#uploadImageDisable').prop('disabled', false);
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(files[0]);

        }
    } else {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Max file size is 5MB",
            showConfirmButton: true

        }).then((result) => {
        });
    }

    };

    const createdDate=(field,date)=>
    {
  
     field.onChange(date);
     setStartDate(date);
    }
    const onLiecenceChange = (e) => {
        const MIN_FILE_SIZE = 5120 // 5MB
        const fileSizeKiloBytes = Math.floor(e.target.files[0].size / 1024); //

        if (fileSizeKiloBytes <= MIN_FILE_SIZE) {
            e.preventDefault();
            let files;
            var ext = e.target.value.split('.').pop();
            var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

            if (!allowedExtensions.exec(ext)) {

                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Invalid file type",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {

                        e.target.value = '';
                        return false;
                    }
                });

            } else {
                $("#DB_ImageLicense").hide();
                $('#selectedImageLicense').show();
                $('#uploadImageDisableLicense').prop('disabled', false);
                if (e.dataTransfer) {
                    files = e.dataTransfer.files;
                } else if (e.target) {
                    files = e.target.files;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    setLiecenceImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Max file size is 5MB",
                showConfirmButton: true
            }).then((result) => {
            });
        }
    };

    function handleChange(event) {
        setImageSrc(URL.createObjectURL(event.target.files[0]))

    }
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            document.getElementById("btnclose").click();
        }
    };
    const getCropDataLiecence = () => {

        if (typeof cropperLiecence !== "undefined") {
            setCropLiecenceData(cropperLiecence.getCroppedCanvas().toDataURL());
            document.getElementById("btnclose2").click();
        }
    };
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgsrc,
                croppedAreaPixels,
                rotation
            )

            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        showCroppedImage()
    }, [])
    const document_details = (id, filePath) => {

        var extension = filePath.match('[^.]+$');

        var document_request =
        {

            "id": id

        };

        var requestOptions = requestObject.RequestHeader(document_request);
        $("#loadingfordocs").show();
        services.getUserDocumentDetails(requestOptions).then((res) => {
            $("#loadingfordocs").hide();
            if (res.customValues.fileByte === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "File is not attached",

                    showConfirmButton: true

                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
            else {

                var file;

                if (extension == "pdf") {
                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (extension == "jpg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "jpeg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "png") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/png;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                //  else if(extension=="bmp")
                //  {
                //     var image = new Image();
                //     image.src = "data:image/bmp;base64,"+ res.customValues.fileByte;

                //     var w = window.open("");
                //     w.document.write(image.outerHTML);
                //  }
                //  else if(extension=="word")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
                //  else if(extension=="docx"||extension=="doc"||extension=="docm"||extension=="dotx"||extension=="dotm")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
            }

        });

    }
    const redirect = (page) => {

        navigate(page);

    }
    const submitValue = () => {

        var liecenceImage = "";
        var liecenceExtension = "";
        try {

            for (var i = 0; i < inputList.length; i++) {

                var extension = "";
                var byteString = "";

                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                //   else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
                //   {
                //       extension="bmp";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                //   }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                //   else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
                //   {
                //       extension="word";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                //   {
                //       extension="docm";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
                //   {
                //       extension="docx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                //   {
                //       extension="dotx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                //   {
                //       extension="dotx";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                //   }
                //   else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
                //   {
                //       extension="dotm";
                //       byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
                //   }
                else {

                }
                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });


            }
        } catch (exception) {
            documentLists.push();
            // Swal.fire({
            //     position: 'center',
            //     icon: 'error',
            //     title: "",
            //     text:"Please attach document",
            //     showConfirmButton: true

            //   }).then((result) =>
            //   {

            //         if(result.isConfirmed)
            //         {


            //         }
            //   });
            //   return false;
        }
        try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {

                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }

            } else {

                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }

        } catch (ex) {

        }

        if ($("#licenceNumber").val() != '') {
            // documentLists.push(
            //     {
            //            "title":$("#licenceNumber").val(),
            //            "byteString":liecenceImage?liecenceImage:"",
            //            "name":"drivingLisence",
            //            "extension":liecenceExtension?liecenceExtension:"",
            //            "documentNumber":$("#licenceNumber").val()!=''?$("#licenceNumber").val():'',
            //            "issuedDate":moment($("#issuedDate").val()!=''?$("#issuedDate").val():'').format('YYYY-MM-DD'),
            //            "expiryDate":moment($("#expDate").val()!=''?$("#expDate").val():'').format('YYYY-MM-DD'),
            //            "name": "drivingLisence",


            //     });
        }
        // try
        // {
        //     for(var i=0;i<inputList.length;i++)
        //     {
        //         // console.log("filebase64=="+inputList[i].filetobase64);
        //         var extension="";
        //         var byteString="";

        //         if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
        //         {
        //             extension="pdf";
        //             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
        //         }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
        //         {
        //             extension="jpg";
        //             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
        //         }
        //         else if(inputList[i].filetobase64.match("data:image/png;base64,"))
        //         {
        //             extension="png";
        //             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
        //         }
        //         else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
        //         {
        //             extension="word";
        //             byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
        //         }
        //         else{

        //         }



        //             if(JSON.parse(sessionStorage.getItem('documentList')).length>0)
        //             {

        //               var i;
        //               var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
        //               for (i = 0; i < storedArray.length; i++)
        //               {
        //                 documentList.push({"byteString":storedArray[i].byteString,"name":storedArray[i].name.replace(/\.[^/.]+$/, ""), "title": storedArray[i].name.replace(/\.[^/.]+$/, ""),"extension":storedArray[i].extension});

        //                          //documentList.push({"byteString":byteString,"name":storedArray[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":storedArray[i].extension},{"id":storedArray[i].id,"name":storedArray[i].name,"filePath":storedArray[i].filePath, "title": storedArray[i].title,"extension":storedArray[i].extension});
        //               }

        //             }else
        //             {
        //                 documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"extension":extension});
        //             // documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        //             }
        //     }

        if ($("#driverLiecence").attr('src').match("data:application/pdf;base64,")) {
            liecenceImage = $("#driverLiecence").attr('src').replace("data:application/pdf;base64,", "");
            liecenceExtension = "pdf";
        } else if ($("#driverLiecence").attr('src').match("data:image/jpeg;base64,")) {
            liecenceImage = $("#driverLiecence").attr('src').replace("data:image/jpeg;base64,", "");
            liecenceExtension = "jpg";
        } else if ($("#driverLiecence").attr('src').match("data:image/png;base64,")) {
            liecenceImage = $("#driverLiecence").attr('src').replace("data:image/png;base64,", "");
            liecenceExtension = "png";

        } else if ($("#driverLiecence").attr('src').match("data:image/msword;base64,")) {
            liecenceImage = $("#driverLiecence").attr('src').replace("data:image/msword;base64,", "");
            liecenceExtension = "word";

        } else {

        }
        //     if($("#licenceNumber").val()!='')
        //     {
        //         documentList.push(
        //             {
        //                    "title":$("#licenceNumber").val(),
        //                    "byteString":liecenceImage?liecenceImage:"",
        //                    "name":"drivingLisence",
        //                    "extension":liecenceExtension?liecenceExtension:"",
        //                    "documentNumber":$("#licenceNumber").val()!=''?$("#licenceNumber").val():'',
        //                    "issuedDate":moment($("#issuedDate").val()!=''?$("#issuedDate").val():'').format('YYYY-MM-DD'),
        //                    "expiryDate":moment($("#expDate").val()!=''?$("#expDate").val():'').format('YYYY-MM-DD'),
        //                    "name": "drivingLisence",


        //             });
        //     }

        // }catch(exception)
        // {
        //     documentList.push();

        // }



        // setLoading(true);
        $("#loadingforsubmit").show();
        var vechileReq =
        {
            "id": userId,
            "firstName": $("#firstName").val() != '' ? $("#firstName").val().trim() : '',
            "lastName": $("#lastName").val() != '' ? $("#lastName").val().trim() : '',
            "email": $("#email").val() != '' ? $("#email").val().trim() : '',
            "deskNumber": $("#deskNumber").val() != '' ? $("#deskNumber").val().trim() : '',
            "extnNo": $("#ext").val() != '' ? $("#ext").val().trim() : '',
            "phoneNumber": $("#mobileNumber").val() != '' ? $("#mobileNumber").val().trim() : '',
            "addressLine1": $("#address1").val() != '' ? $("#address1").val().trim() : '',
            "addressLine2": $("#address2").val() != '' ? $("#address2").val().trim() : '',
            "city": $("#city").val() != '' ? $("#city").val().trim() : '',
            "securityRoleId": sessionStorage.getItem("driverRoleId"),
            "securityRoleName": sessionStorage.getItem("driverRoleName"),
            "fkStateId": $("#state").val() != '' ? $("#state").val() : '',
            "zip": $("#zipcode").val() != '' ? $("#zipcode").val().trim() : '',
            "fkGarageId": "1",
            "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : cropData === "/images/transport-logo.jpg" ? "" : parseInt(Math.random() * 1000000000),
            "imageExtension": cropper.getCroppedCanvas() ? "jpeg" : cropData === "/images/transport-logo.jpg" ? "" : "jpeg",
            "imageByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : cropData === "/images/transport-logo.jpg" ? "" : cropData.replace("data:image/png;base64,", ""),
            // "imageName":"",
            // "imageExtension":"",
            // "imageByteString":"",
            "dlNumber": $("#licenceNumber").val().trim(),
            "dlIssuedDate": $("#issuedDate").val() != '' ? moment($("#issuedDate").val()).format('YYYY-MM-DD') : '',
            // "dlIssuedDate":moment($("#issuedDate").val()!=''?$("#issuedDate").val():'').format('YYYY-MM-DD'),
            "dlExpDate": $("#expDate").val() != '' ? moment($("#expDate").val()).format('YYYY-MM-DD') : '',
            "dlState": $("#license_state").val(),
            "dlImageByteString": cropperLiecence.getCroppedCanvas() ? cropperLiecence.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 450,  maxHeight: 450}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : cropLiecenceData === "/images/transport-logo.jpg" ? "" : cropLiecenceData.replace("data:image/png;base64,", ""),
            // "dlImageByteString": liecenceImage ? liecenceImage : "",
            "dlExt": cropperLiecence.getCroppedCanvas() ? "jpeg" : cropLiecenceData === "/images/transport-logo.jpg" ? "" : "jpeg",
            // "dlExt": liecenceExtension ? liecenceExtension : "",
            "dlName": "drivingLisence",
            "documents": documentLists

        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.updateUser(requestOptions).then((res) => {
            $("#loadingforsubmit").hide();

            if (res.message === "Successfully updated user") {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/fleetowner/employees");
                    }
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: res.message,
                    showConfirmButton: true
                }).then((result) => {

                    if (result.isConfirmed) {

                    }
                })
            }

        });
        // }

    }
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value, filename } = e.target;
        const list = [...inputList];
        //   setfileNames(value);
        list[index][name] = value;
        list[index][filename] = value;
        setInputList(list);
        // alert(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (e, index) => {
        const { name, value, filename } = e.target;
        const list = [...inputList];


        if (list[index][filename] == "" || list[index][filename] == "undefined" || list[index][filename] == undefined) {

            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please Enter Doc Name",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;

        } else {
            setInputList([...inputList, {}]);
        }

    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }
    const onUploadFileChange = (event, index) => {


        var target = event.target;
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                const { name, value, fileName } = event.target;
                const list = [...inputList];
                list[index][name] = result;
                list[index]["fileName"] = target.files[0].name;
                setBase64file(result);
                // setFile(result)


                setInputList(inputList);

                // setFileName(target.files[0])
            }
        })
    }
    $(document).on("click", "#EditImage", function () {
        $('#selectedImage').hide();
        $('#DB_Image').show();
        $('#uploadImageDisable').prop('disabled', true);
    });
    $(document).on("click", "#EditImageLicense", function () {
        $('#selectedImageLicense').hide();
        $('#DB_ImageLicense').show();
        $('#uploadImageDisableLicense').prop('disabled', true);
    });
    return (
        <>
        {LoadingComponentfordocuments}
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="col-lg-9 col-md-12 m-auto ">
                        <form onSubmit={handleSubmit(submitValue)}>
                            <div class="row">
                                <div class="col-md-12 m-auto mb-2 ">
                                    <h4 class="font-weight-bold my-4 text-uppercase">Edit Driver</h4>
                                    <div class="card mb-2 px-4">
                                        <div class="col-md-12 pt-4 pr-4 px-0 mb-2">
                                            <h3>Basic Information</h3>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>First Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="firstName" maxLength="20" minLength="2" {...register('firstName', { required: true })} defaultValue="FirstName" />
                                                {errors.firstName && errors.firstName.type === "required" && <span class="error">First name is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Last Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="lastName" maxLength="20" minLength="2" {...register('lastName', { required: true })} defaultValue="lastName" />
                                                {errors.lastName && errors.lastName.type === "required" && <span class="error">Last name is required</span>}
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 1<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="address1" maxLength="50" defaultValue="address1" {...register('address1', { required: true })} />
                                                {errors.address1 && errors.address1.type === "required" && <span class="error">Address is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 2</label>
                                                <input type="text" class="form-control custome-input" id="address2" maxLength="50" />
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>City<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" defaultValue="city" id="city" {...register('city', { required: true, minLength: "2" })} maxLength="50" minLength="2" />
                                                {errors.city && errors.city.type === "required" && <span class="error">City is required</span>}
                                                {errors.city && errors.city.type === "minLenght" && <span class="error">City atleast 2 characters</span>}
                                                {/* {errors.city && errors.city.type === "pattern" && <span class="error">City is allow only character's</span>} */}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>State<span class="star">*</span></label>
                                                <select defaultValue="hawai" class="form-control custome-select" id="state"  >
                                                    {/* <option value='' >Select</option> */}
                                                    {
                                                        states ? states.map((state, index) => (
                                                            <option key={state.key} value={state.id} selected={DriverDetails.fkStateId == state.id ? "selected" : ""}>{state.name}</option>
                                                        )) : <option value=""></option>
                                                    }
                                                </select>
                                                {/* {errors.state && errors.state.type === "required" && <span class="error">State is required</span>} */}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Zip code<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="zipcode" defaultValue="12345" {...register('zipcode', { required: true, pattern: /^[0-9]{5}(-[0-9]{1})?$/ })} maxLength="5" />
                                                {errors.zipcode && errors.zipcode.type === "required" && <span class="error">Zipcode is required</span>}
                                                {errors.zipcode && errors.zipcode.type === "pattern" && <span class="error">Zip code should be 5 digits</span>}
                                                {/* {errors.zipcode && errors.zipcode.type === "maxLength" && <span class="error">Zipcode should max 6 digits</span>} */}
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <div class="row g-2">
                                                    <div class="col-8">
                                                        <label>Desk Number</label>
                                                        <input type="text" class="form-control custome-input" id="deskNumber" defaultValue="123-456-7890" {...register('deskNumber', { maxLength: 12, minLength: 12 })} />
                                                        {/* {errors.deskNumber && errors.deskNumber.type === "required" && <p class="error">Desk Number is required</p>} */}
                                                        {errors.deskNumber && errors.deskNumber.type === "maxLength" && <p class="error">Desk number should be 10 digits</p>}
                                                        {errors.deskNumber && errors.deskNumber.type === "minLength" && <p class="error">Desk number should be 10 digits</p>}
                                                        {/* {...register('deskNumber', { required: true})} */}
                                                        {/* {errors.deskNumber && errors.deskNumber.type === "required" && <span class="error">Desk Number is required</span>} */}
                                                    </div>
                                                    <div class="col-4">
                                                        <label style={{ fontSize: "10px" }}>Extn no</label>
                                                        <input type="text" class="form-control custome-input" id="ext" defaultValue="ext" />
                                                        {/* {...register('ext', { required: true})} */}
                                                        {/* {errors.ext && errors.ext.type === "required" && <span class="error">Ext is required</span>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Phone Number<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="mobileNumber" {...register('mobileNumber', { required: true, maxLength: 12, minLength: 12 })} defaultValue="mobileNumber" />
                                                {errors.mobileNumber && errors.mobileNumber.type === "required" && <span class="error">Phone number is required</span>}
                                                {errors.mobileNumber && errors.mobileNumber.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                                {errors.mobileNumber && errors.mobileNumber.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                            </div>

                                            <div class="col-md-6 mb-3">
                                                <label>Email<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="email" defaultValue="email@gmail.com" {...register('email', {
                                                    required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                                })} readOnly />
                                                {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                                {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                            </div>

                                        </div>

                                    </div>

                                    <div class="card mb-2">
                                        <div class="card-body pt-0 pb-2 p-3">
                                            <div class="row d-flex align-items-center">
                                                {/* <div class="col-auto text-center">
                                                <div class="avatar">
                                                    <img src="/images/nia.jpg" alt=""/>
                                                </div>
                                            </div> */}

                                                <div class="col-md-2">
                                                    <b>Picture</b>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="avatar">
                                                        <img src={cropData ? cropData : dummyImge} id="driverImage" />
                                                    </div>




                                                </div>

                                                <div class="col-md-4">
                                                    <a href="" class="btn btn-outline-dark btn-fw" data-toggle="modal" id="EditImage" data-target="#exampleModal" data-backdrop="static" data-keyboard="false">Upload Photo</a>
                                                </div>
                                                <div class="col-md-3 text-left">
                                                    <div class="instruction">
                                                        <span>Instruction</span>
                                                        <p>Max File Size : 5MB</p>
                                                        <p>Formats: JPG/PNG</p>
                                                        <p>Ratio: 1:1 (width and height must be equal)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-2">
                                        <div class="col-md-12 pt-4 pr-4 pl-4">
                                            <h3>Driving Licence</h3>
                                        </div>
                                        <form class="p-4">
                                            <div class="row">
                                                <div class="col-md-6 mb-3">
                                                    <label>Licence Number<span class="star">*</span></label>
                                                    <input type="text" class="form-control custome-input" id="licenceNumber" {...register('licenceNumber', { required: true, maxLength: 50, minLength: 2 })} maxLength="50" minLength="2" />
                                                    {errors.licenceNumber && errors.licenceNumber.type === "required" && <span class="error">Licence number is required</span>}
                                                    {/* {errors.licenceNumber && errors.licenceNumber.type === "maxLength" && <span class="error">Licence number shouldn't exceed the max length 50 characters</span>}
                                                    {errors.licenceNumber && errors.licenceNumber.type === "minLength" && <span class="error">Licence number atleast 2 characters</span>} */}
                                                </div>
                                                <div class="col-md-3 mb-3">
                                                    <label>Issued Date<span class="star">*</span></label>
                                                    {/* <DatePicker dateFormat="MM-dd-yyyy" selected={issuedDate} onChange={(date) => setIssuedDate(date)} name="issuedDate" class="form-control" id="issuedDate" placeholderText="MM-DD-YYYY" style={{ minWidth: "100%" }} /> */}
                                                    <Controller
                                                        name="issuedDate"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                               onChange={(date) => createdDate(field,date)}
                                                           
                                                                selected={field.value}
                                                                inputProps={{
                                                                    placeholder: "",
                                                                }}

                                                                id="issuedDate"

                                                            />
                                                        )}
                                                    />
                                                    {errors.issuedDate && <span class="error">Issued date is required</span>}
                                                </div>
                                                <div class="col-md-3 mb-3">
                                                    <label>Expiry Date</label>
                                                    {/* <DatePicker dateFormat="MM-dd-yyyy" selected={expDate} onChange={(date) => setExpDate(date)} name="expDate" class="form-control" id="expDate" placeholderText="MM-DD-YYYY" style={{ minWidth: "100%" }} /> */}
                                                    <Controller
                                                        name="expDate"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                               minDate={moment(startDate).toDate()}
                                                                onChange={(date) => field.onChange(date)}
                                                                selected={field.value}
                                                                inputProps={{
                                                                    placeholder: "MM-DD-YYYY ",
                                                                }}
                                                                id="expDate"
                                                            />
                                                        )}
                                                    />
                                                    {errors.expDate && <span class="error">Expiry date  is required</span>}
                                                </div>
                                                {/* <div class="col-auto text-center"><a href="" class="btn btn-outline-dark btn-fw">Upload Image</a></div> */}
                                                <div class="col-md-6 mb-3">
                                                    <label>State<span class="star">*</span></label>
                                                    <select defaultValue="hawai" class="form-control custome-select" id="license_state"  >
                                                        {/* <option value='' >Select</option> */}
                                                        {
                                                            states ? states.map((state, index) => (
                                                                <option key={state.key} selected={licenseStateId == state.id ? "selected" : ""} value={state.id} >{state.name}</option>
                                                            )) : <option value=""></option>
                                                        }
                                                    </select>
                                                </div>

                                                {/* {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-4">
                                                            {data.name == "drivingLisence" ?
                                                                <div class="d-flex align-items-center">

                                                                    <a onClick={() => document_details(data.id, data.filePath)} class="ml-2"><b>{data.name}.{data.extension}</b></a>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />
                                                                </div>
                                                                : ""}
                                                        </div>


                                                    )) : ""

                                                } */}


                                                <div class="col-md-12">
                                                    {/* <div className="my-4 border-top border-bottom py-3">
                                                        <div className="m-auto" style={{ width: "320px", height: "200px", background: "#ccc" }}> <img src={cropLiecenceData ? cropLiecenceData : dummyImge} id="driverLiecence" /></div>
                                                    </div> */}
                                                    <div className="my-4 border-top border-bottom py-3">
                                                        <div class="row d-flex align-items-center ">
                                                            <div class="col-md-3">
                                                                <div class="avatar">
                                                                    <img src={cropLiecenceData ? cropLiecenceData : dummyImge} id="driverLiecence" />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-4">
                                                                <a href="" class="btn btn-outline-dark btn-fw" id="EditImageLicense" data-toggle="modal" data-target="#exampleModal2" data-backdrop="static" data-keyboard="false">
                                                                    Upload licence</a>
                                                            </div>
                                                            <div class="col-md-3 text-left">
                                                                <div class="instruction">
                                                                    <span>Instruction</span>
                                                                    <p>Max File Size : 5MB</p>
                                                                    <p>Formats: JPG/PNG</p>
                                                                    <p>Ratio: 1:1 (width and height must be equal)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div class="col-md-12"><a href="" class="btn btn-outline-dark btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                                    Upload licence</a>
                                                </div><br /><br /><br />
                                                <div class="col-md-4 text-left">
                                                    <div class="instruction">
                                                        <span>Instruction</span>
                                                        <p>Max File Size : 50MB</p>
                                                        <p>Formats: JPG/PNG/PDF</p>
                                                        <p>Ratio: 1:1 (width and height must be equal)</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>

                                    <div class="divider2"></div>
                                    <div class="form-footer-comon-wrap">

                                        <div class="card mb-2 p-3">
                                            <h3 class="mb-4 pl-1">IDs & Other Documents</h3>
                                            <div class="col-md-6 text-left">
                                                <div class="instruction">
                                                    <span>Instruction</span>
                                                    <p>Max File Size : 50MB</p>
                                                    <p>Formats: JPG/PNG/PDF</p>
                                                    <p>Ratio: 1:1 (width and height must be equal)</p>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-12">
                                                            {data.name == "DrivingLisence" || data.name == "drivingLisence" ? "" :
                                                                <div class="align-items-center">

                                                                    {/* <a onClick={() => document_details(data.id, data.filePath)} class="ml-2"><b>{data.name}.{data.extension}</b></a> */}

                                                                    {/* {data.name}.{data.extension} */}
                                                                    <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                        <img src="" class="mr-2" />
                                                                        <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                            <a onClick={() => document_details(data.id, data.filePath)} >
                                                                                {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                                {(() => {
                                                                                    if (data.filePath.split(".")[1] == 'pdf') {
                                                                                        return (
                                                                                            <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                        )
                                                                                    } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                                        return (
                                                                                            <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                        )
                                                                                    } else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                                        return (
                                                                                            <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                                {data.name ? data.name + '' + data.filePath.split(".")[1] : "--"}  </a> </p>

                                                                        </span>
                                                                        <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>


                                                                </div>}
                                                        </div>


                                                    )) : ""

                                                }
                                            </div><br />
                                            <AdddocsDriver inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                        </div>





                                    </div>

                                    <div class="card">
                                        <div class="p-3">
                                            <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                            <a onClick={() => redirect("/fleetowner/employees")} class="btn gray-mid-color mw-120">CANCEL</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                            <button type="button" class="close" data-dismiss="modal" id="btnclose2" aria-label="Close">
                                ×
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="file" class="w-100 p-3 mb-3" onChange={onLiecenceChange} accept="image/png, image/jpg, image/jpeg" />
                            <div class="row mb-2" id="selectedImageLicense">
                                <div class="col-12">

                                    <div class="image-upload-plg">


                                        {/* <Cropper
                                            style={{ height: 320, width: "100%" }}
                                            zoomTo={0.5}
                                            initialAspectRatio={4 / 4}
                                            preview=".img-preview"
                                            src={liecenceimage}
                                            viewMode={3}

                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropperLiecence(instance);
                                            }}
                                            guides={true}
                                        /> */}

                                        <Cropper
                                            style={{ height: 300, width: "100%" }}
                                            aspectRatio={5/3}
                                            guides={false}
                                            dragMode={"move"}
                                            src={liecenceimage}
                                            onInitialized={(instance) => {
                                                setCropperLiecence(instance);
                                            }}
                                        />


                                    </div>


                                </div>

                                <div>
                                    <div className="box" style={{ width: "340px", height: "240px", float: "right", display: "none" }}>
                                        <h1>Preview</h1>
                                        <div
                                            className="img-preview"

                                        />
                                    </div>
                                    {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                                </div>
                            </div>
                            <div class="row mb-2" id="DB_ImageLicense">
                                <div className="m-auto mt-2">
                                    <img src={liecenceimage} style={{ width: "340px", height: "240px", marginTop: "20px" }} ></img>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnclose2">Close</button>
                            <button class="btn btn-primary" id="uploadImageDisableLicense" onClick={getCropDataLiecence}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                            <button type="button" class="close" data-dismiss="modal" id="btnclose" aria-label="Close">
                                ×
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/png, image/jpg, image/jpeg" />
                            <div class="row mb-2" id="selectedImage">
                                <div class="col-12">

                                    <div class="image-upload-plg">


                                        {/* <Cropper
                                            style={{ height: 320, width: "100%" }}
                                            zoomTo={0.5}
                                            initialAspectRatio={4 / 3}
                                            preview=".img-preview"
                                            src={image}
                                            viewMode={3}

                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        /> */}

                                        <Cropper
                                            style={{ height: 300, width: "100%" }}
                                            aspectRatio={1}
                                            guides={false}
                                            dragMode={"move"}
                                            src={image}
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                        />
                                    </div>


                                </div>

                                <div>
                                    <div className="box" style={{ width: "320px", height: "320px", float: "right", display: "none" }}>
                                        <h1>Preview</h1>
                                        <div
                                            className="img-preview"

                                        />
                                    </div>
                                    {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                                </div>
                            </div>
                            <div class="row mb-2" id="DB_Image">
                                <div className="m-auto mt-2">
                                    <img src={image} style={{ width: "240px", height: "240px", marginTop: "20px" }} ></img>

                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnclose">Close</button>
                            <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={getCropData}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

FleetEditDriver.propTypes = {};

FleetEditDriver.defaultProps = {};

export default FleetEditDriver;
