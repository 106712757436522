export const LoadingComponentForSubmit = (
    //   <div class="loader-wrapper" id="loading">
    //   <div class="truck-wrapper">
    //     <div class="truck">
    //       <div class="truck-container"></div>
    //       <div class="glases"></div>
    //       <div class="bonet"></div>

    //       <div class="base"></div>

    //       <div class="base-aux"></div>
    //       <div class="wheel-back"></div>
    //       <div class="wheel-front"></div>

    //       <div class="smoke"></div>
    //     </div>
    //   </div>
    // </div>

    <div class="spin-body formsubmit" id="loadingforsubmit" style={{"display":"none"}}>
    <div class="spinner-wrap">
    {/* <div class="spinner"style={{marginLeft:"28px"}}></div> */}
    {/* <span style={{marginTop:"10px"}}> */}
      <div class="truck-wrapper-formsubmit">
        <div class="truck">
          <div class="truck-container"></div>
          <div class="glases"></div>
          <div class="bonet"></div>

          <div class="base"></div>

          <div class="base-aux"></div>
          <div class="wheel-back"></div>
          <div class="wheel-front"></div>

          <div class="smoke"></div>
        </div>
      </div>
    {/* </span> */}
    {/* <div class="truck-wrapper">
    <div class="truck">

    <div class="truck-container"></div>


    <div class="glases"></div>


    <div class="bonet"></div>





    <div class="base"></div>





    <div class="base-aux"></div>


    <div class="wheel-back"></div>


    <div class="wheel-front"></div>





    <div class="smoke"></div>


    </div>


    </div> */}
    </div>
    </div>

      )