import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const CarrierEquipmentRead = () => {
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const [ maxCompartmentCapacity, setmaxCompartmentCapacity ] = useState([]);
   const [ probeOutage, setprobeOutage ] = useState([]);
   const [ carrierOutage, setcarrierOutage ] = useState([]);
   const [ maximumPreset, setmaximumPreset ] = useState([]);
   const [ carrierEquipmentInspection, setcarrierEquipmentInspection ] = useState([]);
   const [ certifiedInspectionRequirements, setcertifiedInspectionRequirements ] = useState([]);
   const [ carrierVerificationRequirements, setcarrierVerificationRequirements ] = useState([]);
   const [expiryDate, setexpiryDate] = useState(new Date());
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
    
    
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
    
      let timerFunc = setTimeout(() => {
         getInpection(fkAccountId,inspectionId);
      }, 500);

      return () => clearTimeout(timerFunc);
   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
   
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
        
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList);
         setmaxCompartmentCapacity(res.rows.carrierEquipmentInspection.maxCompartmentCapacity);
         setprobeOutage(res.rows.carrierEquipmentInspection.probeOutage);
         setcarrierOutage(res.rows.carrierEquipmentInspection.carrierOutage);
         setmaximumPreset(res.rows.carrierEquipmentInspection.maximumPreset);
         setcarrierEquipmentInspection(res.rows.carrierEquipmentInspection);
         setcertifiedInspectionRequirements(res.rows.carrierEquipmentInspection.certifiedInspectionRequirements);
         setcarrierVerificationRequirements(res.rows.carrierEquipmentInspection.carrierVerificationRequirements);
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
        
      });


 

   }
   const redirect=(page)=>
   {
 
      navigate(page);
   
   }
  
   const save=(status)=>
   {
 
   // console.log("results=="+JSON.stringify(inputList));
   if($("#verifiedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Verified by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select disposition of tank",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="inspectionFailed"]:checked').val()===""||$('input:radio[name="inspectionFailed"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select inspection result",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   var status = $('input:radio[name="inspectionFailed"]:checked').val();
   
   if(status==="approvedCertify")
   {
       status="APPROVED";
   }else
   {
      status="Rejected";
   } 
        
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,
          
           "result": {
            "approvedCertify":$("input[name='inspectionFailed']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='inspectionFailed']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }
          
         
       }; 

       $("#loadingforsubmit").show();
 
      //  setLoading(true);
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) => 
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
 
   }
  return(
  <>
  {LoadingComponentForSubmit}
     <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
                
                  <div class="row mb-4">
                          <div class="col-lg-11 col-md-12 m-auto ">
                          <HeaderBlock cids={fkAccountId} />
                           </div>
                  </div>

                  <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold">Vehicle Inspection</h4>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white">Back</a>
                     </div>
                  </div>
               </div>

                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">

                          <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div> 
                            <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="mb-2 form-inspection-title text-center">Carrier Equipment Inspection</h4>
                                </div>
                                <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div>
                                
                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                        <div class="table-no-border mb-4">
                                            <table class="table  ">
                                                <thead>
                                                  <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Example</th>
                                                    <th scope="col">1#</th>
                                                    <th scope="col">2#</th>
                                                    <th scope="col">3#</th>
                                                    <th scope="col">4#</th>
                                                    <th scope="col">5#</th>
                                                    <th scope="col">6#</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr className="border-bottom">
                                                      <td className="border-right">1. Max Compartment Capacity</td>
                                                      <td class="red-txt border-right">Ex: 3410</td>
                                                      {
                                                                                               maxCompartmentCapacity? maxCompartmentCapacity.map((data, index) => (
                                                                                                   <td class="text-left border-right">{data!=''?data:"--"}</td>
                                                                                                )):""
                                                                                               
                                                      }
                                                      {/* <td class="red-txt border-right">Ex: 3410</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td> */}
                                                  </tr>

                                                   <tr className="border-bottom">
                                                    <td className="border-right">2. Probe Outage (60 gal min)   </td>
                                                    <td class="red-txt border-right">Ex: 60</td>
                                                    {
                                                                                                probeOutage?probeOutage.map((data, index) => (
                                                                                                   <td class="text-left border-right">{data!=''?data:"--"}</td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                 <tr className="border-bottom">
                                                    <td className="border-right">3. *Carrier Outage</td>
                                                    <td class="red-txt border-right">Ex: 3080</td>
                                                    {
                                                                                                carrierOutage?carrierOutage.map((data, index) => (
                                                                                                   <td class="text-left border-right">{data!=''?data:"--"}</td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                 <tr className="border-bottom">
                                                    <td className="border-right">4. Maximum Preset</td>
                                                    <td class="red-txt border-right">Ex: 3000</td>
                                                    {
                                                                                                maximumPreset?maximumPreset.map((data, index) => (
                                                                                                   <td class="text-left border-right">{data!=''?data:"--"}</td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                <tr></tr>

                                                <tr style={{height: "70px"}}>
                                                    <td>Subtract Lines 2&3 from Line 1</td>
                                                    <td></td>
                                                    <td colspan="6">Sections must be completed</td>  
                                                </tr>

                                                 
                                                </tbody>
                                              </table>
                                        </div>

                                        <div class="p-3 mb-4" style={{backgroundColor: "#ccc"}}>
                                            <p>'Carrier outage is the distance between the overfill probe and the product that prevents setting off the rack shutdown system (domeouts). This option is at the discretion of the carrier and varies on the tank strapping charts and the level outage selected.	
                                            </p>
                                        </div>

                                        <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Certified inspection Requirements</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Has the overfill protection probe been set & tested to a minimum of 60 gross  below the maximum compartment capacity?</td>
                                                   <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.maximumCompartmentCapacity=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.maximumCompartmentCapacity=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is the overfill protection system in working condition?</td>
                                                    <td class="text-center">  {certifiedInspectionRequirements?certifiedInspectionRequirements.systemInWorkingCondition=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.systemInWorkingCondition=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td>3. Has each compartments probe been tested with liquid to verify that it activates the shutdown circuitry?</td>
                                                    <td class="text-center">  {certifiedInspectionRequirements?certifiedInspectionRequirements.activatestheShutdownCircuitry=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.activatestheShutdownCircuitry=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                     <tr>
                                                    <td>4. Has the grounding system been checked and is in proper operating condition?</td>
                                                    <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.properOperatingCondition=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.properOperatingCondition=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td><p>5. Has the grounding system been checked to ensure that has not been modified or rewired in any manner that	
                                                        would allow it to provide a false reading to allow loading?</p>	
                                                        </td>
                                                        <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.readingToAllowLoading=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.readingToAllowLoading=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Are all gauge rods and any other compartment protrusions properly grounded with secure bonding wires?</td>
                                                    <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.secureBondingWires=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.secureBondingWires=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>  

                                                   <tr>
                                                    <td>7. Is a functional brake interlock system installed on the loading header and vapor recovery hose?</td>
                                                    <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.hose=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.hose=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>

                                        <div class="table-2 mb-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Carrier Verification Requirements</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Is an MC306, DOT406 or other specification plate installed?</td>
                                                   <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.specificationPlateInstalled=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.specificationPlateInstalled=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is proper placarding installed for the product(s) that are hauled?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.productsThatareHauled=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.productsThatareHauled=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td>3. Is the state DOT inspection or DOT 396/17 data current?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.dataCurrent=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.dataCurrent=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                     <tr>
                                                    <td>4. Are pressure, leakage and visual decals current?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.decalsCurrent=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.decalsCurrent=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td>5. Is emergency response information (including guidebook) on board?
                                                        </td>
                                                        <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.includingGuidebook=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.includingGuidebook=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Is each tank/trailer marked with appropriate unit numbers?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.appropriateUnitNumbers=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.appropriateUnitNumbers=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>  

                                                   <tr>
                                                    <td>7. Are compartment capacity charts current and available upon request?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.availableUponRequest=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.availableUponRequest=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>  

                                                   <tr>
                                                    <td>8. Is each compartment loading headers matching with maximum presets recorded above?</td>
                                                    <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                           
                            <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                            
                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='1' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <div class="divider"></div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}
         
         </>
          )
        }
      }
      })()}
                             </div>

                            <div class="divider"></div>
                            {(() => 
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') 
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                           </div>
                        </div>
                  </div>
                 
               </div>

            </div>
  </>
)};

CarrierEquipmentRead.propTypes = {};

CarrierEquipmentRead.defaultProps = {};

export default CarrierEquipmentRead;
