import services from "../services/services";
import { requestObject } from "./requestObject";
import $ from 'jquery';
import Swal from "sweetalert2";
import printJS from 'print-js'
export const document_print = () => 
{
   
   $("#loadingforsubmit").show();
    // var extension = filePath.match('[^.]+$');
      var documentIdsArray = [];
      $('input:checkbox[name=documentIds]:checked').each(function () {

         documentIdsArray.push($(this).val());
   
      });
      if(documentIdsArray.length>0)
      {
     

      
      var document_request =
      {

         "documentIds": documentIdsArray

      };

      var requestOptions = requestObject.RequestHeader(document_request);

      services.getDocumentPrint(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         if (res.customValues.fileByte === "") {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "File is not attached",

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         } else {

            
           
               var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
               var byteNumbers = new Array(byteCharacters.length);
               for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
               var byteArray = new Uint8Array(byteNumbers);
               var file = new Blob([byteArray], { type: 'application/pdf;base64' });
               var fileURL = URL.createObjectURL(file);
               printJS(fileURL);
            // if (extension == "pdf") {
            //    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
            //    var byteNumbers = new Array(byteCharacters.length);
            //    for (var i = 0; i < byteCharacters.length; i++) {
            //       byteNumbers[i] = byteCharacters.charCodeAt(i);
            //    }
            //    var byteArray = new Uint8Array(byteNumbers);
            //    file = new Blob([byteArray], { type: 'application/pdf;base64' });
            //    var fileURL = URL.createObjectURL(file);
            //    window.open(fileURL);
            // }
            // else if (extension == "jpg") {
            //    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
            //    var image = new Image();
            //    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

            //    var w = window.open("");
            //    w.document.write(image.outerHTML);
            // }
            // else if (extension == "png") {
            //    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
            //    var image = new Image();
            //    image.src = "data:image/png;base64," + res.customValues.fileByte;

            //    var w = window.open("");
            //    w.document.write(image.outerHTML);
            // }
            // else if (extension == "bmp") {
            //    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
            //    var image = new Image();
            //    image.src = "data:image/bmp;base64," + res.customValues.fileByte;

            //    var w = window.open("");
            //    w.document.write(image.outerHTML);
            // }
            // else if (extension == "word") {

            //    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
            //    var byteNumbers = new Array(byteCharacters.length);
            //    for (var i = 0; i < byteCharacters.length; i++) {
            //       byteNumbers[i] = byteCharacters.charCodeAt(i);
            //    }
            //    var byteArray = new Uint8Array(byteNumbers);
            //    file = new Blob([byteArray], { type: 'application/msword;base64' });
            //    var fileURL = URL.createObjectURL(file);
            //    window.open(fileURL);
            // }
            // else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

            //    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
            //    var byteNumbers = new Array(byteCharacters.length);
            //    for (var i = 0; i < byteCharacters.length; i++) {
            //       byteNumbers[i] = byteCharacters.charCodeAt(i);
            //    }
            //    var byteArray = new Uint8Array(byteNumbers);
            //    file = new Blob([byteArray], { type: 'application/msword;base64' });
            //    var fileURL = URL.createObjectURL(file);
            //    window.open(fileURL);
            // }
         }

      });
   }
   else
   {
    Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please choose the at least one document",

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {
               $("#loadingforsubmit").hide();
            }
         });
   }
}