import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import getDateAndTimeStamp from "../../../../utility/getTimeStamp";
import moment from "moment";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const DOTRead = () => {
    const { fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [CheckPerformed, setCheckPerformed] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const [compartments, setcompartments] = useState();
    const [manywaytest, setmanywaytest] = useState();
    const [total, settotal] = useState();
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    const [expiryDate, setexpiryDate] = useState(new Date());
    const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);

    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    const [roles, setRoles] = useState("");
    const [statusInspection, setStatus] = useState("");
    useEffect(() => {
        setRoles(localStorage.getItem("Userroles"));
        // $('#otherField').hide();
        // $('#otherFieldTextFiled').hide();
        getInpection(fkAccountId, inspectionId);

    }, []);

    // $(document).on("input", "input[name='checkPerformed']", function () {

    //     var otherValue = $("input[name='checkPerformed']:checked").val();
    //     if (otherValue == 'other') {
    //         $('#otherField').show();
    //     } else {
    //         $('#otherField').hide();
    //     }
    // });

    // $(document).on("input", "input[name='other']", function () {

    //     var otherValue = $("input[name='other']:checked").val();
    //     if (otherValue == '1') {
    //         $('#otherFieldTextFiled').show();
    //     } else {
    //         $('#otherFieldTextFiled').hide();
    //     }

    // });

    var totalGallons = 0;
    var totalmanyGallons = 0;
    var workingLen = [];
    const redirect = (page) => {

        navigate(page);

    }
    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForLeakage =
        {

            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

        services.getInspectionDetails(requestOptions).then((res) => {

            setInspectionDetails(res.rows);
            setDocumentLists(res.rows.documentList ? res.rows.documentList : "");

            //   setPressureTest1startTime(new Date(res.rows.dOTCargoTankTest.pressureTest1.startTime));
            //   setPressureTest1finishTime(new Date(res.rows.dOTCargoTankTest.pressureTest1.finishTime));

            //   setPressureTest2startTime(new Date(res.rows.dOTCargoTankTest.pressureTest2.startTime));
            //   setPressureTest2finishTime(new Date(res.rows.dOTCargoTankTest.pressureTest2.finishTime));

            //   setVaccumeTest1startTime(new Date(res.rows.dOTCargoTankTest.vaccumeTest1.startTime));
            //   setVaccumeTest1finishTime(new Date(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime));

            //   setVaccumeTest2startTime(new Date(res.rows.dOTCargoTankTest.vaccumeTest2.startTime));
            //   setVaccumeTest2finishTime(new Date(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime));

            //   setVaporValveTeststartTime(new Date(res.rows.dOTCargoTankTest.vaporValveTest.startTime));
            //   setVaporValveTestfinishTime(new Date(res.rows.dOTCargoTankTest.vaporValveTest.finishTime));

            //   setPressureTest3startTime(new Date(res.rows.dOTCargoTankTest.pressureTest3.startTime));
            //   setPressureTest3finishTime(new Date(res.rows.dOTCargoTankTest.pressureTest3.finishTime));

            // setPressureTest1startTime(res.rows.dOTCargoTankTest.pressureTest1.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest1.startTime)):"");
            // setPressureTest1finishTime(res.rows.dOTCargoTankTest.pressureTest1.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest1.finishTime)):"");

            // setPressureTest2startTime(res.rows.dOTCargoTankTest.pressureTest2.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest2.startTime)):"");
            // setPressureTest2finishTime(res.rows.dOTCargoTankTest.pressureTest2.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest2.finishTime)):"");

            // setVaccumeTest1startTime(res.rows.dOTCargoTankTest.vaccumeTest1.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest1.startTime)):"");
            // setVaccumeTest1finishTime(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime)):"");
            // setVaccumeTest2startTime(res.rows.dOTCargoTankTest.vaccumeTest2.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest2.startTime)):"");
            // setVaccumeTest2finishTime(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime)):"");

            // setVaporValveTeststartTime(res.rows.dOTCargoTankTest.vaporValveTest.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaporValveTest.startTime)):"");
            // setVaporValveTestfinishTime(res.rows.dOTCargoTankTest.vaporValveTest.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaporValveTest.finishTime)):"");

            // setPressureTest3startTime(res.rows.dOTCargoTankTest.pressureTest3.startTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest3.startTime)):"");
            // setPressureTest3finishTime(res.rows.dOTCargoTankTest.pressureTest3.finishTime?new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest3.finishTime)):"");

            $("#pressureTest1startTime").text(res.rows.dOTCargoTankTest.pressureTest1.startTime ? res.rows.dOTCargoTankTest.pressureTest1.startTime : "");
            $("#pressureTest1finishTime").text(res.rows.dOTCargoTankTest.pressureTest1.finishTime ? res.rows.dOTCargoTankTest.pressureTest1.finishTime : "");

            $("#pressureTest2startTime").text(res.rows.dOTCargoTankTest.pressureTest2.startTime ? res.rows.dOTCargoTankTest.pressureTest2.startTime : "");
            $("#pressureTest2finishTime").text(res.rows.dOTCargoTankTest.pressureTest2.finishTime ? res.rows.dOTCargoTankTest.pressureTest2.finishTime : "");

            $("#vaccumeTest1startTime").text(res.rows.dOTCargoTankTest.vaccumeTest1.startTime ? res.rows.dOTCargoTankTest.vaccumeTest1.startTime : "");
            $("#vaccumeTest1finishTime").text(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime ? res.rows.dOTCargoTankTest.vaccumeTest1.finishTime : "");

            $("#vaccumeTest2startTime").text(res.rows.dOTCargoTankTest.vaccumeTest2.startTime ? res.rows.dOTCargoTankTest.vaccumeTest2.startTime : "");
            $("#vaccumeTest2finishTime").text(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime ? res.rows.dOTCargoTankTest.vaccumeTest2.finishTime : "");

            $("#vaporValveTeststartTime").text(res.rows.dOTCargoTankTest.vaporValveTest.startTime ? res.rows.dOTCargoTankTest.vaporValveTest.startTime : "");
            $("#vaporValveTestfinishTime").text(res.rows.dOTCargoTankTest.vaporValveTest.finishTime ? res.rows.dOTCargoTankTest.vaporValveTest.finishTime : "");

            $("#pressureTest3startTime").text(res.rows.dOTCargoTankTest.pressureTest3.startTime ? res.rows.dOTCargoTankTest.pressureTest3.startTime : "");
            $("#pressureTest3finishTime").text(res.rows.dOTCargoTankTest.pressureTest3.finishTime ? res.rows.dOTCargoTankTest.pressureTest3.finishTime : "");


            setcompartments(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.compartmentList : "");
            if (res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.compartmentList : "" !== "") {
                for (var i = 0; i < res.rows.dOTCargoTankTest.compartmentList.length; i++) {
                    if (res.rows.dOTCargoTankTest.compartmentList[i].working == "1") {
                        workingLen.push(res.rows.dOTCargoTankTest.compartmentList[i].working);
                        totalGallons += parseFloat(res.rows.dOTCargoTankTest.compartmentList[i].safeFillAmountInGals);
                    }

                }
                $("#allValues").val(totalGallons);
            }
            try {
                if (res.rows.dOTCargoTankTest.compartmentList.length == workingLen.length) {
                    $('#checkAll').prop('checked', true);
                }
                if (res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.manwayCompartmentsList : "" !== "") {
                    for (var i = 0; i < res.rows.dOTCargoTankTest.manwayCompartmentsList.length; i++) {
                        $('#checkAllmany').prop('checked', true);
                        if (res.rows.dOTCargoTankTest.manwayCompartmentsList[i].working == "1") {
                            totalmanyGallons += parseFloat(res.rows.dOTCargoTankTest.manwayCompartmentsList[i].safeFillAmountInGals);
                        }

                    }
                    $("#allManyValues").val(totalmanyGallons);
                }
                setmanywaytest(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.manwayCompartmentsList : "");
                $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
                // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
                //   alert(res.rows.dOTCargoTankTest.checkPerformed.checkPerformed);
                setCheckPerformed(res.rows.dOTCargoTankTest.checkPerformed.checkPerformed);
                $('input[name="checkPerformed"][value="' + res.rows.dOTCargoTankTest.checkPerformed.checkPerformed + '"]').trigger("click").prop('checked', true);
                $('input[name="other"][value="' + res.rows.dOTCargoTankTest.componentsTested.other + '"]').trigger("click");
                $('#otherData').val(res.rows.dOTCargoTankTest.checkPerformed.otherData);
                $('#notes22').val(res.rows.dOTCargoTankTest.componentsTested.otherDataCom);
                setStatus(res.rows.status);
                if (res.rows) {
                    if (res.rows.verifiedDate) {
                        setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
                    }
                    if (res.rows.expiryDate) {
                        setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
                    }
                }
            } catch (ex) {

            }



        });

    }


    const save = (status) => {


        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter Inspected by",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }
        var status = $('input:radio[name="inspectionFailed"]:checked').val();

        if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please select disposition of tank",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }
        if ($('input:radio[name="inspectionFailed"]:checked').val() === "" || $('input:radio[name="inspectionFailed"]:checked').val() === undefined) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please select inspection result",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }

        if (status === "approvedCertify") {
            status = "APPROVED";
        } else {
            status = "Rejected";
        }
        var requestObjectInspection =
        {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "status": status,

            "result": {
                "approvedCertify": $("input[name='inspectionFailed']:checked").val() === "approvedCertify" ? "1" : "0",
                "inspectionFailed": $("input[name='inspectionFailed']:checked").val() === "inspectionFailed" ? "1" : "0"
            },
            "dispositionOfVehicle": {
                "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
            }


        };
        $("#loadingforsubmit").show();

        // setLoading(true);
        // const requestOptions =
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },

        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions = requestObject.RequestHeader(requestObjectInspection);
        services.certifyInspection(requestOptions).then((res) => {
            //   setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: res.message,
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                    navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                }
            });
        });

    }

    return (
        <>
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex justify-content-between align-items-center my-4">
                            <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <HeaderBlock cids={fkAccountId} />
                        </div>
                    </div>



                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card">

                                <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                        <VehicleBasicInfo customerId={vid} />
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="row p-3">
                                    <div class="col-lg-12">
                                        <h4 class="mb-2 form-inspection-title text-center">DOT Cargo Tank - EPA - Pressure Vacuum Test (OHIO)</h4>
                                    </div>
                                    <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div>

                                    <div class="w-100 px-3">
                                        <div class="inspection-wrap px-0">

                                            <div class="row mb-2 mt-2">
                                                <div class="col-lg-12">
                                                    <div class="py-2">
                                                        <h5 class="text-uppercase">Check Performed</h5>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="radio" name="checkPerformed" value="new" class="mr-2" disabled checked={CheckPerformed == 'new' ? "checked" : ""} />
                                                        <span>New</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="radio" name="checkPerformed" value="annual_retest" class="mr-2" disabled checked={CheckPerformed == 'annual_retest' ? "checked" : ""} />
                                                        <span>Annual Retest</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-1 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="radio" name="checkPerformed" value="repair" class="mr-2" disabled checked={CheckPerformed == 'repair' ? "checked" : ""} />
                                                        <span>Repair </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 mb-2 d-flex align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <input type="radio" name="checkPerformed" value="other" class="mr-2" disabled checked={CheckPerformed == 'other' ? "checked" : ""} />
                                                        <span>Other (Specify)</span>

                                                    </div>
                                                    {(() => {
                                                        if (CheckPerformed == "other") {

                                                            return (<><div id="otherField">
                                                                <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" size="10" name="otherData" id="otherData" class="mr-2" /> </div></div>
                                                            </div></>)
                                                        }
                                                    })()}


                                                </div>



                                            </div>


                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ "width": "50%" }}>Compartment Tested/Inspected </th>
                                                            <th scope="col" style={{ "width": "40%" }}>Safe fill amount(GALS)</th>
                                                            <th scope="col" class="d-flex align-items-center"><input type="checkbox" placeholder="" disabled id="checkAll" class="mr-2" />ALL</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            compartments ? compartments.map((data, index) => (
                                                                <tr>
                                                                    <td>
                                                                        <div class="row align-items-center">
                                                                            <div class="col-md-4">Compartment #{index + 1} </div>

                                                                        </div>
                                                                    </td>
                                                                    <td>

                                                                        <div class="col-md-4"><input type="text" class="form-control" id={"comp" + index + "gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} disabled /></div>

                                                                    </td>
                                                                    {(() => {
                                                                        if (data.working == "1") {

                                                                            return (<><td><input type="checkbox" aria-label="Radio button for following text input" value="1" checked disabled /></td></>)
                                                                        } else {
                                                                            return (<><td><input type="checkbox" aria-label="Radio button for following text input" value="1" disabled /></td></>)
                                                                        }
                                                                    })()}

                                                                </tr>
                                                            )) : ""

                                                        }
                                                        {/* <tr>
                                        <td>
                                            <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #1 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.safeFillAmountInGals:""}</div>
                                            </div>
                                        </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #2 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                        </tr>



                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #3 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment3.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #4 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #5 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #6 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr> */}


                                                        <td>Total in Gallons</td> <td><div class="col-md-4"><input type="text" class="form-control" id="allValues" disabled /></div></td>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="w-100">
                                <div class="inspection-wrap px-3">

                                <div class="table-2 mb-4">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                        <th scope="col" style={{ "width": "50%" }}>Manway Tested/Inspected(fill cover gaskets & vents) </th>

                                                    <th scope="col" class="d-flex align-items-center"><input type="checkbox"   aria-describedby="emailHelp" placeholder=""  id="checkAllmany" disabled class="mr-2" />ALL</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                        manywaytest ? manywaytest.map((data, index) => (
                                            <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #{index+1} </div>

                                                </div>
                                            </td>

                                        { (() => {
                                                  if (data.working=="1")
                                                  {

                                                  return(<><td class="text-center"><input type="checkbox" aria-label="Radio button for following text input" value="1" checked disabled    /></td></>)
                                                  }else
                                                  {
                                                  return(<><td class="text-center"><input type="checkbox" aria-label="Radio button for following text input"  value="1"   disabled /></td></>)
                                                  }
                                            })()}

                                        </tr>
                                        )) : ""

                                    }

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                </div> */}
                                    <div class="col-lg-12">
                                        <div class="inspection-wrap px-0">



                                            <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                                <p>Test Method: US EPA Method 27, 40CFR, Part 60 APPX. A (03.420) <br />
                                                    Test Requirements: Modified Method 27, Pressure 18" W.C., Vacuum 6" W.C. <br />
                                                    *Note: Two consecutive test within 1/2 W.C. Test Result is average of two consecutive test
                                                </p>
                                            </div>


                                            <div class="table-3 mb-4">
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="2">Pressure - 18" W.C / 5 mins. Less than 1" W.C loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #1</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="pressureTest1startTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="pressureTest1finishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.change : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #2</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="pressureTest2startTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="pressureTest2finishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.change : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>

                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Test result </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.testResult : "--"}</b>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-3 mb-4">
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="2">Vacuum - 6" W.C / 5 mins. Less than 1" W.C loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">

                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #3</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="vaccumeTest1startTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="vaccumeTest1finishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.change : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #4</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="vaccumeTest2startTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="vaccumeTest2finishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.change : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Test result </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.testResult : "--"}</b>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row mb-4">
                                                <div class="col-lg-12">
                                                    <div class="p-4 text-center">
                                                        <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Components Tested / Inspected</h5>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="productPiping" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.productPiping == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Product Piping/Gaskets</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="dischargeValves" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.dischargeValves == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Discharge Valves</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="dischargeHoses" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.dischargeHoses == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Discharge Hoses</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="manholeAssemblies" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.manholeAssemblies == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Manhole Assemblies</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="tankVessel" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.tankVessel == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Tank vessel weld seams</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="vaporCollection" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.vaporCollection == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Vapor collection piping</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="compartmentVent" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.compartmentVent == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Compartment Vent</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="vaporvent" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.vaporvent == '1' ? "checked" : '' : ''} disabled />
                                                        <span>Vapor vent hood / hose assemblies'</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input type="checkbox" name="other" value="1" class="mr-2" checked={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.componentsTested.other == '1' ? "checked" : '' : ''} disabled />
                                                        {/* checked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.other=='1'?"checked":'':''} */}
                                                        <span>Other  (List)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {(() => {
                                                if (inspectionDetails.dOTCargoTankTest) {
                                                    if (inspectionDetails.dOTCargoTankTest.componentsTested.other == '1') {

                                                        return (<> <div id="otherFieldTextFiled">
                                                            <div class="d-flex align-items-center"><textarea class="form-control mb-2" name="notes22" maxLength="300" id="notes22" rows="3" spellcheck="false" placeholder="" disabled></textarea><br /><br /> </div>
                                                        </div></>)
                                                    }
                                                }
                                            })()}


                                            <div class="table-3 mb-4">
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Vapor Valve Test: (40CFR 63.420(E) (2))</th>
                                                            <th scope="col">Pressure 18" W.C/ 5 min. duration &lt;5" W.0 loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #1</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="vaporValveTeststartTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="vaporValveTestfinishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.change : "N/A"}</b>
                                                                            <span>W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #2</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Start Time</span>
                                                                            <b id="pressureTest3startTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.startTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Finish Time</span>
                                                                            <b id="pressureTest3finishTime"></b>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3">Pres.</span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.finishTimePressure : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3" style={{ minWidth: "75px" }}>Change </span>
                                                                            <b class="mr-3">{inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.change : "N/A"}</b>
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="form-footer-comon-wrap">
                                    <div class="row documents p-3">
                                        <div class="col-lg-12">
                                            {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                            <div class="row">
                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-12">
                                                            <div class="d-flex align-items-center">

                                                                <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                                    {/* <b>{data.title}.{data.extension}</b> */}
                                                                    <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                        <img src="" class="mr-2" />
                                                                        <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                            {(() => {
                                                                                if (data.extension == 'pdf') {
                                                                                    return (
                                                                                        <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    )
                                                                                } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                    return (
                                                                                        <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                    return (
                                                                                        <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                            {data.name ? data.name : "--"} </p></span>
                                                                        {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                                </a>
                                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                            </div>
                                                        </div>


                                                    )) : ""

                                                }
                                            </div>
                                        </div><br />

                                    </div>
                                    <div class="row documents p-3">
                                        <div class="col-lg-12">
                                            <h6 class="mb-4">Notes: </h6>
                                            {inspectionDetails ? inspectionDetails.notes : ''}
                                        </div>
                                    </div>
                                    <div class="divider2"></div>
                                    <div class="row performby p-4">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="d-flex flex-column">
                                                        <label for="">Test Performed By:</label>
                                                        <b>{inspectionDetails.inspectedBy}</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-flex flex-column">
                                                        <label for="">Date</label>
                                                        <b>{inspectionDetails.inspectedDate}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="divider"></div>
                                    {(() => {
                                        if (roles !== "Technician") {
                                            if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                                return (
                                                    <>
                                                        <div class="result-didspos">
                                                            <div class="p-4">
                                                                <span>Result</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Inspection Failed</b>
                                                                </div>
                                                            </div>
                                                            <div class="p-4">
                                                                <span>Disposition of Tank</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                                    <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="divider"></div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <><div class="result-didspos ">
                                                        <div class="p-4">
                                                            <span>Result</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                                            </div>
                                                        </div>
                                                        <div class="p-4">
                                                            <span>Disposition of Tank</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
                                                        {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

                                                    </>
                                                )
                                            }
                                        }
                                    })()}

                                </div>

                                <div class="divider"></div>
                                {(() => {
                                    if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    }
                                    else {
                                        return (<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1 ">Cancel</a>
                                            {roles.match("Technician") ? "" : <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                            {roles.match("Technician") ? <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a> : ""}

                                        </div></>)
                                    }
                                })()}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
};

DOTRead.propTypes = {};

DOTRead.defaultProps = {};

export default DOTRead;