import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const LeakageTestRead = () => {



   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {
         console.log("  " + JSON.stringify(res));
         setInspectionDetails(res.rows);
         // $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
     
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

     // setEndDate(inspectionDetails.verifiedDate);
   }

   const redirect=(page)=>
   {

      navigate(page);

   }

   const save=(status)=>
   {

   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select disposition of tank",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select inspection result",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
   {

   }else{

    status="Rejected";
   }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };

       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               

               <div class="row mb-2">
               <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                       <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Leakage Test</h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div><br/>

                           <div class="col-lg-4 mb-4">
                              <div class="d-flex align-items-center">

                                 <b>Leakage Check Type:</b> &nbsp;&nbsp;<span>{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.checkType.Hydrostatic == '1'?"Hydrostatic":'Pneumatic':''}</span>
                                 {/* {inspectionDetails.type?inspectionDetails.type:"N/A"} */}
                              </div>
                           </div>

                           {/* <div class="col-lg-12 mb-3">
                              <div class="d-flex align-items-center">

                              <input type="checkbox" id="MODIFIEDMETHOD27"  name="MODIFIEDMETHOD27" value="1" class="mr-2" checked={inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.checkType.modifiedmethod27 == '1'?'checked':'':''} disabled/><span>MODIFIED METHOD 27</span>

                              </div>
                           </div> */}

                           {/* <b>MODIFIED METHOD 27:</b> <span>{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.checkType.modifiedmethod27 == '1'?"True":'False':''}</span> */}
                                 {/* {inspectionDetails.type?inspectionDetails.type:"N/A"} */}





                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Unit Design Pressure : <b>{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.unitDesignPressure: ''}</b></th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.closeAllValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.closeAllValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""} </td>
                                          </tr>

                                          <tr>
                                             <td>Internal valves: open external valves - inspect for leaks - close external valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.internalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center"> {inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.internalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>External valves: open internal valves, fill piping with water - inspect for leaks - close internal valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.externalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.externalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          {/* <tr>
                                                        <td><td>Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes</td></td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.unitdeSignPressure.closeAllTanksAndOpens=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.unitdeSignPressure.closeAllTanksAndOpens=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>  */}


                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-12">
               <div class="inspection-wrap">

               <div class="table-2 mb-4">
                  <table class="table">
                     <thead>
                     {/* <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                     </tr> */}
                     </thead>
                     <tbody>
                     <tr>
                        <td colspan="3">Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes.<br/>  Test Pressure Used  : <b>{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.testPressureUsed.testPressure : ''}</b></td>

                     </tr>
                     </tbody>
                  </table>
               </div>

               </div>
          </div>

                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col"></th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>During the test period : Inspect all surfaces and component installations for leaks. Attention given to manhole  assembly area. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.testPressureUsed.duringTheTestPeriod == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.testPressureUsed.duringTheTestPeriod == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>




                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Inspection for valve Leakage</th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>Internal valves : open external valves and drain piping. Inspect for continued drainage - close external valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.internalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.internalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>External valves : open internal valves and fill piping with water - inspect leakage from external valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.externalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.externalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>Remote valves operators : open internal valve with normal operator - close with remote operator - open external valves and drain piping-  inspect for continued drainage - close external valves - open and close internal valve with normal operator, filling pipe with water. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.remotevalveoperator == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.remotevalveoperator == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>Inspect overall piping system for leakage. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.inspectOverallPiping == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.inspectOverallPiping == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>With all valves closed, hold test pressure 5 mins for successful test. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.withAllValvesClosed == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.withAllValvesClosed == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>Tank marking: Date(month/year) and service symbol after all defects are corrected. Did you update markings on tank?                                                       </td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center"> {inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          {/* <tr>
                                                        <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr> */}


                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />

                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Additional notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

         </>
          )
        }
      }
      })()}
                             </div>

                        <div class="divider"></div>
                        {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>
                                        */}
                                        {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

LeakageTestRead.propTypes = {};

LeakageTestRead.defaultProps = {};

export default LeakageTestRead;
