import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";

const GarageReminders=(customerId,pageNumber,ITEMS_PER_PAGE_DUE,status,vehicleTypeId,pageSize,setTotalItemsDue,settotalPagesDue,setCurrentPageDue,setDue,setdisplayPageDue,sortBy,sortDir,displayItems,dstatus)=>
{
   /////////////get vechile list//////////////////////////
     var vechileReq="";
     if(sortBy==="lisencePlateNumber")
     {

      sortBy="lisencePlateNumber";    
     }
    //  else if(sortBy==="documentCategoryName")
     else if(sortBy==="documentSubCategoryName")
     {
      // docSubCategory
      // sortBy="documentCategoryName";
      sortBy="docSubCategory";
     }else if(sortBy==="VIN Number")
     {

      sortBy="vinNumber";
     }else if(sortBy==="Unit Number")
     {

      sortBy="unitNumber";
     }
     if(customerId=="")
     {
             vechileReq=
            {

              "pageNumber": pageNumber,
              "pageSize": ITEMS_PER_PAGE_DUE,
              "lisencePlateNumber":status,
              "vehicleTypeId": '',
              "overDue": dstatus,
              "sortBy": sortBy,
              "sortDir": sortDir,
              "isActive":true

              // "pageNumber":pageNumber,
              // "pageSize":pageSize,
              // "lisencePlateNumber": "",
              // "vehicleTypeId":vehicleTypeId,
              //  "overDue":title,
              // "sortBy": sortBy,
              // "sortDir": sortDir
                       
            };
     }else
	 {
	     vechileReq=
		  {
            
        "pageNumber": pageNumber,
        "pageSize": ITEMS_PER_PAGE_DUE,
        "lisencePlateNumber":status,
        "vehicleTypeId": '',
        "overDue": dstatus,
        "sortBy": sortBy,
        "sortDir": sortDir,
        "isActive":true
		  }
	 }
     

      console.log("results="+JSON.stringify(vechileReq));

      // const requestOptions = 
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vechileReq)
      // };
      var requestOptions=requestObject.RequestHeader(vechileReq);
      services.listAllGarageReminders(requestOptions).then((res) => 
      {
        
            if(res.success)
            {

              setTotalItemsDue(res.customValues.totalNumberOfRecords);
            
              settotalPagesDue(Math.ceil(res.customValues.totalNumberOfPages / ITEMS_PER_PAGE_DUE));
              setCurrentPageDue(res.customValues.currentPageNumber);
              if(res.rows.length>0)
              {
                 
                setDue(res.rows);
              }else{

                
                setDue("");
              }
             
              setTotalItemsDue(res.customValues.totalNumberOfRecords);
        
              var displaypages=[];
              if(displayItems=="displayItems")
              {
              
                      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE_DUE));i++)
                      {
                       
                        displaypages.push(i*ITEMS_PER_PAGE_DUE);
                      }
                      
                      setdisplayPageDue(displaypages);
              
              }
            }else{
            
                // setDue("");
               setdisplayPageDue("");
              //alert(res.message);
             
            }
         
      });
   
   



}

export default GarageReminders;