import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../services/services";
import "cropperjs/dist/cropper.css";
import Getstates from "../../../utility/getstates";
import getCroppedImg from "../../../utility/cropImage";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import GetTruckType from "../../../utility/getTruckTypes";
import { requestObject } from "../../../utility/requestObject";
import Cropper from "react-cropper";
import UsNumberValidate from "../../../utility/usNumberValidate";
import UploadImage from "../../../utility/uploadImage";
import AdddocsDispatcher from "../../../utility/AdddocsDispatcher";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";

const AddGarage = () => {
    // const [loading, setLoading] = useLoading("Please wait...");
    const [states, setState] = useState([])
    const [listVendors, setListVendors] = useState([]);
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onKeyup"
    });

    $(document).ready(function () {

        $('#email').keypress(function (e) {
            if (e.which === 32) {
                return false;
            }
        });

        $("#deskNumber,#ext,#zipcode").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
            }
        });
    });

    useEffect(() => {
        Getstates(setState);
        $('#mobileNumber').usPhoneFormat();
        $('#fax').usPhoneFormat();
        $('#deskNumber').usPhoneFormat();
        //   listVendorsApi();
    }, []);

    const listVendorsApi = () => {
        var listVendorsReq =
        {

        };

        var requestOptions = requestObject.RequestHeader(listVendorsReq);
        services.listVendors(requestOptions).then((res) => {
            // console.log("@@@@@vendors list@@@@@@" + res.rows);
            setListVendors(res.rows);
        });
    }


    const submitValue = () => {


       
        //$("#loadingforsubmit").show();
        var vechileReq =
        {
            "name": $("#firstName").val() != '' ? $("#firstName").val().trim() : '',
            "email": $("#email").val() != '' ? $("#email").val().trim() : '',
            "deskNumber": $("#deskNumber").val() != '' ? $("#deskNumber").val().trim() : '',
            "extNo": $("#ext").val() != '' ? $("#ext").val().trim() : '',
            "phoneMobile": $("#mobileNumber").val() != '' ? $("#mobileNumber").val().trim() : '',
            "addressLine1": $("#address1").val() != '' ? $("#address1").val().trim() : '',
            "addressLine2": $("#address2").val() != '' ? $("#address2").val().trim() : '',
            "city": $("#city").val() != '' ? $("#city").val().trim() : '',
            // "securityRoleId":"4", 
            // "securityRoleName":"ADMIN", 
            "fkStateId": $("#state").val() != '' ? $("#state").val() : '',
            "zipCode": $("#zipcode").val() != '' ? $("#zipcode").val().trim() : '',
            "dotNumber": $("#dotnumber").val().trim(),
            "ctNumber": $("#ctnumber").val().trim(),
            "fax": $("#fax").val() != '' ? $("#fax").val() : '',

            // "name":$("#firstName").val()!=''?$("#firstName").val():'', 
            // "addressLine1":$("#address1").val()!=''?$("#address1").val():'', 
            // "addressLine2":$("#address2").val()!=''?$("#address2").val():'', 
            // "phoneBusiness":"", 
            // "phoneMobile":$("#mobileNumber").val()!=''?$("#mobileNumber").val():'', 
            // "fax":$("#fax").val()!=''?$("#fax").val():'', 
            // "email":$("#email").val()!=''?$("#email").val():'', 
            // "logPath":"", 
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.addVendor(requestOptions).then((res) => 
        {
            //   setLoading(false);
            $("#loadingforsubmit").hide();

            switch(res.message)
            {
                case "Garage with DOT number already exists":
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text:res.message,
                        showConfirmButton: true
            
                      }).then((result) =>
                      {
                        
                        
                            if(result.isConfirmed)
                            {
                            

                            }
                          
                      });

                    break;
                case "Successfully added vendor":
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text:res.message,
                        showConfirmButton: true
            
                      }).then((result) =>
                      {
                        
                        
                            if(result.isConfirmed)
                            {
                              //navigate('/customer');
                              window.location.reload()

                            }
                          
                      });
                 break;

                 default:
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text:res.message,
                        showConfirmButton: true
            
                      }).then((result) =>
                      {
                        
                        
                            if(result.isConfirmed)
                            {
                              //navigate('/customer');
                              window.location.reload()

                            }
                          
                      });

            }
            
            // Swal.fire({
            //     position: 'center',
            //     icon: 'success',
            //     title: "",
            //     text: res.message,
            //     showConfirmButton: true
            // }).then((result) => 
            // {

            //     if (result.isConfirmed) 
            //     {
            //         document.getElementById("closeModel").click();
            //         if (result.isConfirmed) 
            //         {
            //             // window.location.reload();
            //         }
            //         // listVendorsApi();

            //         //  $('#assignGarageBlock').load('#assignGarageBlock');
            //         // $("#assignGarageBlock").load(window.location.reload + "#assignGarageBlock" );

            //         // $("#closeModel").click();
            //         // navigate("");
            //         //  window.location.reload();
            //     }
            // });

        });
        // }

    }
    return (
        <>
            {LoadingComponentForSubmit}
            <div class="modal fade" id="addGarrage" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Vendor</h5>
                            <button type="button" id="closeModel" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit(submitValue)}>
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label>Vendor Name<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" id="firstName" {...register('firstName', { required: true })} maxLength="50"/>
                                        {errors.firstName && errors.firstName.type === "required" && <span class="error">Vendor name is required</span>}
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label>Address line 1<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" id="address1" maxLength="50" {...register('address1', { required: true })} />
                                        {errors.address1 && errors.address1.type === "required" && <span class="error">Address line 1 is required</span>}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label>Address line 2</label>
                                        <input type="text" class="form-control custome-input" maxLength="50" id="address2" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label>City<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" id="city" {...register('city', { required: true, minLength: "2" })} maxLength="50" minLength="2" />
                                        {errors.city && errors.city.type === "required" && <span class="error">City is required</span>}
                                        {errors.city && errors.city.type === "minLenght" && <span class="error">City atleast 2 characters</span>}
                                        {/* {errors.city && errors.city.type === "pattern" && <span class="error">City is allow only character's</span>} */}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label>State<span class="star">*</span></label>
                                        <select class="form-control custome-select" id="state" {...register('state', { required: true })}>
                                            <option value='' >Select</option>
                                            {
                                                states ? states.map((state, index) => (
                                                    <option value={state.id}>{state.name}</option>
                                                )) : <option value=""></option>
                                            }
                                        </select>
                                        {errors.state && errors.state.type === "required" && <span class="error">State is required</span>}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label>Zip code<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" maxLength="5" id="zipcode" {...register('zipcode', { required: true, minLength: "5" })} minLength="5" />
                                        {errors.zipcode && errors.zipcode.type === "required" && <span class="error">Zip code is required</span>}
                                        {errors.zipcode && errors.zipcode.type === "minLength" && <span class="error">Zip code should be 5 digits</span>}

                                    </div>



                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row g-2">
                                            <div class="col-8">
                                                <label>Desk Number<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="deskNumber" {...register('deskNumber', { required: true, maxLength: 12, minLength: 12 })} />
                                                {/* {...register('deskNumber', { required: true})} */}
                                                {errors.deskNumber && errors.deskNumber.type === "required" && <span class="error">Desk number is required</span>}
                                                {errors.deskNumber && errors.deskNumber.type === "maxLength" && <span class="error">Desk number should be 10 digits</span>}
                                                {errors.deskNumber && errors.deskNumber.type === "minLength" && <span class="error">Desk number should be 10 digits</span>}
                                            </div>
                                            <div class="col-4">
                                                <label style={{ fontSize: "10px" }}>Extn no</label>
                                                <input type="text" class="form-control custome-input" id="ext" />
                                                {/* {...register('ext', { required: true})} */}
                                                {/* {errors.ext && errors.ext.type === "required" && <span class="error">Ext is required</span>} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control custome-input" id="mobileNumber" {...register('mobileNumber', { maxLength: 12, minLength: 12 })} />
                                        {/* {...register('mobileNumber', { required: true})}
                                                {errors.mobileNumber && errors.mobileNumber.type === "required" && <span class="error">Mobile number is required</span>} */}
                                        {errors.mobileNumber && errors.mobileNumber.type === "maxLength" && <span class="error">Phone number should be 10 digits</span>}
                                        {errors.mobileNumber && errors.mobileNumber.type === "minLength" && <span class="error">Phone number should be 10 digits</span>}
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label>Fax Number</label>
                                        <input type="text" class="form-control custome-input" id="fax" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label>Email<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" id="email" {...register('email', {
                                            required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                        })} />
                                        {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                        {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                    </div>
                                   
                                </div>
                                <div class="p-3 mt-2 mb-2" style={{ marginLeft: "-15px" }}>
                                    <h4>ID Numbers</h4>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label ><span class="lableUpper">DOT</span> Number<span class="star">*</span></label>
                                        <input type="text" class="form-control custome-input" id="dotnumber" maxLength="20" {...register('dotNumber', { required: true })} />
                                        {errors.dotNumber && errors.dotNumber.type === "required" && <span class="error"><span class="lableUpper">DOT</span> number is required</span>}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label ><span class="lableUpper">CT</span> Number</label>
                                        <input type="text" class="form-control custome-input" id="ctnumber" maxLength="20" />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                        {/* <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    )
};

AddGarage.propTypes = {};

AddGarage.defaultProps = {};

export default AddGarage;
