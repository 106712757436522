import React, { history, useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import $ from 'jquery';
const ListOfContacts=(Customer_Id,setCustomerContactList)=>
{
   if(Customer_Id==''){
   var contactRequest =
   {
      
      "fkCustomerId": localStorage.getItem("customerId")=="null"||localStorage.getItem("customerId")==null?localStorage.getItem("cid"):localStorage.getItem("customerId")
     
   }
 }else{
   var contactRequest =
   {
      
      "fkCustomerId":Customer_Id 
     
   }
}
   var requestOptions = requestObject.RequestHeader(contactRequest);

   services.getCustomerContacts(requestOptions).then((res) =>
   {
    
      setCustomerContactList(res.rows)
      
   })
 
}

export const CustomercontactId =(e,setChecked,checked)=>
{
  
    var updatedList = [...checked];
    if (e.target.checked) {
      updatedList = [...checked, e.target.value];
    } else {
      updatedList.splice(checked.indexOf(e.target.value), 1);
    }
   
    setChecked(updatedList);
  
 
}

export const SelectAllContactList =(event,setChecked)=>
{
  

       const {checked} = event.target;
        if(checked)
        {
            
            
            $('input:checkbox[name=contactListemails]').prop('checked', true).attr('checked', 'checked');
        }else{
      
            $('input:checkbox[name=contactListemails]').prop('checked', false).attr('checked', 'checked');
        }
 
}
export const SelectAllinspections =(event,setChecked)=>
{
  

       const {checked} = event.target;
        if(checked)
        {
            
            
            $('input:checkbox[name=inspectionIds]').prop('checked', true).attr('checked', 'checked');
        }else{
      
            $('input:checkbox[name=inspectionIds]').prop('checked', false).attr('checked', 'checked');
        }
 
}
export const SelectAllContactListFromSC =(event,setChecked)=>
{
  

       const {checked} = event.target;
        if(checked)
        {
            
            
            $('input:checkbox[name=contactListemailIds]').prop('checked', true).attr('checked', 'checked');
        }else{
      
            $('input:checkbox[name=contactListemailIds]').prop('checked', false).attr('checked', 'checked');
        }
 
}

export const getInspectionType = (setInspectionType) => {


  var request =
  {
    
  };

  
  var requestOptions = requestObject.RequestHeader(request);

 
  services.getInspectionTypeS(requestOptions).then((res) =>
  {
   
     
    setInspectionType(res.rows);

  });
}

export const listTaskOrders=(pageNumber,pageSize,setTotalTaskOrder,setTotalTaskList,setdisplayPageForInspection)=>
{
  var listoftask=
   {
     
       "pageNumber": pageNumber,
       "pageSize": pageSize,
        "sortBy": "",
       "sortDir": "",
       "isActive":true
      
   }

   var requestOptions = requestObject.RequestHeader(listoftask);
   services.listTaskOrder(requestOptions).then((res) => 
   {
      $("#loading").hide();
       setTotalTaskOrder(res.customValues?res.customValues.totalNumberOfRecords:0);
       var displaypages=[];
       if(res.customValues)
       {
        for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/10));i++)
        {
           displaypages.push(i*10);
        }

       }else{

       }
       
     
       setdisplayPageForInspection(displaypages);
       if(setTotalTaskList=="")
       {

       }else{
        setTotalTaskList(res.rows?res.rows:0);
       }
     

   });

}
export default ListOfContacts;
