export const LoadingComponentfordocuments = (
    <div class="loader-wrapper" id="loadingfordocs">
    <div class="truck-wrapper-docs">
      <div class="truck" >
        <div class="truck-container"></div>
        <div class="glases"></div>
        <div class="bonet"></div>
  
        <div class="base"></div>
  
        <div class="base-aux"></div>
        <div class="wheel-back"></div>
        <div class="wheel-front"></div>
  
        <div class="smoke"></div>
      </div>
    </div>
  </div>

  
  
  
    )