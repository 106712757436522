 ////////////////documentDetails Customer/////////////////////////////
import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";
import Swal from "sweetalert2";
 const documentDetails_=(filePath,extension)=>
 { 
   
   
       var document_request=
       {
                 
                 "filePath":filePath
              
       };
      
    
       var requestOptions=requestObject.RequestHeader(document_request);
       services.getInspectionDocumentDetails(requestOptions).then((res) => 
       {
          
           if(res.customValues.fileByte==="")
           {
             Swal.fire({  
               position: 'center',  
               icon: 'error',  
               title: "",  
               text:"File is not attached",
             
               showConfirmButton: true 
               
             }).then((result) => 
             {
                   if (result.isConfirmed) 
                   {
                    
                   }
             });
           }else{
           
            
             var file;
             if(extension=="pdf")
             {
                var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                 file = new Blob([byteArray], { type: 'application/pdf;base64' });
                 var fileURL = URL.createObjectURL(file);
                 window.open(fileURL);
             }
             else if(extension=="jpg")
             {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                var image = new Image();
                image.src = "data:image/jpg;base64,"+ res.customValues.fileByte;
 
                var w = window.open("");
                w.document.write(image.outerHTML);
             }
             else if(extension=="png")
             {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                var image = new Image();
                image.src = "data:image/png;base64,"+ res.customValues.fileByte;
 
                var w = window.open("");
                w.document.write(image.outerHTML);
             }
             else if(extension=="bmp")
             {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                var image = new Image();
                image.src = "data:image/bmp;base64,"+ res.customValues.fileByte;
 
                var w = window.open("");
                w.document.write(image.outerHTML);
             }
             else if(extension=="word")
             {
             
                var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                file = new Blob([byteArray], { type: 'application/msword;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
             }
             else if(extension=="docx"||extension=="doc"||extension=="docm"||extension=="dotx"||extension=="dotm")
             {
             
                var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                file = new Blob([byteArray], { type: 'application/msword;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
             }
            
             
            
           }
            
      
          
       });
 
 } 
 export default documentDetails_;