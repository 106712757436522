import React, { history, useEffect, useState, useMemo } from "react";
import HeaderBlock from "../vehicle/VehicleHeaderBlock";
import $ from "jquery";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
const InspectionVaporTightnessCertificate3 = () => {

    useEffect(() => {

        waterGauge();
    
      }, []);
    
      function waterGauge() {
   
        var gauge = JSON.parse(sessionStorage.getItem("waterGauge"));
        
        var pi = gauge == null ? '' : gauge.pf;
        var pipf = gauge == null ? '' : gauge.pfpi;
        var ti = gauge == null ? '' : gauge.ti;
        var tf = gauge == null ? '' : gauge.tf;
        var titf = gauge == null ? '' : gauge.titf;
    
        $("input[name='water_pf']").val(pi);
        $("input[name='water_pfpi']").val(pipf);
        $("input[name='water_ti']").val(ti);
        $("input[name='water_tf']").val(tf);
        $("input[name='water_titf']").val(titf);
    
      }
      const sectionSubmit = () => {
        var waterGauge = {
          "pf": $("input[name='water_pf']").val()!=""?$("input[name='water_pf']").val():"",
          "pfpi": $("input[name='water_pfpi']").val()!=""?$("input[name='water_pfpi']").val():"",
          "ti": $("input[name='water_ti']").val()!=""?$("input[name='water_ti']").val():"",
          "tf": $("input[name='water_tf']").val()!=""?$("input[name='water_tf']").val():"",
          "titf": $("input[name='water_titf']").val()!=""?$("input[name='water_titf']").val():"",
        };
        console.log(JSON.stringify(waterGauge));
        sessionStorage.setItem("waterGauge", JSON.stringify(waterGauge));
  
   
}
  
  
     $(document).ready(function () {
        $("input[name='water_pf'],input[name='water_pfpi'],input[name='water_ti'],input[name='water_tf'],input[name='water_titf']").keypress(function (e) {
           if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
              return false;
           }
        });
     });

    return (
        <>

            <div class="row mb-2">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">

                        {/* <div class="divider"></div> */}
                        <div class="row px-3 mb-3">

                            <div class="w-100">
                                <h4 class="form-inspection-title text-center">Vapor Tightness Certification Form</h4>
                            </div>
                            {/* <div class="col-lg-12"><WorkOrderNumberDiv/></div> */}
                            <div class="w-100">
                                <div class="inspection-wrap mb-4">

                                    <div class="step-wrap p-3 mb-3">
                                        <div class="step-main d-flex">
                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                                {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""></div> --> */}
                                                <div class="labl-section">Annual Certification</div>
                                            </div>
                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                                <div class="labl-section">Pressure Test</div>
                                            </div>

                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2 current-tick">3</div>
                                                <div class="labl-section">Internal Vapor Valve Test</div>
                                            </div>
                                            <div class="step-itm mr-3">
                                                <div class="no-cl mr-2">4</div>
                                                <div class="labl-section">Vaccum Test</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="text-center mb-5 mt-2">
                                    <b>MACTR-40 CFR-63.425 (E) Internal vapor valve test</b>
                                    <div class="p-3 mb-4 mt-3 text-left" style={{ backgroundColor: "#ccc" }}>
                                        <p>After two consecutive pressure runs, with the tank still pressurized to 18 inches water, close all the internal vapor valves, and drop the pressure on the vapor line. Then, reseal the line. Test is the gauge
                                            pressure change in the vapor return line. Record initial time (Ti) and Initial pressure (Pi should be zero), then record final time (Tf) and final pressure (Pf). Test is run at least 5 minutes allowing
                                            no more than 5 inch pressure increase over that time.
                                        </p>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div>
                                            <table style={{ width: "100%" }}>
                                                <tr class="border">
                                                    <th colspan="6" class="text-center p-2">Water Gauge Reading</th>

                                                </tr>
                                                <tr class="border vapor-table">
                                                    <td class="border-right border-bottom p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Pi = 0-inch</label>

                                                        </div>
                                                    </td>
                                                    <td class="border-right border-bottom p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Pf =</label>
                                                            <input type="input" name="water_pf"  class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                        </div>
                                                    </td>
                                                    <td class="border-bottom p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Pf-Pi =</label>
                                                            <input type="input" name="water_pfpi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                        </div>
                                                    </td>
                                                    <td class="border-right p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Ti =</label>
                                                            <input type="input" name="water_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                        </div>
                                                    </td>
                                                    <td class="border-right p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Tf =</label>
                                                            <input type="input" name="water_tf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                        </div>
                                                    </td>
                                                    <td class="border-bottom p-2">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="">Tf-Ti =</label>
                                                            <input type="input" name="water_titf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                        </div>
                                                    </td>



                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>

                            <button class="btn btn-primary mr-1" id="certificate2">Back</button>
                            <button class="btn btn-primary mr-1" id="certificate4">Next</button>
                            {/* <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button> */}
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

InspectionVaporTightnessCertificate3.propTypes = {};

InspectionVaporTightnessCertificate3.defaultProps = {};

export default InspectionVaporTightnessCertificate3;
