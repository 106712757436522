import React,{history,useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import { useNavigate ,useParams} from "react-router-dom";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const CargoTankThicknessTestRead = () => {
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [designpressure, setDesignPressure] = useState();
   const [certify, setApproveCertify] = useState();
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));

      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId,inspectionId)
   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {

        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) =>
      {

         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList);
         // inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.designPressure:""}
         // setDesignPressure(res.rows.inspectionDetails.cargoTankThicknessTest.designPressure);
        $('#thicknessDesignPressure').val(res.rows.cargoTankThicknessTest?res.rows.cargoTankThicknessTest.designPressure:'');
        $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
        setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
      //   sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : ""); 
        setApproveCertify(res.result?res.result.approvedCertify:"");
        setStatus(res.rows.status);
        if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      //   $('input[name="corrosionallowance"][value="' + res.rows.cargoTankThicknessTest?res.rows.cargoTankThicknessTest.minimumCorrissionAllowence + '"]').trigger("click");

      });

   }
   const redirect=(page)=>
   {

      navigate(page);

   }
   const save=(status)=>
   {

   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select disposition of tank",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select inspection result",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
     if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
     {

     }else{

      status="Rejected";
     }



         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };
       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }

  return(
  <>
  {LoadingComponentForSubmit}
   <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>

               <div class="row mb-0">
                                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                                    <div class="d-flex justify-content-between align-items-center my-4">
                                    <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                                    </div>
                                  </div>
                              </div>

                            <div class="row mb-2">
                           <div class="col-lg-11 col-md-12 m-auto ">
                           <HeaderBlock cids={fkAccountId} />
                           </div>
                         </div>
                              

                              <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">
                        <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                        </div>
                            <div class="divider"></div>

                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="form-inspection-title text-center">Cargo Tank Thickness Test</h4>
                                    <WorkOrderNumberDiv status={"readOnly"} />
                                </div>
                                {/* <WorkOrderNumberDiv/> */}
                                <div class="col-lg-12">
                                    <div class="inspection-wrap">


                                        <div class="table-2 mb-1 ">
                                            <table class="table">

                                                <tbody>

                                                    <tr class="p-2 ">
                                                        <td style={{overflowX:"hidden"}}>
                                                        {/* <div class="row align-items-center justify-content-start mb-3  px-3 ">
                                                                <div class="col-md-9">1. Design Pressure. </div>
                                                                <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.designPressure:""}</b></div>
                                                            </div> */}



                                                <div class="row pt-3 mb-3 border-bottom">
                                                            <div class="col-md-6 mb-3">
                                                            <div className="unitDzine2">
                                                                <div> <strong>Design Pressure</strong> </div>
                                                                <div> <input type="text" class="form-control" value={inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.designPressure:""}  id="thicknessDesignPressure" aria-describedby="emailHelp" width="90px"/></div>
                                                           </div>
                                                            </div>

                                                            <div class="col-md-6 mb-3">
                                                            <div className="unitDzine2">
                                                                <div> <strong>MAWP</strong></div>
                                                                <div> <input type="text" class="form-control" value={inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.mawp:""} id="mawp" aria-describedby="emailHelp" width="90px"/></div>
                                                             </div>
                                                            </div></div>


                                                            <div class="row align-items-center justify-content-start mb-3  px-3 ">
                                                                <div class="col-md-12">1. Thickness test must be performed in numerical sequence without exception. </div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.thicknessTestWithoutException:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-3  px-3">
                                                                <div class="col-md-12">2. Thickness test must be performed at completion of internal visual inspection (when thickness testing is required)</div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.thicknessTestAtVisualInspection:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-3  px-3">
                                                                <div class="col-md-12">3. Abrated or corroded areas found internally or externally on cargo tank during thickness test must be identified (circle area) on drawing on
                                                                    reverse side and must be thoroughly thickness tested ONLY UPON COMPLETION OF SEQUENCED INSPECTION FUNCTION. </div>
                                                                    {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.sequencedInspectionFunction:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-2  px-3">
                                                                <div class="col-md-12">4. Bolsters, frames, rings and sub-frames must be thoroughly inspected. Any areas that show corrosion, rust or deterioration must        be thickness tested. Areas found to be less than minimum thickness must be reported in comment section noting location and current thickness. </div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.currentThickness:""}</b></div> */}
                                                            </div>

                                                            <div class="d-flex align-items-start mb-3">
                                                            <div class="col-md-12"> 5.<input type="checkbox" aria-label="Checkbox for following text input" name="corrosionallowance" value="1" class="mr-2 cb-ins" checked={inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.minimumCorrissionAllowence=='1'?"checked":"":""}  style={{position: "relative",top:"14px",left:"4px"}}/>Check box if tank must be thickness tested annually because last results are within .005" of minimum corrosion allowance.</div>
                                                                {/* <div class="col-md-3 "> {inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.minimumCorrissionAllowence=='1'?"Yes":"No":""}</div> */}
                                                            </div>



                                                        </td>



                                                       </tr>



                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                    </div>
                                </div>

                            <div class="divider2"></div>
                            <div class="form-footer-comon-wrap card">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                  {documentList.length>0?<h4 class="mb-4">Documents</h4>:""}
                                      <div class="row">
                                      {
                                                                                                documentList?documentList.map((data, index) => (
                                         <div class="col-md-12">
                                            <div class="d-flex align-items-center">

                                               <a onClick={() => documentDetails_(data.filePath,data.extension)} class="ml-2 w-100">
                                                 {/* <b>{data.title}.{data.extension}</b> */}
                                                 <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                 </a>
                                               {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                            </div>
                                         </div>


                                         )):""

                                       }
                                      </div>
                                   </div><br/>

                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"}/> */}
            </>
          )
        } else {
          return (
            <>
           <div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

         </>
          )
        }
      }
      })()}

                             </div>

                            <div class="divider2"></div>
                            {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                     <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}

                           </div>
                        </div>

                  </div>
                  </div>
            </div>



  </>
)};

CargoTankThicknessTestRead.propTypes = {};

CargoTankThicknessTestRead.defaultProps = {};

export default CargoTankThicknessTestRead;
