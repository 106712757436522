import React,{history,useEffect, useState, useMemo } from "react";
import vehicle_details_api from "../vehicle/vehicle_details_api";
import CheckAuthentication from "../../../utility/checkAuthentication";
import {useNavigate , useParams } from "react-router-dom";
const FleetAddInspection = () => {
      const { customerId,vehicleId } = useParams();
      const [vechileDetails,setVehicleDetails] = useState([]);
      const navigate = useNavigate();
      const [cid,setCustomerId] = useState([]);
      useEffect(() =>
      {
         if(CheckAuthentication)
         {
            setCustomerId(customerId);
            vehicle_details_api(vehicleId,setVehicleDetails,setCustomerId,"dontcallcontactsAPI");
         }else{
            sessionStorage.clear();
            localStorage.clear();
           //navigate("/login");
             window.location.href="/login";
         }

      },[]);
  return(
  <>
    <div class="main-panel">
                <div class="content-wrapper" style={{display: "block"}}>

                        <div class="row">
                            <div class="col-md-12 m-auto mb-2 ">
                                <h4 class="font-weight-bold my-4">Add Inspection</h4>
                                <div class="card mb-2">
                                    <div class="header-top-bg p-3 ">
                                        <h4>Vehicle Information</h4>
                                        <div class="info-labl">
                                            <img src="/images/icons/vehicle-icon.svg" alt=""/>|<span>{vechileDetails.lisencePlateNumber}</span>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                    <div class="row mb-2">
                                          <div class="col-lg-12 col-md-12 m-auto ">
                                                <div class="card p-3">
                                                <div class="banner-owner">
                                                      <div class="owner-logo">
                                                      <img  id="customerImage" />
                                                      <div class="owner-name">
                                                            <h4 >{vechileDetails.customerName?vechileDetails.customerName:""}</h4>
                                                            <span>DOT Number: </span> <span>{vechileDetails?vechileDetails.dotNumber:""}</span>
                                                      </div>
                                                      </div>
                                                      <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
                                                      <div class="row clearfix">
                                                            <div class="col-md-5">
                                                            <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                                  <img src="/images/icons/map-icon.svg" alt="" class="mr-2"/>
                                                                  <div style={{minWidth: "240px"}}>
                                                                  <span>{vechileDetails ? vechileDetails.addressLine1 : ""}{vechileDetails ? vechileDetails.addressLine1 ? "," : "" : ""}<span>
                                                                    &nbsp;</span>{vechileDetails ? vechileDetails.addressLine2 : ""}{vechileDetails ? vechileDetails.addressLine2 ? "," : "" : ""}<span>
                                                                    &nbsp;</span><br />{vechileDetails ? vechileDetails.city : ""}{vechileDetails ? vechileDetails.city ? "," :"" : ""}<span> <span>
                                                                    {vechileDetails ? vechileDetails.stateName : ""}{vechileDetails ? vechileDetails.stateName ? "," :"" : ""}</span>
                                                                    &nbsp;</span>{vechileDetails ? vechileDetails.zip : ""}{vechileDetails ? vechileDetails.zip ? "." : "" : ""}</span>
                                                                  </div>
                                                            </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                            <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                                  <img src="/images/icons/phone-icon.svg" alt="" class="mr-2"/>
                                                                  <span>{vechileDetails.customerNumber?vechileDetails.customerNumber:"N/A"}</span>
                                                            </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                            <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                                  <img src="/images/icons/message-icon.svg" alt="" class="mr-2"/>
                                                                  <span>{vechileDetails.customerEmail?vechileDetails.customerEmail:"N/A"}</span>
                                                            </div>
                                                            </div>
                                                      </div>
                                                      </div>
                                                </div>
                                                </div>
                                          </div>
                                          </div>
                                    </div>
                                </div>

                                <div class="card mb-2">
                                    <div class="card-body pt-0 pb-2 p-3">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{width: "30%"}} class="p-2">Inspection</th>
                                                    <th style={{width: "20%"}} class="p-2">Inspection Spec</th>
                                                    <th style={{width: "20%"}} class="p-2">Created Date</th>
                                                    <th style={{width: "20%"}} class="p-2">Expiry Date</th>
                                                    <th style={{width: "10%"}} class="p-2">Options</th>
                                                </tr>
                                                <tr>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="Inspection title 1"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <select class="form-control custome-select">
                                                            <option>Select Licence</option>
                                                            <option>Licence One</option>
                                                            <option>Licence Two</option>
                                                            <option>Licence Three</option>
                                                        </select>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="12-08-2021"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="12-08-2021"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0 d-flex">
                                                            <img src="/images/icons/pdf-icon.svg" class="pr-2"/>
                                                            <a href="#" class="mr-2"><img src="/images/icons/remove-minus.svg" alt="upload"/></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="Inspection title 1"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <select class="form-control custome-select">
                                                            <option>Select Licence</option>
                                                            <option>Licence One</option>
                                                            <option>Licence Two</option>
                                                            <option>Licence Three</option>
                                                        </select>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="12-08-2021"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0">
                                                            <input type="email" class="form-control custome-input" placeholder="12-08-2021"/>
                                                        </div>
                                                    </td>
                                                    <td class="p-2">
                                                        <div class="form-group m-0 d-flex">
                                                            <a href="#" class="mr-2"><img src="/images/icons/upload.svg" alt=""/></a>
                                                            <a href="#"><img src="/images/icons/add-plus.svg" alt="upload"/></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="p-3">
                                        <a href="vehicle-information.html" class="btn btn-primary mw-120 mr-2">SAVE</a>
                                        <a href="vehicle-information.html" class="btn gray-mid-color mw-120">CANCEL</a>
                                    </div>
                                </div>
                            </div>
                        </div>







                </div>
            </div>
  </>
)};

FleetAddInspection.propTypes = {};

FleetAddInspection.defaultProps = {};

export default FleetAddInspection;
