import { CompareArrowsOutlined } from "@material-ui/icons";
import services from "../services/services";
import { requestObject } from "./requestObject";
import { itemperPagetoDisplay } from "../modules/constant";
import $ from "jquery";
const employeeList=(securityRoleName,pageNumber,pageSize,ITEMS_PER_PAGE,sortBy,sortDir,setdispathcerlist,setdisplayPage,displayItems,setCurrentPage,setTotalRecord,setTotalItems,settotalPages,searchTxt,activestatus)=>
{

     var request= 
     {

            
      
            "securityRoleName":securityRoleName,
      
            "pageNumber":pageNumber,

            "firstName":searchTxt,

            "pageSize":pageSize,
      
            "sortBy":sortBy,
      
            "sortDir":sortDir,
            "isActive":activestatus,
  
      }
      var requestOptions=requestObject.RequestHeader(request);
      services.listStaff(requestOptions).then((res)=>
      // services.listUsersByRole(requestOptions).then((res)=>
      {
            $("#loading").hide();
            setdispathcerlist(res.rows);
            var displaypages=[];
            setTotalItems(res.customValues.totalNumberOfRecords);
            
            settotalPages(res.customValues.totalNumberOfRecords);
           
            setCurrentPage(res.customValues.currentPageNumber);
            if(res.rows.length>0)
            {
               
                  setdispathcerlist(res.rows);
            }else{

              
                  setdispathcerlist("");
            }
         
            setTotalRecord(res.customValues.totalNumberOfRecords);
            if(displayItems=="displayItems")
            {
                  
                    for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/itemperPagetoDisplay));i++)
                    {
                  //  alert(i*ITEMS_PER_PAGE);
                      displaypages.push(i*itemperPagetoDisplay);
                    }
                    
                    setdisplayPage(res.rows.length>0?displaypages:[0]);
            
            }

      });

}

export default employeeList;